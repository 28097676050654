import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface PoBuyerMultipleModalConfig {
    title: string,
    data:any;
    count?:any,
    type?: string
}

export enum PoBuyerMultipleDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface PoBuyerMultipleModalEvent {
    type: PoBuyerMultipleDialogueEvent,
    params?: any,
    note?: any
}

@Injectable({ providedIn: 'root' })

export class PoBuyerMultipleService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<PoBuyerMultipleModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public noteText = "";

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === PoBuyerMultipleDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === PoBuyerMultipleDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === PoBuyerMultipleDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === PoBuyerMultipleDialogueEvent.OnHidden));
  public onClosingPoBuyerMultipleRemove$ = this.event$.pipe(filter((x) => x.type === PoBuyerMultipleDialogueEvent.OnClosingRemove));
  public onClosingPoBuyerMultipleRemoved$ = this.event$.pipe(filter((x) => x.type === PoBuyerMultipleDialogueEvent.OnClosingRemoved));

  show(config: PoBuyerMultipleModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: PoBuyerMultipleModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingPoByerMultiple(params?: any, note?: any) {
    this.event$.next({
      type: PoBuyerMultipleDialogueEvent.OnClosingRemove,
      params: params,
      note: note
    });
  }

  setClosingPoByerMultipleRemoved() {
    this.event$.next({
      type: PoBuyerMultipleDialogueEvent.OnClosingRemoved
    });
  }
}