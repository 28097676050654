// Duplicate interface `Dropdown` at Booking library
export interface Dropdown {
    display: string,
    value: string
}

/**
 * Return a nested dropdown objects with default values
 * 
 * @param arr: an array of type `Dropdown`
 * @param display: First option which act as placeholder, value is equal to ''
 * @returns 
 */
export function addSelectOneIfMutiple(arr: Dropdown[], display: string): Dropdown[]{
    return arr.length == 1 ? arr : [{ value: '', display }].concat(arr);
  }