<div class="card">
    <div class="card-header bg-transparent border-dark border-1 mx-5 px-0 pt-4">
        <div class="d-flex align-items-center justify-content-between">
            <h2 *ngIf="featurePermissions?.showDemurrageColumns && !featurePermissions?.showDetentionColumns" class="mb-0">Demurrage</h2>
            <h2 *ngIf="!featurePermissions?.showDemurrageColumns && featurePermissions?.showDetentionColumns" class="mb-0">Detention</h2>
            <h2 *ngIf="featurePermissions?.showDemurrageColumns && featurePermissions?.showDetentionColumns" class="mb-0">Demurrage & Detention</h2>
            <div><a href="javascript:void(0);" (click)="viewMore()" data-bs-toggle="modal" *ngIf="demurrageData || detentionData">View More</a></div>
        </div>
    </div>
    <ng-container *ngIf="demurrage.length; else noData">
        <div class="card-body px-5 pb-4">
            <div *ngIf="demurrageData?.chargeType == 'Demurrage' && featurePermissions?.showDemurrageColumns">
                <div class="d-flex justify-content-between align-items-center">
                    <h5>Demurrage Time Left ({{demurrageData?.daysLeft}} {{days(demurrageData?.daysLeft)}})</h5>
                    <span *ngIf="demurrageData?.isCompleted" class="material-icons mi-24 material-icons-outlined" data-bs-toggle="tooltip" data-bs-title="Empty Returned">timer_off</span>
                </div>
                <div *ngIf="yellowBar(demurrageData?.daysLeft)" class="progress" role="progressbar" aria-label="Warning example"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning text-dark"
                    attr.aria-valuenow="{{demurrageData?.daysLeft}}" aria-valuemin="0"
                    style="width: {{ demurrageData?.daysLeft * 100 / demurrageData?.totalDays }}%" [attr.aria-valuemax]="demurrageData?.totalDays"
                    >{{demurrageData?.daysLeft}} {{days(demurrageData?.daysLeft)}}</div>
                </div>
                <div *ngIf="blueBar(demurrageData?.daysLeft)" class="progress" role="progressbar" aria-label="Warning example"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-success" attr.aria-valuenow="{{demurrageData?.daysLeft}}" aria-valuemin="0"
                    style="width: {{ demurrageData?.daysLeft * 100 / demurrageData?.totalDays }}%" [attr.aria-valuemax]="demurrageData?.totalDays"
                    >{{demurrageData?.daysLeft}} {{days(demurrageData?.daysLeft)}}</div>
                </div>
                <div *ngIf="redBar(demurrageData?.daysLeft)" class="progress" role="progressbar" aria-label="Warning example"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger text-dark"
                        style="width: 100%">{{demurrageData?.daysLeft}} {{days(demurrageData?.daysLeft)}}</div>
                </div>
               <span *ngIf="demurrageData?.chargeTotal != 0">Current Estimated Charges: ${{demurrageData?.chargeTotal}}</span>
            </div>

            <div *ngIf="detentionData?.chargeType == 'Detention' && featurePermissions?.showDetentionColumns">
                <div class="d-flex justify-content-between align-items-center" [ngClass]="demurrageData?.chargeType == 'Demurrage' ? 'mt-3':''">
                    <h5>Detention Time Left ({{detentionData?.daysLeft}} {{days(detentionData?.daysLeft)}})</h5>
                    <span *ngIf="detentionData?.isCompleted" class="material-icons mi-24 material-icons-outlined" data-bs-toggle="tooltip" data-bs-title="Empty Returned">timer_off</span>
                </div>
                <div *ngIf="yellowBar(detentionData?.daysLeft)" class="progress" role="progressbar" aria-label="Warning example"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning text-dark"
                    attr.aria-valuenow="{{detentionData?.daysLeft}}" aria-valuemin="0"
                    style="width: {{ detentionData?.daysLeft * 100 / detentionData?.totalDays }}%" [attr.aria-valuemax]="detentionData?.totalDays"
                        >{{detentionData?.daysLeft}} {{days(detentionData?.daysLeft)}}</div>
                </div>
                <div *ngIf="blueBar(detentionData?.daysLeft)" class="progress" role="progressbar" aria-label="Warning example"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-success" attr.aria-valuenow="{{detentionData?.daysLeft}}" aria-valuemin="0"
                    style="width: {{ detentionData?.daysLeft * 100 / detentionData?.totalDays }}%" [attr.aria-valuemax]="detentionData?.totalDays"
                    >{{detentionData?.daysLeft}} {{days(detentionData?.daysLeft)}}</div>
                </div>
                <div *ngIf="redBar(detentionData?.daysLeft)" class="progress" role="progressbar" aria-label="Warning example"
                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger text-dark"
                        style="width: 100%">{{detentionData?.daysLeft}} {{days(detentionData?.daysLeft)}}</div>
                </div>
                <span *ngIf="detentionData?.chargeTotal != 0">Current Estimated Charges: ${{detentionData?.chargeTotal}}</span>
            </div>
        </div>
    </ng-container>
    <ng-template #noData>
        <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
            <p><span class="material-icons mi-64 text-purple"><span
                        class="material-icons-outlined">cloud_off</span></span>
            </p>
            <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
        </div>
    </ng-template>
</div>

<cds-ui-demurrage-detention-modal></cds-ui-demurrage-detention-modal>