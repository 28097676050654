import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GetFilteredPOsGQL,
         PurchaseOrder, 
         GetCompaniesGQL, GetVendorsDropdownListGQL, Company, CompanyVendor, GetOpenPurchaseOrdersGQL, PurchaseOrderItem } from '@cds-ui/data-access';
import { filterNullish, groupByMap } from '@cds-ui/shared/core-rx';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderApiService {
    constructor(private http: HttpClient, private apollo: Apollo) { }

    public getOpenPurchaseOrders(vendorCode: string) {
        return new GetOpenPurchaseOrdersGQL(this.apollo).fetch({}, {fetchPolicy: 'no-cache'})
            .pipe(
                map(x => x.data.purchaseOrderItemSummaries?.nodes),
                filterNullish(),
                map(x => x.filter(i => i.vendorCode == vendorCode )),
                groupByMap(x => x.poNumber,
                    ([first, grouped]) => ({
                        companyCode: first.companyCode,
                        vendorCode: first.vendorCode,
                        poNumber: first.poNumber,
                        items: grouped.map(x => ({
                            poNumber: x.poNumber,
                            itemNumber: x.itemNumber,
                            companyCode: x.companyCode,
                            unitPrice: x.itemCost,
                            units: x.unitsOrdered,
                            color: x.color,
                            size: x.size,
                            status: x.itemStatus
                        } as PurchaseOrderItem))
                    } as PurchaseOrder)
                ),
            );
    }

    public getPurchaseOrder(list: { poNumber: string, companyCode: string }[]) {
        return new GetFilteredPOsGQL(this.apollo).fetch({ poKeys: list }, {fetchPolicy: 'no-cache'})
            .pipe(
                map(x => x.data.purchaseOrders as PurchaseOrder[]),
            );
    }

    public getCompanies() {
        return new GetCompaniesGQL(this.apollo).fetch({}).pipe(
            map(x => x.data.companies as Company[]),
            map(x => x.filter(company => company.isActive))
        );
    }

    public getVendors(companyCode: string) {
        return new GetVendorsDropdownListGQL(this.apollo).fetch({ companyCode: companyCode }).pipe(
            map(x => x.data.company.vendors as CompanyVendor[]),
        );

    }
} 