import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, catchError, throwError, takeUntil, filter, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

export interface PrintSaModalConfig {
  title: string,
  data: any
}
@Injectable({ providedIn: 'root' })
export class PrintSaModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public  afterPrint$ = new BehaviorSubject<any>(null);
  public  afterPrintDataSource$ = this.afterPrint$.asObservable();
  
  headers = new HttpHeaders({ Accept: 'application/pdf' });
  options = {
    headers: this.headers,
    responseType: 'blob' as 'json'
  };
  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  constructor(private http: HttpClient, private toastrService: ToastrService) {

  }
  show(config: PrintSaModalConfig): void {
    this.showSubject$.next(config);
  }

  public printData(data: any): Observable<any> {
    return this.http
    .post('internal/shipmentReport/PrintAdvice', data, this.options)
    .pipe(
      catchError((error: HttpErrorResponse) => {        
        return throwError(() => error);
      })
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
