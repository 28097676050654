import { GridReadyEvent } from '@ag-grid-community/core';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, combineLatestWith, map, shareReplay, startWith, takeUntil, tap, withLatestFrom } from 'rxjs';
import { select } from '@ngneat/elf';
import { ShippingViewContainersGridModalService } from './shipping-view-containers-grid-modal.service';
import { columns } from './shipping-view-containers-grid-modal.column';

@Component({
  selector: 'cds-ui-shipping-view-containers-grid-modal',
  templateUrl: './shipping-view-containers-grid-modal.component.html'
})
export class ShippingViewContainersGridModalComponent implements OnInit, OnDestroy {
  private readonly destroy$$ = new Subject<void>();
  private readonly gridLoaded$$ = new Subject<GridReadyEvent>();
  private readonly modalRef$$ = new Subject<BsModalRef>();
  private readonly config$$ = new Subject<{ title: string }>();
  private readonly shippingViewContainerModalDetails$$ = new Subject<any[]>();

  public isLoading$ = this.appStateRepository.isLoading$;
  public title$ = this.config$$.pipe(map(x => x.title), startWith("Containers - More Details"));
  public data$ = this.shippingViewContainerModalDetails$$.pipe(map(x => x.flatMap(r => ({...x ,...r}))),tap(x=>console.log("tetsing 2",x)), shareReplay(1));

  @Input() set shippingViewContainerModalDetails$(stream: Observable<any>) {
    stream.pipe(
      takeUntil(this.destroy$$),
      combineLatestWith([this.gridLoaded$$]),
      tap(([x, _]) => this.shippingViewContainerModalDetails$$.next(x))
    )
    .subscribe();
  }

  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private shippingViewContainersGridModalService: ShippingViewContainersGridModalService,
    private appStateRepository: AppStateRepository
  ) {}
  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  defaultColDef = {
    suppressMenu: false,
    suppressMovable: false,
    enableRowGroup: true,
  
    resizable: true,   
      
  };

  public gridClass = this.appStateRepository.theme$.pipe(select((x) => x.grid));
  public defaultColumns: any = columns

  onGridReady(event: GridReadyEvent) {
    this.gridLoaded$$.next(event);
    this.gridLoaded$$.complete();
  }

  onHide() {
    this.shippingViewContainersGridModalService.hide();
  }

  ngOnInit(): void {
    this.subscribeModalShow();
    this.subscribeModalHide();
    this.subscribeGridReady();
  }  

  subscribeGridReady() {
    this.gridLoaded$$.pipe(
      takeUntil(this.destroy$$),
      tap(e => {
        const api = e.columnApi;
        const allColumnIds = api.getColumns()?.map((response) => response.getColId());
        api.autoSizeColumns(allColumnIds ?? [], false);
      })
    )
    .subscribe();
  }

  subscribeModalShow() {
    this.shippingViewContainersGridModalService.onShow$.pipe(
      takeUntil(this.destroy$$),
      tap(x => this.config$$.next(x)),
      map(_ => this.modalservice.show(
        this.template,
        {
          backdrop: false,
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-xl modal-dialog-centered'
        }
      )),
      tap(x => this.modalRef$$.next(x))
    )
    .subscribe();
  }

  subscribeModalHide() {
    this.shippingViewContainersGridModalService.onHide$.pipe(
      takeUntil(this.destroy$$),
      withLatestFrom(this.modalRef$$),
      tap(([_, ref]) => ref.hide())
    )
    .subscribe();
  }

}

