import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string | Observable<string>): Observable<string> | string {
    if (typeof value === 'string') {
      return this.convertToTitleCase(value);
    } else if (value instanceof Observable) {
      return value.pipe(map((data) => this.convertToTitleCase(data)));
    } 
    return '';
  }

  private convertToTitleCase(value: string): string {
    if (!value) return '';
    if (value == '--') return value;
    return value
      .toLowerCase()
      .replace(/(?:^|\s|-|_)\w/g, match => match.toUpperCase());
  }
}