import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellEditorParams } from "@ag-grid-community/core";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { AppStateRepository } from "@cds-ui/shared/core-state";
import { ToastrService } from "ngx-toastr";

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
@Component({
  selector: 'counter-decline-editor-component',
  templateUrl: './counter-decline-editor-component.html',
  styleUrls: ['./counter-decline-editor-component.scss'] 

})
export class counterDeclineEditorComponent implements OnInit, ICellRendererAngularComp {
  value!: any;
  params: any;
  datePickerConfig:any;
  public position: boolean = true;
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  public isDecimalAllowed = false;
  fieldType:string = '';
  changeLevel!:string;
  dropDownValue:any;
  companyCode:any;
  constructor(private appStateRepository:AppStateRepository, private toastrService:ToastrService) {
    
  }
  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;
  
  agInit(params: any): void {
    this.params = params;
    this.value = this.params?.value;
    this.isDecimalAllowed = false;
    this.changeLevel = params?.changeLevel;
    this.fieldType = params?.isType;
    this.dropDownValue = params?.dropData;
    if(params?.isType == 'Change Price' || params?.isType == 'Change Retail Price'){
      this.isDecimalAllowed = true;
     }
    this.setInitialState(this.params);
    const inputKeysValues = '1234567890';
    const inputKeysValuesWithDecimal = this.isDecimalAllowed ? inputKeysValues.concat('.') : inputKeysValues;
    this.cancelBeforeStart = !!(
      params.charPress && inputKeysValuesWithDecimal.indexOf(params.charPress) < 0
    );
    
  }
  getValue(): any {
    if (this.params.data.fieldChanged != "Change Quantity") {
      return this.value;
    } else {
      if (this.companyCode) {
        if (Number(this.value) % Number(this.params.data.casePack) !== 0) {
          this.toastrService.error(`Please enter a Quantity divisible by the Case Pack quantity ${this.params.data.casePack}.`);
          return null;
        } else {
          return this.value;
        }
      }else{
        return this.value;
      }
    }
  }
  isPopUp(): boolean {
    return false;
  }
  refresh(params: any): boolean {
    return true;
  }
  ngOnInit(): void {
    this.appStateRepository.availableCompanies$.subscribe((resp) => {
      this.companyCode = resp.some(c => c.companyCode === '110') ? true : false;
     })
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue',adaptivePosition: true, isAnimated: true });
    this.setInitialState(this.params);
  }
  
  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE) {
      startValue = '';
    } else if (params.charPress) {
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      startValue = params.value;
      if (params.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }
  onKeyDown(event: any): void {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (
      !this.finishedEditingPressed(event)&&
      !this.isCharNumeric(event)    
    ) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
      if (this.highlightAllOnFocus) {
        this.input.element.nativeElement.select();

        this.highlightAllOnFocus = false;
      } else {
        const length = this.input.element.nativeElement.value
          ? this.input.element.nativeElement.value.length
          : 0;
        if (length > 0) {
          this.input.element.nativeElement.setSelectionRange(length, length);
        }
      }
      this.input.element.nativeElement.focus();
    });
  }

  private isCharNumeric(charStr: any): boolean {
    if (this.isDecimalAllowed) {
      let current: string = charStr.target.value;
      let next: string = current.concat(charStr.key)
      return next as any && /^\d*\.?\d{0,3}$/.test(next as any);
    }
    else {
      return !!/\d/.test(charStr.key);
    }
  }

  private deleteOrBackspace(event: any) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
  onClicked(): void {
    this.params.api.startEditingCell({
      rowIndex: this.params?.rowIndex,
      colKey: this.params?.column?.colId
    });

  }

  clearDate() {
    this.value = null;
  }
}