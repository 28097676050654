import { Injectable } from "@angular/core";
import { PageInfo, PurchaseOrder } from "@cds-ui/data-access";
import { deleteEntities, selectActiveIds, getEntitiesIds, upsertEntities, resetActiveIds, getActiveIds, setActiveIds, selectActiveEntities, selectAllEntities, UIEntitiesRef, getActiveEntities, getAllEntities } from "@ngneat/elf-entities";
import { Observable, share, tap } from "rxjs";
import { PurchaseOrderApiService } from "./purchase-order.api";
import { store } from "./purchase-order.store";

@Injectable({ providedIn: 'any' })
export class PurchaseOrderRepository {
    ActivePurchaseOrderNo$ = store.pipe(selectActiveIds());
    ActivePurchaseOrder$ = store.pipe(selectActiveEntities());
    PurchaseOrder$ = store.pipe(selectAllEntities());

    constructor(private apiService: PurchaseOrderApiService) { }

    public setActvePurchaseOrder(poNos: string[]) {
        store.update(resetActiveIds(), setActiveIds([...poNos]));
        return this;
    }

    public concatActivePurchaseOrder(poNos: string[]) {
        const active = store.query(getActiveIds) ?? [];
        const updated = [...new Set(active.concat(poNos))];
        const allPos = store.query(getEntitiesIds());
        const validPos = updated.filter(x => allPos.includes(x));
        return this.setActvePurchaseOrder(validPos);
    }

    public deactivePurchaseOrder(poNos: string[]) {
        const active = store.query(getActiveIds) ?? [];
        const filtered = active.filter(po => !poNos.includes(po));
        return this.setActvePurchaseOrder(filtered);
    }

    public filterInvalidPurchaseOrder(poNos: string[]) {
        const OpenPurchaseOrders = store.query(getEntitiesIds());
        return poNos.filter(x => !OpenPurchaseOrders.includes(x));
    }

    public loadPurchaseOrder(companyCode: string | null = null) {
        const po = store.query(getActiveEntities()).find(_ => true);
        const comp = po?.companyCode ?? companyCode;
        if (!comp) throw "No companyCode not defined";

        const poNos = store.query(getActiveIds);
        const filtered = poNos.filter(x => !store.query(getEntitiesIds()).includes(x));
        const list = filtered.map(poNo => ({ poNumber: poNo, companyCode: comp }));

        this.apiService
            .getPurchaseOrder(list)
            .subscribe(po => {
                store.update(upsertEntities(po));
            });
    }

    public listOpenPo(vendorCode: string): Observable<PurchaseOrder[]> {

        const stream = this.apiService.getOpenPurchaseOrders(vendorCode);
        stream.subscribe(x => store.update(upsertEntities(x)));

        return stream;
    }

    public clearPoStore() {
        const key = store.query(getActiveIds) ?? "";

        return store.update(
            resetActiveIds(),
            deleteEntities(key),
            deleteEntities(key, { ref: UIEntitiesRef })
        );
    }

    public loadVendors(companyCode: string) {
        const stream = this.apiService.getVendors(companyCode)
        return stream;
    }

    public loadCompanies() {
        const stream = this.apiService.getCompanies()
        return stream;
        ;
    }

    getActivePurchaseOrder(){
        return store.query(getAllEntities()) ?? []
    }

    getSelectedPurchaseOrderNumber() : string[]{
        return store.query(getActiveIds) ?? []
    }

    /**
     * helper function to help upper level upsert entities directly
     * 
     * @param po 
     */
    upsertEntities(po: PurchaseOrder[]){
        store.update(upsertEntities(po))
    }

    /**
     * Filter out any selected po number to render the list for add-remove-booking-item-grid
     * 
     * @param po 
     */
    filterSelectedPo(po: PurchaseOrder[]){
        const activeIds = store.query(getActiveIds) ?? [];
        const filtered = po.filter(x => activeIds.indexOf(x.poNumber) === -1)

        return filtered;
    }

}