import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { ReviewDialogueEvent, ReviewEventModalConfig, ReviewModalService } from './review-modal.service';
// import { PageRemoveShippingMarksDialogueEvent, PageRemoveShippingMarksEventModalConfig, PageRemoveShippingMarksModalService,  } from './page-remove-shipping-marks-modal.service';

@Component({
  selector: 'cds-ui-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent implements OnInit {
  
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: ReviewEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private reviewModalService: ReviewModalService,
    private appStateRepository: AppStateRepository
  ) {}

  ngOnInit(): void {}  

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.reviewModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as ReviewEventModalConfig;
        
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      ReviewDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      ReviewDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      ReviewDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      ReviewDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: ReviewDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.reviewModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onReviewClick() {
    this.reviewModalService.setClosingReview(this.config);
  }

  private subscribeOverwrittenExisting() {
    this.reviewModalService.onClosingReviewRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}
