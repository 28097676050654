import { Component, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditTerminalDialogueEvent, EditTerminalEventModalConfig, EditTerminalModalService, TerminalSearchResult } from './edit-terminal.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppStateRepository } from '@cds-ui/shared/core-state';

@Component({
  selector: 'cds-ui-edit-terminal',
  templateUrl: './edit-terminal.component.html',
  styleUrls: ['./edit-terminal.component.scss'],
})
export class EditTerminalComponent {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: EditTerminalEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  public form = new FormGroup({
    editTerminal: new FormControl('',Validators.required)
  });
  public noResult = false;
  public placeholderText = 'Choose Terminal';
  public search!: string;
  public terminalId!: number;
  suggestionData:TerminalSearchResult[] = [];
  public suggestions$!: Observable<TerminalSearchResult[]>;
  onLoad:boolean = false;

  constructor(
    private modalservice: BsModalService,
    private EditTerminalModalService: EditTerminalModalService,
    private appStateRepository: AppStateRepository,
  ) {}

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.EditTerminalModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.form.reset();
        this.config = error as EditTerminalEventModalConfig;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(this.modalservice.onShow,EditTerminalDialogueEvent.OnShow);
    this.handler(this.modalservice.onShown,EditTerminalDialogueEvent.OnShown);
    this.handler(this.modalservice.onHide,EditTerminalDialogueEvent.OnHide);
    this.handler(this.modalservice.onHidden,EditTerminalDialogueEvent.OnHidden);
  }

  handler(handler: EventEmitter<any>,type: EditTerminalDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.EditTerminalModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  close(){
    this.modalRef.hide();
    this.form.reset();
    this.onLoad = false;
    this.suggestions$ = new Observable<[]>();
  }

  private subscribeOverwrittenExisting() {
    this.EditTerminalModalService.onClosingEditTerminalRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val:any) => this.modalRef.hide());
  }
  
  searchQuery(e: string) {
    this.search = e;
    if (this.search.length > 2) {
      this.onLoad = true;
      this.suggestions$ = this.EditTerminalModalService.getTerminalSearchValueData(this.search)
      .pipe(tap(x=> this.onLoad = false));
      const newSuggestionData = this.suggestions$;
        newSuggestionData.subscribe((resp) => {
          this.suggestionData = resp;
        })
    }else{
      this.suggestions$ = new Observable<[]>();
      this.onLoad = false
    }
  }

  selectMenuItem(e: TerminalSearchResult) {
    this.search = e.terminalName;
    this.terminalId = e.terminalId;
    this.suggestions$ = new Observable<[]>();
  }

  save() {
    let payLoad = {}
    this.suggestionData.forEach((obj) => {
      if(obj.terminalId === this.terminalId){
        payLoad = {
          terminalId: obj.terminalId,
          locationCode:obj.locationCode,
          locationKey:obj.locationKey
        }
      }
    })
    this.form.reset();
    this.EditTerminalModalService.setClosingEditTerminal(payLoad);
  }
}
