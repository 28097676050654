<div class="card-header bg-transparent border-dark border-1 mx-5 px-0 pt-4 d-flex justify-content-between">
  <h2 *ngIf="gridTitle" class="mb-0">{{gridTitle}}</h2>
  <div class="d-flex gap-2">
    <input *ngIf="gridHeaderActionButtonsToShow.quickFilter" type="text" class="quickfilter-input"
      placeholder="Quick Filter..." (input)="filterGridDataMethod($event)">
    <ng-container *ngIf="featurePermissionBtn?.allowEdit">
      <a *ngIf="gridHeaderActionButtonsToShow.save" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Save Changes">
        <button type="button" alt="Edit Data" class="btn btn-outline-primary text-light d-flex"
          [disabled]="saveBtn" (click)="saveDataMethod()">
          <span _ngcontent-dmy-c157="" class="material-icons mi-28"> save </span>
        </button>
      </a>
    </ng-container>
    <ng-container *ngIf="featurePermissionBtn?.allowEdit">
      <a *ngIf="gridHeaderActionButtonsToShow.add" data-bs-toggle="tooltip" data-bs-placement="top" title="Add row">
        <button type="button" alt="Edit Data"
          class="btn btn-outline-primary text-light d-flex" (click)="addRowMethod()">
          <span _ngcontent-dmy-c157="" class="material-icons mi-28"> add_circle_outline </span>
        </button>
      </a>
    </ng-container>
    <ng-container *ngIf="featurePermissionBtn?.allowEdit">
      <a *ngIf="gridHeaderActionButtonsToShow.delete" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
        <button [disabled]="!isRowSelected" type="button" alt="Delete Data"
          class="btn btn-outline-primary text-light d-flex" (click)="onDeleteClickMethod()">
          <span _ngcontent-dmy-c157="" class="material-icons mi-28"> delete_outline </span>
        </button>
      </a>
    </ng-container>
    <ng-container *ngIf="featurePermissionBtn?.allowSplitLine">
      <a *ngIf="gridHeaderActionButtonsToShow.splitLine" data-bs-toggle="tooltip" data-bs-placement="top" title="Split Line">
        <button (click)="onSplitLineClickMethod()" type="button" class="btn btn-outline-primary text-light d-flex" alt="Split Line">
          <span class="material-icons mi-24"> call_split </span>
        </button>
      </a>
    </ng-container>
    <a *ngIf="gridHeaderActionButtonsToShow.restore" data-bs-toggle="tooltip" data-bs-placement="top"
      title="Reset to Default">
      <button type="button" alt="Reset to Default" class="btn btn-outline-primary text-light d-flex"
        (click)="onRefreshClickMethod()">
        <span _ngcontent-dmy-c157="" class="material-icons mi-28"> settings_backup_restore </span>
      </button>
    </a>
    <a *ngIf="gridHeaderActionButtonsToShow.fullScreen" data-bs-toggle="tooltip" data-bs-placement="top" title="Expand">
      <button type="button" (click)="expandGridMethod()" class="btn btn-outline-primary text-light d-flex" id="expander"
        alt="Expand">
        <span class="material-icons mi-28"> fullscreen </span>
      </button>
    </a>
    <span class="d-flex" style="width:3.6rem">
      <span class="material-icons mi-28"></span>
    </span>
  </div>
</div>
<!-- <div *ngIf="gridHeaderActionButtonsToShow.quickFilter" class="d-flex mx-5 py-2 flex-row-reverse">
  <input type="text" class="quickfilter-input" placeholder="Quick Filter..." (input)="filterGridDataMethod($event)" >
</div> -->
