import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter, BehaviorSubject } from 'rxjs';

export interface PocChangeRequestEventModalConfig {
    title: string,
    message?: string,
    data1:any;
    data2:any;
    data3:any;
    count?:any,
    btnOne:string,
    btnTwo:string
}

export enum PocChangeRequestDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface PocChangeRequestModalEvent {
    type: PocChangeRequestDialogueEvent,
    data?: any
}

@Injectable({ providedIn: 'root' })

export class PocChangeRequestModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<PocChangeRequestModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public noteText = "";
  public refreshAfterModalClose$$ = new BehaviorSubject(false);
  public refreshAfterModalClose$ = this.refreshAfterModalClose$$.asObservable();
  public refreshHistoryClose$$ = new BehaviorSubject(false);
  public refreshHistoryClose$ = this.refreshHistoryClose$$.asObservable();
  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === PocChangeRequestDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === PocChangeRequestDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === PocChangeRequestDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === PocChangeRequestDialogueEvent.OnHidden));
  public onClosingPocChangeRequestRemove$ = this.event$.pipe(filter((x) => x.type === PocChangeRequestDialogueEvent.OnClosingRemove));
  public onClosingPocChangeRequestRemoved$ = this.event$.pipe(filter((x) => x.type === PocChangeRequestDialogueEvent.OnClosingRemoved));

  show(config: PocChangeRequestEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: PocChangeRequestModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingPocChangeRequest(data:any) {
    this.event$.next({
      type: PocChangeRequestDialogueEvent.OnClosingRemove,
      data: data
    });
  }

  setClosingPocChangeRequestRemoved() {
    this.event$.next({
      type: PocChangeRequestDialogueEvent.OnClosingRemoved
    });
  }
}