import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { PocApproveDialogueEvent, PocApproveEventModalConfig, PocApproveModalService } from './poc-approve-modal.service';

@Component({
  selector: 'cds-ui-poc-approve-modal',
  templateUrl: './poc-approve-modal.component.html',
  styleUrls: ['./poc-approve-modal.component.scss'],
})
export class PocApproveModalComponent {
  
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: PocApproveEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  PocApproveValue = "";
  public PocApproveForm = new FormGroup({
    PocApproveField: new FormControl('', Validators.required)
  });
  constructor(
    private modalservice: BsModalService,
    private PocApproveModalService: PocApproveModalService,
    private appStateRepository: AppStateRepository
  ) {}


  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.PocApproveModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.PocApproveValue = "";
        this.config = error as PocApproveEventModalConfig;
        
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      PocApproveDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      PocApproveDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      PocApproveDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      PocApproveDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: PocApproveDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.PocApproveModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  initiatedSelection(event:any){
    this.PocApproveValue = event.target.value;
  }
  close(){
    this.modalRef.hide()
    this.PocApproveValue = "";
  }

  onPocApproveClick(note: any) {
    this.PocApproveModalService.setClosingPocApprove(this.PocApproveValue, note);
  }

  private subscribeOverwrittenExisting() {
    this.PocApproveModalService.onClosingPocApproveRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}
