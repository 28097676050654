
<ng-template #template>
    <div class="modal-header mb-0 pb-3 bg-gray-800">
      <h2 class="modal-title">{{config.title}}</h2>
      <button type="button" class="btn btn-cancel" data-bs-dismiss="modal" aria-label="Close" (click)="onCloseManageDashboardClick()">Close</button>
  </div>
    <div class="modal-body bg-gray-700" *featurePermission="listOfPermissions; callback: permssionKeys$$;">
      <nav class="mb-3">
        <div class="nav nav-pills" id="nav-tab" role="tablist">
          <button class="nav-link active" id="nav-tally-tab" data-bs-toggle="tab" data-bs-target="#nav-tally" type="button" role="tab" aria-controls="nav-tally" aria-selected="true">Tally Cards</button>
          <button class="nav-link" id="nav-panels-tab" data-bs-toggle="tab" data-bs-target="#nav-panels" type="button" role="tab" aria-controls="nav-panels" aria-selected="false" (click)="openCharts()">Panels</button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-tally" role="tabpanel" aria-labelledby="nav-tally-tab" tabindex="0">
          <div class="alert alert-warning" role="alert" *ngIf="staticsCards.length == 5">5/5 tally cards used. Please remove one of the 5 tally cards in use and replace it with the new one.</div>
          <div id="manage-tally-cards" class="row" *ngIf="getContainer().length">
          <h3>Container</h3>
                  <div class="col-2 mb-3" *ngFor="let data of getContainer()">
                      <div class="tracker-card card">
                          <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                              <h1 class="m-0 font-monospace">00</h1>
                          </div>
                          <div class="card-body">
                              <h4>{{data.attributes.label}}</h4>
                              <p class="fs-md m-0">{{data.attributes.description}}</p>
                          </div>
                      </div>
                      <div class="text-center mt-2">
                          <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                          <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                      </div>
                  </div>
              </div>
              <div id="manage-tally-cards" class="row mt-4" *ngIf="getInbound().length">
              <h3>Inbound Planning</h3>
                  <div class="col-2 mb-3" *ngFor="let data of getInbound()">
                      <div class="tracker-card card">
                          <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                              <h1 class="m-0 font-monospace">00</h1>
                          </div>
                          <div class="card-body">
                              <h4>{{data.attributes.label}}</h4>
                              <p class="fs-md m-0">{{data.attributes.description}}</p>
                          </div>
                      </div>
                      <div class="text-center mt-2">
                          <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                          <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                      </div>
                  </div>            
              </div>
              <div id="manage-tally-cards" class="row mt-4" *ngIf="getPurchase().length">
                  <h3>Purchase Orders</h3>
                      <div class="col-2 mb-3" *ngFor="let data of getPurchase()">
                          <div class="tracker-card card">
                              <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                                  <h1 class="m-0 font-monospace">00</h1>
                              </div>
                              <div class="card-body">
                                  <h4>{{data.attributes.label}}</h4>
                                  <p class="fs-md m-0">{{data.attributes.description}}</p>
                              </div>
                          </div>
                          <div class="text-center mt-2">
                              <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                          <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                          </div>
                      </div>            
                  </div>
                  <div id="manage-tally-cards" class="row mt-4" *ngIf="getShipment().length">
                      <h3>Shipments</h3>
                          <div class="col-2 mb-3" *ngFor="let data of getShipment()">
                              <div class="tracker-card card">
                                  <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                                      <h1 class="m-0 font-monospace">00</h1>
                                  </div>
                                  <div class="card-body">
                                      <h4>{{data.attributes.label}}</h4>
                                      <p class="fs-md m-0">{{data.attributes.description}}</p>
                                  </div>
                              </div>
                              <div class="text-center mt-2">
                                  <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                                  <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                              </div>
                          </div>            
                      </div>
                      <div id="manage-tally-cards" class="row mt-4" *ngIf="getPoiPoc().length">
                        <h3>Purchase Order Management</h3>
                            <div class="col-2 mb-3" *ngFor="let data of getPoiPoc()">
                                <div class="tracker-card card">
                                    <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                                        <h1 class="m-0 font-monospace">00</h1>
                                    </div>
                                    <div class="card-body">
                                        <h4>{{data.attributes.label}}</h4>
                                        <p class="fs-md m-0">{{data.attributes.description}}</p>
                                    </div>
                                </div>
                                <div class="text-center mt-2">
                                    <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                                    <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                                </div>
                            </div>            
                        </div>
                      <div id="manage-tally-cards" class="row mt-4" *ngIf="getCompliance().length">
                          <h3>Compliance</h3>
                              <div class="col-2 mb-3" *ngFor="let data of getCompliance()">
                                  <div class="tracker-card card">
                                      <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                                          <h1 class="m-0 font-monospace">00</h1>
                                      </div>
                                      <div class="card-body">
                                          <h4>{{data.attributes.label}}</h4>
                                          <p class="fs-md m-0">{{data.attributes.description}}</p>
                                      </div>
                                  </div>
                                  <div class="text-center mt-2">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                                  </div>
                              </div>            
                          </div>
                          <div id="transload-manage-tally-cards" class="row mt-4" *ngIf="transload.length">
                            <h3>Transload</h3>
                                    <div class="col-2 mb-3" *ngFor="let data of transload">
                                        <div class="tracker-card card">
                                            <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
                                                <h1 class="m-0 font-monospace">00</h1>
                                            </div>
                                            <div class="card-body">
                                                <h4>{{data.attributes.label}}</h4>
                                                <p class="fs-md m-0">{{data.attributes.description}}</p>
                                            </div>
                                        </div>
                                        <div class="text-center mt-2">
                                            <button class="btn btn-danger btn-sm fw-medium" (click)="addRemoveClick(data,'remove')" *ngIf="!data.showOnMyDay">Remove</button>
                                            <button class="btn btn-primary btn-sm fw-medium" (click)="addRemoveClick(data,'add')" *ngIf="data.showOnMyDay">Add</button>
                                        </div>
                                    </div>
                                </div>
          </div>
          <div class="tab-pane fade" id="nav-panels" role="tabpanel" aria-labelledby="nav-panels-tab" tabindex="0">
              <div id="manage-tally-cards">
                  <div class="row d-flex">
                      <h3>Panels</h3>
                      <div  class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">

                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Purchase Orders</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Pos Not
                                                                  Booked</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">561</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Bookings In
                                                                  Progress</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">0</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countlate-shipments"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Late
                                                                  Shipments</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">50</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-counton-schedule"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">On
                                                                  Schedule</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">225</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','PurchaseOrder')" *ngIf="panelButton('PurchaseOrder')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','PurchaseOrder')" *ngIf="!panelButton('PurchaseOrder')">Add</button>
                                      
                                  </div>
                              </div>
                          </div>
                      </div>


                      <div class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">
                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Containers</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">At Origin
                                                                  Port</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">168</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">On
                                                                  Water</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">472</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countlate-shipments"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">At Discharge
                                                                  Port</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">0</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-counton-schedule"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Final
                                                                  Mile</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">0</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','Container')" *ngIf="panelButton('Container')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','Container')" *ngIf="!panelButton('Container')">Add</button>
                                  </div>
                              </div>
                          </div>

                      </div>


                      <div class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">

                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Inbound Planning</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Inbound to
                                                                  Port</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">301</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">At
                                                                  Port</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">33</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countlate-shipments"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Inbound to
                                                                  DC</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">114</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','InboundPlanning')" *ngIf="panelButton('InboundPlanning')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','InboundPlanning')" *ngIf="!panelButton('InboundPlanning')">Add</button>
                                  </div>
                              </div>
                          </div>

                      </div>

                      <div class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">
                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">New Messages</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">All
                                                                  Messages</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">5</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Shipment</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">2</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','Message')" *ngIf="panelButton('Message')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','Message')" *ngIf="!panelButton('Message')">Add</button>
                                  </div>
                              </div>
                          </div>

                      </div>

                      <div class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">
                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Vibe Exceptions</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">PO Item HTS
                                                                  Exception</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">11</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Regulatory
                                                                  Hold</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">44</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','Vibe')" *ngIf="panelButton('Vibe')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','Vibe')" *ngIf="!panelButton('Vibe')">Add</button>
                                  </div>
                              </div>
                          </div>

                      </div>

                      <div class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">

                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Shipments</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Updated
                                                                  ETA</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">14</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">New
                                                                  Advices</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">75</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countlate-shipments"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">Scanned
                                                                  Documents</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">0</span>
                                                      </li>
                                                  </ul>

                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','Shipping')" *ngIf="panelButton('Shipping')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','Shipping')" *ngIf="!panelButton('Shipping')">Add</button>
                                  </div>
                              </div>
                          </div>

                      </div>


                     


                      <div *ngIf="poiPocView()" class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">
                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Purchase Order Management</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <div>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countpos-not-booked"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">PO Change Requests
                                                                  Pending</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">159</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countbookings-in-progress"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">PO Change Requests
                                                                  Approved</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">8</span>
                                                      </li>
                                                  </ul>
                                                  <ul class="list-unstyled tally-list tally-list-item">
                                                      <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                          id="widget-countlate-shipments"><span
                                                              class="fs-sm tally-list-heading"><span
                                                                  class="tally-heading-content">POs Pending
                                                                  Acknowledgement</span></span><span
                                                              class="border-spacer flex-grow-1"></span><span
                                                              class="tally-list-value font-monospace">0</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','PurchaseOrderChangeManagement')" *ngIf="panelButton('PurchaseOrderChangeManagement')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','PurchaseOrderChangeManagement')" *ngIf="!panelButton('PurchaseOrderChangeManagement')">Add</button>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="col-3 mb-3 manage-custom-card">
                          <div class="card px-0">
                              <div
                                  class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                  <div>
                                      <h2 class="mb-0">Favorites</h2>
                                  </div>
                              </div>
                              <div class="row mt-4" class="widget">
                                  <div class="col">
                                      <div class="card-body overflow-auto favroties-widget-height tally-list-scroll">
                                          <div>
                                              <h3 class="h4 border-bottom mb-0 pb-1">Reports</h3>
                                              <ul class="list-group mb-3">
                                                  <li class="list-group-item"><a
                                                          class="d-flex justify-content-between fs-sm align-items-center">
                                                          EDocs Commercial Invoice<span
                                                              class="material-icons text-success">chevron_right</span></a>
                                                  </li>
                                                  <li class="list-group-item"><a
                                                          class="d-flex justify-content-between fs-sm align-items-center">
                                                          Ship Advice by Report Print Date Range<span
                                                              class="material-icons text-success">chevron_right</span></a>
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer pb-3 bg-gray">
                                  <div class="d-flex justify-content-center">
                                      <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','Favorite')" *ngIf="panelButton('Favorite')">Remove</button>
                                      <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','Favorite')" *ngIf="!panelButton('Favorite')">Add</button>
                                  </div>
                              </div>
                          </div>
                      </div>


                      <div class="col-3 mb-3 manage-custom-card">
                        <div class="card px-0">
                            <div
                                class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                <div>
                                    <h2 class="mb-0">Supply Chain Map</h2>
                                </div>
                            </div>
                            <div class="row mt-4" class="widget">
                                <div class="col">
                                    <div class="card-body overflow-auto favroties-widget-height tally-list-scroll">
                                        <div id='mapModal' class="manage-map-card" ></div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pb-3 bg-gray">
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','Map')" *ngIf="panelButton('Map')">Remove</button>
                                    <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','Map')" *ngIf="!panelButton('Map')">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="isCarrierIncluded()" class="col-3 mb-3 manage-custom-card">
                        <div class="card px-0">

                            <div
                                class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                <div>
                                    <h2 class="mb-0">YTD Ocean Carrier Utilization</h2>
                                </div>
                            </div>
                            <div class="row mt-4" class="widget">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body">
                                            <div id='pieChartModal' style="min-height:100px;width:100%;"></div>
                                            <ul
                                                class="list-unstyled tally-list tally-list-scroll overflow-auto mt-0">
                                                <li class="tally-list-item d-flex align-items-end mb-3 px-1 py-2">
                                                    
                                                    <span class="fs-sm tally-list-heading"><span
                                                            class="tally-heading-content">Zim Container Lines</span></span>
                                                    <span class="border-spacer flex-grow-1"></span>
                                                    <span
                                                        class="tally-list-value font-monospace text-end flex-shrink-1">30.1%</span>
                                                </li>

                                                <li class="tally-list-item d-flex align-items-end mb-3 px-1 py-2">
                                                    
                                                    <span class="fs-sm tally-list-heading"><span
                                                            class="tally-heading-content">Cosco Shipping Lines Co.,LTD</span></span>
                                                    <span class="border-spacer flex-grow-1"></span>
                                                    <span
                                                        class="tally-list-value font-monospace text-end flex-shrink-1">45.9%</span>
                                                </li>

                                                <li class="tally-list-item d-flex align-items-end mb-3 px-1 py-2">
                                                    
                                                    <span class="fs-sm tally-list-heading"><span
                                                            class="tally-heading-content">Ocean Network Express</span></span>
                                                    <span class="border-spacer flex-grow-1"></span>
                                                    <span
                                                        class="tally-list-value font-monospace text-end flex-shrink-1">24%</span>
                                                </li>
                                            </ul>
                                            <!-- <ul
                                                class="list-unstyled tally-list tally-list-scroll overflow-auto mt-0">
                                               
                                            </ul>
                                            <ul
                                                class="list-unstyled tally-list tally-list-scroll overflow-auto mt-0">
                                                
                                            </ul> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pb-3 bg-gray">
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','CarrierUtilization')" *ngIf="panelButton('CarrierUtilization')">Remove</button>
                                    <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','CarrierUtilization')" *ngIf="!panelButton('CarrierUtilization')">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-3 mb-3 manage-custom-card">
                        <div class="card px-0">

                            <div
                                class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                <div>
                                    <h2 class="mb-0">Container Usage</h2>
                                </div>
                            </div>
                            <div class="row mt-4" class="widget">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body">
                                            <div id='barChartModal' style="min-height:100px;width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pb-3 bg-gray">
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','ContainerUsage')" *ngIf="panelButton('ContainerUsage')">Remove</button>
                                    <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','ContainerUsage')" *ngIf="!panelButton('ContainerUsage')">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 mb-3 manage-custom-card" *ngIf="hasTransloadPanelAccess">
                        <div class="card px-0">

                            <div
                                class="card-header drag-handler cursor-grab bg-transparent d-flex align-items-center mx-3 px-0 pt-3">
                                <div>
                                    <h2 class="mb-0">Transload</h2>
                                </div>
                            </div>
                            <div class="row mt-4" class="widget">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body">
                                            <div>
                                                <ul class="list-unstyled tally-list tally-list-item">
                                                    <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                        id="widget-tranload-inbound-to-port"><span
                                                            class="fs-sm tally-list-heading"><span
                                                                class="tally-heading-content">Inbound to Port</span></span><span
                                                            class="border-spacer flex-grow-1"></span><span
                                                            class="tally-list-value font-monospace">159</span>
                                                    </li>
                                                </ul>
                                                <ul class="list-unstyled tally-list tally-list-item">
                                                    <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                        id="widget-transload-at-port"><span
                                                            class="fs-sm tally-list-heading"><span
                                                                class="tally-heading-content">At Port</span></span><span
                                                            class="border-spacer flex-grow-1"></span><span
                                                            class="tally-list-value font-monospace">8</span>
                                                    </li>
                                                </ul>
                                                <ul class="list-unstyled tally-list tally-list-item">
                                                    <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                        id="widget-transload-at-transload-facility"><span
                                                            class="fs-sm tally-list-heading"><span
                                                                class="tally-heading-content">AtTransload Facility</span></span><span
                                                            class="border-spacer flex-grow-1"></span><span
                                                            class="tally-list-value font-monospace">0</span>
                                                    </li>
                                                </ul>
                                                <ul class="list-unstyled tally-list tally-list-item">
                                                    <li class="d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard"
                                                        id="widget-transload-inbound-to-dc"><span
                                                            class="fs-sm tally-list-heading"><span
                                                                class="tally-heading-content">Inbound to DC</span></span><span
                                                            class="border-spacer flex-grow-1"></span><span
                                                            class="tally-list-value font-monospace">0</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pb-3 bg-gray">
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-danger btn-sm fw-medium" (click)="addRemovePanelClick('remove','TransloadIPDCT')" *ngIf="panelButton('TransloadIPDCT')">Remove</button>
                                    <button class="btn btn-primary btn-sm fw-medium" (click)="addRemovePanelClick('add','TransloadIPDCT')" *ngIf="!panelButton('TransloadIPDCT')">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

  
</ng-template>





