import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject, takeUntil } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    constructor(
        private oidcSecurityService: OidcSecurityService, 
        private router: Router, 
        private authService: AuthService) { }
    
        ngOnDestroy(): void {
            this.destroy$.next();
            this.destroy$.complete();
        }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this.oidcSecurityService
            .checkAuth()
            .pipe(
                takeUntil(this.destroy$),
                tap(x => {
                    if(!x.isAuthenticated){
                        const url = state.url.includes("callback") ? "" : state.url;
                        localStorage.setItem('previousLocation', url);
                    }
                }),
                tap(x => {
                    x.isAuthenticated
                        ? this.authService.initializeTimeout(this.destroy$)
                        : this.oidcSecurityService.authorize()
                }),
                map(({ isAuthenticated }) => (isAuthenticated)
                                        ? true
                                        : true)
        );
    }
}