/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { CatalogName, LogiReportServer, ReportName } from './models/logi.report-model';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BehaviorSubject, Subject, combineLatest, map, take, takeUntil, tap } from 'rxjs';
import { AuthService } from '@cds-ui/shared/auth';
import { filterNullish } from '@cds-ui/shared/core-rx';

@Injectable()
export class LogiService implements OnDestroy {
  appFactory: any;
  appWindow: any = window;
  destroy$$ = new Subject<void>();
  config$$ = new BehaviorSubject<{ logiServer: string, isDark: boolean }>({ logiServer: '', isDark: true });

  constructor(private appState: AppStateRepository, private authService: AuthService) {
    combineLatest([
      this.appState.environment$,
      this.appState.appTheme$
    ])
      .pipe(
        takeUntil(this.destroy$$),
        tap(([env, theme]) => {
          this.config$$.next({
            logiServer: env.urls.logiServer,
            isDark: theme === 'dark'
          })
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  initializeLogiAppFactory() {
    this.appFactory = this.appWindow['com']['jinfonet']['api']['AppFactory'];
  }

  configureLogiServerInstance(
    entryId: string,
    viewMode: boolean = true,
    showToolbar: boolean = false,
    showSideArea: boolean = false
  ) {
    /* This should be read from the redis analytics permissions dataset.- Once the redis
    framework is done, this will be wired up to that dataset.- For now adding this here as
    a temporary solution... */
    const analyticsProfileData = JSON.parse(
      window.localStorage.getItem('reportAnalyticsData') as any
    );
    const logiProfileName = analyticsProfileData.isEdittable
      ? 'VIZIVEdit'
      : 'VIZIVView';

    const serverConfiguration: LogiReportServer = {
      url: new URL(this.config$$.getValue().logiServer).toString(),
      auth_token: '',
      // auth_token: this.getToken(),
      jrd_userinfo: { prefer: { rpt_timezone: 'UTC' } },
      jrd_prefer: {
        pagereport: {
          userInfoBar: false,
          toolBar: false,
          toolbox: false,
          dSOTree: false,
          tOCTree: false,
          popupMenu: false,
          aDHOC: false
        },
        webreport: {
          viewMode: {
            hasToolbar: showToolbar,
            hasSideArea: showSideArea
          }
        }
      },
      jrd_studio_mode: viewMode ? 'view' : 'edit',
      'jrs.param_page': false,
      paramPageDisabled: false,
      'jrs.profile': logiProfileName,
      'jrs.has_style': true,
      'jrs.style_group': this.config$$.getValue().isDark
        ? "CenturyDark<CSS>"
        : "CenturyLight<CSS>",
      entryId: entryId
    };

    return serverConfiguration;
  }

  loadReportStudio(
    serverConfiguration: LogiReportServer,
    reportName: ReportName,
    catalogName: CatalogName
  ) {
    this.runReport(serverConfiguration, reportName, catalogName, {});
  }

  runReport(
    serverConfiguration: LogiReportServer,
    reportName: ReportName,
    catalogName: CatalogName,
    params: any
  ) {
    this.authService.accessToken$.pipe(
      takeUntil(this.destroy$$),
      filterNullish(),
      take(1),
      map(token => ({ ...serverConfiguration, auth_token: token })),
      tap(config => 
        this.appFactory.runReport(config, reportName, catalogName, 
                                  params, config.entryId))
    )
    .subscribe();
  }
}