import { Component, Input } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, map } from 'rxjs';
import { MessageCenterService } from '@cds-ui/libs/domain/message-center/domain-core';

export type Message = {
  actor: string,
  time: Date,
  message: string,
  showReplyIcon?: boolean
};

@Component({
  selector: 'cds-ui-messages-pane',
  templateUrl: './messages-pane.component.html',
  styleUrls: ['./messages-pane.component.scss'],
})
export class MessagesPaneComponent {
  constructor(private messageCenterService: MessageCenterService) {}

  @Input() set messagesData(messages: Message[] | null){
    this.messagesSubject.next(messages ?? []);
  }

  @Input() set poMessagesData(messages: any){
    this.poMessagesSubject.next(messages ?? []);
  }
  
  @Input() cardHeaderName = 'Messages';
  @Input() purchaseOrderMsgs!:boolean;

  private readonly messagesSubject = new BehaviorSubject<Message[]>([]);
  public readonly messages$ = this.messagesSubject.asObservable();
  public readonly messageCount$ = this.messages$.pipe(map((x) => x.length));

  private readonly poMessagesSubject = new BehaviorSubject<any>([]);
  public readonly poMessages$ = this.poMessagesSubject.asObservable();
  public readonly poMessageCount$ = this.poMessages$.pipe(map((x) => x.length));

  formatDate(date: Date) {
    return date
      ? moment.utc(date).isValid()
        ? moment.utc(date).format('MMM DD YYYY, hh:mm A')
        : null
      : null;
  }

  openReplyPopup(messageData: any, value:any) {
    const obj = {
      keys: [
        {
          key: value,
          value: value === 'shipKey' ? messageData.shipKey : messageData.poNumber,
        },
      ],
      receivers: [
        {
          receiver:''
        }
      ],
      messageTypeName: messageData.messageTypeName,
      messageId: messageData.messageId,
      sendTime: messageData.time,
      sender: messageData.sender,
      subject: messageData.subject,
      text: messageData.message,
      showSaveDraftBtn: true,
      topic:messageData.topic
    };
    this.messageCenterService.inboxArchiveDataSubject$.next(obj);
  }
}
