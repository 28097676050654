import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil, } from 'rxjs';
import { LocationDateDialogueEvent, LocationDateModalService } from './location-date-modal.service';

@Component({
  selector: 'cds-ui-location-date-modal',
  templateUrl: './location-date-modal.component.html',
  styleUrls: ['./location-date-modal.component.scss'],
})
export class LocationDateModalComponent implements OnInit {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public data!: any;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private LocationDateModalService: LocationDateModalService,
    private appStateRepository: AppStateRepository
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.LocationDateModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        if(data[4].displayName){
          const final = data[3].displayName;
          const cyName = data[4].displayName;
          data[3].displayName = cyName;
          data[4].displayName = final;
        }else{
          data.pop();
        }
        this.data = data;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
      });

    this.handler(
      this.modalservice.onShow,
      LocationDateDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      LocationDateDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      LocationDateDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      LocationDateDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>, type: LocationDateDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.LocationDateModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onLocationDateClick() {
    this.LocationDateModalService.setClosingLocationDate(this.data);
  }

  private subscribeOverwrittenExisting() {
    this.LocationDateModalService.onClosingLocationDateRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}
