<ng-template #template>
  <div  id="activity-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
  <div class="modal-header p-3">
      <h2 class="m-0">All Activity</h2>
      <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
          <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
          <span class="visually-hidden">Close</span>
      </button>
  </div>
         <div class="modal-body pt-0 mt-4">
          <div class="container-fluid">
          <div class="row">
            <table class="table table-dark table-striped table-borderless">
              <thead class="text-purple">
                <th>Provider</th>
                <th>Status</th>
                <th>Date</th>
                <th>Location</th>
                <th>ISA Sent Date</th>
                <th>Sender ID</th>
              </thead>
              <tbody>
                <ng-container *ngIf="(configData$ | async)?.data as rowData">
                <tr *ngFor="let activityFeed of rowData">
                  <td>{{activityFeed?.providerDescription ?? ''}}</td>
                  <td>{{activityFeed?.eventStatus ?? ''}}</td>
                  <td>{{dateFormatter(activityFeed?.statusDate)}}</td>
                  <td>{{activityFeed?.locationName ?? ''}}</td>
                  <td>{{dateFormatter(activityFeed?.isaSentDate)}}</td>
                  <td>{{activityFeed?.senderId ?? ''}}</td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          </div>
          </div>

        </div>
      </div>
      </div>
</ng-template>
