import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, catchError, throwError, takeUntil, filter } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
export interface CreateDdpContainerModalConfig {
  title: string,
  data: any
}

@Injectable({ providedIn: 'root' })
export class CreateDdpContainerModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  constructor(private http: HttpClient, private toastrService: ToastrService) {

  }
  show(config: CreateDdpContainerModalConfig): void {
    this.showSubject$.next(config);
  }
  public submitData(data: any): Observable<any> {
    return this.http.post('containers/inboundPlanning/createDdpContainer', data).pipe(catchError((error: HttpErrorResponse) => {
      this.toastrService.error(error.error.text || error.error.error);
      return throwError(() => error);
    }));
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
