import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Edi315Event } from '@cds-ui/data-access';
import { Observable, startWith, ReplaySubject, tap, shareReplay, filter, takeUntil, Subject, take } from 'rxjs';
import { ActivityPanelViewMoreModalService } from './activity-panel-view-more-details-modal/activity-panel-view-more-detail-modal.service';

@Component({
  selector: 'cds-ui-activity-panel',
  templateUrl: './activity-panel.component.html',
  styleUrls: ['./activity-panel.component.scss'],
})

export class ActivityPanelComponent implements OnDestroy {
  private readonly destroy$$ = new Subject<void>();
  private readonly dataSource$$ = new ReplaySubject<ActivityPanel[]>(1);
  @Input() set data$(stream: Observable<ActivityPanel[]>) {
    stream.pipe(
      takeUntil(this.destroy$$),
      tap(x => this.dataSource$$.next(x))
    ).subscribe();
  }

  readonly activityPanelData$ = this.dataSource$$.pipe(startWith([]), shareReplay(1));
  readonly displayViewMoreLink$ = this.activityPanelData$.pipe(
    filter(x => !!x?.length),
    startWith(false),
  );

  constructor(private vmService: ActivityPanelViewMoreModalService) { }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  public onViewMoreClickEvent(): void {
    this.activityPanelData$.pipe(
      takeUntil(this.destroy$$),
      take(1),
      tap(x => this.vmService.activityPanelData$$.next(x)),
      tap(_ => this.vmService.show())
    ).subscribe();

  }
}

export type ActivityPanel = Edi315Event & { key?: string, eventStatus?: string };