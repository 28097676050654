import { AfterViewInit, Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { GetEventLocationGQL, GetEventStatusGQL } from '@cds-ui/data-access';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { Apollo } from 'apollo-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, shareReplay, Subject, takeUntil } from 'rxjs';
import { AddContainerStatusDialogueEvent, AddContainerStatusEventModalConfig, AddContainerStatusModalService } from './add-container-status-modal.service';

@Component({
  selector: 'cds-ui-add-container-status-modal',
  templateUrl: './add-container-status-modal.component.html',
  styleUrls: ['./add-container-status-modal.component.scss'],
})
export class AddContainerStatusModalComponent implements OnInit, AfterViewInit {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: AddContainerStatusEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  private readonly locationListSubject$: Subject<any> = new Subject();
  public locationList$ = this.locationListSubject$.asObservable().pipe(shareReplay(1));
  public isContainer :boolean = false;
  private readonly statusListSubject$: Subject<any> = new Subject();
  public statusList$ = this.statusListSubject$.asObservable().pipe(shareReplay(1));
  datePickerConfig:any;
  public form = new FormGroup({
    shipKey: new FormControl(''),
    containerNo: new FormControl(''),
    eventDate: new FormControl('', Validators.required),
    eventLocation: new FormControl(''),
    eventType: new FormControl('', Validators.required),
    comments: new FormControl('')
  });
  minDate!: Date;
  maxDate!: Date;
  constructor(
    private modalservice: BsModalService,
    private AddContainerStatusModalService: AddContainerStatusModalService,
    private appStateRepository: AppStateRepository,
    private apollo: Apollo,

  ) {
    this.dateSet()
   }

  ngOnInit(): void { 
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue',adaptivePosition: true, isAnimated: true });
  }

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.AddContainerStatusModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        this.config = error as AddContainerStatusEventModalConfig;
        this.locationListSubject$.next([])
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.statusListData();
        this.form.patchValue(
          {
            shipKey: this.config.data.shipKey,
            containerNo: this.config.data.containerNumber,
            eventDate: '',
            eventLocation: '',
            eventType: '',
            comments: ''
          }
        );
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
      });

    this.handler(
      this.modalservice.onShow,
      AddContainerStatusDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      AddContainerStatusDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      AddContainerStatusDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      AddContainerStatusDialogueEvent.OnHidden
    );
  }

  dateSet(){
    this.form.patchValue({eventDate: ''});
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 60);
    this.maxDate.setDate(this.maxDate.getDate() + 60);
  }
  handler(handler: EventEmitter<any>, type: AddContainerStatusDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.AddContainerStatusModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAddContainerStatusClick() {
    this.AddContainerStatusModalService.setClosingAddContainerStatus(this.form.value);
  }

  private subscribeOverwrittenExisting() {
    this.AddContainerStatusModalService.onClosingAddContainerStatusRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }

  onSave() {
    this.AddContainerStatusModalService.saveContainerStatus(this.form.value).subscribe(val => {
      this.onAddContainerStatusClick();
      this.modalRef?.hide();
    })
  }
  
  statusSelection(val: any) {
    this.isContainer = false;
    this.form.patchValue({ eventLocation: '' });
    if(val.target.value){
      this.statusList$.subscribe(el => {
        const elData = el.find((x: any) => x.statusCode == val.target.value)
        this.isContainer = elData.isContainerLocation;
      if (this.isContainer) {
        this.form.patchValue({ eventDate: '' });
        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setDate(this.minDate.getDate() - 60);
        this.maxDate.setDate(this.maxDate.getDate());
        this.form.get('eventLocation').setValidators([Validators.required]);
        this.form.get('eventLocation').updateValueAndValidity();
      } else {
        this.dateSet()
        this.form.get('eventLocation').clearValidators();
        this.form.get('eventLocation').updateValueAndValidity();
      }
    })
    }else{
      this.form.patchValue({ eventDate: '' });
      this.form.get('eventLocation').setValidators([Validators.required]);
      this.form.get('eventLocation').updateValueAndValidity();
    }
         
    new GetEventLocationGQL(this.apollo).fetch(
      { "filter": { "shipKey": this.config.data.shipKey, "statusCode": val.target.value } }, { fetchPolicy: 'no-cache' }).pipe(
        map(res => res.data.eventLocation?.nodes)).subscribe(y => this.locationListSubject$.next(y ?? []))
  }
  onDateChange(){
    this.dateCondition();
  }
  dateCondition(){
   return new Date(this.form.value.eventDate) > new Date()
  }

   statusListData() {
      new GetEventStatusGQL(this.apollo).fetch(
        {"filter": {"categoryName": "QSContainerStatusEvents"}}, { fetchPolicy: 'no-cache' })
          .pipe(
            map(x=> x.data.eventStatus?.nodes),
            map(nodes => nodes?.sort((a, b) => a.statusName.localeCompare(b.statusName)))
          ).subscribe(y=> this.statusListSubject$.next(y ?? []))
    }
}
