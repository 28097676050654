<div class="card">
  <div class="card-header bg-transparent border-dark border-1 mx-5 px-0 pt-4">
    <div class="d-flex align-items-center">
      <h2 class="mb-0">Documents</h2>
      <div class="btn-group ms-auto" *ngIf="scannedDocs$ | async as list">
        <a href="#" data-bs-toggle="modal" data-bs-target="#documents-modal"
        *ngIf="list.length > 5" (click)="onViewMore()">View More</a>
      </div>
    </div>
  </div>
  <ng-container *ngIf="scannedDocs$ | async as list as any">
  <div class="card-body px-4" *ngIf="list.length > 0;else noData">
    <div *ngFor="let item of list.slice(0, 5); odd as isOdd; even as isEven;let i = index" [ngClass]="{'bg-gray-700': isEven, 'bg-gray-600': isOdd}" class="message-card">
      <div class="message-card-meta d-flex align-items-center mb-2">
        <h6 class="flex-fill fs-sm fw-bold m-0">{{item.docType}}<span class="badge border border-primary mx-2">{{'v' + item.docVersion}}</span></h6>
        <div *ngIf="item.docStatus === 'Approved'"><span class="badge bg-primary">{{item.docStatus}}</span></div>
        <div><span>{{item.documentSentDate | date:'MM/dd/YYYY'}}</span> <span *ngIf="item.documentSentDate" class="material-icons mi-18 ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Document Scanned Date">info</span></div>
      </div>
      <h5 class="message-card-title mt-3"><a [href]="scannedDocUrl + item.docHandle" target="_blank">{{item.docName}}</a></h5>
    </div>
  </div>
</ng-container>
  <ng-template #noData>
    <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
      <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">cloud_off</span></span></p>
      <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
    </div>
  </ng-template>
</div>

<cds-ui-scanned-documents-modal></cds-ui-scanned-documents-modal>