import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface EditTerminalEventModalConfig {
    title: string,
    message?: string,
    data:any;
    count?:any,
    btnOne:string,
    btnTwo:string,
}

export enum EditTerminalDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

export interface EditTerminalModalEvent {
    type: EditTerminalDialogueEvent,
    params?: any,
    data?: any
}

export interface TerminalSearchResult {
  city: string,
  postalCode: string,
  state: string,
  streetAddress: string,
  terminalId: number,
  terminalName: string,
  locationCode: string,
  locationKey: number
}

@Injectable({ providedIn: 'root' })

export class EditTerminalModalService implements OnDestroy {

  constructor(private http: HttpClient) { }

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<EditTerminalModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === EditTerminalDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === EditTerminalDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === EditTerminalDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === EditTerminalDialogueEvent.OnHidden));
  public onClosingEditTerminalRemove$ = this.event$.pipe(filter((x) => x.type === EditTerminalDialogueEvent.OnClosingRemove));
  public onClosingEditTerminalRemoved$ = this.event$.pipe(filter((x) => x.type === EditTerminalDialogueEvent.OnClosingRemoved));

  show(config: EditTerminalEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: EditTerminalModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingEditTerminal(params?: any, data?: any) {
    this.event$.next({
      type: EditTerminalDialogueEvent.OnClosingRemove,
      params: params,
      data: data
    });
  }

  setClosingEditTerminalRemoved() {
    this.event$.next({
      type: EditTerminalDialogueEvent.OnClosingRemoved
    });
  }

  getTerminalSearchValueData(query: string): Observable<TerminalSearchResult[]> {
    return this.http.get<TerminalSearchResult[]>(
      `search/terminals/suggestions?keyValue=${query}`
    );
}
}