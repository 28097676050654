export interface ReportDetails {
  cardTitle: string;
  cardDescription: string;
  cardImg: string;
  tags: string[];
  reportFullName: string;
  catalogName: string;
  isFavorited: boolean;
  isEditMode: boolean;
  showMoreInfo: boolean;
  allowAllSubgroup: boolean;
  defaultTag?:string;
  permission: string[];
}