import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

export type Card = {
  id?: string | null | undefined,
  label: string,
  count: number | undefined,
  description: string | string [],
  totalValue?: number | undefined
};

@Component({
  selector: 'cds-ui-card-count',
  templateUrl: './card-count.component.html',
  styleUrls: ['./card-count.component.scss']
})
export class CardCountComponent {
  @Input() isWidget = false;
  @Input() set selectIndexFromExternal(index: number | null){
    if(index != null && index > -1){
      this.activeIndex = index;
    }
  }
  @Input() cards$!: Observable<Card[]>;
  @Output() selected$ = new EventEmitter<Card>();

  public activeIndex = 0;

  public selectCard(card: Card, i: number = 0) {
    this.activeIndex = i;
    this.selected$.emit(card);
  }
}
