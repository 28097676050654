<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div >
      <div class="card">
        <div class="card-body px-5 py-4">
            <div class="timeline-animate-wrapper">
              <div class="vessel">
                <img src="assets/img/cargo-ship.svg" width="80">
              </div>
            </div>
            <ol class="progress-tracker pt-5 mt-3">
                <li  *ngFor="let data of config.data">
                    <h5>{{portVal(data.portName)}}</h5>
                    ARR: {{data.updatedETA | date:'MM/dd/YYYY'}}<br />
                    DEP: {{data.updatedETD | date:'MM/dd/YYYY'}}
                </li>
            </ol>
        </div>
      </div>
    </div>
  </div>
</ng-template>