import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { map, tap, BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs';

@Directive({ selector: '[deprecatedFeaturePermission]' })
export class DeprecatedFeaturePermissionDirective implements OnDestroy {
    private destroy$$ = new Subject<void>();
    private matchAll$ = new BehaviorSubject<boolean>(false);

    @Input() set matchAll(match: boolean) {
        this.matchAll$.next(match);
    };  

    @Input() set deprecatedFeaturePermission(featureKey: string | string[] | null) {
        const featureKeys = !featureKey
                          ? []
                          : Array.isArray(featureKey)
                          ? featureKey
                          : [featureKey];
        combineLatest([
            this.appState.features$,
            this.matchAll$
        ])
        .pipe(
            takeUntil(this.destroy$$),
            map(([x, matchAll]) => matchAll
                   ? featureKeys.every(r => this.checkFeatureList(x, r))
                   : featureKeys.some(r => this.checkFeatureList(x, r))
            ),
            tap(allowed => allowed
                ? this.createEmbeddView()
                : this.viewContainer.clear()
            )
        )
        .subscribe();
    }

    private createEmbeddView() {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    private checkFeatureList(featureKeys: string[], featureKey: string) {
        return featureKeys.map(x => x.toLowerCase())
                          .indexOf(featureKey.toLowerCase()) >= 0;
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private appState: AppStateRepository) { }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

}