import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface SaveModalConfig {
    title: string,
    message: string
}

export enum SaveDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingSave,
    OnClosingSaveError,
    OnClosingSaved,
    OnClosingDontSave,
    OnClosingDontSaved,
    OnCanceled
}

interface SaveModalEvent {
    type: SaveDialogueEvent,
    params: any | undefined | null
}

@Injectable({ providedIn: 'root' })
export class SaveModalService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private event$: Subject<SaveModalEvent> = new Subject();
    private showSubject$ = new Subject(); 
    public saveDialogue$ = new Subject();

    public isDirty = false;
    public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));

    public onShow$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnShow));
    public onShown$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnShown));
    public onHide$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnHide));
    public onHidden$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnHidden));

    public onClosingDontSave$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnClosingDontSave));
    public onClosingDontSaved$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnClosingDontSaved));

    public onClosingSave$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnClosingSave));
    public onClosingSaveError$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnClosingSaveError));
    public onClosingSaved$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnClosingSaved));

    public onCanceled$ = this.event$.pipe(filter(x => x.type === SaveDialogueEvent.OnCanceled));
    
    show(config: SaveModalConfig): void {
        this.showSubject$.next(config);
    }

    handle(event: SaveModalEvent): void {
        this.event$.next(event);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    markAsDirty() {
        this.isDirty = true;
    }

    markAsPristine() {
        this.isDirty = false;
    }

    setClosingSave(params: any = {}) {
        this.event$.next({ type: SaveDialogueEvent.OnClosingSave, params });
    }

    setClosingSaved(params: any = {}) {
        this.isDirty = false;
        this.event$.next({ type: SaveDialogueEvent.OnClosingSaved, params });
    }

    setClosingDontSave(params: any = {}) {
        this.isDirty = false;
        this.event$.next({ type: SaveDialogueEvent.OnClosingDontSave, params });
    }

    setClosingDontSaved(params: any = {}) {
        this.isDirty = true;
        this.event$.next({ type: SaveDialogueEvent.OnClosingDontSaved, params });
    }

    setClosingSaveError(params: any = {}) {
        this.event$.next({ type: SaveDialogueEvent.OnClosingSaveError, params });
    }

    setCancel(params: any = {}) {
        this.event$.next({ type: SaveDialogueEvent.OnCanceled, params });
    }
}
