import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CellEditorComponent } from './editors/cell-editor/cell-editor.component';
import { NumericCellEditorComponent } from './editors/numeric-cell-editor/numeric-cell-editor.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AddRemoveGridButtonRendererComponent } from './renderers/add-remove-grid-button-renderer/add-remove-grid-button-renderer.component';
import { AddItemGridButtonRendererComponent } from './renderers/add-item-grid-button-renderer/add-item-grid-button-renderer.component';
import { NavLinkRendererComponent } from './renderers/nav-link-renderer/nav-link-renderer.component';
import { EditGridButtonRendererComponent } from './renderers/edit-grid-button-renderer/edit-grid-button-renderer.component';
import { NavigationLinkRendererComponent } from './renderers/navigation-link-renderer/navigation-link-renderer.component';
import { RouterModule } from '@angular/router';
import { customDatePickerComponent } from './editors/custom-datepicker/custom-datepicker-component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IPCustomDatePickerComponent } from './editors/IP-custom-datepicker/IP-custom-datepicker.component';
import { counterDeclineEditorComponent } from './editors/counter-decline-editor/counter-decline-editor-component';
import { EditIconCustomHeaderComponent } from './edit-icon-custom-header/edit-icon-custom-header.component';
import { GridColumnService } from './settings/grid-column.service';

@NgModule({
  imports: [CommonModule, FormsModule, AgGridModule,RouterModule,BsDatepickerModule.forRoot()],
  declarations: [
    CellEditorComponent,
    NumericCellEditorComponent,
    AddRemoveGridButtonRendererComponent,
    AddItemGridButtonRendererComponent,
    NavLinkRendererComponent,
    EditGridButtonRendererComponent,
    NavigationLinkRendererComponent, 
    customDatePickerComponent,
    IPCustomDatePickerComponent,
    counterDeclineEditorComponent,
    EditIconCustomHeaderComponent
  ],
  exports: [
    CellEditorComponent,
    NumericCellEditorComponent,
    AddRemoveGridButtonRendererComponent,
    AddItemGridButtonRendererComponent,
    NavLinkRendererComponent,
    EditGridButtonRendererComponent,
    NavigationLinkRendererComponent,
    customDatePickerComponent,
    IPCustomDatePickerComponent,
    counterDeclineEditorComponent,
    EditIconCustomHeaderComponent
  ],
  providers: [GridColumnService]
})
export class SharedUiGridModule {}
