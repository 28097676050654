import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { combineLatest, map, startWith, Subject, takeUntil, tap } from 'rxjs';

@Directive({ selector: '[spinner]' })
export class SpinnerDirective implements OnInit, OnDestroy {
    private useInput = false;
    private destroy$$ = new Subject<void>();
    private inputLoading$$ = new Subject<boolean>();
    private inputLoading$ = this.inputLoading$$.pipe(startWith(false));
    private appLoading$ = this.appState.isLoading$.pipe(startWith(false));

    @Input() set isLoading(isLoading: boolean | null) {
        if(isLoading === undefined || isLoading === null) return;
        this.useInput = true;
        this.inputLoading$$.next(isLoading);
    }

    ngOnInit(): void {
        combineLatest([
            this.appLoading$,
            this.inputLoading$
        ])
        .pipe(
            takeUntil(this.destroy$$),
            map(([a, i]) => this.useInput ? i : a),
            tap(isLoading => { 
                if(isLoading) return;

                const element = this.el.nativeElement;
                const spinner = element.parentNode.querySelector('.spinner-grow');

                if(spinner) {
                    this.renderer.removeChild(element.parentNode, spinner);
                }
            }),
            tap(isLoading => {
                const element = this.el.nativeElement;
                const spinner = element.parentNode.querySelector('.spinner-grow');

                if(!isLoading || spinner) return;

                const spinnerElement = this.renderer.createElement('i');
                this.renderer.addClass(spinnerElement, 'spinner-grow');
                this.renderer.addClass(spinnerElement, 'spinner-grow-sm');
                this.renderer.addClass(spinnerElement, 'mx-2');
                this.renderer.setAttribute(spinnerElement, 'role', 'status');

                this.renderer.insertBefore(this.el.nativeElement, spinnerElement, this.el.nativeElement.firstChild);
            })
        )
        .subscribe();
    }
    
    constructor(
        private el: ElementRef,
        private appState: AppStateRepository, 
        private renderer: Renderer2
    ){ }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

    
}