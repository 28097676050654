<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="card">
        <div class="card-body px-5 pb-4">
            <div *ngFor="let value of config.data">
                <div class="d-flex bg-gray-600 pt-3">
                    <div class="w-50">
                        <span class="label" *ngIf="value?.portType == 'FeederPortDepart'">Feeder Port Depart</span>
                        <span class="label" *ngIf="value?.portType == 'MotherPortDepart'">Mother Port Depart</span>
                        <span class="label" *ngIf="value?.portType == 'VesselArrive'">Vessel Arrive</span>
                        <span class="label" *ngIf="value?.portType == 'FinalArrive'">Final Arrive</span>
                        <p class="fw-bold">{{value?.portName ?? '--'}}</p>
                    </div>
                    <div class="w-50">
                        <span class="label">Planned</span>
                        <p class="fw-bold">{{value?.planned ?? '--'}}</p>
                    </div>
                    <div class="w-50">
                        <span class="label">Current Plan</span>
                        <p class="fw-bold">{{value?.currentPlan ?? '--'}}</p>
                    </div>
                    <div class="w-50">
                        <span class="label">Actual</span>
                        <p class="fw-bold">{{value?.actual ?? '--'}}</p>
                    </div>
                </div>
                <div class="mb-3"></div>
            </div>
        </div>
    </div>
  </div>
</ng-template>