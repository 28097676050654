import { Injectable } from "@angular/core";
import { PermissionControlSearchKeyInput, QueryPermissionGQL, PermissionResult } from "@cds-ui/data-access";
import { Apollo } from "apollo-angular";
import { map } from "rxjs";

export interface FeaturePermissionQuery {
    domain: string,
    category: string
    feature: string,
    regex: RegExp
};

@Injectable({ providedIn: 'root' })
export class FeaturePermissionApiService {

    constructor(private apollo: Apollo) { }

    public get(params: FeaturePermissionQuery[]) {

        const permissionControlKey = params.map(x => ({
            domain: x.domain,
            category: x.category,
            feature: x.feature
        }) as PermissionControlSearchKeyInput);

        return new QueryPermissionGQL(this.apollo)
                    .fetch({filter: permissionControlKey})
                    .pipe(
                        map(res => res.data.userPermission),
                    );
    }

    public expandFeature(queries: string[]): string[] {
        return queries
            .map(input => {
                const [domain, ...rest] = input.split(":");
                const feature = rest.pop() ?? "";
                const domainCatergory = `${domain}:${rest.join(":")}`;

                return feature.split(",").map(x => `${domainCatergory}:${x}`);
            })
            .flat(1);
    }

    public parseKey(queries: string[]): FeaturePermissionQuery[] {
        return queries
            .map(input => {
                const [domain, ...rest] = input.split(":");
                const feature = rest.pop() ?? "";
                const category = rest.join(":");

                const domainPattern = domain.replace(/\*/g, ".*");
                const categoryPattern = category.replace(/\*/g, ".*");
                const featurePattern = feature.replace(/\*/g, ".*");

                const regex = new RegExp(`^${domainPattern}:${categoryPattern}:${featurePattern}$`);

                return { domain, feature, category, regex };
            });
    }


}