import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DemurrageDetentionModalService implements OnDestroy {

    private destory$$ = new Subject<void>();
    private show$$ = new Subject<void>();
    private hide$$ = new Subject<void>();
    private save$$ = new Subject<void>();
    private cancel$$ = new Subject<void>();

    public onShow$ = this.show$$.asObservable();
    public onHide$ = this.hide$$.asObservable();
    public onSave$ = this.save$$.asObservable();
    public onCancel$ = this.cancel$$.asObservable();

    show(data:any): void {
        this.show$$.next(data);
    }

    save(): void {
        console.log('save method');
        this.hide$$.next();
        this.save$$.next();
    }

    cancel(): void {
        this.hide$$.next();
        this.cancel$$.next();
    }

    hide(): void {
        this.hide$$.next();
    }

    ngOnDestroy(): void {
        this.destory$$.next();
        this.destory$$.complete();
    }

}