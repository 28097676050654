import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface DocItemsUploadedModalConfig {
    title: string,
   data : any;
}

export enum DocItemsUploadedDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnCanceled
}

interface  DocItemsUploadedModalEvent {
    type: DocItemsUploadedDialogueEvent,
    params: any | undefined | null
}

@Injectable({ providedIn: 'root' })
export class DocItemsUploadedModalService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private event$: Subject< DocItemsUploadedModalEvent> = new Subject();
    private showSubject$ = new Subject(); 
    public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
    public onShow$ = this.event$.pipe(filter(x => x.type === DocItemsUploadedDialogueEvent.OnShow));
    public onShown$ = this.event$.pipe(filter(x => x.type ===  DocItemsUploadedDialogueEvent.OnShown));
    public onHide$ = this.event$.pipe(filter(x => x.type === DocItemsUploadedDialogueEvent.OnHide));
    public onHidden$ = this.event$.pipe(filter(x => x.type === DocItemsUploadedDialogueEvent.OnHidden));

    public OnCanceled$ = this.event$.pipe(filter(x => x.type ===  DocItemsUploadedDialogueEvent.OnCanceled));


    
    show(config: DocItemsUploadedModalConfig): void {
        this.showSubject$.next(config);
    }

    handle(event: DocItemsUploadedModalEvent): void {
        this.event$.next(event);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setCancel(params: any = {}) {
        this.event$.next({ type:  DocItemsUploadedDialogueEvent.OnCanceled, params });
    }
}
