<div class="card">
  <div class="card-header d-flex bg-transparent border-dark border-1 justify-content-between mx-5 px-0 pt-4">
    <h2 class="m-0">Messages</h2>
    <button *featurePermission="'poi-poc:action:po-change-request:create-po-message'" type="button"data-bs-toggle="offcanvas"
    data-bs-target="#create-offcanvas" aria-controls="create-offcanvas" class="btn btn-primary ms-2">Create Message</button>
  </div>
  <div *ngIf="!purchaseOrderMsgs">
  <div class="card-body px-5 pb-4" *ngIf="((messageCount$ | async) ?? 0) > 0; else noData">   
    <div class="message-cards overflow-auto"  >
      <div *ngFor="let item of messages$ | async;let ind = index">
        <div *ngIf="ind === 0" class="p-5 bg-gray-600 mb-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="mb-2 fw-bold">{{item.actor}}</div>
            <div class="text-white-50">{{formatDate(item.time)}}</div>
          </div>
          <div *ngIf="ind === 0">{{item?.message}}</div>
          <ng-container *featurePermission="['messaging:action:reply:view']">
            <div *ngIf="ind == 0 && item.showReplyIcon" class="text-end"><a (click)="openReplyPopup(item, 'shipKey')" class="material-icons mi-24 text-decoration-none" data-bs-target="#reply-offcanvas" aria-controls="reply-offcanvas" data-bs-toggle="offcanvas">reply</a></div>
          </ng-container>
        </div>     

        <div *ngIf="ind !== 0" class="card p-5 msg-history-card ms-3 mb-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="mb-2 fw-bold">{{item.actor}}</div>
            <div class="text-white-50">{{formatDate(item.time)}}</div>
          </div>
          <div *ngIf="ind !== 0">{{item?.message}}</div>
        </div>
        
      </div>
    </div>
  </div>
  </div>
  <div *ngIf="purchaseOrderMsgs">
    <div class="card-body px-5 pb-4" *ngIf="((poMessageCount$ | async) ?? 0) > 0; else noData">   
      <div class="message-cards overflow-auto"  >
        <div *ngFor="let items of poMessages$ | async;let ind1 = index">
          <div *ngFor="let item of items.messages;let ind = index">
          <div *ngIf="ind === 0" class="p-5 bg-gray-600 mb-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mb-2 fw-bold">{{item.subGroupCode}}&nbsp;&nbsp;-&nbsp;&nbsp;{{item.messageSender}}&nbsp;&nbsp;Item# {{item.itemNumber}}</div>
              <div class="text-white-50">{{formatDate(item.messageDate)}}</div>
            </div>
            <div *ngIf="ind === 0">{{item?.messageBody}}</div>
            <ng-container *featurePermission="'poi-poc:action:po-change-request:reply-po-message'">
              <div *ngIf="ind == 0 && item.showReplyIcon" class="text-end"><a (click)="openReplyPopup(item,'purchaseOrderNumber')" class="material-icons mi-24 text-decoration-none" data-bs-target="#reply-offcanvas" aria-controls="reply-offcanvas" data-bs-toggle="offcanvas">reply</a></div>
            </ng-container>
          </div>     
  
          <div *ngIf="ind !== 0" class="card p-5 msg-history-card ms-3 mb-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mb-2 fw-bold">{{item.subGroupCode}}&nbsp;&nbsp;-&nbsp;&nbsp;{{item.messageSender}}&nbsp;&nbsp;Item# {{item.itemNumber}}</div>
              <div class="text-white-50">{{formatDate(item.messageDate)}}</div>
            </div>
            <div *ngIf="ind !== 0">{{item?.messageBody}}</div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noData>
    <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
      <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">cloud_off</span></span></p>
      <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
    </div>
  </ng-template>
</div>
