import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

@Component({
    selector: 'cds-ui-callback',
    template: ''
})

export class CallbackComponent {
    constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {

        this.oidcSecurityService
            .checkAuth()
            .subscribe(x => {                
                if (!x.isAuthenticated) {
                    this.oidcSecurityService.authorize();
                }
                else {
                    let callbackUrl = localStorage.getItem('previousLocation') ?? "";
                    localStorage.removeItem('previousLocation');

                    const url = callbackUrl.includes("callback") ? "" : callbackUrl;

                    url.indexOf('?') === -1
                        ? this.router.navigate([url])
                        : this.routeWithQueryParameter(url);
                }
            });
    }

    routeWithQueryParameter(url: string) {
        const urlParts = url.split("?");
        const path = urlParts.slice(0, 1).join("");
        const queryParameterString = urlParts.slice(1).join("");
        const queryParameterParts = queryParameterString.split("&");

        const queryParameterList = queryParameterParts.map(x => {
            const parts = x.split("=");
            const key = parts.slice(0, 1).join("");
            const value = parts.slice(1).join("");

            return { key, value }
        });

        const queryParameters = new Map(queryParameterList.map(i => [i.key, i.value]));

        return this.router.navigate([path], {
            queryParams: Object.fromEntries(queryParameters),
            queryParamsHandling: "merge"
        });
    }
}
