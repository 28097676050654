import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GhostingDialogService } from './ghosting-dialog.service';
import { CompleterService } from 'ng2-completer';
import { AuthService } from '@cds-ui/shared/auth';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { AppStateRepository } from '@cds-ui/shared/core-state';

@Component({
  selector: 'cds-ui-ghosting-dialog',
  templateUrl: './ghosting-dialog.component.html',
  styleUrls: ['./ghosting-dialog.component.scss'],
})
export class GhostingDialogComponent implements OnInit, OnDestroy {
  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router,
    private toastrService: ToastrService, private ghostingDialogService: GhostingDialogService,
    private completerService: CompleterService, private appState: AppStateRepository) { }

  private destroy$$ = new Subject<void>();
  public ghostPermission$$ = new BehaviorSubject<string[]>([]);
  public hasGhostUser$ = this.ghostPermission$$.pipe(map(x => x.some(p => p === 'user:action:user:ghost')));
  public hasGhostSubgroup$ = this.ghostPermission$$.pipe(map(x => x.some(p => p === 'user:action:subgroup:ghost')));

  searchUserField: any;
  searchSubgroupField: any;
  datasourceUserId: any;
  datasourceSubgroupCd: any;
  ghostUserArr: any;
  @ViewChild('completerUserId') completerUserId: any;
  @ViewChild('completerSubCd') completerSubCd: any;
  ghostingForm = this.fb.group({
    searchUserField: ['', Validators.required],
    searchSubgroupField: ['', Validators.required]
  });

  ngOnInit(): void {
    this.ghostingDialogService.getGhostUser$.subscribe(response => {
      if (response) {
        this.getGhostHistory();
      }
    });

    const urlUserId = '/menu/view/GetGhostUserTypeAhead?SearchValue=';
    const urlSubgroupCd = '/menu/view/GetSubGroupCodeTypeAhead?SearchValue=';

    this.hasGhostUser$.pipe(
      takeUntil(this.destroy$$),
      withLatestFrom(this.appState.currentUser$),
      tap(([x, u]) => x
        ? (this.datasourceUserId = this.completerService.remote(urlUserId, 'userId', 'userId'))
        : (this.ghostingForm.patchValue({ searchUserField: u.orginalUserId }))
      )
    )
      .subscribe();

    this.hasGhostSubgroup$.pipe(
      takeUntil(this.destroy$$),
      withLatestFrom(this.appState.currentUser$),
      tap(([x, u]) => x
        ? (this.datasourceSubgroupCd = this.completerService.remote(urlSubgroupCd, 'subgroupCd', 'subgroupCd'))
        : (this.ghostingForm.patchValue({ searchSubgroupField: u.orginalSubgroupCode }))
      )
    )
      .subscribe();
  }

  onSelectedUserId(item: any) {
    this.hasGhostUser$.pipe(
      take(1),
      filter(x => x),
      withLatestFrom(this.appState.currentUser$),
      tap(([x, u]) => this.ghostingForm.patchValue({
        searchUserField: x
          ? item.originalObject.userId
          : u.orginalUserId
      }))
    )
      .subscribe();

    this.hasGhostSubgroup$.pipe(
      take(1),
      filter(x => x),
      withLatestFrom(this.appState.currentUser$),
      tap(([x, u]) => this.ghostingForm.patchValue({
        searchSubgroupField: x
          ? item.originalObject.subgroupCd
          : u.orginalSubgroupCode
      }))
    )
      .subscribe();

  }

  onSelectedSubgroupCd(item: any) {
    console.log('On Selected Subgroup Code');
  }

  getGhostHistory() {
    this.ghostingDialogService.getUserGhostHistory()
      .subscribe(response => {
        this.ghostUserArr = response;
      });
  }

  ghostUser() {
    this.ghostingDialogService.getGhostUserValidate(this.completerUserId._searchStr, this.completerSubCd._searchStr).subscribe(response => {
      if (response) {
        this.authService.ghostUserFromAuthServer(this.completerUserId._searchStr, this.completerSubCd._searchStr)
          .subscribe(_ => {
            this.router.navigate(['ghosting/ghosting-confirmation'])
              .then(_ => window.location.reload());
          });
      } else {
        this.toastrService.error(`You do not have the proper credentials
                to ghost this user or the User ID is invalid.`);
      }
    });
  }

  populateGhostUser(userId: any, subgroupCd: any) {
    this.ghostingForm.setValue({ searchUserField: userId, searchSubgroupField: subgroupCd });

    this.hasGhostUser$.pipe(
      take(1),
      withLatestFrom(this.appState.currentUser$),
      tap(([x, u]) => this.ghostingForm.patchValue({
        searchUserField: x
          ? userId
          : u.orginalUserId
      }))
    )
      .subscribe();

    this.hasGhostSubgroup$.pipe(
      take(1),
      withLatestFrom(this.appState.currentUser$),
      tap(([x, u]) => this.ghostingForm.patchValue({
        searchSubgroupField: x
          ? subgroupCd
          : u.orginalSubgroupCode
      }))
    )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

}
