import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { FormBuilder } from '@ngneat/reactive-forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { PocWithdrawalDialogueEvent, PocWithdrawalEventModalConfig, PocWithdrawalModalService } from './poc-withdrawal-modal.service';

@Component({
  selector: 'cds-ui-poc-withdrawal-modal',
  templateUrl: './poc-withdrawal-modal.component.html',
  styleUrls: ['./poc-withdrawal-modal.component.scss'],
})
export class PocWithdrawalModalComponent {

  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: PocWithdrawalEventModalConfig;
  public pocWithdrawalForm = new FormGroup({});
  @ViewChild('template') template!: TemplateRef<any>;
  pocWithdrawalValue = "";
  
  constructor(
    private modalservice: BsModalService,
    private pocWithdrawalModalService: PocWithdrawalModalService,
    private appStateRepository: AppStateRepository,
    private fb: FormBuilder
  ) {}

  withdrawalForm() {
    this.pocWithdrawalForm = this.fb.group({
      withdrawNote: ['', Validators.required]
    })
  }
  ngAfterViewInit() {
    this.withdrawalForm();
    this.subscribeOverwrittenExisting();
    this.pocWithdrawalModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.pocWithdrawalForm.reset();
        this.config = error as PocWithdrawalEventModalConfig;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      PocWithdrawalDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      PocWithdrawalDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      PocWithdrawalDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      PocWithdrawalDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: PocWithdrawalDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.pocWithdrawalModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
 
  close(){
    this.modalRef.hide()
    this.pocWithdrawalForm.reset();
  }

  onPocWithdrawalClick(note: any) {
    this.pocWithdrawalModalService.setClosingPocWithdrawal(this.pocWithdrawalForm.value.withdrawNote,note);
  }

  private subscribeOverwrittenExisting() {
    this.pocWithdrawalModalService.onClosingPocWithdrawalRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}

