import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, filter, forkJoin, map, mergeMap, shareReplay, Subject, switchMap, takeUntil, tap, take, distinctUntilChanged, Observable, skipUntil, debounceTime, pipe, UnaryFunction, withLatestFrom } from "rxjs";
import { filterNullish } from '@cds-ui/shared/core-rx';
import { Buyer, Company, GetUserPermissionsGQL, GetUserPermissionsQuery } from '@cds-ui/data-access';
import { createStore, select, withProps } from "@ngneat/elf";
import { Injectable, OnDestroy } from "@angular/core";
import { AppTheme } from './props/app-theme.props';
import { AppState, AppLrm } from './props/app-state.prop';
import { AppFilter } from './props/app-filter.prop';
import { AppEnvironment, Environment } from './props/app-env.prop';
import { Apollo } from "apollo-angular";
import { CurrentUserControl, User } from "./props/user-state.prop";
import _ from 'lodash';

const store = createStore(
    { name: 'app-state' },
    withProps<AppTheme>({ theme: { grid: 'ag-theme-alpine-dark', apptheme: 'dark' } }),
    withProps<AppLrm>({lrmData : { findDisplay: "", replaceDisplay: "" }}),
    withProps<AppState>({ hasInit: false, isLoading: false, useFlex: true, showLogoOnly: false, showHeader: true, scrollLock: false }),
    withProps<AppFilter>({advanceSearchData:{}, avaibleCompanies: [], activeCompanyCode: [],activeBuyerCode: [], availableBuyers: [], nonFilteredRequest: [], usingCompanyFilter: false,usingBuyerFilter:false, selectAllFilter:false }),
    withProps<AppEnvironment>({ environment: null }),
    withProps<CurrentUserControl>({ currentUser: null, features: [], officeCode: null, persona: null, firstName: '' }),
);

@Injectable({ providedIn: 'root' })
export class AppStateRepository implements OnDestroy {
  public bypassCompanyFilterRequest = [
    'GetUserPermissions',
    'search/suggestions',
    'QueryPermission',
    'QueryDomainSetting',
  ];
  public appTheme$ = store.pipe(select((state) => state.theme.apptheme));

  private lrmCacheRoute = '/redisCache/view/GetLanguageResourceBySubgroup';

  private trackers$$ = new BehaviorSubject<BehaviorSubject<boolean>[]>([]);
  public ghostedUserPreferences$ = new BehaviorSubject<any>([]);
  private destory$ = new Subject<void>();

  public initState$ = store.pipe(select((state) => state.hasInit));
  public currentUser$ = store.pipe(
    filter((x) => !!x.currentUser),
    select((state) => ({
      ...state.currentUser,
      officeCode: state.officeCode,
      persona: state.persona,
      firstName: state.firstName,
    })),
    map((x) => ({
      ...x,
      hasGhosted:
        `${x.userId}-${x.subgroupCode}`.toLowerCase() !==
        `${x.orginalUserId}-${x.orginalSubgroupCode}`.toLowerCase(),
    })),
    distinctUntilChanged((a, b) => _.isEqual(a, b)),
    shareReplay(1)
  );

  public features$ = store.pipe(
    select((state) => state.features),
    filter((x) => !!x.length),
    withLatestFrom(this.currentUser$),
    map(([features, user]) =>
      user.hasGhosted ? features.concat(['user:ghosting']) : features
    ),
    shareReplay(1)
  );

  public useScrollLock$ = store.pipe(select((state) => state.scrollLock));
  public useFlex$ = store.pipe(select((state) => state.useFlex));
  public showLogoOnly$ = store.pipe(select((state) => state.showLogoOnly));
  public showHeader$ = store.pipe(select((state) => state.showHeader));
  public isLoading$ = store.pipe(select((state) => state.isLoading));
  public theme$ = store.pipe(select((state) => state.theme));
  public lrmData$ = store.pipe(select((state) => state.lrmData));
  public advanceSearchData$ = store.pipe(select((state) => state.advanceSearchData))
  public environment$ = store.pipe(select((state) => state.environment)).pipe(
    filterNullish(),
    map((x) => ({
      ...x,
      urls: {
        ...x.urls,
        legacyServer: window.location.origin.includes('localhost')
          ? 'https://www.testcenturyvms.com'
          : window.location.origin,
      },
    }))
  );

  public nonFilteredRequest$ = store.pipe(
    select((state) => state.nonFilteredRequest)
  );
  public usingCompanyFilter$ = store.pipe(
    select((state) => state.usingCompanyFilter)
  );
  public usingBuyerFilter$ = store.pipe(
    select((state) => state.usingBuyerFilter)
  );
  public selectAllFilter$ = store.pipe(
    select((state) => state.selectAllFilter)
  );

  public availableCompanies$ = store.pipe(
    select((state) => state.avaibleCompanies),
    filter((x) => !!x.length),
    distinctUntilChanged((a, b) =>
      _.isEqual(
        a.map((x) => x.companyCode),
        b.map((x) => x.companyCode)
      )
    ),
    shareReplay(1)
  );

  public availableBuyers$ = store.pipe(
    select((state) => state.availableBuyers),
    // filter(x => !!x.length),
    distinctUntilChanged(
      (a, b) =>
        _.isEqual(
          a?.map((x) => x.buyerCode) ?? [],
          b?.map((x) => x.buyerCode)
        ) ?? []
    ),
    shareReplay(1)
  );
  public activeBuyer$ = store
    .pipe(select((state) => state.activeBuyerCode))
    .pipe(
      distinctUntilChanged((a, b) =>
        _.isEqual(
          _.sortBy(a.map((x) => x.buyerCode)),
          _.sortBy(b.map((x) => x.buyerCode))
        )
      ),
      debounceTime(10),
      map((x) => x as Buyer[]),
      shareReplay(1)
    );

  public activeCompany$ = store
    .pipe(select((state) => state.activeCompanyCode))
    .pipe(
      distinctUntilChanged((a, b) =>
        _.isEqual(
          _.sortBy(a.map((x) => x.companyCode)),
          _.sortBy(b.map((x) => x.companyCode))
        )
      ),
      debounceTime(10),
      map((x) => x as Company[]),
      shareReplay(1)
    );

  constructor(private apollo: Apollo, private http: HttpClient) {
    const appThemeCache =
      (window.localStorage.getItem('appTheme') as 'dark' | 'light') ??
      store.query((x) => x.theme.apptheme);

    this.setThemeMode(appThemeCache);

    this.trackers$$
      .pipe(
        takeUntil(this.destory$),
        filter((arr) => arr.length > 0),
        map((arr) => forkJoin({ ...arr.filter((x) => !x.getValue()) })),
        tap(() => this.setLoadingState(true)),
        switchMap((tack) => tack)
      )
      .subscribe(() => {
        this.trackers$$.next([]);
        this.setLoadingState(false);
      });
  }

  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }

  public setEnvironment(environment: Environment) {
    if (store.query((x) => x.environment)) return;

    store.update((state) => ({
      ...state,
      environment: environment,
    }));
  }

   getLRMData(){
    this.http.get(this.lrmCacheRoute)
    .pipe(
      map(x => x as ({ findDisplay: any; replaceDisplay: any; })),
      map(a => {
        store.update((state) => ({
          ...state,
          lrmData: a,
        }));
      })
    ).subscribe();
  }

  public setNonFilteredRequest(filter: string[]) {
    store.update((state) => ({
      ...state,
      nonFilteredRequest: filter,
    }));
  }

  public setUsingCompanyFilter(shouldUse: boolean) {
    store.update((state) => ({
      ...state,
      usingCompanyFilter: shouldUse,
    }));
  }
  public setUsingBuyerFilter(shouldUse: boolean) {
    store.update((state) => ({
      ...state,
      usingBuyerFilter: shouldUse,
    }));
  }
  public setSelectAllFilter(shouldUse: boolean) {
    store.update((state) => ({
      ...state,
      selectAllFilter: shouldUse,
    }));
  }
  public setActiveCompanies(companyCodes: Company[] | '*') {
    store.update((state) => ({
      ...state,
      activeCompanyCode:
        companyCodes === '*'
          ? state.avaibleCompanies.map((x) => ({ ...x }))
          : companyCodes,
    }));
  }

  public setActiveBuyers(buyerCodes: Buyer[] | '*') {
    store.update((state) => ({
      ...state,
      activeBuyerCode:
        buyerCodes === '*'
          ? state.availableBuyers.map((x) => ({ ...x }))
          : buyerCodes,
    }));
  }

  public useScrollLock(scrollLock: boolean) {
    store.update((state) => ({
      ...state,
      scrollLock,
    }));
  }

  public useFlex(useFlex: boolean) {
    store.update((state) => ({
      ...state,
      useFlex,
    }));
  }

  public setThemeMode(apptheme: 'dark' | 'light') {
    store.update((state) => ({
      ...state,
      theme: { grid: state.theme.grid, apptheme: apptheme },
    }));
  }

  public toggleLogoOnly(showLogoOnly: boolean) {
    store.update((state) => ({
      ...state,
      showLogoOnly,
    }));
  }

  public toggleShowHeader(showHeader: boolean) {
    store.update((state) => ({
      ...state,
      showHeader,
    }));
  }

  public advanceSearch(advanceSearchData: any) {
    store.update((state) => ({
      ...state,
      advanceSearchData,
    }));
  }

  public trackRequest(request: BehaviorSubject<boolean>) {
    const tracker = this.trackers$$;
    this.trackers$$.next([...tracker.getValue(), request]);
  }

  public setCurrentUser(user: User) {
    store.update((state) => ({
      ...state,
      currentUser: user,
    }));
  }

  public loadPermission() {
    combineLatest([
      this.currentUser$.pipe(
        map((x) => x.subgroupCode),
        distinctUntilChanged()
      ),
      this.currentUser$.pipe(
        map((x) => x.userId),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        skipUntil(this.initLoadPermission()),
        debounceTime(500),
        this.retrievePermission()
      )
      .subscribe();
  }

  private initLoadPermission() {
    return this.currentUser$.pipe(
      filterNullish(),
      take(1),
      this.retrievePermission(),
      tap(() => this.setActiveCompanies('*')),
      tap(() => this.setActiveBuyers('*')),
      tap(() => this.getLRMData()),
      tap(() =>
        store.update((state) => ({
          ...state,
          hasInit: true,
        }))
      )
    );
  }

  private retrievePermission<T>(): UnaryFunction<
    Observable<T>,
    Observable<GetUserPermissionsQuery>
  > {
    return pipe(
      mergeMap(() =>
        new GetUserPermissionsGQL(this.apollo).fetch(
          {},
          { fetchPolicy: 'no-cache' }
        )
      ),
      map((result) => result.data),
      tap((data) =>
        store.update((state) => ({
          ...state,
          officeCode: data.currentUser.officeCode ?? null,
          persona: data.currentUser.persona ?? null,
          features: data.currentUser.featurePermissions,
          firstName: data.currentUser.firstName,
        }))
      ),
      distinctUntilChanged((a, b) =>
        _.isEqual(
          _.sortBy(a.currentUser.companies, (x) => x.companyCode),
          _.sortBy(b.currentUser.companies, (x) => x.companyCode)
        )
      ),
      tap((data) => {
        store.update((state) => ({
          ...state,
          avaibleCompanies: (data.currentUser.companies.filter((x) => !!x) ??
            []) as Company[],
          availableBuyers: (data.currentUser.buyers.filter((x) => !!x) ??
            []) as Buyer[],
        }));
      })
    );
  }

  private setLoadingState(isLoading: boolean) {
    store.update((state) => ({
      ...state,
      isLoading,
    }));
  }
}
