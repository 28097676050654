import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, of, shareReplay } from 'rxjs';

export type ProgressbarSegment = {
  label: string,
  count: number,
  color: string,
  description?: string
};

@Component({
  selector: 'cds-ui-progressbar-composition',
  templateUrl: './progressbar-composition.component.html'
})
export class ProgressbarCompositionComponent {
  @Input() title!: string;
  @Input() set input$(parent: Observable<ProgressbarSegment[]>) {
    this.totalSubject.next(parent);
  }
  @Output() selected$ = new EventEmitter<ProgressbarSegment>();

  private readonly totalSubject = new BehaviorSubject<Observable<ProgressbarSegment[]>>(of([]));
  public readonly segments$: Observable<ProgressbarSegment[]> = this.totalSubject.asObservable().pipe(
    mergeMap(x => x),
    shareReplay(1)
  );

  public readonly total$ = this.segments$.pipe(
    map(x => x.reduce((p, c) => p + c.count, 0)),
    shareReplay(1)
  );

  public selectItem(segment: ProgressbarSegment | any) {
    this.selected$.emit(segment);
  }
}
