import { ColDef } from "@ag-grid-community/core";

export const defaultColumns: ColDef =  {   
   filter:true,
    floatingFilter: true,
    filterParams: {
      trimInput: true,
    },
    resizable: true,
    sortable: true,
    enableRowGroup: true,
    cellEditor: 'agTextCellEditor',
  }
  export enum EventSourceType  {
    uiColumnResized = 'uiColumnResized',
    paginationChanged ='paginationChanged'
  }
