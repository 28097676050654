import { AfterViewInit, Component, EventEmitter, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, Observable, of, Subject, takeUntil, tap, timeout } from 'rxjs';
import { AdvanceSearchDialogueEvent, AdvanceSearchEventModalConfig, AdvanceSearchModalService } from './advance-search-modal.service';
import { ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MainMenuService, SystemSearchResult } from '../main-header-nav-bar/main-menu.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Router } from '@angular/router';
import moment from 'moment';
import { GetContainerGridGlobalSearchGQL, GetMileStoneGridSearchGQL, GetPurchaseOrderSearchGridGQL, GetShipmentGridGlobalSearchGQL } from '@cds-ui/data-access';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'cds-ui-advance-search-modal',
  templateUrl: './advance-search-modal.component.html',
  styleUrls: ['./advance-search-modal.component.scss'],
})
export class AdvanceSearchModalComponent implements OnDestroy, AfterViewInit{

  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: AdvanceSearchEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  onBtnTopic: string = "";
  onContainerClick: boolean = false;
  onPOClick: boolean = false;
  onShipmentClick: boolean = false;
  onMileStoneClick: boolean = false;
  dropdownList: any = [];
  selectedItems: any = [];
  filterCriteria: any[] = [];
  containerCriteria: any[] = [{
    type: "Keys",
    criteria:
      [

        {
          value: 'Keys=Booking #', name: 'Booking #'
        },
        {
          value: 'Keys=Container #', name: 'Container #'
        },
        {
          value: 'Keys=HBL #', name: 'HBL #'
        },
        {
          value: 'Keys=Item #', name: 'Item #'
        },
        {
          value: 'Keys=MBL #', name: 'MBL #'
        },
        {
          value: 'Keys=PO #', name: 'PO #'
        },
        {
          value: 'Keys=Ship Key', name: 'Ship Key'
        },


      ]
  },
  {
    type: "Dates",
    criteria:
      [{
        value: 'Dates=Actual Vessel Arrive', name: 'Actual Vessel Arrive'
      },
      {
        value: 'Dates=Est Feeder Depart', name: 'Est Feeder Depart'
      },
      {
        value: 'Dates=Est Mother Depart', name: 'Est Mother Depart'
      },
      {
        value: 'Dates=Est Vessel Arrive', name: 'Est Vessel Arrive'
      }]
  },
  {
    type: "Status",
    criteria:
      [{
        value: 'Status=Container Late Status', name: 'Container Late Status'
      },
      {
        value: 'Status=Current Milestone', name: 'Current Milestone'
      },
      {
        value: 'Status=Hot', name: 'Hot'
      }]
  },
  {
    type: "Additional",
    criteria:
      [
        {
          value: 'Additional=Company', name: 'Company'
        },
        {
          value: 'Additional=Final Destination', name: 'Final Destination'
        },
        {
          value: 'Additional=Mother Depart Port', name: 'Mother Depart Port'
        },
        {
          value: 'Additional=Mother Vessel', name: 'Mother Vessel'
        },
        {
          value: 'Additional=Origin Port', name: 'Origin Port'
        },
        {
          value: 'Additional=Vessel Arrive Port', name: 'Vessel Arrive Port'
        },
        {
          value: 'Additional=Vendor (code/name)', name: 'Vendor (code/name)'
        }]
  }
  ]


  poCriteria: any[] = [{
    type: "Keys",
    criteria:
      [
        {
          value: 'Keys=Container #', name: 'Container #'
        },
        {
          value: 'Keys=Item #', name: 'Item #'
        },
        {
          value: 'Keys=MBL #', name: 'MBL #'
        },
        {
          value: 'Keys=PO #', name: 'PO #'
        },
        {
          value: 'Keys=Ship Key', name: 'Ship Key'
        }]
  },
  {
    type: "Dates",
    criteria:
      [{
        value: 'Dates=First Ship Date', name: 'First Ship Date'
      },
      {
        value: 'Dates=In Warehouse Date', name: 'In Warehouse Date'
      },
      {
        value: 'Dates=Last Ship Date', name: 'Last Ship Date'
      }]
  },
  {
    type: "Status",
    criteria:
      [
        {
          value: 'Status=Booking Status', name: 'Booking Status'
        },
        {
          value: 'Status=Hot', name: 'Hot'
        },
        {
          value: 'Status=Item Status', name: 'Item Status'
        },
        {
          value: 'Status=PO Status', name: 'PO Status'
        },


      ]
  },
  {
    type: "Additional",
    criteria:
      [

        {
          value: 'Additional=Event Code', name: 'Event Code'
        },
        {
          value: 'Additional=Vendor (code/name)', name: 'Vendor (code/name)'
        },
        {
          value: 'Additional=Company', name: 'Company'
        }

      ]
  }
  ]

  shipmentCriteria: any[] = [{
    type: "Keys",
    criteria:
      [
        {
          value: 'Keys=Booking #', name: 'Booking #'
        },
        {
          value: 'Keys=Commercial Invoice #', name: 'Commercial Invoice #'
        },
        {
          value: 'Keys=Container #', name: 'Container #'
        },
        {
          value: 'Keys=FCR #', name: 'FCR #'
        },
        {
          value: 'Keys=HBL #', name: 'HBL #'
        },
        {
          value: 'Keys=Item #', name: 'Item #'
        },
        {
          value: 'Keys=MBL #', name: 'MBL #'
        },
        {
          value: 'Keys=PO #', name: 'PO #'
        },
        {
          value: 'Keys=Ship Key', name: 'Ship Key'
        }





      ]
  },
  {
    type: "Dates",
    criteria:
      [
        {
          value: 'Dates=Actual Vessel Arrive', name: 'Actual Vessel Arrive'
        },
        {
          value: 'Dates=Est Feeder Depart', name: 'Est Feeder Depart'
        },
        {
          value: 'Dates=Est Mother Depart', name: 'Est Mother Depart'
        },
        {
          value: 'Dates=Est Vessel Arrive', name: 'Est Vessel Arrive'
        },

      ]
  },
  {
    type: "Status",
    criteria:
      [{
        value: 'Status=Container Late Status', name: 'Container Late Status'
      },
      {
        value: 'Status=Current Milestone', name: 'Current Milestone'
      },
      {
        value: 'Status=Hot', name: 'Hot'
      },
      {
        value: 'Status=Ship Status', name: 'Ship Status'
      }
      ]
  },
  {
    type: "Additional",
    criteria:
      [
        {
          value: 'Additional=Company', name: 'Company'
        },
        {
          value: 'Additional=Final Destination', name: 'Final Destination'
        },
        {
          value: 'Additional=Mother Depart Port', name: 'Mother Depart Port'
        },
        {
          value: 'Additional=Mother Vessel', name: 'Mother Vessel'
        },
        {
          value: 'Additional=Origin Port', name: 'Origin Port'
        },
        {
          value: 'Additional=Vessel Arrive Port', name: 'Vessel Arrive Port'
        },
        {
          value: 'Additional=Vendor (code/name)', name: 'Vendor (code/name)'
        }
      ]
  }
  ]

  milestoneCriteria: any[] = [{
    type: "Keys",
    criteria:
      [
        {
          value: 'Keys=Booking #', name: 'Booking #'
        },
        {
          value: 'Keys=Container #', name: 'Container #'
        },
        {
          value: 'Keys=Commercial Invoice #', name: 'Commercial Invoice #'
        },
        {
          value: 'Keys=FCR #', name: 'FCR #'
        },
        {
          value: 'Keys=HBL #', name: 'HBL #'
        },
        {
          value: 'Keys=Item #', name: 'Item #'
        },
        {
          value: 'Keys=MBL #', name: 'MBL #'
        },
        {
          value: 'Keys=PO #', name: 'PO #'
        },
        {
          value: 'Keys=Ship Key', name: 'Ship Key'
        },





      ]
  },
  {
    type: "Dates",
    criteria:
      [
        {
          value: 'Dates=Actual Vessel Arrive', name: 'Actual Vessel Arrive'
        },
        {
          value: 'Dates=Est Feeder Depart', name: 'Est Feeder Depart'
        },
        {
          value: 'Dates=Est Mother Depart', name: 'Est Mother Depart'
        },
        {
          value: 'Dates=Est Vessel Arrive', name: 'Est Vessel Arrive'
        },
      ]
  },
  {
    type: "Status",
    criteria:
      [
        {
          value: 'Status=Booking Status', name: 'Booking Status'
        },
        {
          value: 'Status=Container Late Status', name: 'Container Late Status'
        },
        {
          value: 'Status=Current Milestone', name: 'Current Milestone'
        },
        {
          value: 'Status=Hot', name: 'Hot'
        },
        {
          value: 'Status=Ship Status', name: 'Ship Status'
        },


      ]
  },
  {
    type: "Additional",
    criteria:
      [
        {
          value: 'Additional=Company', name: 'Company'
        },
        {
          value: 'Additional=Event Code', name: 'Event Code'
        },
        {
          value: 'Additional=Final Destination', name: 'Final Destination'
        },
        {
          value: 'Additional=Mother Depart Port', name: 'Mother Depart Port'
        },
        {
          value: 'Additional=Mother Vessel', name: 'Mother Vessel'
        },
        {
          value: 'Additional=Origin Port', name: 'Origin Port'
        },
        {
          value: 'Additional=Vessel Arrive Port', name: 'Vessel Arrive Port'
        },
        {
          value: 'Additional=Vendor (code/name)', name: 'Vendor (code/name)'
        },





      ]
  }
  ]

  containerLateStatus = [
    { value: '0', name: 'On-time' },
    { value: '2', name: 'Late 1-10' },
    { value: '1', name: 'Late 10+' }
  ]
  containerMileStoneStatus = [
    { value: 'ContainerAtOriginPort', name: 'Container At Origin Port' },
    { value: 'ContainerOnWater', name: 'Container On Water' },
    { value: 'ContainerAtDischarge Port', name: 'Container At Discharge Port' },
    { value: 'ContainerAtFinalMile', name: 'Container At Final Mile' },
    { value: 'Indeterminate', name: 'Indeterminate' },
    // { value: 'Completed', name: 'Completed' }
  ]
  containerStatusHot = [
    { value: 'true', name: 'True' },
    { value: 'false', name: 'False' }
  ]
  POStatusHot = [
    { value: 'true', name: 'True' },
    { value: 'false', name: 'False' }
  ]

  shipLateStatus = [
    { value: '0', name: 'On-time' },
    { value: '2', name: 'Late 1-10' },
    { value: '1', name: 'Late 10+' }
  ]
  shipMileStoneStatus = [
    { value: 'ContainerAtOriginPort', name: 'Container At Origin Port' },
    { value: 'ContainerOnWater', name: 'Container On Water' },
    { value: 'ContainerAtDischargePort', name: 'Container At Discharge Port' },
    { value: 'ContainerAtFinalMile', name: 'Container At Final Mile' },
    { value: 'Indeterminate', name: 'Indeterminate' },
    // { value: 'Completed', name: 'Completed' }
  ]

  ShipStatusHot = [
    { value: 'true', name: 'True' },
    { value: 'false', name: 'False' }
  ]

  MileStatusHot = [
    { value: 'true', name: 'True' },
    { value: 'false', name: 'False' }
  ]

  MileLateStatus = [
    { value: '0', name: 'On=time' },
    { value: '2', name: 'Late 1-10' },
    { value: '1', name: 'Late 10+' }
  ]
  MileMileStoneStatus = [
    { value: 'ContainerAtOriginPort', name: 'Container At Origin Port' },
    { value: 'ContainerOnWater', name: 'Container On Water' },
    { value: 'ContainerAtDischargePort', name: 'Container At Discharge Port' },
    { value: 'ContainerAtFinalMile', name: 'Container At Final Mile' },
    { value: 'Indeterminate', name: 'Indeterminate' },
    // { value: 'Completed', name: 'Completed' }
  ]
  keysOpt = [
    { value: 'Keys=Equals', name: 'Equals' },
    { value: 'Keys=In List (x,x,x)', name: 'In List (x,x,x)' },
    { value: 'Keys=Starts With', name: 'Starts With' },
    { value: 'Keys=Ends With', name: 'Ends With' }
  ]
  additionalOpt = [
    { value: 'Additional=Equals', name: 'Equals' },
    { value: 'Additional=In List (x,x,x)', name: 'In List (x,x,x)' },
    { value: 'Additional=Starts With', name: 'Starts With' },
    { value: 'Additional=Ends With', name: 'Ends With' }
  ]
  additionalVendorOpt = [
    { value: 'Additional=Equals', name: 'Equals' },
    { value: 'Additional=In List (x,x,x)', name: 'In List (x,x,x)' }
  ]
  datesOpt = [
    { value: 'Dates=Today', name: 'Today' },
    { value: 'Dates=Current Week', name: 'Current Week' },
    { value: 'Dates=Current Month', name: 'Current Month' },
    { value: 'Dates=Past 5 Days; Next 5 Days', name: 'Past 5 Days; Next 5 Days' },
    { value: 'Dates=Past 10 Days; Next 10 Days', name: 'Past 10 Days; Next 10 Days' },
    { value: 'Dates=Custom Date Range', name: 'Custom Date Range' }
  ]

  containerLateStatusDrop = [
    { value: '0', name: 'On-time' },
    { value: '2', name: 'Late 1-10' },
    { value: '1', name: 'Late 10+' }
  ];
  currentMilestoneDrop = [
    { value: 'ContainerAtOriginPort', name: 'Container At Origin Port' },
    { value: 'ContainerOnWater', name: 'Container On Water' },
    { value: 'ContainerAtDischargePort', name: 'Container At Discharge Port' },
    { value: 'ContainerAtFinalMile', name: 'Container At Final Mile' },
    { value: 'Indeterminate', name: 'Indeterminate' },
    // { value: 'Completed', name: 'Completed' }
  ];
  hotDrop = [
    { value: 'true', name: 'True' },
    { value: 'false', name: 'False' }
  ];
  statusDropdownMap:any[]=[];

  poStatusDrop!: statusDropDown[];
  shipmentStatusDrop!: statusDropDown[];
  bookingStatusDrop!: statusDropDown[];


  filterCriteriaVal = "";
  suggestionText = 'Info Text'
  errorText = 'Please add a date from the filters list to search.'
  public errorValidation: boolean = false;
  public suggestionValidation: boolean = false;
  public noResult = false;
  public placeholderText = 'Search..';
  public typeaheadLoading!: boolean;
  public suggestions$!: Observable<SystemSearchResult[]>;
  public vendorSuggestions$!: Observable<any>;
  public search!: string;
  disableBtn: boolean = false;
  onLoad: boolean = false;
  public keyNames!: string;
  selectedFilterData: any[] = [];
  selectedLabel: any[] = [];
  public position: boolean = true;
  datePickerConfig: any;
  filterCriteriaForm = formGroup
  maxDate = new Date();
  minDate = new Date();
  typeHeadIndex!: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false,
    enableCheckAll: false,
    itemsShowLimit: 2,
  };
  constructor(
    private modalservice: BsModalService,
    private AdvanceSearchModalService: AdvanceSearchModalService,
    private appStateRepository: AppStateRepository,
    private toastrService: ToastrService,
    private mainMenuService: MainMenuService,
    private router: Router,
    private apollo: Apollo
  ) { }

  dropDownStatus() {
    this.poStatusDrop = [];
    this.shipmentStatusDrop = [];
    this.bookingStatusDrop = [];
    this.mainMenuService.getPoStatus().subscribe(x => {
      x.forEach((el: any) => {
        this.poStatusDrop.push({ value: el.statusCode, name: el.statusName })
      });
    });
    this.mainMenuService.getShipmentStatus().subscribe(x => {
      x.forEach((el: any) => {
        this.shipmentStatusDrop.push({ value: el.shipStatusCode, name: el.shipStatusName })
      });
    });
    this.mainMenuService.getBookingStatus().subscribe(x => {
      x.forEach((el: any) => {
        this.bookingStatusDrop.push({ value: el.bookingStatusCode, name: el.bookingStatusName })
      });
    });
    setTimeout(() => {
      this.statusDropdownMap  = [
        ...this.containerLateStatusDrop.map((v) => ({ milestoneName: 'Container Late Status', dropdownValues: { ...v } })),
        ...this.currentMilestoneDrop.map((v) => ({ milestoneName: 'Current Milestone', dropdownValues: { ...v } })),
        ...this.poStatusDrop.map((v) => ({ milestoneName: 'PO Status', dropdownValues: { ...v } })),
        ...this.bookingStatusDrop.map((v) => ({ milestoneName: 'Booking Status', dropdownValues: { ...v } })),
        ...this.shipmentStatusDrop.map((v) => ({ milestoneName: 'Ship Status', dropdownValues: { ...v } })),
        ...this.poStatusDrop.map((v) => ({ milestoneName: 'Item Status', dropdownValues: { ...v } })),
        ...this.hotDrop.map((v) => ({ milestoneName: 'Hot', dropdownValues: { ...v } }))
    ];
    }, 2000);
  }
  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.AdvanceSearchModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.clearAll();
        this.dropDownStatus();
        this.datePickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue', adaptivePosition: this.position, isAnimated: true });
        this.config = error as AdvanceSearchEventModalConfig;

        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
        setTimeout(() => {
          if (this.config.message != 'header') {
            let updatedData: any;
            let updatedFilter: any;
            this.appStateRepository.advanceSearchData$.subscribe(val => {
              updatedData = val.advanceSearch;
              updatedFilter = val.advanceSearchFilter;
              this.selectedFilterData = updatedFilter;
              const topic = updatedData[0].topic;
              switch (topic) {
                case "Container": {
                  this.filterCriteria = this.containerCriteria
                  this.onContainerClick = true
                  break;
                }
                case "Purchase Order": {
                  this.filterCriteria = this.poCriteria
                  this.onPOClick = true
                  break;
                }
                case "Shipment": {
                  this.filterCriteria = this.shipmentCriteria
                  this.onShipmentClick = true
                  break;
                }
                default: {
                  this.filterCriteria = this.milestoneCriteria
                  this.onMileStoneClick = true
                  break;
                }
              }
              updatedData.forEach((el: any) => {
                (this.filterCriteriaForm.get('filterCriteriaFormArray') as any).push(this.updatedSearchData(el));
              });
            })
          }
        }, 1000);
      });

    this.handler(
      this.modalservice.onShow,
      AdvanceSearchDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      AdvanceSearchDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      AdvanceSearchDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      AdvanceSearchDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>, type: AdvanceSearchDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.AdvanceSearchModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAdvanceSearchClick() {
    this.AdvanceSearchModalService.setClosingAdvanceSearch(this.config);
  }

  private subscribeOverwrittenExisting() {
    this.AdvanceSearchModalService.onClosingAdvanceSearchRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
  removeRow(numb: any) {
    const index = this.filterCriteriaForm.get('filterCriteriaFormArray').controls.map((x, indx) => indx);
    index.reverse().forEach((i) => {
      if (numb == i) {
        this.selectedFilterData.splice(i, 1);
        this.filterCriteriaForm.get('filterCriteriaFormArray').removeAt(i);
        this.dateTypeDrop('')
        this.validations();
      }
    });
  }

  selectedFilterName(item: any) {
    return item.split('=')[1];
  }
  typeCheck(items: any) {
    return items.value.type;
  }
  statusCheck(items: any) {
    const statusValues = this.statusDropdownMap
      .filter((s) => s.milestoneName === items.value.label)
      .map((x) => x.dropdownValues);
    return statusValues;
  }

  statusCriteriaCheck(items: any) {
    return items.value.label;
  }
  filterSelect(items: any) {
    return items.value.label;
  }
  statusDropData(item: any) {
  }
  customDateSelect(items: any) {
    return items.value.typeDrop;
  }
  operatorSelect(items: any) {
    return items.value.typeDrop;
  }
  searchQueryId(i: any) {
    return `searchQueryId${i}`
  }

  displayDropSearch(items: any, i: any) {
    return items.value.label == (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['label'].value;
  }
  dateTimeFormat(params: any): string | any {
    if (params) {
      return moment.utc(params.toLocaleString()).format("YYYY-MM-DD") + 'T' +
        ("00" + new Date().getHours()).slice(-2) + ":" +
        ("00" + new Date().getMinutes()).slice(-2) + ":" +
        ("00" + new Date().getSeconds()).slice(-2) + ".000Z"
    } else {
      return params;
    }
  }
  onSearchClick() {
    let payLoad: any[] = [];
    let sDate: any;
    let eDate: any;
    this.filterCriteriaForm.value.filterCriteriaFormArray.forEach((x: any) => {
      switch (x.type) {
        case "Keys":
          payLoad.push({
            "searchType": this.onContainerClick ? 'Container' : this.onPOClick ? "Purchase Order" : this.onShipmentClick ? 'Shipment Detail' : 'Milestone',
            "searchName": x.label == 'PO #' ? 'PO Number' : x.label == 'Booking #' ? 'Booking Number' : x.label == 'Container #' ? 'Container Number' : x.label == 'HBL #' ?
              'HBL' : x.label == 'MBL #' ? 'MBL' : x.label == 'Item #' ?
                'Item Number' : x.label == 'Commercial Invoice #' ? 'Commercial Invoice Number' : x.label == 'FCR #' ? 'FCR' : 'Ship Key',
            "operatorType": x.typeDrop.split('=')[1] == 'In List (x,x,x)' ? 'InList' : x.typeDrop.split('=')[1] == 'Starts With' ? 'StartWith' : x.typeDrop.split('=')[1] == 'Ends With' ? 'EndWith' : 'Equals',
            "stringTypeSearchValue": {
              "searchValue": x.valueField
            }
          })
        break;
        case "Dates":
          switch (x.typeDrop.split('=')[1]) {
            case "Today":
              sDate = moment(new Date()).startOf("day").format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              eDate = moment(new Date()).endOf("day").format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              break;
            case "Current Week":
              sDate = moment(new Date()).startOf("week").format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              eDate = moment(new Date()).endOf("week").format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              break;
            case "Current Month":
              sDate = moment(new Date()).startOf("month").format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              eDate = moment(new Date()).endOf("month").format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              break;
            case 'Past 5 Days; Next 5 Days':
              sDate = moment(new Date()).startOf("day").add(-5, 'days').format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              eDate = moment(new Date()).endOf("day").add(5, 'days').format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              break;
            case "Past 10 Days; Next 10 Days":
              sDate = moment(new Date()).startOf("day").add(-10, 'days').format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              eDate = moment(new Date()).endOf("day").add(10, 'days').format("YYYY-MM-DD") + 'T' +
                ("00" + new Date().getHours()).slice(-2) + ":" +
                ("00" + new Date().getMinutes()).slice(-2) + ":" +
                ("00" + new Date().getSeconds()).slice(-2) + ".000Z";
              break;
            case "Custom Date Range":
              sDate = this.dateTimeFormat(x.startDate);
              eDate = this.dateTimeFormat(x.endDate);
              break;
          }
          payLoad.push({
            "searchType": this.onContainerClick ? 'Container' : this.onPOClick ? "Purchase Order" : this.onShipmentClick ? 'Shipment Detail' : 'Milestone',
            "searchName": x.label == 'Actual Vessel Arrive' ? 'Act Vessel Arrive' : x.label,
            "operatorType": "Between",
            "dateTypeSearchValue": {
              "startDate": sDate,
              "endDate": eDate
            }
          })
        break;
        case "Status":
          payLoad.push({
            "searchType": this.onContainerClick ? 'Container' : this.onPOClick ? "Purchase Order" : this.onShipmentClick ? 'Shipment Detail' : 'Milestone',
            "searchName": x.label,
            "operatorType": 'Equals',
            "stringTypeSearchValue": {
              "searchValue": x.typeDrop
            }
          })
        break;
        case "Additional":
          payLoad.push({
            "searchType": this.onContainerClick ? 'Container' : this.onPOClick ? "Purchase Order" : this.onShipmentClick ? 'Shipment Detail' : 'Milestone',
            "searchName": x.label == 'Vendor (code/name)' ? 'Vendor' : x.label,
            "operatorType": x.typeDrop.split('=')[1] == 'In List (x,x,x)' ? 'InList' : x.typeDrop.split('=')[1] == 'Starts With' ? 'StartWith' : x.typeDrop.split('=')[1] == 'Ends With' ? 'EndWith' : 'Equals',
            "stringTypeSearchValue": {
              "searchValue": x.label == 'Vendor (code/name)' ? x.valueField.split('-')[0] : x.valueField
            }
          })
        break;
      }
    })


    this.mainMenuService.advanceSearch(payLoad).pipe(
      takeUntil(this.destroy$),
      tap({
        next: (val) => {
          let reqBody: any;
          if(val.length){
            if (val[0].searchLevel == 'Container' ) {
              let searchKeys:any[] = []
              val.forEach((el:any) => {
                searchKeys.push({
                  "shipKey": el.shipKey,
                  "containerNumber": el.containerNumber
                })
              });
              reqBody = {
                intermodalContainerKeys: searchKeys
              }
              new GetContainerGridGlobalSearchGQL(this.apollo)
                .fetch(
                  {
                    filter: reqBody
                  },
                  { fetchPolicy: 'no-cache' }
                )
                .pipe(
                  takeUntil(this.destroy$),
                  map((a: any) => a?.data?.containerSummaries)
                )
                .subscribe((res) => {
                  this.modalRef.hide();
                  this.appStateRepository.advanceSearch({'advanceSearch':this.filterCriteriaForm.value.filterCriteriaFormArray ,'advanceSearchFilter':this.selectedFilterData})
                  this.mainMenuService.containerGridSearchSubject$.next(of(res));
                  this.router.navigate(['/global-search/container-search'], { queryParams: { filter: 'advance' } });
                });
            } else if (val[0].searchLevel == 'Shipment Detail'){
              let searchKeys:any[] = []
              val.forEach((el:any) => {
                searchKeys.push({
                  "bookSerial": el.bookSerial,
                  "shipSerial": el.shipSerial,
                  "purchaseOrderItemId": el.purchaseOrderItemId
                })
              });
              reqBody = {
                advancedSearchKeys: searchKeys
              }
              new GetShipmentGridGlobalSearchGQL(this.apollo)
                .fetch(
                  {
                    filter: reqBody
                  },
                  { fetchPolicy: 'no-cache' }
                )
                .pipe(
                  takeUntil(this.destroy$),
                  map((a: any) => a?.data?.shipmentItemSummary)
                )
                .subscribe((res) => {
                  this.modalRef.hide();
                  this.appStateRepository.advanceSearch({'advanceSearch':this.filterCriteriaForm.value.filterCriteriaFormArray ,'advanceSearchFilter':this.selectedFilterData})
                  this.mainMenuService.shipmentGridSearchSubject$.next(res);
                  this.router.navigate(['/global-search/shipment-search'], { queryParams: { filter: 'advance' } });
                });
            }else if(val[0].searchLevel == 'Purchase Order'){
              let searchKeys:any[] = []
              val.forEach((el:any) => {
                searchKeys.push({
                  "bookSerial": el.bookSerial,
                  "shipSerial": el.shipSerial,
                  "purchaseOrderItemId": el.purchaseOrderItemId
                })
              });
              reqBody = {
                advancedSearchKeys: searchKeys
              }
              new GetPurchaseOrderSearchGridGQL(this.apollo)
              .fetch(
                {
                  filter: reqBody
                },
                { fetchPolicy: 'no-cache' }
              ).pipe(takeUntil(this.destroy$), map((a: any) => a?.data?.purchaseOrderItemSummariesForSearch)).subscribe(x => {
                this.modalRef.hide();
                this.appStateRepository.advanceSearch({'advanceSearch':this.filterCriteriaForm.value.filterCriteriaFormArray ,'advanceSearchFilter':this.selectedFilterData})
                this.mainMenuService.porGridSearchSubject$.next(x);
                this.router.navigate(['/global-search/po-search'], { queryParams: { filter: 'advance' } });
                
              });
            }else if(val[0].searchLevel == 'Milestone'){
              let searchKeys:any[] = []
              val.forEach((el:any) => {
                searchKeys.push({
                  "bookSerial": el.bookSerial,
                  "shipSerial": el.shipSerial,
                  "purchaseOrderItemId": el.purchaseOrderItemId
                })
              });
              reqBody = {
                advancedSearchKeys: searchKeys
              }
              new GetMileStoneGridSearchGQL(this.apollo)
              .fetch(
                {
                  filter: reqBody
                },
                { fetchPolicy: 'no-cache' }
              )
              .pipe(
                takeUntil(this.destroy$),
                map((a) => a?.data?.supplyChainMilestoneSummaries),
                map(a=> ({
                  nodes: a?.nodes?.map(x=> ({...x, poKey:`${x?.purchaseOrderNumber}-${x?.itemNumber}-${(x?.color ?? '')}-${(x?.size ?? '')}`})) ?? [],
                  purchaseOrderItem: a?.purchaseOrderItem?.map(x=> ({...x, poKey: `${x?.poNumber}-${x?.itemNumber}-${(x?.color ?? '')}-${(x?.size ?? '')}`})) ?? [],
                  data: a
                })),
                map(a=>  {
                  return {
                    poItems:  a?.purchaseOrderItem?.map(b=> {
                        const matchedData  = a?.nodes?.find(x=> x?.poKey === b?.poKey) ?? null
                        return matchedData ?  {...b,...matchedData } : null
                      }) ?? [].filter(x=> x!== null) ?? [],
                      data: a?.data
                    }
                }),
            )
              .subscribe((res) => {
                this.modalRef.hide();
                this.appStateRepository.advanceSearch({'advanceSearch':this.filterCriteriaForm.value.filterCriteriaFormArray ,'advanceSearchFilter':this.selectedFilterData})
                this.mainMenuService.mileStoneGridSearchSubject$.next(res);
                this.router.navigate(['/global-search/milestone-search'], { queryParams: { filter: 'advance' } });
              });
            }
          } else {
            this.appStateRepository.advanceSearch({'advanceSearch':this.filterCriteriaForm.value.filterCriteriaFormArray ,'advanceSearchFilter':this.selectedFilterData})
            if(this.onContainerClick) {
              this.modalRef.hide();
              this.mainMenuService.containerGridSearchSubject$.next(null);
              this.router.navigate(['/global-search/container-search'], { queryParams: { filter: 'advance' } });
            }else if(this.onShipmentClick){
              this.modalRef.hide();
              this.mainMenuService.shipmentGridSearchSubject$.next(null);
              this.router.navigate(['/global-search/shipment-search'], { queryParams: { filter: 'advance' } });
            }else if(this.onPOClick){
              this.modalRef.hide();
              this.mainMenuService.porGridSearchSubject$.next(null);
              this.router.navigate(['/global-search/po-search'], { queryParams: { filter: 'advance' } });
            }else if(this.onMileStoneClick){
              this.modalRef.hide();
              this.mainMenuService.mileStoneGridSearchSubject$.next(null);
              this.router.navigate(['/global-search/milestone-search'], { queryParams: { filter: 'advance' } });
            }
          }
        },
        error: (e) => console.log(e)
      })
    ).subscribe()
  }
  selected(val: any) {
    return this.selectedFilterData.includes(val)
  }
  validations() {
    let typeDropList: any[] = []
    if (this.selectedFilterData.length) {
      (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls.forEach((el: any) => {
        typeDropList.push(el.controls['typeDrop'].value);
      });

      if (this.selectedFilterData.filter(x => x.includes('Additional')).length) {
        if (!this.selectedFilterData.filter(x => x.includes('Dates')).length) {
          this.errorValidation = true;          
          this.errorText = 'Please add a date from the filters list to search.';
          this.suggestionValidation = false;
        } else {
          this.errorValidation = false;          
          this.suggestionValidation = false;
        }
        if (this.selectedFilterData.filter(x => x.includes('Dates')).length && this.selectedFilterData.length == 2) {
          this.errorValidation = false;          
          this.suggestionValidation = true;
          this.suggestionText = "Consider refining your search parameters by adding Key, Additional, or Status."
        }
      }

      if (this.selectedFilterData.filter(x => x.includes('Keys')).length) {
        if (!this.selectedFilterData.filter(x => x.includes('Dates')).length) {
          this.errorValidation = true;          
          this.errorText = 'Please add a date from the filters list to search.';
          this.suggestionValidation = false;
        } else {
          this.errorValidation = false;          
          this.suggestionValidation = false;
        }
        if (this.selectedFilterData.filter(x => x.includes('Dates')).length && this.selectedFilterData.length == 2) {
          this.errorValidation = false;          
          this.suggestionValidation = true;
          this.suggestionText = "Consider refining your search parameters by adding Status or Additional."
        }

        if (this.selectedFilterData.filter(x => x.includes('Dates')).length && this.selectedFilterData.length > 2) {
          this.errorValidation = false;          
          this.suggestionValidation = false;
        }
      }

      if ((typeDropList.filter(x => x.includes("Keys=Equals")).length || typeDropList.filter(x => x.includes("Keys=In List (x,x,x)")).length)) {
        this.errorValidation = false;        
        this.suggestionValidation = false;
      }

      if ((typeDropList.filter(x => x.includes("Today")).length ||
        typeDropList.filter(x => x.includes("Current Week")).length ||
        typeDropList.filter(x => x.includes("Current Month")).length ||
        typeDropList.filter(x => x.includes("Past 5 Days; Next 5 Days")).length ||
        typeDropList.filter(x => x.includes("Past 10 Days; Next 10 Days")).length)
        && this.selectedFilterData.filter(x => x.includes('Dates')).length) {
        this.errorValidation = false;        
        this.suggestionValidation = false;
      }

      if (typeDropList.filter(x => x.includes("Keys=Starts With")).length || typeDropList.filter(x => x.includes("Keys=Ends With")).length) {
        if (this.selectedFilterData.filter(x => x.includes('Dates')).length) {
          if (this.selectedFilterData.length == 1) {
            this.errorValidation = false;            
            this.suggestionValidation = true;
            this.suggestionText = "Consider refining your search parameters by adding Key, Additional, or Status.";
          }
          if (this.selectedFilterData.length == 2) {
            this.errorValidation = false;            
            this.suggestionValidation = true;
            this.suggestionText = "Consider refining your search parameters by adding Status or Additional.";
          }
          if (this.selectedFilterData.length == 2 && !typeDropList.filter(x => x.includes("Custom Date Range")).length) {
            this.errorValidation = false;            
            this.suggestionValidation = false;
          }
          if (typeDropList.filter(x => x.includes("Custom Date Range")).length
            && this.selectedFilterData.filter(x => x.includes('Keys')).length &&
            this.selectedFilterData.length == 2) {
            this.errorValidation = false;            
            this.suggestionValidation = true;
            this.suggestionText = "Consider refining your search parameters by adding Status or Additional.";
          }
          if (this.selectedFilterData.length > 2) {
            this.errorValidation = false;            
            this.suggestionValidation = false;
          }
        } else {
          if ((typeDropList.filter(x => x.includes("Keys=Equals")).length || typeDropList.filter(x => x.includes("Keys=In List (x,x,x)")).length)) {
            this.errorValidation = false;            
            this.suggestionValidation = false;
          }else{
            if ((typeDropList.filter(x => x.includes("Additional=Equals")).length || typeDropList.filter(x => x.includes("Keys=In List (x,x,x)")).length)) {
              this.errorValidation = true;              
              this.suggestionValidation = false;
            }
          }
        }
      }

      if (typeDropList.filter(x => x.includes("Custom Date Range")).length
        && this.selectedFilterData.filter(x => x.includes('Dates')).length) {
        if (this.selectedFilterData.length == 1) {
          this.errorValidation = false;          
          this.suggestionValidation = true;
          this.suggestionText = "Consider refining your search parameters by adding Key, Additional, or Status.";
        }

        if (this.selectedFilterData.filter(x => x.includes('Additional')).length &&
          this.selectedFilterData.length == 2) {
          this.errorValidation = false;          
          this.suggestionValidation = true;
          this.suggestionText = "Consider refining your search parameters by adding Key, Additional, or Status.";
        }

        if (this.selectedFilterData.filter(x => x.includes('Status')).length &&
          this.selectedFilterData.length == 2) {
          this.errorValidation = false;          
          this.suggestionValidation = true;
          this.suggestionText = "Consider refining your search parameters by adding Key or Additional.";
        }
      }

      if (this.selectedFilterData.filter(x => x.includes('Status')).length) {
        if (!this.selectedFilterData.filter(x => x.includes('Dates')).length) {
          this.errorValidation = true;
          this.errorText = 'Please add a date from the filters list to search.';
          this.suggestionValidation = false;
        } else {
          this.errorValidation = false;          
          this.suggestionValidation = false;
        }
        if (this.selectedFilterData.filter(x => x.includes('Dates')).length && this.selectedFilterData.length == 2) {
          this.errorValidation = false;          
          this.suggestionValidation = true;
          this.suggestionText = "Consider refining your search parameters by adding Key or Additional"
        }
        if (this.selectedFilterData.filter(x => x.includes('Dates')).length && this.selectedFilterData.length > 2) {
          this.errorValidation = false;          
          this.suggestionValidation = false;
        }
        
        if (this.selectedFilterData.filter(x => x.includes('Status=Current Milestone')).length) {
          this.errorValidation = false;          
          this.suggestionValidation = false;
        }
        
      }

      setTimeout(() => {
        let dateChkShort: any[] = [];
        let dateChkLong: any[] = [];
        const index = this.filterCriteriaForm.get('filterCriteriaFormArray').controls.forEach((element: any) => {
          if (element.controls['typeDrop'].value.includes('Custom Date Range')) {
            const start = new Date(element.controls['startDate'].value);
            const end = new Date(element.controls['endDate'].value);
            const daysLeft = Math.floor(
              (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
                Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
              (1000 * 60 * 60 * 24)
            );
            switch (this.selectedFilterData.length) {
              case 1:
                if (daysLeft > 180)
                  dateChkShort.push('true');
                break;
              case 2:
                if (daysLeft > 365)
                  dateChkLong.push('true');
                break;
            }
          }
        });
        if (dateChkLong.length) {
          this.errorValidation = true;
          this.suggestionValidation = false;
          this.errorText = "Date range cannot be greater than 365 days. Please consider using one of our Analytics for expanded data.";
        }
        if (dateChkShort.length) {
          this.errorValidation = true;
          this.suggestionValidation = false;
          this.errorText = "Date range cannot be greater than 180 days alone. Please add additional criteria to expand the available range.";
        }
      }, 200);
    } else {
      this.errorValidation = false;
      this.suggestionValidation = false;
    }
  }
  clearAll() {
    this.errorValidation = false;
    this.suggestionValidation = false;
    this.search = "";
    this.selectedFilterData = [];
    this.onContainerClick = false;
    this.onPOClick = false;
    this.onShipmentClick = false;
    this.onMileStoneClick = false;
    this.filterCriteria = [];
    this.typeHeadIndex = '';
    const index = this.filterCriteriaForm.get('filterCriteriaFormArray').controls.map((x, indx) => indx);
    index.reverse().forEach((i) => {
      this.filterCriteriaForm.get('filterCriteriaFormArray').removeAt(i);
    });
  }

  filterCriteriaDrop(event: any) {
    const selectLnt = this.selectedFilterData.filter(x => x == event.target.value);
    if (!selectLnt.length) {
      this.selectedFilterData.push(event.target.value);
      (this.filterCriteriaForm.get('filterCriteriaFormArray') as any).push(this.newRowItem(event.target.value));
      this.dateTypeDrop('');
      this.validations();      
    }
    this.filterCriteriaForm.controls['filterCriteria'].patchValue('')
  }

  newRowItem(event: any) {
    const topic = this.onContainerClick ? 'Container' : this.onPOClick ? "Purchase Order" : this.onShipmentClick ? 'Shipment' : 'Milestone';
    switch (event.split('=')[0]) {
      case 'Keys':
        return new FormGroup({
          topic: new FormControl(topic),
          type: new FormControl(event.split('=')[0]),
          label: new FormControl(event.split('=')[1]),
          typeDrop: new FormControl('Keys=Equals', Validators.required),
          valueField: new FormControl('', Validators.required)
        })
        case 'Status':
          return new FormGroup({
            topic: new FormControl(topic),
            type: new FormControl(event.split('=')[0]),
            label: new FormControl(event.split('=')[1]),
            typeDrop: new FormControl('', Validators.required),
          })
        case 'Additional':
          return new FormGroup({
            topic: new FormControl(topic),
            type: new FormControl(event.split('=')[0]),
            label: new FormControl(event.split('=')[1]),
            typeDrop: new FormControl('Additional=Equals', Validators.required),
            valueField: new FormControl('', Validators.required)
          })
        case 'Dates':
          return new FormGroup({
            topic: new FormControl(topic),
            type: new FormControl(event.split('=')[0]),
            label: new FormControl(event.split('=')[1]),
            typeDrop: new FormControl('Dates=Today', Validators.required),
            startDate: new FormControl(''),
            endDate: new FormControl(''),
          },
            this.customValidateArrayGroup())
      default:
        return null;
    }
  }

  updatedSearchData(event: any) {
    switch (event.type) {
      case 'Keys':
        return new FormGroup({
          topic: new FormControl(event.topic),
          type: new FormControl(event.type),
          label: new FormControl(event.label),
          typeDrop: new FormControl(event.typeDrop, Validators.required),
          valueField: new FormControl(event.valueField, Validators.required)
        })
      case 'Status':
        return new FormGroup({
          topic: new FormControl(event.topic),
          type: new FormControl(event.type),
          label: new FormControl(event.label),
          typeDrop: new FormControl(event.typeDrop, Validators.required),
        })
      case 'Additional':
        return new FormGroup({
          topic: new FormControl(event.topic),
          type: new FormControl(event.type),
          label: new FormControl(event.label),
          typeDrop: new FormControl(event.typeDrop, Validators.required),
          valueField: new FormControl(event.valueField, Validators.required)
        })
      case 'Dates':
        return new FormGroup({
          topic: new FormControl(event.topic),
          type: new FormControl(event.type),
          label: new FormControl(event.label),
          typeDrop: new FormControl(event.typeDrop, Validators.required),
          startDate: new FormControl(new Date(event.startDate)),
          endDate: new FormControl(new Date(event.endDate)),
        },
          this.customValidateArrayGroup())
      default:
        return null;
    }
  }

  keyDropDown(event: any, i: any) {
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].patchValue('');
    this.validations();
  }
  statusDropDown(event: any, i: any) {
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].patchValue('');
    this.validations();
  }
  additionalDropDown(event: any, i: any) {
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].patchValue('');
    this.validations();
  }

  dateTypeDrop(event: any) {
    switch (this.selectedFilterData.length) {
      case 1:
        if (this.selectedFilterData.filter((x: any) => x.includes('Dates')).length) {
          this.minDate = new Date();
          this.minDate.setDate(new Date().getDate() - 180);
        }
        break;
      case 2:
        if (this.selectedFilterData.filter((x: any) => x.includes('Dates')).length) {
          this.minDate = new Date();
          this.minDate.setDate(new Date().getDate() - 365);
        }
        break;
      default:
        this.minDate = new Date();
        this.minDate.setDate(new Date().getDate() - 365);
        break;
    }
    this.validations();
  }
  customValidateArrayGroup(): ValidatorFn {
    return (formArray: any): { [key: string]: any } | null => {
      let valid: boolean = false;
      if (formArray.controls['typeDrop'].value == 'Dates=Custom Date Range') {

        const startCtl = formArray.controls['startDate'].value;
        const endCtl = formArray.controls['endDate'].value;
        if (!startCtl || !endCtl) {
          return valid ? null : { error: 'please select dates' };
        }

        const start = new Date(formArray.controls['startDate'].value);
        const end = new Date(formArray.controls['endDate'].value);

        const daysLeft = Math.floor(
          (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
            Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
          (1000 * 60 * 60 * 24)
        );
        if (this.selectedFilterData.length == 1 && this.selectedFilterData.filter((x: any) => x.includes('Dates')).length) {

          return daysLeft > 0 && daysLeft <= 180 ? null : { error: 'require date difference between entered Start/End date be 180 days' }
        } else {

          return daysLeft > 0 && daysLeft <= 365 ? null : { error: 'require date difference between entered Start/End date be 365 days' }
        }

      } else {
        if (formArray.controls['typeDrop'].value) {
          return null;
        } else {
          return { error: 'please select' };
        }
      }
    }
  };
  onValueChange() {
    this.validations()
    this.errorText = 'Please add a date from the filters list to search.';
  }
  searchQuery(e: string, i: any) {
    this.typeHeadIndex = i;
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].setErrors({ 'incorrect': true });
    this.search = e;
    const labelName = (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['label'].value;
    const filterVal = labelName == 'Container #' ? 'Container' :
    labelName == 'PO #' ? 'PO' :
    labelName == 'Booking #' ? 'BookingKey' :
    labelName == 'Item #' ? 'Item' :
    labelName == 'MBL #' ? 'MasterBL' :
    labelName == 'Ship Key' ? 'ShipKey' : 
    labelName == 'FCR #' ? 'FCR' : 'HouseBL';
    this.keyNames = labelName == 'Container #' ? '["Container"]' :
    labelName == 'PO #' ? '["PO"]' :
    labelName == 'Booking #' ? '["BookingKey"]' :
    labelName == 'Item #' ? '["Item"]' :
    labelName == 'MBL #' ? '["MasterBL"]' :
    labelName == 'Ship Key' ? '["ShipKey"]' : 
    labelName == 'FCR #' ? '["FCR"]' : '["HouseBL"]';
    if (this.search) {
      this.suggestions$ = this.mainMenuService.getSystemSearchValueData(
        this.keyNames,
        this.search
      ).pipe(map(x => x.filter(y => y.keyName == filterVal))
      );
    }
  }
  searchQueryVendor(e: string, i: any) {
    this.typeHeadIndex = i;
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].setErrors({ 'incorrect': true });
    this.search = e;
    if (this.search) {
      this.vendorSuggestions$ = this.mainMenuService.getVendorList(
        this.search
      ).pipe(map(x => x.map((y: any) => { return { ...y, keyValue: `${y.vendorCode}-${y.vendorName}`, keyName: 'Vendor' } })
      ))
    }
  }

  selectedSearchItem(e: TypeaheadMatch, i: any) {
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].setErrors(null);
    (this.filterCriteriaForm.controls['filterCriteriaFormArray'] as any).controls[i].controls['valueField'].patchValue(e.value);
    this.suggestions$ = new Observable<[]>();
    this.vendorSuggestions$ = new Observable<[]>();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  loading(i: any) {
    return i;
  }
  onTopicClick(val: string) {
    this.clearAll();
    this.onBtnTopic = val;
    if (val == 'PO') {
      this.onContainerClick = false;
      this.onPOClick = true;
      this.onShipmentClick = false;
      this.onMileStoneClick = false;
      this.filterCriteria = this.poCriteria;
    } if (val == 'ship') {
      this.onContainerClick = false;
      this.onPOClick = false;
      this.onShipmentClick = true;
      this.onMileStoneClick = false;
      this.filterCriteria = this.shipmentCriteria;
    }
    if (val == 'mile') {
      this.onContainerClick = false;
      this.onPOClick = false;
      this.onShipmentClick = false;
      this.onMileStoneClick = true;
      this.filterCriteria = this.milestoneCriteria;
    }
    if (val == 'container') {
      this.onContainerClick = true;
      this.onPOClick = false;
      this.onShipmentClick = false;
      this.onMileStoneClick = false;
      this.filterCriteria = this.containerCriteria;
    }
  }
}
export const formGroup = new FormGroup({
  filterCriteria: new FormControl(''),
  filterCriteriaFormArray: new FormArray([
    new FormGroup({

    })
  ])
});

export interface statusDropDown {
  value: string;
  name: string;
}

