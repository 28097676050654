import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { CreateDdpContainerModalConfig, CreateDdpContainerModalService } from './create-ddp-container-modal.service';
import { select } from '@ngneat/elf';
import { CellClassParams, ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { ToastrService } from 'ngx-toastr';
import { isEmpty } from 'lodash';
import { customDatePickerComponent,LanguageResourceModule} from '@cds-ui/shared/ui-grid';


@Component({
  selector: 'cds-ui-create-ddp-container-modal',
  templateUrl: './create-ddp-container-modal.component.html'
})
export class CreateDdpContainerModalComponent implements OnInit {
  private gridApi!: GridApi;
  private destroy$: Subject<void> = new Subject();
  private configDataSubject$$ = new Subject<CreateDdpContainerModalConfig>();
  public configData$ = this.configDataSubject$$.asObservable().pipe(shareReplay(1));
  public modalRef!: BsModalRef;
  public config!: CreateDdpContainerModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private createDdpContainerModalService: CreateDdpContainerModalService,
    private appStateRepository: AppStateRepository,
    private toastrService: ToastrService
  ) { }
  public gridClass = this.appStateRepository.theme$.pipe(select((x) => x.grid));
  public containerStatus: boolean = true;
  ngOnInit(): void {
    this.containerStatus = true;
    this.configData$.pipe(takeUntil(this.destroy$)).subscribe();
  }
  closeModal(){
    this.containerStatus = true;
    this.modalRef.hide();   
  }
  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  defaultColumns: ColDef[] = [
    {
      headerName: LanguageResourceModule.containerNumber,
      field: 'ContainerNumber',
      editable: true,     
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 15,
      },
      cellStyle: this.cellStyle,
    },
    {
      headerName: LanguageResourceModule.sealNumber, field: 'SealNumber', editable: true,    
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: '20'
      },
      cellStyle: this.cellStyle,
    },
    {
      headerName: LanguageResourceModule.finalDestination, field: 'FinalDestination', editable: true,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: '25'
      },
      cellStyle: this.cellStyle,
    },
    {
      headerName: LanguageResourceModule.estFinalDestination,
      editable: true,    
      cellEditor: customDatePickerComponent,
      field: 'FinalEta',
      valueFormatter: this.dateFormatter,
      filterParams: {
        valueFormatter: this.dateFormatter,
      },
      minWidth: 190,
      cellStyle: this.cellStyle,
    },
    {
      headerName: LanguageResourceModule.PurchaseOrderNumber, field: 'PurchaseOrderNumber', editable: true,       
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: '25'
      },
      cellStyle: this.cellStyle
    },
  ];
  public defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,   
  };

   cellStyle(params: CellClassParams) {    
    return { backgroundColor: params.value === null ? '#292f3d' : '#BC7A30', color: 'white', };
  }
  addRow() {
    const newItem = { ContainerNumber: null, SealNumber: null, FinalDestination: null, FinalEta: null, PurchaseOrderNumber:null };
    this.gridApi.applyTransaction({ add: [newItem] });
  }
  saveData(): void {
    let data: any[] = [];
    this.containerStatus = true;
    this.gridApi.stopEditing();
    this.gridApi.forEachNode(node => data.push(node.data));
    data.forEach((item) => {
      if (item.ContainerNumber === '' || item.ContainerNumber === null ) {
        this.containerStatus = false;
        return;
      }
    }
    );
    if (this.containerStatus && !isEmpty(data)) {
      this.createDdpContainerModalService.submitData(data).subscribe((response) => {
        this.toastrService.success('Data submitted successfully');
      });
      this.modalRef?.hide()
    }
  }
  onCellValueChanged(params: any) {
    this.gridApi.refreshCells({ rowNodes: [params.node], force: true })
  }

  ngAfterViewInit(): void {
    this.createDdpContainerModalService.show$
      .pipe(takeUntil(this.destroy$),
        tap(x => this.configDataSubject$$.next(x as CreateDdpContainerModalConfig))
      ).subscribe((_) => {
        if (this.modalRef) this.modalRef.hide();
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
      });

  }

  dateFormatter(params: any): string | any {
    if (params.value) {
      return moment.utc(params.value).format('MM/DD/YYYY');
    }
  }

  ngOnDestroy(): void {
    this.containerStatus = true;
    this.destroy$.next();
    this.destroy$.complete();
  }

}
