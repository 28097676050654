import { ReportDetails } from '../../analytics-card/models/analytics-card.model';

export interface CollectionDetails {
  collectionName: string;
  reportDetails: ReportDetails[];
  collectionHasReports: boolean;
  showCollection: boolean;
  showIfEmpty: boolean;
  sequence: number;
}

export interface ScheduledReport {
  reportName: string;
  scheduleName: string;
  nextRunTime: string;
  scheduleType: string;
  scheduleDetails: string[];
  taskId: string
}