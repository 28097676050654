<div class="card">
  <div class="card-body px-5 py-4" *ngIf="timelineData.length > 0; else noData">
    <h2>Timeline</h2>
    <ol class="progress-tracker">
      <li [ngClass]="{'completed': getCompletedStatus(item, i)}" *ngFor="let item of timelineData; let i = index">
        <span class="progress-marker"></span>
        <!-- <span *ngIf="item.Event">{{item?.Event[0]?.StatusEvent}}<br /></span> -->
        <span>{{item?.Location}}<br /></span>
        <span class="text-warning" *ngIf="item.Event; else locationDate">
          {{(item?.Event[0].EventDate ?? item?.LocationDate | date:'MM/dd/YYYY')}}
            <span *ngIf="((item.noOfcontainer) && (item.noOfcontainer ?? 0) > 0)"class="text-warning ms-1">({{item.noOfcontainer}})</span>
        </span>
        <ng-template #locationDate>
          <span class="text-success">{{ item.LocationDate }}</span>
          <ng-container  *ngIf="item.dateDiff">
           <span *ngIf="(item.dateDiff).replace('d','') > 0" class="text-danger"> +{{item.dateDiff}}</span>
           <span *ngIf="(item.dateDiff).replace('d','') < 0" class="text-warning"> {{item.dateDiff}}</span>
          </ng-container><br />
          <span *ngIf="((item.noOfcontainer) && (item.noOfcontainer ?? 0) > 0)"class="ms-1">Container count {{item.noOfcontainer}}</span>
        </ng-template>
      </li>
    </ol>
  </div>
  <ng-template #noData>
    <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
      <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">cloud_off</span></span></p>
      <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
    </div>
  </ng-template>
</div>
