import { FormControl, FormGroup } from "@ngneat/reactive-forms";

export const poPanel  = {
    purchaseOrderInfo : 'purchase-order-info',
    poDates: 'po-dates',
    vendorInfo : 'vendor-info',
    customHeaderAttributes : 'custom-header-attributes',
    locationInfo :'location-info',
    additionalInfo: 'additional-info',
    paymentInfo: 'payment-info',
}

export const form  = new FormGroup({
    buyerCode:new FormControl(''),
    eventCode: new FormControl(''),
    productCode: new FormControl(''),
    division:new FormControl(''),
    shipMode: new FormControl(''),
    purchaseOrderStatus: new FormControl(''),
    poCancelledDate:new FormControl(''),
    revisedShipDate: new FormControl(''),
    firstShipDate:new FormControl(''),
    lastShipDate: new FormControl(''),
    inWarehouseDate: new FormControl(''),
    deliveryDestination:new FormControl(''),
    finalDestination: new FormControl(''),
    originLocation:new FormControl(''),
    vendorCode:new FormControl(''),
    vendor: new FormControl(''),
    supplierReferenceNumber: new FormControl(''),
    misc1:new FormControl(''),
    misc2:new FormControl(''),
    misc3: new FormControl(''),
    poSpecialInstructions: new FormControl(''),
    currencyCode:  new FormControl(''),
    paymentCode: new FormControl(''),
    shipOriginContury: new FormControl('')
  });
  
 
export const editableFieldsPoItemGrid = [
    'htsus',
    'packages',
    'cartonsUOM',
    'manufactureItemNumber',
    'misc1',
    'originCountry',
    'unitFirstCost',
    'unitPrice',
    'units',
    'poItemStatus',
    'firstShipDate',
    'lastShipDate',
    'instoreDate'
  ];
  
   export type Currency = {  currencyName:string ,currencyCode: string };
   export type PoStatus = {  statusName:string , statusCode: string };
   export type OriginCountries = {countryName:string , countryCode: string };
   export type Payment = { paymentCode: string };
   export type VendorList = { vendor?: string, vendorName: string, vendorCode: string };
   export type OriginLocation = { locationName: string, locationCode: string };
   export type CartonsUOM =  { text:string ,value: string };
   export type ShipMethod =  { transportTypeName:string ,transportTypeCode: string };

  
