import { filterNullish } from '@cds-ui/shared/core-rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, filter, fromEvent, interval, map, mergeMap, shareReplay, startWith, Subject, switchMap, take, takeUntil, tap, timer } from 'rxjs';
import { User } from './auth-data.model';
import { AppStateRepository } from '@cds-ui/shared/core-state';

export interface AuthEnv {
    authority: string,
    clientId: string
}

@Injectable({ providedIn: 'root' })
export class AuthService {   
    public vizivKey = `oidc.user:viziv:${this.authEnvironment.authority}:${this.authEnvironment.clientId}`;
    public legacyKey = `oidc.user:${this.authEnvironment.authority}:${this.authEnvironment.clientId}`;

    private authTimeout!: Subject<void>;
    private ghostSubject$ = new Subject<User>();
    private legacyAuth$$ = new BehaviorSubject<string | null>(sessionStorage.getItem(this.legacyKey));
    private legacyAuth$ = this.legacyAuth$$.pipe(filterNullish(), map(x => JSON.parse(x)), filterNullish());

    public ghost$ = this.ghostSubject$.asObservable();
    public user$ =this.legacyAuth$.pipe(map(x => x.profile), filterNullish(), shareReplay(1));
    public accessToken$ = this.legacyAuth$.pipe(map(x => x.access_token), startWith(null), shareReplay(1))

    constructor(@Inject('AUTH_SERVER') private authEnvironment: AuthEnv, private http: HttpClient, private securityService: OidcSecurityService, private appState: AppStateRepository) {
        fromEvent<StorageEvent>(window, "storage")
                            .pipe(
                                filter(e => e.key === this.legacyKey),
                                map(e => e.newValue),
                                filterNullish(),
                                tap(x => this.legacyAuth$$.next(x))
                            )
                            .subscribe();
     }

    public initToken() {
        interval(100)
        .pipe(
          map(x => sessionStorage.getItem(this.vizivKey)),
          filterNullish(),
          map(x => !!x ? JSON.parse(x) : null),
          filterNullish(),
          filter(x => !!x.authnResult && !!x.userData && !sessionStorage.getItem(this.legacyKey)),
          take(1),
          map(x => ({
              ...x.authnResult,
              profile: { ...x.userData }
          })),
          tap(x => {
              sessionStorage.setItem(this.legacyKey, JSON.stringify(x))
          }),
          tap(x => this.legacyAuth$$.next(JSON.stringify(x)))
        )
        .subscribe();
    }

    public refreshSessionStorage() {
        const legacyData = sessionStorage.getItem(this.legacyKey);

        if(legacyData) {
            sessionStorage.setItem(this.legacyKey, legacyData);
        }

        const authData = sessionStorage.getItem(this.vizivKey);
        // console.log(authData);
        if(authData) {
            sessionStorage.setItem(this.vizivKey, authData);
        }
    }

    public logout() {
        sessionStorage.removeItem(this.legacyKey);
        this.securityService.logoff();
    }

    public initializeTimeout(destoryToken$: Subject<void>) {
        if (this.authTimeout) return this.refreshTimeout();

        this.authTimeout = new Subject<void>();
        const idleLogoutTime = 7200000; // 2 hours

        this.authTimeout.asObservable()
            .pipe(
                takeUntil(destoryToken$),
                switchMap(() => timer(idleLogoutTime))
            )
            .subscribe(() => this.logout());
    }

    public refreshTimeout() {
        this.authTimeout?.next();
    }

    public ghostUserFromAuthServer(userId: string, subgroupCd: string) {

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;' })
        }

        return this.accessToken$
            .pipe(
                map(x => this.getTokenBody(userId, subgroupCd, x as string)),
                mergeMap(body => this.http.post(`${this.authEnvironment.authority}connect/token`, body, httpOptions)
                    .pipe(
                        map((x: any) => {
                            const accessToken = x.access_token;
                            const base64 = atob(accessToken.split('.')[1]);
                            const base64D = base64.replace(/(.)/g, (_, p) => {
                                let code = p.charCodeAt(0).toString(16).toUpperCase();
                                if (code.length < 2) {
                                    code = `0${code}`;
                                }
                                return `%${code}`;
                            });

                            const profile = JSON.parse(decodeURIComponent(base64D));
                            return { access_token: x.access_token, profile };
                        }),
                        tap(x => {
                            const json = sessionStorage.getItem(this.legacyKey) ?? '';
                            const data = JSON.parse(json);

                            data.access_token = x.access_token;
                            data.profile = x.profile;
                            data.expire_at = x.profile.exp;

                            sessionStorage.setItem(this.legacyKey, JSON.stringify(data));
                            this.legacyAuth$$.next(JSON.stringify(data));
                            
                            this.ghostSubject$.next({
                                userId: data.profile.username,
                                subgroupCode: data.profile.subgroup_code,
                                orginalUserId: data.profile.orginal_username,
                                orginalSubgroupCode: data.profile.orginal_subgroup
                            });
                        })
                    )
                )
            );
    }

    private getTokenBody(userId: string, subgroupCd: string, accessToken: string): URLSearchParams {

        const body = new URLSearchParams();
        body.set('refresh_token', accessToken);

        body.set('client_id', 'vmsGhost');
        body.set('grant_type', 'ghost');
        body.set('client_secret', '02869AAD-96B7-438B-A09C-9C50688D9A2A');
        body.set('scope', 'ghost_permission');

        body.set('ghost_target', userId);
        body.set('ghost_group', subgroupCd);

        return body;
    }

}
