import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { filter, Subject, shareReplay } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'cds-ui-ag-grid-custom-toolbar',
  templateUrl: './ag-grid-custom-toolbar.component.html'
})
export class AgGridCustomToolbarComponent implements OnDestroy {
  private destroy$$ = new Subject<void>();
  public modalRef!: BsModalRef;
  public isLoading$ = this.appState.isLoading$;

  @ViewChild('template') template!: TemplateRef<any>;
  @Output() resetUserPreferenceEvent = new EventEmitter<BsModalRef<any>>();
  @Input() gridId: string = "";
  @Input() hideRestoreButton: boolean = false;

  resetClick() {
    this.modalRef = this.modalservice.show(this.template, {
      backdrop: false,
      ignoreBackdropClick: true,
      class:'modal-dialog-centered'
    });
  }

  onConfirmationClick() {
    this.resetUserPreferenceEvent.emit(this.modalRef);
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  constructor(
    private modalservice: BsModalService,
    private appState: AppStateRepository
    ) { }
}