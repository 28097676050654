/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Favorites, Message, WidgetData } from './models/dashboard-model';
import { BookingStatusWidget } from './enums/booking-status-widget.enum';
import { ContainerOverviewWidget } from './enums/container-overview-widget.enum';
import { PurchaseOrderWidget } from './enums/purchase-order-widget.enum';
import { ShippingStatusWidget } from './enums/shipping-status-widget.enum';
import { GridsterItem } from 'angular-gridster2';
import { QueryPermissionGQL } from '@cds-ui/data-access';
import { Apollo } from 'apollo-angular';
import { AppStateRepository } from '@cds-ui/shared/core-state';


@Injectable({ providedIn: 'root' })
export class DashboardQueryService {
  //TO DO: This data will come from the Redis cache. Just hardcoded here for now.
  widgetData: WidgetData[] = [
    { Id: 'Favorite', Title: 'Favorite', NewTitle: 'Favorite' },
    { Id: 'Map', Title: 'Supply Chain Map', NewTitle: 'Supply Chain Map' },
    { Id: 'CarrierUtilization', Title: 'Carrier Utilization (Container Count)', NewTitle: 'Carrier Utilization (Container Count)' },
    { Id: 'ContainerUsage', Title: 'Container Usage', NewTitle: 'Container Usage' },
    { Id: ContainerOverviewWidget.containerId, Title: ContainerOverviewWidget.widgetName, NewTitle: ContainerOverviewWidget.widgetName },
    { Id: ShippingStatusWidget.containerId, Title: ShippingStatusWidget.widgetName, NewTitle: 'Shipping' },
    { Id: 'InboundPlanning', Title: 'Inbound Planning', NewTitle: 'Inbound Planning' },
    { Id: 'Vibe', Title: 'Vibe', NewTitle: 'Vibe' },
    { Id: BookingStatusWidget.containerId, Title: BookingStatusWidget.widgetName, NewTitle: BookingStatusWidget.widgetName },
    { Id: PurchaseOrderWidget.containerId, Title: PurchaseOrderWidget.widgetName, NewTitle: PurchaseOrderWidget.widgetName },
  ];
  private redisKey = "dashboard:customer:dashboard";
  private preferencesRoute = "/user/preferences/page/";
  private widgetDimension$ = new Subject<any>();
  public getWidgetDiemension = this.widgetDimension$.asObservable();

  public layOutSub$ = new Subject<any>();
  public layOut$ = this.layOutSub$.asObservable();

  public selMyDayPOwidget = new BehaviorSubject<string>("");

  constructor(private http: HttpClient,
    private apollo: Apollo, 
    private appState: AppStateRepository) { }

  public getFavorites() {
    return this.http.get<Favorites>('/pagelayout/view/GetFavoritesForWidget');
  }

  public getMessages() {
    return this.http.get<Message[]>('/widget/view/GetMessagesForWidget');
  }

  public getCarrierUtilizationData(): Observable<any> {
    return this.http.get('/widget/view/GetCarrierUtilization');
  }

  public getContainerUsageData(): Observable<any> {
    return this.http.get('/widget/view/GetContainerUsage');
  }

  public getWidgetLayout(): Observable<any> {
    return this.http.get(this.preferencesRoute + this.redisKey);
  }

  public updateWidgetLayout(layout: GridsterItem[], cards: GridsterItem[]): Observable<any> {
    const dictionary = {
      "PageLayout": JSON.stringify(layout),
      "StaticCards": JSON.stringify(cards),
    };
    this.appState.ghostedUserPreferences$.next({
      path: 'pages/dashboard:customer:dashboard',
      data: dictionary
    })
    return this.http.post(this.preferencesRoute + this.redisKey, dictionary);
  }

  public updateWidgetData(index: any, title: any) {
    this.widgetData[index].NewTitle = title;
  }

  public getWidgetDataById(id: any) {
    return this.widgetData.find((s) => s.Id == id);
  }

  public setDimension(value: any) {
    this.widgetDimension$.next(value);
  }

  public getContainersLateDepartingCount(): Observable<any> {
    return this.http.get('/tallycard/view/GetLateDepartingContainersCount');
  }

  public getDischargeAtPortContainersCount(): Observable<any> {
    return this.http.get('/tallycard/view/GetDischargeAtPortContainersCount');
  }

  public getShipmentUpdatedETACount(): Observable<any> {
    return this.http.get('/tallycard/view/GetShipmentUpdatedETACount');
  }

  public getNewBookingsCount(): Observable<any> {
    return this.http.get('/tallycard/view/GetNewBookingsCount');
  }

  public getSupplyChainMapVesselLateDepartingCount() {
    //return this.http.get('/tallycard/view/GetSupplyChainMapVesselLateDepartingCount');
    return this.http.get('/voyages?filter=vessels');
  }

  public getPOIPOCPermissions(permissionControlKey:any){
   return new QueryPermissionGQL(this.apollo)
    .fetch(
      { filter: permissionControlKey },
      { fetchPolicy: 'no-cache' }
    )
    .pipe(map((x) => x.data.userPermission));
  }
}
