import { ICellRendererParams } from "@ag-grid-community/core";
import moment from "moment";


const dateFormatter = (params: any) => {
    if (params.value) {
      return moment.utc(params.value).format('MM/DD/YYYY');
    }

    return '';
  }
  

export const columns = [
    
    {
      headerName: 'Validation Desc',
      field: 'validationDescription',
      minWidth: 200
    },
    {
      headerName: 'Error Message',
      field: 'errorMessage',
      minWidth: 250,
      wrapText: true,
      cellRenderer: (params: ICellRendererParams) => {
        const msg = params.data.errorMessage.replace(/&lt;BR&gt;/g, "<br>");
        const a = document.createElement('span');
        a.innerHTML = msg;
        return a;
      },
    },
    {
      headerName: 'Workflow Desc',
      field: 'workflowLogDescription',
      hide: true
    },
    {
      headerName: 'Date',
      field: 'validationLogCreatedDate',
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
      },
    },
    {
      headerName: 'Approved/Declined',
      field: 'validationStatus',
    },
    {
      headerName: 'Action Date',
      field: 'workflowLogCreatedDate',
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
      },
    },
    {
      headerName: 'Actioned By',
      field: 'workflowLogUserId',
    },
    {
      headerName: 'Log Message',
      valueGetter: (params: { data: { logMessage: { message: string, createdDate: string }[]; }; }) => {
        if (params.data.logMessage && params.data.logMessage.length > 0) {
          const mostRecentLogMessage = params.data.logMessage.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())[0];
          return mostRecentLogMessage.message;
        }
        return null;
      }
    },
    {
      headerName: 'Reason',
      field: 'reason',
    },
    {
      headerName: 'Milestone Name',
      field: 'milestoneName',
    },
    {
      headerName: 'Validation Label',
      field: 'validationLabel',
    },
  ];
