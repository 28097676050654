import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface ContainerStatusEventModalConfig {
    title: string,
    data:any
}

export enum ContainerStatusDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface ContainerStatusModalEvent {
    type: ContainerStatusDialogueEvent,
    params?: any
}

@Injectable({
  providedIn: 'root'
})
export class ContainerStatusModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<ContainerStatusModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === ContainerStatusDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === ContainerStatusDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === ContainerStatusDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === ContainerStatusDialogueEvent.OnHidden));
  public onClosingContainerStatusRemove$ = this.event$.pipe(filter((x) => x.type === ContainerStatusDialogueEvent.OnClosingRemove));
  public onClosingContainerStatusRemoved$ = this.event$.pipe(filter((x) => x.type === ContainerStatusDialogueEvent.OnClosingRemoved));

  show(config: ContainerStatusEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: ContainerStatusModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingContainerStatus(params?: any) {
    this.event$.next({
      type: ContainerStatusDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingContainerStatusRemoved() {
    this.event$.next({
      type: ContainerStatusDialogueEvent.OnClosingRemoved,
    });
  }
}
