/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LogiReportServer {
  url: string;
  auth_token: string;
  jrd_userinfo: {
    prefer: {
      rpt_timezone: string;
    };
  };
  jrd_prefer: {
    pagereport?: PageReport;
    webreport?: Webreport;
  };
  jrd_studio_mode: string;
  'jrs.param_page': boolean;
  paramPageDisabled: boolean;
  'jrs.profile': string;
  'jrs.has_style': boolean;
  'jrs.style_group': string;
  entryId: string;
}

export interface ModalReportInfo {
  headerTitle: string;
  reportName: ReportName;
  catalogName: CatalogName;
  params: any;
}

export interface ReportName {
  name: string;
}

export interface CatalogName {
  name: string;
}

export interface PageReport {
  userInfoBar: boolean;
  toolBar: boolean;
  toolbox: boolean;
  dSOTree: boolean;
  tOCTree: boolean;
  popupMenu: boolean;
  aDHOC: boolean;
}

export interface Webreport {
  viewMode?: ReportViewMode;
  editMode?: ReportEditMode;
}

export interface ReportViewMode {
  hasToolbar: boolean;
  hasSideArea: boolean;
}

export interface ReportEditMode {
  hasToolbar: boolean;
  hasVToolbar: boolean;
  hasSideArea: boolean;
  hasInspector: boolean;
}