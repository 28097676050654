<div class="card vessel-schedule-timeline">
	<div class="card-header bg-transparent border-dark border-1 mx-5 pt-4 px-0">
		<div class="d-flex align-items-center">
			<h2 class="mb-0">Vessel Schedule</h2>
		</div>
	</div>
	<ng-container>
	<div class="card-body px-5 py-4" *ngIf="vesselData.sailingSchedules.length > 0 else noData">
		<div class="timeline-animate-wrapper">
		  <div class="vessel">
			<img src="assets/img/cargo-ship.svg" width="80">
		  </div>
		</div>
		<ol class="progress-tracker pt-5 mt-3">
			<li  *ngFor="let data of vesselData.sailingSchedules">
				<h5>{{portVal(data.portName)}}</h5>
				ARR: {{data.updatedETA | date:'MM/dd/YYYY'}}<br />
				DEP: {{data.updatedETD | date:'MM/dd/YYYY'}}
			</li>
		</ol>
	</div>
	</ng-container>
	<ng-template #noData>
		<div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
		  <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">cloud_off</span></span></p>
		  <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
		</div>
	  </ng-template>
</div>