<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header title-pd" cdkDragHandle>
      <h3 class="modal-title">{{config.title}}</h3>
      <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="form" class="row g-3">
        <div class="drop-position">
          <input #filter [placeholder]="placeholderText" formControlName="editTerminal" [(ngModel)]="search" (input)="searchQuery(search)" type="text"
            class="form-control" id="globalSystemSeach" />
          <div class="loading" *ngIf="onLoad">Loading...</div>
          <div *ngIf="suggestions$ | async as list">
            <div *ngIf="list.length else noData">
              <ul class="dropdown-menu" [ngClass]="list.length ? 'show-search-dropdown':''" #menu>
                <li class="dropdown-submenu" *ngFor="let menu of list">
                  <div class="dropstart">
                    <a (click)="selectMenuItem(menu)" class="dropdown-item d-flex align-items-center has-submenu"
                      aria-expanded="false">{{menu?.terminalName}},{{menu?.streetAddress}},{{menu?.city}}</a>
                  </div>
                </li>
              </ul>
            </div>
            <ng-template #noData>
              <div class="noResults">No Results Found</div>
            </ng-template>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn bg-purple-300" data-bs-dismiss="modal" (click)="close()">{{config.btnTwo}}</button>
      <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">{{config.btnOne}}</button>
    </div>
  </div>
</ng-template>