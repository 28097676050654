import { Injectable, OnDestroy } from "@angular/core";
import { PurchaseOrder } from "@cds-ui/data-access";
import { ReplaySubject, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DetailPaneEditModalService implements OnDestroy {

    private destory$$ = new Subject<void>();
    public readonly paneData$$ = new ReplaySubject<{data: PurchaseOrder, type: string}>();
    public readonly  paneData$ = this.paneData$$.asObservable();
    private hide$$ = new Subject<void>();
    private show$$ = new Subject<void>();
    private save$$ = new Subject<void>();
    private cancel$$ = new Subject<void>();
    public refreshPoHeaderSignels$$ = new Subject<void>();

    public onShow$ = this.show$$.asObservable();
    public onHide$ = this.hide$$.asObservable();
    public onSave$ = this.save$$.asObservable();
    public onCancel$ = this.cancel$$.asObservable();
    public onRefreshPoHeaderSignels$ = this.refreshPoHeaderSignels$$.asObservable();

    show(): void {
        this.show$$.next();
    }

    save(): void {
        console.log('save method');
        this.hide$$.next();
        this.save$$.next();
    }

    cancel(): void {
        this.hide$$.next();
        this.cancel$$.next();
    }

    hide(): void {
        this.hide$$.next();
    }

    ngOnDestroy(): void {
        this.destory$$.next();
        this.destory$$.complete();
    }

}