export interface AuthData {
    accessToken: string,
    idToken: string,
    expireAt: number,
    scope: string,
    sessionState: string,
    tokenType: string,
    userData: any,
    isGhostToken: boolean
}

export interface User {
    userId: string,
    subgroupCode: string,
    orginalUserId: string,
    orginalSubgroupCode: string
}