// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const regexHostMatch = /(\w+\.\w+\.(cn|com))$/i;

export const environment = {
  envName: 'prod',
  production: true,
  pathPrefix: '/ui',
  uiServerUrl: window.location.origin,
  uiServerUrlLocal: 'http://localhost:8080',
  apiServer: `https://${regexHostMatch.exec(window.location.hostname)
    ? window.location.hostname + "/api"
    : "cds-web-vms-prod-api-as.azurewebsites.net"}/`, 
  // apiServer: 'http://localhost:5000/',
  authServer: {
    authority: `https://authdr.${
      ( /(?:\w+\.)?(\w+\.\w+)$/.exec(window.location.hostname) || [null, null])[1]
      ?? "centuryvms.com"
    }/`,
    clientId: 'CdsWebimplicit'
  },
  appInsights: {
    instrumentationKey: '8723d5b3-6e3c-41c1-9d73-621b34c5408e'
  },
  disabledFeatures: [],
  /* At some point in the near future, we should move all urls into this section for readability... */
  urls: {
    logiServer: 'https://analytics.centuryvms.com/jinfonet/tryView.jsp',
    logiServerLogout: 'https://analytics.centuryvms.com/jinfonet/logout.jsp',
    legacyApiServer: 'https://apidr.centuryvms.com/'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
