import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { SaveModalComponent } from './save-modal/save-modal.component';
import { CountDownDirective } from './count-down.directive';
import { OverwriteModalComponent } from './overwrite-modal/overwrite-modal.component';
import { DocItemsUploadedModalComponent } from './doc-items-uploaded-modal/doc-items-uploaded-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ViewmoreModalComponent } from './viewmore-modal/viewmore-modal.component';
import { VibeModalComponent } from './vibe-modal/vibe-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationDateModalComponent } from './location-date-modal/location-date-modal.component';
import { CommentsModalComponent } from './comments-modal/comments-modal.component';
import { CreateDdpContainerModalComponent } from './create-ddp-container-modal/create-ddp-container-modal.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { VesselViewmoreModalComponent } from './vessel-viewmore-modal/vessel-viewmore-modal.component';
import { AcknowledgeModalComponent } from './Acknowledge-modal/Acknowledge-modal.component';
import { PrintSaModalComponent } from './print-sa-modal/print-sa-modal.component';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
import { PocApproveModalComponent } from './poc-approve-modal/poc-approve-modal.component';
import { PocDeclineModalComponent } from './poc-decline-modal/poc-decline-modal.component';
import { PocWithdrawalModalComponent } from './poc-withdrawal-modal/poc-withdrawal-modal.component';
import { ReviewModalComponent } from './review-modal/review-modal.component';
import { ManageDashboardModalComponent } from './manage-dashboard-modal/manage-dashboard-modal.component';
import { EditTerminalComponent } from './edit-terminal/edit-terminal.component';
import { EditColumnDateComponent } from './edit-column-date/edit-column-date.component';
import { SharedUiDirectiveModule } from '@cds-ui/ui-directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeclineCounterModalComponent } from './decline-counter-modal/decline-counter-modal.component';
import { PocChangeRequestModalComponent } from './poc-change-request-modal/poc-change-request-modal.component'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PoBuyerMultipleComponent } from './po-buyer-multiple-modal/po-buyer-multiple-modal.component';
import { VesselScheduleModalComponent } from './vessel-schedule-modal/vessel-schedule-modal.component';
import { EditTransloadFacilityModalComponent } from './edit-transload-facility-modal/edit-transload-facility-modal.component';
import { SaveUserPreferenceModalComponent } from './save-user-preference-modal/save-user-preference-modal.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


@NgModule({
  imports: [CommonModule, FormsModule,ModalModule.forRoot(), RouterModule,ReactiveFormsModule,AgGridModule,TooltipModule.forRoot(),SharedUiDirectiveModule,DragDropModule,BsDatepickerModule.forRoot(),TypeaheadModule.forRoot(),
  ],
  declarations: [
    NotificationModalComponent,
    SaveModalComponent,
    DocItemsUploadedModalComponent,
    CountDownDirective,
    OverwriteModalComponent,
    ConfirmationModalComponent,
    ViewmoreModalComponent,
    CreateDdpContainerModalComponent,
    PrintSaModalComponent,    
    VibeModalComponent,
    LocationDateModalComponent,
    CommentsModalComponent,
    VesselViewmoreModalComponent,
    AcknowledgeModalComponent,
    PocApproveModalComponent,
    PocDeclineModalComponent,
    ReviewModalComponent,
    ManageDashboardModalComponent,
    PocWithdrawalModalComponent,
    EditTerminalComponent,
    DeclineCounterModalComponent,
    PocChangeRequestModalComponent,
    PoBuyerMultipleComponent,
    EditColumnDateComponent,
    VesselScheduleModalComponent,
    EditTransloadFacilityModalComponent,
    SaveUserPreferenceModalComponent
  ],
  exports: [
    NotificationModalComponent,
    SaveModalComponent,
    OverwriteModalComponent,
    DocItemsUploadedModalComponent,
    ConfirmationModalComponent,
    ViewmoreModalComponent,    
    CreateDdpContainerModalComponent,
    PrintSaModalComponent,
    VibeModalComponent,
    LocationDateModalComponent,
    CommentsModalComponent,
    VesselViewmoreModalComponent,
    AcknowledgeModalComponent,
    PocApproveModalComponent,
    PocDeclineModalComponent,
    ReviewModalComponent,
    ManageDashboardModalComponent,
    PocWithdrawalModalComponent,
    EditTerminalComponent,
    DeclineCounterModalComponent,
    PocChangeRequestModalComponent,
    PoBuyerMultipleComponent,
    EditColumnDateComponent,
    VesselScheduleModalComponent,
    EditTransloadFacilityModalComponent,
    SaveUserPreferenceModalComponent
  ],
})
export class SharedUiModalModule { }
