import { Observable, Subject, takeUntil } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface ScannedDocument {
  docHandle: string,
  docCreatedDate: string,
  docVersion: string,
  docStatus: string,
  docName: string,
  docType: string,
  documentSentDate: string
}

@Injectable({ providedIn: 'root' })
export class ScannedDocumentsModalService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  private showSubject$ = new Subject<Observable<ScannedDocument[]>>();

  public show$ = this.showSubject$.pipe(takeUntil(this.destroy$));

  constructor(private http: HttpClient) {}

  show(param: Observable<ScannedDocument[]> | string) {

    const config$ = (typeof param === 'string')
                  ? this.http.get<ScannedDocument[]>('/shipment/GetShipmentDocumentDetail?KeyValue=' + param + '&KeyName=ShipKey')
                  : param;

    this.showSubject$.next(config$);
  }

  getShipmentScannedDocumentDetail(shipKey:any){
    return this.http.get<ScannedDocument[]>('/shipment/GetShipmentDocumentDetail?KeyValue=' + shipKey + '&KeyName=ShipKey');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
