import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter, BehaviorSubject } from 'rxjs';

export interface PocApproveEventModalConfig {
    title: string,
    message?: string,
    data:any;
    count?:any,
    btnOne:string,
    btnTwo:string,
    params:string
}

export enum PocApproveDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface PocApproveModalEvent {
    type: PocApproveDialogueEvent,
    params?: any,
    note?: any
}

@Injectable({ providedIn: 'root' })

export class PocApproveModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<PocApproveModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public noteText = "";
  public refreshAfterModalClose$$ = new BehaviorSubject(false);
  public refreshAfterModalClose$ = this.refreshAfterModalClose$$.asObservable();
  public refreshHistoryClose$$ = new BehaviorSubject(false);
  public refreshHistoryClose$ = this.refreshHistoryClose$$.asObservable();
  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === PocApproveDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === PocApproveDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === PocApproveDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === PocApproveDialogueEvent.OnHidden));
  public onClosingPocApproveRemove$ = this.event$.pipe(filter((x) => x.type === PocApproveDialogueEvent.OnClosingRemove));
  public onClosingPocApproveRemoved$ = this.event$.pipe(filter((x) => x.type === PocApproveDialogueEvent.OnClosingRemoved));

  show(config: PocApproveEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: PocApproveModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingPocApprove(params?: any, note?: any) {
    this.event$.next({
      type: PocApproveDialogueEvent.OnClosingRemove,
      params: params,
      note: note
    });
  }

  setClosingPocApproveRemoved() {
    this.event$.next({
      type: PocApproveDialogueEvent.OnClosingRemoved
    });
  }
}