import { AppStateRepository } from '@cds-ui/shared/core-state';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, filter, finalize, forkJoin, iif, map, mergeMap, Observable, of, take, tap } from "rxjs";
import { AuthService } from "./auth.service";
import { filterNullish } from '@cds-ui/shared/core-rx';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(
        @Inject('BASE_API_URL') private baseUrl: string,
        private authService: AuthService,
        private appStateRepository: AppStateRepository) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.has('UseLegacyURL')) {
            let token;
            const url = this.validURL(request.url);
            this.authService.accessToken$.pipe(take(1),tap(x=> token = x)).subscribe();
            // Clone the request without the custom header and pass it through
            url && request.headers.append('Authorization', `Bearer ${token}`)
            const headers = request.headers.delete('UseLegacyURL').append('Authorization', `Bearer ${token}`);
            const clonedRequest = request.clone({ headers });
            return next.handle(clonedRequest);
          } 
          else {
        const url = this.validURL(request.url) ? request.url : this.combineURLs(this.baseUrl, request.url);
        const isCdsApiRequest = this.validURL(url) && url.indexOf(this.baseUrl) !== -1;
        const isWhiteListRequest = (request.body as any)?.operationName === "GetUserPermissions"
            || (isCdsApiRequest && request.url.indexOf("/health/app") !== -1)
            || (isCdsApiRequest && request.url.indexOf("/user/preferences") !== -1)
            || !isCdsApiRequest;

        return forkJoin([
            isCdsApiRequest
                ? this.authService.accessToken$.pipe(filterNullish(), take(1)) 
                : this.authService.accessToken$.pipe(take(1)),
            this.appStateRepository.initState$.pipe(take(1))
        ])
            .pipe(
                map(([token, hasInit]) => ({ token: token, isInit: hasInit ? false : isWhiteListRequest })),
                mergeMap((params) =>
                    forkJoin([
                        of(params.token),
                        iif(() => params.isInit, of([]), this.appStateRepository.activeCompany$.pipe(filter(x => !!x.length), take(1))),
                        iif(() => params.isInit, of([]), this.appStateRepository.availableCompanies$.pipe(filter(x => !!x.length), take(1))),
                        iif(() => params.isInit, of([]), this.appStateRepository.nonFilteredRequest$.pipe(take(1))),                       
                        iif(() => params.isInit, of([]), this.appStateRepository.activeBuyer$.pipe(map(x => x ?? []), take(1))),
                        iif(() => params.isInit, of([]), this.appStateRepository.availableBuyers$.pipe(map(x => x ?? []), take(1))),
                        iif(() => params.isInit, of(false), this.appStateRepository.usingBuyerFilter$.pipe(take(1))),
                        iif(() => params.isInit, of(false), this.appStateRepository.selectAllFilter$.pipe(take(1))),
                    ])
                        .pipe(                           
                            mergeMap(([token, activeCompanies, availableCompanies, whiteListFilter, activeBuyers, availableBuyers, usingBuyerFilter, selectAllFilter]) => {
                                this.authService.refreshTimeout();                                
                                const activeCompaniesFilter = [...activeCompanies];
                                const activeBuyersFilter = [...activeBuyers];                       
                                const companySelectAll = availableCompanies.length === activeCompaniesFilter.length;
                                const buyerSelectAll   = availableBuyers.length === activeBuyersFilter.length;                        
                                const status = usingBuyerFilter ? selectAllFilter ? (companySelectAll || buyerSelectAll): !selectAllFilter : !usingBuyerFilter;

                                const shouldByPassUrl = whiteListFilter.map(x => ({ url: x, isUrl: this.validURL(x) }))
                                    .some(x => x.isUrl
                                        ? url === x.url
                                        : url.indexOf(x.url) > -1 || (request.body as any)?.operationName === x.url);
                                    const headers = isCdsApiRequest? !status|| shouldByPassUrl
                                    ? request.headers.append('Authorization', `Bearer ${token}`)                                   
                                    : (usingBuyerFilter)
                                    ? request.headers
                                       .append('Authorization', `Bearer ${token}`)
                                       .append('X-Division', companySelectAll ? [] :activeCompaniesFilter.map(x => x.companyCode))
                                       .append('X-Buyer', buyerSelectAll ? [] :activeBuyersFilter.map(x =>  x.companyCode + "_" + x.buyerCode))
                                    :request.headers
                                       .append('Authorization', `Bearer ${token}`)
                                       .append('X-Division', companySelectAll ? [] :activeCompaniesFilter.map(x => x.companyCode))                                                
                                    : request.headers;

                                const apiReq = request.clone({
                                    url: url,
                                    headers: headers
                                });

                                const requestTracker$$ = new BehaviorSubject<boolean>(false);
                                this.appStateRepository.trackRequest(requestTracker$$);

                                return next.handle(apiReq).pipe(
                                    finalize(() => {
                                        requestTracker$$.next(true);
                                        requestTracker$$.complete();
                                    })
                                );
                            })
                        )
                )
            );
        }
}

    private combineURLs(baseURL: string, relativeURL?: string) {
        return relativeURL
            ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
            : baseURL;
    }

    private validURL(str: string) {
        try {
            new URL(str);
            return true;
        } catch (err) {
            return false;
        }
    }
}