import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';

export const defaultColumnSetting: ColDef = {
    resizable: true,
    suppressMenu: false,
    filter: true,
    sortable: true,
    enableRowGroup: true,
    floatingFilter: true,
    filterParams: {
      trimInput: true,
    },
    editable: false
};

export const getNullValueAsBlank = (params: ValueFormatterParams) => {
  const paramsValue = typeof params?.value === 'object' ?  ( params?.value ?? '' ) : String(( params?.value) ?? '' )?.trim();
  return (!( paramsValue ||  params?.node?.group)) ? '' : (params?.value);
};

