import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface VibeEventModalConfig {
    title: string,
    message?: string,
    btnOne:string,
    btnTwo:string,
    comments?:string
}

export enum VibeDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface VibeModalEvent {
    type: VibeDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class VibeModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<VibeModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === VibeDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === VibeDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === VibeDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === VibeDialogueEvent.OnHidden));
  public onClosingVibeRemove$ = this.event$.pipe(filter((x) => x.type === VibeDialogueEvent.OnClosingRemove));
  public onClosingVibeRemoved$ = this.event$.pipe(filter((x) => x.type === VibeDialogueEvent.OnClosingRemoved));

  show(config: VibeEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: VibeModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingVibe(params?: any) {
    this.event$.next({
      type: VibeDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingVibeRemoved() {
    this.event$.next({
      type: VibeDialogueEvent.OnClosingRemoved,
    });
  }
}