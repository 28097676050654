<div class="d-flex h-100 w-100 flex-column position-absolute" 
     [ngClass]="{'scroll-lock-body': (useScrollLock$ | async) === true}">
    <header class="w-100 page-header" *ngIf="(isAuth$ | async)" scrollLock>
        <cds-ui-main-header-menu *ngIf="(showHeader$ | async)" [logoSrc]="logoSrc" [showLogoOnly]="showLogoOnly$"></cds-ui-main-header-menu>
        <cds-ui-main-header-nav-bar *ngIf="(showLogoOnly$ | async) === false && (showHeader$ | async)"></cds-ui-main-header-nav-bar>
    </header>
    <main class="container-fluid flex-fill position-relative"
    [ngClass]="{'px-0': (showLogoOnly$ | async) === true, 'd-flex': (useFlex$ | async) === true }"
        *ngIf="(isAuth$ | async) || showContent === true">
        <router-outlet></router-outlet>
    </main>

    <cds-ui-overwrite-modal></cds-ui-overwrite-modal>
    <cds-ui-notification-modal></cds-ui-notification-modal>
    <cds-ui-save-modal></cds-ui-save-modal>
    <cds-ui-doc-items-uploaded-modal></cds-ui-doc-items-uploaded-modal>
    <cds-ui-ghosting-dialog></cds-ui-ghosting-dialog>
    <cds-ui-save-user-preference-modal></cds-ui-save-user-preference-modal>
</div>