import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
@Component({
  selector: 'cds-ui-numeric-cell',
  templateUrl: './numeric-cell-editor.component.html',
  styleUrls: ['./numeric-cell-editor.component.scss']
})

export class NumericCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value!: number;
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  public isDecimalAllowed = false;

  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;

  agInit(params: any): void {
    this.isDecimalAllowed = params?.isDecimalAllowed ?? false;
    // this.isDecimalAllowed = params.colDef.cellRendererParams?.isDecimalAllowed ?? false;
    this.params = params;
    this.setInitialState(this.params);
    const inputKeysValues = '1234567890';
    const inputKeysValuesWithDecimal = this.isDecimalAllowed ? inputKeysValues.concat('.') : inputKeysValues;
    this.cancelBeforeStart = !!(
      params.charPress && inputKeysValuesWithDecimal.indexOf(params.charPress) < 0
    );
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE) {
      startValue = '';
    } else if (params.charPress) {
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      startValue = params.value;
      if (params.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  getValue(): number {
    if(this.params.colDef.headerName == "Duty Rate") {
      if(this.value) {
        return Number(this.value);
      }
      return this.value;
    }
    return Number(this.value);
  }

  onKeyDown(event: any): void {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (
      !this.finishedEditingPressed(event) &&
      !this.isCharNumeric(event)
    ) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
      if (this.highlightAllOnFocus) {
        this.input.element.nativeElement.select();

        this.highlightAllOnFocus = false;
      } else {
        const length = this.input.element.nativeElement.value
          ? this.input.element.nativeElement.value.length
          : 0;
        if (length > 0) {
          this.input.element.nativeElement.setSelectionRange(length, length);
        }
      }
      this.input.element.nativeElement.focus();
    });
  }

  private isCharNumeric(charStr: any): boolean {
    if (this.isDecimalAllowed) {
      let current: string = charStr.target.value;
      let next: string = current.concat(charStr.key)
      return next as any && /^\d*\.?\d{0,3}$/.test(next as any);
    }
    else {
      return !!/\d/.test(charStr.key);
    }
  }

  private deleteOrBackspace(event: any) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
