import { Component } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';


@Component({
  selector: 'cds-ui-add-item-grid-button-renderer',
  templateUrl: './add-item-grid-button-renderer.component.html',
  styleUrls: ['./add-item-grid-button-renderer.component.scss'],
})
export class AddItemGridButtonRendererComponent implements ICellRendererAngularComp{

  constructor(private appStateRepository: AppStateRepository) {}

  public isBottom = false;
  public  data: any;
  public isLoading$ = this.appStateRepository.isLoading$;

  agInit(params: any): void {
    this.isBottom = params.node.rowPinned === "bottom"
    this.data = params.node.data;
  }

  btnClickedHandler($event: any) {
    this.data.staged = !(this.data.staged ?? false);
  }

  refresh(params?: any): boolean {
    return true;
  }


}
