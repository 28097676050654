import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { combineLatest, Subject, filter, Observable, tap, BehaviorSubject, startWith, shareReplay, map, takeUntil } from 'rxjs';
import { CommandError } from '@cds-ui/data-access';
import { FormControl } from '@ngneat/reactive-forms';

@Directive({
    selector: '[formControl]',
})
export class EnhancedFormControlDirective implements OnInit, OnDestroy {
    private readonly destroy$$ = new Subject<void>();
    private readonly formError$$ = new Subject<CommandError | null>();
    private readonly isDisabled$$ = new BehaviorSubject<boolean>(false);
    private readonly isDisabled$ = this.isDisabled$$.asObservable();

    @Input() labelText = "";
    @Input() formControl!: FormControl<any>;
    @Input() isCustomValidator = false;
    @Input() dependUpon!: FormControl<any>[] | null;
    @Input() set formError(stream: Observable<CommandError | null>) {
        stream.pipe(
            tap(x => this.formError$$.next(x)),
        )
            .subscribe();
    }

    @Input() set isDisabled(input: boolean) {
        this.isDisabled$$.next(input);
    }

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

    ngOnInit(): void {
        this.appendRequiredSpanFn();
        this.appendLabelFn();
        this.disableControlFn();
    }

    ngOnChanges() {
        this.disableControlFn();
        this.dependUponFn();
    }

    private dependUponFn() {
        const controls$ = this.dependUpon?.map(x => x.value$.pipe(
            startWith(""),
            shareReplay(1)
        ));

        const isNullish$ = combineLatest(controls$ ?? []).pipe(
            map(x => !!x.filter(v => !v).length),
            startWith(false),
            shareReplay(1)
        );

        combineLatest([isNullish$, this.isDisabled$])
            .pipe(
                takeUntil(this.destroy$$),
                tap(([isNullish, isDisabled]) =>
                    isNullish || isDisabled
                        ? this.formControl?.disable()
                        : this.formControl?.enable())
            )
            .subscribe();
    }

    private appendLabelFn() {
        if (!this.labelText) return;

        const element = this.el.nativeElement;
        this.renderer.setAttribute(element, 'name', this.labelText);

        const labelElement = this.renderer.createElement('label');
        this.renderer.setProperty(labelElement, 'textContent', this.labelText);
        this.renderer.setAttribute(labelElement, 'for', this.labelText);
        this.renderer.setAttribute(labelElement, 'class', 'form-label');
        this.renderer.insertBefore(element.parentNode, labelElement, element);
    }

    private appendRequiredSpanFn() {
        if (!this.formControl) return;
        if (this.isCustomValidator) return;

        combineLatest([
            this.formControl.valid$,
            this.formControl.touch$,
            this.formControl.errors$
        ])
            .pipe(
                takeUntil(this.destroy$$),
                tap(_ => {
                    const element = this.el.nativeElement;
                    const mainDiv = element.parentNode.querySelector('.error-wrapper.required');

                    if (mainDiv) {
                        this.renderer.removeChild(element.parentNode, mainDiv);
                    }
                }),
                filter(([valid, touched, err]) => !valid && touched && err?.['required']),
                tap(_ => {
                    const element = this.el.nativeElement;

                    const mainDiv = this.renderer.createElement('div');
                    this.renderer.setAttribute(mainDiv, 'class', 'required error-wrapper d-flex align-items-center mt-2');

                    const iconSpan = this.renderer.createElement('span');
                    this.renderer.setAttribute(iconSpan, 'class', 'material-icons text-danger float-start me-2');
                    this.renderer.setProperty(iconSpan, 'textContent', 'error_outline');

                    const smallElement = this.renderer.createElement('small');
                    this.renderer.addClass(smallElement, 'text-white');
                    this.renderer.setProperty(smallElement, 'textContent', 'Please fill out this field.');

                    this.renderer.appendChild(mainDiv, iconSpan);
                    this.renderer.appendChild(mainDiv, smallElement);
                    this.renderer.appendChild(element.parentNode, mainDiv);
                })
            )
            .subscribe();
    }

    private disableControlFn() {
        this.isDisabled$
            .pipe(
                takeUntil(this.destroy$$),
                tap(isDisabled =>
                    isDisabled
                        ? this.formControl?.disable()
                        : this.formControl?.enable())
            )
            .subscribe();
    }
}