import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface ActivityFeedEventModalConfig {
    title: string,
    data:any
}

@Injectable({ providedIn: 'root' })
export class ActivityFeedViewMoreModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));

  show(config: ActivityFeedEventModalConfig): void {
    this.showSubject$.next(config);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
