<ng-template #scannedDocumentModal>
  <div class="card" id="modalDrag" cdkDrag>
    <div class="modal-header py-3" cdkDragHandle>
      <h2 class="m-0">Documents</h2>
      <button class="bg-transparent border-0 p-0" (click)="modalRef.hide()">
        <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
        <span class="visually-hidden">Close</span>
    </button>
    </div>
    <div class="modal-body scroll-modal pt-0 mt-4">
    <ng-container *ngIf="(scannedDocumentsDetail$ | async) as list">
      <div class="row" *ngIf="list.length > 0;else noData">
        <div *ngFor="let item of list; odd as isOdd; even as isEven" [ngClass]="{'bg-gray-700': isEven, 'bg-gray-600': isOdd}" class="message-card">
          <div class="message-card-meta d-flex align-items-center mb-2">
            <h6 class="flex-fill fs-sm fw-bold m-0">{{item.docType}}<span class="badge border border-primary mx-2">{{'v' + item.docVersion}}</span></h6>
            <div><span>{{item.documentSentDate | date:'MM/dd/YYYY'}}</span> <span class="material-icons mi-18 ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Document Scanned Date">info</span></div>
            <div *ngIf="item.docStatus === 'Approved'"><span class="badge bg-primary">{{item.docStatus}}</span></div>
          </div>
          <h5 class="message-card-title mt-3"><a [href]="scannedDocUrl + item.docHandle" target="_blank">{{item.docName}}</a></h5>
        </div>
      </div>
    </ng-container>
        <ng-template #noData>
          <div class="row">
            <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
              <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">cloud_off</span></span>
              </p>
              <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
            </div>
          </div>
        </ng-template>
    </div>
</div>
</ng-template>
