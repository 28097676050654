
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { tap, BehaviorSubject, withLatestFrom, map, Subject, takeUntil, take, startWith, combineLatest, filter, shareReplay, debounceTime } from 'rxjs';
import _ from 'lodash';

@Directive({ selector: '[buyerFilter]' })
export class buyerFilterDirective implements OnInit, OnDestroy {
    private readonly destroy$$ = new Subject<void>();
    private readonly nonFilteredRequest$$ = new BehaviorSubject<string[]>([]);
    private readonly nonFilterGlobalRequest = ["GetUserPermissions", "search/suggestions"];
    private readonly nonFilteredRequest$ = this.nonFilteredRequest$$.pipe(
        map(x => x.concat(this.nonFilterGlobalRequest)),
        shareReplay(1),
    );

    private readonly shouldUseFilter$ = this.appState.currentUser$.pipe(
        map(c => c.subgroupCode?.toLowerCase() === "all"),
    );



    private readonly activeBuyer$ = combineLatest([
        this.appState.availableBuyers$,
        this.appState.activeBuyer$
    ]).pipe(
        map(([av, act]) => (av?.length ?? 0) === (act?.length ?? 0) || !act.length
            ? []
            : act),
        startWith([]),
        shareReplay(1),
  )


    @Input("buyerFilter") set query(query: string | string[] | null) {
        const filteredRequest = !query
            ? []
            : Array.isArray(query)
                ? query
                : [query];

        this.nonFilteredRequest$$.next(filteredRequest);
    }

    private param$ = combineLatest([
        this.appState.availableBuyers$,
        this.appState.initState$,
        this.shouldUseFilter$,
        this.nonFilteredRequest$
    ])
        .pipe(  
            map(([buyers, _, shouldFilter, nonFilterRequest]) => ({
                shouldFilter,
                nonFilterRequest,
                buyers
            })),
            withLatestFrom(this.activeBuyer$),
            map(([param, act]) => ({
                filterRequests: param.shouldFilter
                    ? param.nonFilterRequest
                    : [],
                    buyers: act.length
                    ? act
                    : param.shouldFilter
                       ? param.buyers
                        : param.buyers,
                shouldFilter: param.shouldFilter
            })),           
            tap(x => this.appState.setActiveBuyers(x.buyers)),
            tap(x => this.appState.setNonFilteredRequest(x.filterRequests)),
            tap(_ => this.appState.setUsingBuyerFilter(true)),
            tap(_ => this.appState.setSelectAllFilter(true)),

            shareReplay(1)
        );

    private createEmbeddView() {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    constructor(
        private templateRef: TemplateRef<{ permissions: string[] }>,
        private viewContainer: ViewContainerRef,
        private appState: AppStateRepository
    ) { }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

    ngOnInit(): void {
        combineLatest([
            this.param$,
            this.appState.activeBuyer$,
            this.appState.availableBuyers$
        ])
        .pipe(
            takeUntil(this.destroy$$),
            take(1),
            tap(_ => this.createEmbeddView())
        )
        .subscribe();
     }
}