import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface ManageDashboardEventModalConfig {
    title: string,
    message?: string,
    btnOne:string,
    btnTwo:string
}

export enum ManageDashboardDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface ManageDashboardModalEvent {
    type: ManageDashboardDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class ManageDashboardModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<ManageDashboardModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === ManageDashboardDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === ManageDashboardDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === ManageDashboardDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === ManageDashboardDialogueEvent.OnHidden));
  public onClosingManageDashboardRemove$ = this.event$.pipe(filter((x) => x.type === ManageDashboardDialogueEvent.OnClosingRemove));
  public onClosingManageDashboardRemoved$ = this.event$.pipe(filter((x) => x.type === ManageDashboardDialogueEvent.OnClosingRemoved));

  private redisKey = "dashboard:customer:dashboard";
  private preferencesRoute = "/user/preferences/page/";

  constructor(private http: HttpClient, private appState: AppStateRepository,){

  }
  show(config: ManageDashboardEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: ManageDashboardModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingManageDashboard(params?: any) {
    this.event$.next({
      type: ManageDashboardDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingManageDashboardRemoved() {
    this.event$.next({
      type: ManageDashboardDialogueEvent.OnClosingRemoved,
    });
  }

  public getWidgetLayout(): Observable<any> {
    return this.http.get(this.preferencesRoute + this.redisKey);
  }
  
  public updateWidgetLayout(pageLayout:any,staticsCards:any,tallyCardDetails:any): Observable<any> {
    const dictionary = {
      "PageLayout": JSON.stringify(pageLayout),
      "StaticCards": JSON.stringify(staticsCards),
      "TallyCardDetails":JSON.stringify(tallyCardDetails)
    };
    this.appState.ghostedUserPreferences$.next({
      path: 'pages/dashboard:customer:dashboard',
      data: dictionary
    })
    return this.http.post(this.preferencesRoute + this.redisKey, dictionary);
  }
}