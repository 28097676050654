import { Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { AppStateRepository } from "@cds-ui/shared/core-state";
import { createStore, distinctUntilArrayItemChanged, select, withProps } from "@ngneat/elf";
import { Subject, buffer, combineLatest, filter, first, map, shareReplay, switchMap, take, takeUntil, tap, timer } from "rxjs";
import { FeaturePermissionApiService } from "./feature-permission.api";
import { PermissionResult } from "@cds-ui/data-access";
import _ from "lodash";

export const store = createStore(
    { name: 'feature-permission' },
    withProps<{ globalPermissions: PermissionResult[] }>({ globalPermissions: [] }),
    withProps<{ pagePermissions: PermissionResult[] }>({ pagePermissions: [] }),
    withProps<{ pageQueryCache: string[] }>({ pageQueryCache: [] }),
    withProps<{ globalQueryCache: string[] }>({ globalQueryCache: [] }),
);

@Injectable({ providedIn: 'root' })
export class FeaturePermissionRepository implements OnDestroy {
    private cancelSignal$$ = new Subject<void>();
    private globalBufferedQuery$$ = new Subject<string[]>();
    private pageBufferedQuery$$ = new Subject<string[]>();
    private destroy$$ = new Subject<void>();

    private globalBufferedQuery$ = this.globalBufferedQuery$$.pipe(
        buffer(this.globalBufferedQuery$$.pipe(
            switchMap(() => timer(200))
        )),
        filter(x => !!x.length),
        map(x => x.flatMap(q => q)),
        map(arr => Array.from(new Set(arr)))
    );

    private pageBufferedQuery$ = this.pageBufferedQuery$$.pipe(
        buffer(this.pageBufferedQuery$$.pipe(
            switchMap(() => timer(200))
        )),
        filter(x => !!x.length),
        map(x => x.flatMap(q => q)),
        map(arr => Array.from(new Set(arr)))
    );

    public permissions$ = combineLatest([
        store.pipe(select(state => state.pagePermissions)),
        store.pipe(select(state => state.globalPermissions))
    ]).pipe(
        map(([p, g]) => g.concat(p)),
        map(arr => Array.from(new Set(arr))),
        shareReplay(1)
    );

    public query(queries: string[]) {
        return this.queueAndQuery(queries, false);
    }

    public queryGlobal(queries: string[]) {
        return this.queueAndQuery(queries, true);
    }

    constructor(private router: Router, private appState: AppStateRepository, private api: FeaturePermissionApiService) {
        this.onRouteChange(); // clear cache query per page
        this.onGlobalQueryRecieved(); // on global query recieved get permission
        this.onPageQueryReceived(); //on page query recieved get permission
        this.onUserCompanyChanged(); //on user or company changed refresh permission
    }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

    private queueAndQuery(queries: string[], isGlobal = false) {
        const expandedQueries = this.api.expandFeature(queries);
        const target = isGlobal ? this.globalBufferedQuery$$ : this.pageBufferedQuery$$;
        target.next(expandedQueries);

        const patterns = this.api.parseKey(expandedQueries);

        return this.permissions$
            .pipe(
                filter(x => !!x.length),
                map(permissions => permissions.filter(item =>
                    patterns.some(pattern => pattern.regex.test(item.permissionKey))
                ))
            );
    }

    private onRouteChange() {
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationStart),
                tap(_ => this.clearPageCache())
            )
            .subscribe();
    }

    private onPageQueryReceived() {
        this.pageBufferedQuery$
            .pipe(
                switchMap(q => store.pipe(
                    select(state => state.pageQueryCache.concat(state.globalQueryCache)),
                    map(cache => q.filter(i => !cache.includes(i))),
                    first()
                )),
                tap(q => this.queryPermission(q)),
                tap(q => store.update(state => ({
                    ...state,
                    pageQueryCache: Array.from(new Set([...state.pageQueryCache, ...q]))
                }))),
            )
            .subscribe();
    }

    private onGlobalQueryRecieved() {
        this.globalBufferedQuery$
            .pipe(
                switchMap(q => store.pipe(
                    select(state => state.globalQueryCache),
                    map(cache => q.filter(i => !cache.includes(i))),
                    first(),
                )),
                tap(q => this.queryPermission(q, true)),
                tap(q => store.update(state => ({
                    ...state,
                    globalQueryCache: Array.from(new Set([...state.globalQueryCache, ...q]))
                })))
            )
            .subscribe();
    }

    private queryPermission(queries: string[], global = false) {
        if (!queries.length) return;

        this.appState.activeCompany$
            .pipe(
                take(1),
                takeUntil(this.cancelSignal$$),
                map(_ => this.api.parseKey(queries)),
                switchMap(x => this.api.get(x)),
                tap(permissions => {
                    const key = global ? 'globalPermissions' : 'pagePermissions';
                    
                    store.update(state => {
                        const currentPermissions = state[key];
                        const updatedPermissions = _.uniqWith(
                                                        currentPermissions.concat(permissions), 
                                                        (x, y) => x.permissionKey == y.permissionKey
                                                    );

                        return { ...state, [key]: updatedPermissions };
                    });
                }),
            )
            .subscribe();

    }

    private onUserCompanyChanged() {
        this.appState.activeCompany$
            .pipe(
                takeUntil(this.destroy$$),
                tap(() => this.cancelSignal$$.next()),
                switchMap(() => store.pipe(
                    select(state => state.pageQueryCache),
                    first()
                )),
                tap(pageQuery => this.queryPermission(pageQuery)),
                switchMap(() => store.pipe(
                    select(state => state.globalQueryCache),
                    first()
                )),
                tap(globalQuery => this.queryPermission(globalQuery, true))
            )
            .subscribe();
    }

    private clearPageCache() {
        store.update(state => ({
            ...state,
            pageQueryCache: [],
            pagePermissions: []
        }));
    }

}