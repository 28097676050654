import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardQueryService } from '@cds-ui/libs/domain/dashboard/domain-core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, Subject, takeUntil } from 'rxjs';
import { TallyCardTemplate } from './global-tally-card.map';
import { ManageDashboardDialogueEvent, ManageDashboardEventModalConfig, ManageDashboardModalService } from './manage-dashboard-modal.service';
declare let google: any;
import mapboxgl from 'mapbox-gl';
import { convertObservableToBehaviorSubject } from '@cds-ui/shared/core-rx';
import { AppStateRepository } from '@cds-ui/shared/core-state';

@Component({
  selector: 'cds-ui-manage-dashboard-modal',
  templateUrl: './manage-dashboard-modal.component.html',
  styleUrls: ['./manage-dashboard-modal.component.scss'],
})
export class ManageDashboardModalComponent implements OnInit,OnDestroy {
  map!: mapboxgl.Map;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: ManageDashboardEventModalConfig;
  public TallyCardTemplate =  TallyCardTemplate;
  @ViewChild('template') template!: TemplateRef<any>;
  public pageLayout:any[] = [];
  public staticsCards:any[] = [];
  public tallyCardDetails:any[] = [];
  public permssionKeys$$ = new Subject<string[]>();
  public listOfPermissions = [
    "user:ui:customer-dashboard:*",
    "*:action:*:list",
    'poi-poc:action:po-change-requests-pending:view',
    'poi-poc:action:po-change-requests-approved:view',
    'poi-poc:action:po-pending-acknowledgement:view',
    'poi-poc:action:po-changes-pending-acknowledgement:view',
    'poi-poc:action:cancelled-pos:view',
    'transload:action:inbound-to-dc:list'
  ];
  globalPermissions:any[] = [];
  chartColors = ['#287c93','#8292b3','#028f87','#42568e','#253352','#1d5869']
  private readonly destory$ = new Subject<void>();
  carrierUtilizationData:any = [];
  carrierUtilization:any = [];
  constructor(
    private modalservice: BsModalService,
    private manageDashboardModalService: ManageDashboardModalService,
    private dashBoard: DashboardQueryService,
    private appState:AppStateRepository
  ) {
  }
  private readonly companies$$ = convertObservableToBehaviorSubject(
    this.appState.availableCompanies$,
    null
  );
  public isCarrierIncluded(){
    return !(
      this.companies$$.getValue()?.some((c) => c.companyCode === '940') ?? false
    );
  };
  ngOnInit(): void {
    this.getUserPreferences();
  }  
  openCharts(){
    this.mapChart();
  }
  
  mapChart(){
    google.charts.load('current', { 'packages': ['corechart'] }).then((el:any)=>{
      google.charts.setOnLoadCallback(this.getCarrierUtilizationDetails())
    });
    google.charts.load('current', { packages: ['corechart', 'bar'] }).then((el:any)=>{
      google.charts.setOnLoadCallback(this.getContainerUsageData())
    });
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2VudHVyeW1hcHMiLCJhIjoiY2tycDY5ODdiOGwxejJvbXQ3a2NwaTA3cCJ9.yIhuL6uI0UO8RntZ8nVIGg';
    this.map = new mapboxgl.Map({
      container: 'mapModal',
      style: 'mapbox://styles/mapbox/dark-v10', 
      center: [0, 10], 
      renderWorldCopies: true,
      zoom: 2, 
      minZoom: 2
    });
        
  }

  permissionsData(){
      this.permssionKeys$$.pipe(takeUntil(this.destroy$)).subscribe((val) => {this.globalPermissions = val})
  }
  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.manageDashboardModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
      this.getManageDashboardList();
      this.permissionsData();
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as ManageDashboardEventModalConfig;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered modal-fullscreen'
        });
      });

    this.handler(
      this.modalservice.onShow,
      ManageDashboardDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      ManageDashboardDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      ManageDashboardDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      ManageDashboardDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: ManageDashboardDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.manageDashboardModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.staticsCards = [];
    this.tallyCardDetails.map(x=>{x.showOnMyDay = true})
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCloseManageDashboardClick() {
    this.manageDashboardModalService.setClosingManageDashboard({staticsCards : this.staticsCards , pageLayout : this.pageLayout});
    this.modalRef?.hide();
  }

  private subscribeOverwrittenExisting() {
    this.manageDashboardModalService.onClosingManageDashboardRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }

  getContainer(){
    return this.tallyCardDetails.filter(x=>x.category == 'container' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }

  getInbound(){
    return this.tallyCardDetails.filter(x=>x.category == 'inbound' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }

  getPurchase(){
    return this.tallyCardDetails.filter(x=>x.category == 'purchase' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }

  getShipment(){
    return this.tallyCardDetails.filter(x=>x.category == 'shipment' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }

  getCompliance(){
    return this.tallyCardDetails.filter(x=>x.category == 'compliance' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }
 
  getPoiPoc(){
    return this.tallyCardDetails.filter(x=>x.category == 'purchaseOrderManagement' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }

  poiPocView(){
   return this.globalPermissions.some(p => p.includes("purchase-order:action:purchase-order-management:list"))
  }

  get transload(){
    return this.tallyCardDetails.filter(x=>x.category == 'transload' && this.globalPermissions.some(p => p.includes(x.attributes.permissions[0])))
  }

  get hasTransloadPanelAccess(){
    return this.globalPermissions.some(p => p.includes('transload:action:transload:list'));
  }
  
  getUserPreferences(){
    this.manageDashboardModalService.getWidgetLayout().subscribe(el=>{
      this.pageLayout = JSON.parse(el.PageLayout);
      this.staticsCards = JSON.parse(el.StaticCards);
      this.tallyCardDetails = JSON.parse(el.TallyCardDetails);
    })
  }

  updateUserPreferences(){
    this.manageDashboardModalService.updateWidgetLayout(this.pageLayout,this.staticsCards,this.tallyCardDetails).subscribe(x=>{})
  }
  
  getManageDashboardList(){
  this.tallyCardDetails = [];
  this.TallyCardTemplate.map(x=>{this.tallyCardDetails.push(x)})
  this.defaultButtons();
  }

  defaultButtons(){
    this.staticsCards.map(x=>this.tallyCardDetails.map(y=>{
        if(x.id == y.attributes.id){
            y.showOnMyDay = false;
        }
      })
    )
  }

  panelButton(val:any){
  return this.pageLayout.find(x=>x.id == val)
  }

  addRemovePanelClick(type:any ,name:any){
    if(type == 'remove'){
      this.pageLayout.map(x=> {
        if(x.id == name){
          this.pageLayout.splice(this.pageLayout.indexOf(x),1)
        }
      })
      
      this.updateUserPreferences();
    }else{
        if(name == "ContainerUsage"){
          this.pageLayout.push({ "id": name, "cols": 2, "rows": 2, "y": 0, "x": 0 ,"resizeEnabled": false})
        }else if (name == "CarrierUtilization"){
          this.pageLayout.push({ "id": name, "cols": 1, "rows": 3, "y": 0, "x": 0})
        }
        else if (name == "Map"){
          this.pageLayout.push({ "id": name, "cols": 2, "rows": 2, "y": 0, "x": 0})
        }else{
          this.pageLayout.push({ "id": name, "cols": 1, "rows": 2, "y": 0, "x": 0 })
        }
        
        this.updateUserPreferences();
      }
  }

  addRemoveClick(data: any,type:string) {
    if(type == 'remove'){
      this.staticsCards.map(x=> {
        if(x?.id == data?.attributes?.id){
          this.tallyCardDetails.map(x=> {if(x?.attributes?.id == data?.attributes?.id){
            x.showOnMyDay = true;
          }} )
          this.staticsCards.splice(this.staticsCards.indexOf(x),1)
        }
      })
      this.updateUserPreferences();
    }else{
      if(this.staticsCards.length < 5){
        this.staticsCards.push({ "id": data.attributes.id, "cols": 1, "rows": 1, "y": 0, "x": 0 })
        this.updateUserPreferences();
      }
     this.defaultButtons();
    }
    if(this.staticsCards.length){
      this.staticsCards.forEach((val,i)=>{val['x'] = i;})
    }
  }

  getCarrierUtilizationDetails(){
    const pieChart = document.getElementById('pieChartModal');
    if (pieChart){
      const data = google.visualization.arrayToDataTable([["Carrier","Quantity"],["Zim Container Lines",30.1],["Ocean Network Express",45.9],["Ocean Network Express",24],["CASIA GLOBAL LOGISTICS CO.,LTD.",1]]);
      const options = {
        pieHole: 0.5,
        legend: 'none',
        backgroundColor: 'transparent',
        pieSliceBorderColor: 'transparent',
        width: 'auto',
        height: 'auto',
        chartArea: {
        left:10,top:20,width:"90%",height:"90%"
        },
        colors: this.chartColors,
      };

      const chart = new google.visualization.PieChart(document.getElementById('pieChartModal'));
      chart.draw(data, options);
    }
    else{
      setTimeout(() => this.getCarrierUtilizationDetails(), 1000);
    }
  }

  getContainerUsageData() {
      const data = google.visualization.arrayToDataTable([["Container","Quantity",{"role":"style"},{"role":"annotation"}],["20",1,"color: #00b9ae","1 Containers"],["40 HC",2,"color: #00b9ae","2 Containers"]]);

      const options = {
        legend: 'none',
        backgroundColor: 'transparent',
        width: 'auto',
        height: 'auto',
        chartArea: {
          left:10,
          width: '70%',
          height: '80%',
        },

        bar: { groupWidth: '60%' },
        hAxis: {
          title: 'Total Containers',
          minValue: 0,
          textStyle: { color: '#FFF' },
          gridlines: { color: '#515764' },
          minorGridlines: { color: '#515764' },
          titleTextStyle: {color: '#f6f6f6'},
        },
        vAxis: {
          textStyle: { color: '#FFF' },
        },
      };

      const chart = new google.visualization.BarChart(
        document.getElementById('barChartModal')
      );
      chart.draw(data, options);
  }
}

