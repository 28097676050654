import { Injectable, OnDestroy } from "@angular/core";
import { Company } from "@cds-ui/data-access";
import { AppStateRepository } from "@cds-ui/shared/core-state";
import { createStore, select, withProps } from "@ngneat/elf";
import _ from "lodash";
import { Subject, distinctUntilChanged, filter, takeUntil, tap } from "rxjs";

export const store = createStore(
    { name: 'company-filter' },
    withProps<{ lastSelectedCompany: Company[] }>({ lastSelectedCompany: [] }),
);


@Injectable({ providedIn: 'root' })
export class CompanyFilterRepository implements OnDestroy {
    private destroy$$ = new Subject<void>();
    
    public lastSelectedCompany$ = store.pipe(select(state => state.lastSelectedCompany));

    public setLastSelectedCompany(selected: Company[]) {
        store.update(state => ({ ...state, lastSelectedCompany: selected }));
    }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

    constructor(private appState: AppStateRepository) {
        this.appState.currentUser$.pipe(
            takeUntil(this.destroy$$),
            distinctUntilChanged(_.isEqual),
            filter(x => !!x.hasGhosted),
            tap(x => this.setLastSelectedCompany([])),
        )
        .subscribe();

    }
}