/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, EventEmitter, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { FormBuilder } from '@ngneat/reactive-forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, map, takeUntil } from 'rxjs';
import { PocChangeRequestDialogueEvent, PocChangeRequestEventModalConfig, PocChangeRequestModalService } from './poc-change-request-modal.service';

@Component({
  selector: 'cds-ui-poc-change-request-modal',
  templateUrl: './poc-change-request-modal.component.html'
})
export class PocChangeRequestModalComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('template') template!: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject();

  public config!: PocChangeRequestEventModalConfig;
  public isDisableField: any = {};
  public isLoading$ = this.appStateRepository.isLoading$;
  public modalRef!: BsModalRef;
  public position = true;

  cancelErrorMsg = false;
  changeReqForm = new FormGroup({});
  data: any;
  datePickerConfig: any;
  fobErrorMsg = false;
  headerData: any;
  minDate!: Date;
  maxDate!: Date;
  PocChangeRequestValue: any;
  shipDateErrorMsg = false;
  submitDisable = true;

  constructor(
    private appStateRepository: AppStateRepository,
    private fb: FormBuilder,
    private modalservice: BsModalService,
    private PocChangeRequestModalService: PocChangeRequestModalService
  ) {}

  ngAfterViewInit(): void {
    /* This is temporary logic being added to handle displaying form fields.- As of this commit, we are
    in the process of scoping out a new form component framework which will include handling this.- Once
    that is completed, we will come back and clean this up.- See [VNG-10358] for the Epic that will handle this... */
    this.appStateRepository.activeCompany$
      .pipe(
        map((companies) => {
          const activeCompanyCodes = companies.map((c) => c.companyCode);
          
          switch (true) {
            case activeCompanyCodes.includes('033'):
              this.isDisableField.isDisableChangeShipDate = true;
              break;
            case activeCompanyCodes.includes('758'):
              this.isDisableField.isDisableChangeFOB = true;
              break;
          }
        })
      ).subscribe();

    this.subscribeOverwrittenExisting();
    this.PocChangeRequestModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) this.modalRef.hide();

        this.datePickerConfig = Object.assign(
          {},
          {
            containerClass: 'theme-dark-blue',
            adaptivePosition: this.position,
            isAnimated: true
          }
        );

        this.config = error as PocChangeRequestEventModalConfig;
        this.initFormData();
        this.data = this.config.data3;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered'
        });

        const changeReqPending = this.config.data3;
        if (changeReqPending) {
          const checkFOB = changeReqPending.purchaseOrderChangeRequest.find(
            (obj: any) => obj.changeType === 'Change FOB'
          );

          if (checkFOB) {
            this.changeReqForm.get('changeFOB')?.disable();
            this.fobErrorMsg = true;
          }

          const checkShipDate =
            changeReqPending.purchaseOrderChangeRequest.find(
              (obj: any) => obj.changeType === 'Change Ship Date'
            );

          if (checkShipDate) {
            this.changeReqForm?.get('changeShipDate')?.disable();
            this.shipDateErrorMsg = true;
          }

          const checkCancel = changeReqPending.purchaseOrderChangeRequest.find(
            (obj: any) => obj.changeType === 'Cancel'
          );

          if (checkCancel) {
            this.changeReqForm?.get('cancel')?.disable();
            this.cancelErrorMsg = true;
          }
        }
      });

    this.handler(
      this.modalservice.onShow,
      PocChangeRequestDialogueEvent.OnShow
    );

    this.handler(
      this.modalservice.onShown,
      PocChangeRequestDialogueEvent.OnShown
    );

    this.handler(
      this.modalservice.onHide,
      PocChangeRequestDialogueEvent.OnHide
    );

    this.handler(
      this.modalservice.onHidden,
      PocChangeRequestDialogueEvent.OnHidden
    );
  }

  close() {
    this.changeReqForm.reset();
    this.modalRef.hide();
  }

  initFormData() {
    this.changeReqForm = this.fb.group({
      changeFOB: '',
      changeShipDate: '',
      note: ['', Validators.required],
      cancel: 'False'
    });

    this.changeReqForm.contains('initiatedBy') && this.config.data1.length
      ? this.changeReqForm.addControl(
          'initiatedBy',
          new FormControl('', Validators.required)
        )
      : this.changeReqForm.removeControl('initiatedBy');

    this.submitDisable =
      (this.changeReqForm.get('changeFOB')?.value == '' &&
        this.changeReqForm.get('changeShipDate')?.value == '' &&
        this.changeReqForm.get('cancel')?.value == 'False') ??
      false;
  }

  initiatedSelection(event: any) {
    this.PocChangeRequestValue = event.target.value;
  }

  handler(handler: EventEmitter<any>, type: PocChangeRequestDialogueEvent) {
    handler
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        this.PocChangeRequestModalService.handle({ type: type })
      );
  }

  onPocChangeRequestClick() {
    this.PocChangeRequestModalService.setClosingPocChangeRequest(
      this.changeReqForm.value
    );
  }

  private subscribeOverwrittenExisting() {
    this.PocChangeRequestModalService.onClosingPocChangeRequestRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.modalRef.hide());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}