import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalGridService {

  constructor() { }

  private restoreGrid$ = new Subject<any>();
  public restoreGridData$ = this.restoreGrid$.asObservable();

  private fullScreenGrid$ = new Subject<any>();
  public fullScreenGridFeature$ = this.fullScreenGrid$.asObservable();

  private filterGrid$ = new Subject<any>();
  public filterGridData$ = this.filterGrid$.asObservable();

  private gridApiRef$ = new Subject<any>();
  public gridApi$ = this.gridApiRef$.asObservable();

  restoreGrid(){
    this.restoreGrid$.next(true);
  }

  fullScreenGrid(){
    this.fullScreenGrid$.next(true);
  }

  filterGrid(data: any){
    this.filterGrid$.next(data);
  }

  gridApi(apiRefObj?: any){
    this.gridApiRef$.next(apiRefObj);
  }

  agGridStickyHeader(gridType: string): void {
    if(gridType === 'overview' || gridType === 'search'){
      const header = document.getElementsByClassName('ag-header');
      const rowGroup = document.getElementsByClassName('ag-column-drop-wrapper');
      for(let i = 0; i < header.length; i++){
        if(i === 0){
          const agGridHeader = <HTMLElement>header[i];
          const positionInfo = agGridHeader.getBoundingClientRect();
          const width = positionInfo.width;
          const fullWidth = '100%';
          const agGridRowGroup = <HTMLElement>rowGroup[i];
          const positionInfoRowGroup = agGridRowGroup.getBoundingClientRect();
          const top = positionInfoRowGroup.top;
          document.addEventListener("scroll", (event) => {
            if (window.scrollY > top) {
              agGridHeader.classList.add("stickyHeader");
              agGridRowGroup.classList.add("stickyRowGroup");
              agGridHeader.style.width = width + 'px';
              agGridRowGroup.style.width = width + 'px';
            } else {
              agGridHeader.classList.remove("stickyHeader");
              agGridRowGroup.classList.remove("stickyRowGroup");
              agGridHeader.style.width = fullWidth;
              agGridRowGroup.style.width = fullWidth;
            }
          });
        }
      }
    }
  }

  addStickyHorizontalGridScroll(gridType: string){
    if(gridType === 'overview' || gridType === 'search'){
      const horiontalScroll = document.getElementsByClassName('ag-body-horizontal-scroll');
      const header = document.getElementsByClassName('ag-header');
      for(let i = 0; i < horiontalScroll.length; i++){
        if(i === 0){
          const agGridHorizongtalScroll = <HTMLElement>horiontalScroll[i];
          const agGridHeader = <HTMLElement>header[i];
          const positionInfo = agGridHeader.getBoundingClientRect();
          const width = positionInfo.width;
          agGridHorizongtalScroll.classList.add('stickyGridHorizontalScroll');
          agGridHorizongtalScroll.style.width = width + 'px';
        }
      }
    }
  }

}
