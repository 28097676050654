export interface User {
    userId: string,
    subgroupCode: string,
    orginalUserId: string,
    orginalSubgroupCode: string
}

export interface CurrentUserControl {
    currentUser: User | null,
    firstName: string | null | undefined,
    features: string[],
    officeCode: string | null,
    persona: string | null
}