import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { VibeDialogueEvent, VibeEventModalConfig, VibeModalService } from './vibe-modal.service';
// import { PageRemoveShippingMarksDialogueEvent, PageRemoveShippingMarksEventModalConfig, PageRemoveShippingMarksModalService,  } from './page-remove-shipping-marks-modal.service';

@Component({
  selector: 'cds-ui-vibe-modal',
  templateUrl: './vibe-modal.component.html',
  styleUrls: ['./vibe-modal.component.scss'],
})
export class VibeModalComponent implements OnInit {
  
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: VibeEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  public form = new FormGroup({
    ReasonNote: new FormControl('')
});
  constructor(
    private modalservice: BsModalService,
    private VibeModalService: VibeModalService,
    private appStateRepository: AppStateRepository
  ) {}

  ngOnInit(): void {}  

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.VibeModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as VibeEventModalConfig;
        
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      VibeDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      VibeDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      VibeDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      VibeDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: VibeDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.VibeModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onVibeClick() {
    this.config.comments = this.form.value.ReasonNote as unknown as string;
    this.VibeModalService.setClosingVibe(this.config);
  }

  close(){
    this.form.controls['ReasonNote'].setValue('');
    this.modalRef?.hide()
  }

  private subscribeOverwrittenExisting() {
    this.VibeModalService.onClosingVibeRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.form.controls['ReasonNote'].setValue('');
        this.modalRef.hide();
      });
  }
}
