import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormPreferenceService {
  private cacheRoute = '/user/preferences/page/';

  constructor(private http: HttpClient) {}

  getCache(pageName: any): Observable<object> {
    return this.http.get(this.cacheRoute + pageName);
  }

  updateCache(cacheData: any) {
    this.http.post(this.cacheRoute + cacheData.pageName, cacheData.cacheJson).subscribe();
  }

  // #endregion Private Functions...
}