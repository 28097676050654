import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from '@cds-ui/data-access';
import { filterNullish } from '@cds-ui/shared/core-rx';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import _ from 'lodash';
import { Subject, combineLatest, debounce, debounceTime, filter, map, mergeMap, of, shareReplay, startWith, takeUntil, tap, timer, withLatestFrom } from 'rxjs';

export type Card = {
  id?: string | null | undefined;
  label: string;
  count: number | undefined;
  description: string | string[];
  totalValue?: number | undefined;
};

@Component({
  selector: 'cds-ui-company-selector',
  templateUrl: './company-selector.component.html'
})
export class CompanySelectorComponent implements OnInit {
  @Input() cacheData$!: Subject<{divisionSelector?: string, colorTheme?: string}>;

  public searchCompanies$ = new Subject<string>();
  public searchCompanies: string | null = null;
  private saveCompanyPreference$$ = new Subject<void>();
  private readonly destroy$$ = new Subject<void>();

  public activeCompanies$ = this.appState.activeCompany$.pipe(
    filterNullish(),
    shareReplay(1)
  );

  public shouldUseCompFilter$ = combineLatest([
    this.appState.currentUser$,
    this.route.queryParams,
    this.appState.usingCompanyFilter$,
  ]).pipe(
    map(
      ([c, x, y]) =>
        (c.persona === 'cds-origin' || ((x ?? false) && y)) ?? false
    ),
    debounce((x) => (x ? timer(500) : of({}))),
    shareReplay(1)
  );

  public companies$ = combineLatest([
    this.appState.availableCompanies$,
    this.appState.activeCompany$.pipe(filterNullish(), shareReplay(1)),
    this.searchCompanies$.pipe(startWith('')),
  ]).pipe(
    map(([available, active, query]) => ({
      active: active,
      available: available,
      query: query,
    })),
    map((x) => ({
      ...x,
      filtered: x.available
        .map((i: any) => ({
          display: `${i.companyCode} - ${i.companyName}`,
          ...i,
        }))
        .filter((i: any) =>
          x.query === ''
            ? true
            : i.display.match(new RegExp(x.query ?? '', 'gi'))
        ),
    })),
    tap((x) => this.includeInFilter(x.active, x.filtered))
  );

  includeInFilter(active: Company[], filtered: Company[]) {
    const activeCompany = active.map((x) => x.companyCode);
    const filteredCompany = filtered.map((x) => x.companyCode);
    const selectedSome = _.intersection(activeCompany, filteredCompany);

    const result = selectedSome.length
      ? selectedSome.length === filteredCompany.length
        ? 'all'
        : 'some'
      : 'none';

    return result;
  }

  filterCompanyChange(input: string | null) {
    this.searchCompanies$.next(input ?? '');
  }

  onSelectAllCompany(filtered: Company[], active: Company[]) {
    const state = this.includeInFilter(filtered, active);
    const deselectAll = filtered.length === active.length ? true : false;
    let result: Company[];

    if (deselectAll == true) {
      result = [];
      this.appState.setActiveCompanies(result);
      this.appState.setSelectAllFilter(false);
    } else {
      result =
        state === 'none' || state === 'some'
          ? filtered
          : filtered.concat(active);
      this.appState.setActiveCompanies(result.length ? result : filtered);
      this.appState.setSelectAllFilter(true);
      this.saveCompanyPreference$$.next();
    }
  }

  preventClick(event: Event) {
    event.preventDefault();
  }

  isActiveCompany(companyCode: string, activeCompany: Company[]) {
    return activeCompany.some((e) => e.companyCode === companyCode);
  }

  onCompanySelect(company: Company, active: Company[]) {
    const checked = active.filter((x) => x.companyCode === company.companyCode);
    const result = !checked.length
      ? active.concat([company])
      : active.filter((x) => x.companyCode != company.companyCode);

    this.appState.setActiveCompanies(result.length ? result : [company]);
    this.appState.setSelectAllFilter(false);
    this.saveCompanyPreference$$.next();
  }

  constructor(
    private appState: AppStateRepository,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.saveCompanyPreference$$.pipe(
      takeUntil(this.destroy$$),
      withLatestFrom(this.appState.currentUser$),
      filter(([_, x]) => !x.hasGhosted),
      debounceTime(500),
      withLatestFrom(this.activeCompanies$),
      withLatestFrom(this.cacheData$),
      map(([[_, x], cache]) => ({
        path: "global/headers",
        data: {
          divisionselector: {
            selectedCompanies: x
          },
          colorTheme: cache?.colorTheme ?? cache.colorTheme
        }
      })),
      tap((x) => {
        this.cacheData$.next(x.data);
      }),
      mergeMap(x => this.http.post("/user/preferences", x))
    )
      .subscribe();
  }
}