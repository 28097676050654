<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header pb-0" cdkDragHandle>
        <h3 class="modal-title">Acknowledge with 855</h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>
      <!-- <form class="row g-3" [formGroup]="acknowledgeForm"> -->
      <div class="modal-body">
        <form class="row g-3">
        <h3 class="text-purple mb-0 pb-0">{{config.count}} {{config.count == 1 ? 'Record' : 'Records'}} Selected to Acknowledged with 855</h3>
          <div class="col-md-12">
            <!-- formControlName="acknowledgeField" -->
            <label for="initiatedBy" class="form-label">Initiated by</label>
            <select class="form-select" id="initiatedBy" (change)="initiatedSelection($event)">
                <option *ngFor="let initiated of config.data" [value]="initiated">{{initiated}}</option>
            </select>
            
            <div class="mt-4"><span class="fw-bold text-warning">Note: Upon clicking Approve</span><br/>
            <ul>
                <li>AOM POs will automatically update legacy.</li>
                <li>POs will NOT update. RA must manually update legacy system.</li>
            </ul>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal" (click)="close()">{{config.btnTwo}}</button>
		    <button type="button" class="btn btn-submit" [disabled]="!acknowledgeValue" (click)="onAcknowledgeClick()"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>{{config.btnOne}}</button>
      </div>
  </div>
    <!-- </form> -->
    <!-- <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4"> -->
        <!-- <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-grow-1">
        <span>{{config.message}}</span>
    </div>
    <div class="modal-footer">
        
       
        
    </div> -->
</ng-template>