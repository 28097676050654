import { Component } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { startWith, Subject, share } from 'rxjs';

@Component({
  selector: 'cds-ui-edit-grid-button-renderer',
  templateUrl: './edit-grid-button-renderer.component.html',
  styleUrls: ['./edit-grid-button-renderer.component.scss'],
})
export class EditGridButtonRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public buttonType!: string;
  public isLoading$ = this.appStateRepository.isLoading$;
  public tracker$ = new Subject<boolean>();
  public isSaveLoading$ = this.tracker$.asObservable().pipe(startWith(false), share());

  constructor(private appStateRepository: AppStateRepository) { }

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler($event: any) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        tracker$: this.tracker$,
        rowData: this.params.node.data
      }
      this.params.onClick(params);
    }
  }
  refresh(params?: any): boolean {
    return true;
  }

}
