import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class ValidatorCustom {
  static validateLeadingTrailingSpace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value && control.value?.length <= 0) {
        return null;
      }
      var regExp = /^\s|\s$/;
      const isInvalid = regExp.test(control.value);
      return (isInvalid ? { 'leadingTrailingSpaceError': { value: true } } : null);
    };
  }
}
