import { Directive, ElementRef, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { BehaviorSubject, scan, takeWhile, timer, combineLatest } from 'rxjs';

@Directive({
    selector: "[cdsUiCountDown]"
})
export class CountDownDirective implements AfterViewInit, OnChanges {
    @Input() countDown = 2;
    @Input() disabled: boolean | null = false;

    private indicaterTracker$ = new BehaviorSubject<boolean>(false);
    private countDownTracker$ = new BehaviorSubject<number>(0);

    private text$ = combineLatest([this.indicaterTracker$, this.countDownTracker$ ]);

    constructor(private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.indicaterTracker$.next(changes['disabled'].currentValue);
    }

    ngAfterViewInit(): void {
        const text = this.el.nativeElement.innerText;

        timer(0, 1000).pipe(
            scan(acc => --acc, this.countDown),
            takeWhile(x => x >= 0)
        ).subscribe(x => this.countDownTracker$.next(x));

        this.text$.subscribe(([disabled, countDown]) => {
            const countDownDone = countDown === 0;
            const buttonText = countDownDone
                             ? `${text}`
                             : `${text} (${countDown}s)`;
            
            this.el.nativeElement.innerHTML = buttonText;
            this.el.nativeElement.disabled = disabled || !countDownDone;        
        });
    }
}