export const allCollection =  {
    "Collections": [
        {
            "collectionName": "Carrier",
            "reportDetails": [
                  {
                    "cardTitle": "Carrier SO Release Performance",
                    "cardDescription": "Shows carrier SO release performance by carrier and provides metrics on % accepted and # of days to release. Also has details on specific booking requests to provide detailed visibility and the ability to analyze further.",
                    "cardImg": "carrier-so-release-performance",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/SOReleasePerformance.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
                    "permission": ['shipment:action:analytics:carriersoreleaseperformance:view', 'shipment:action:analytics:carriersoreleaseperformance:edit']
                },
                {
                    "cardTitle": "Carrier Space",
                    "cardDescription": "Provides an overview of carrier space released in recent weeks. You can leverage the view to monitor carrier fulfillment and drive conversations with your carriers.",
                    "cardImg": "CarrierSpaceOverview",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/CarrierSpaceOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:CarrierSpaceOverview:View", "shipment:action:analytics:CarrierSpaceOverview:Edit"]
                },
                {
                  "cardTitle": "Carrier Booking Request Performance",
                  "cardDescription": "Shows carrier booking request fulfilment statistics and provides a breakdown on rejection reasons. Also includes a link to SO performance details.",
                  "cardImg": "carrier-booking-request-performance",
                  "tags": ["Carrier"],
                  "reportFullName": "/Dashboard/Snowflake/CarrierBookingRequestPerformance.wls",
                  "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                  "isFavorited": false,
                  "isEditMode": true,
                  "showMoreInfo": false,
                  "allowAllSubgroup": false,
                  "permission": ["shipment:action:analytics:carrierbookingrequestperformance:edit","shipment:action:analytics:carrierbookingrequestperformance:view","shipment:action:analytics:transittime:edit"]
                },
                {
                  "cardTitle": "Transit Time",
                  "cardDescription": "Provides a breakdown of published versus actual transit times by carrier and lane.  Data is grouped into quarters for easy viewing and comparison.",
                  "cardImg": "transit-time",
                  "tags": ["Carrier"],
                  "reportFullName": "/Dashboard/Snowflake/TransitTime.wls",
                  "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                  "isFavorited": false,
                  "isEditMode": true,
                  "showMoreInfo": false,
                  "allowAllSubgroup": false,
                  "permission": ["shipment:action:analytics:carrierbookingrequestperformance:edit","shipment:action:analytics:carrierbookingrequestperformance:view","shipment:action:analytics:transittime:edit"]
                },
                 {
                  "cardTitle": "Carrier Performance by Customer",
                  "cardDescription": "Provides information at the customer level to support and optimize carrier routing and carrier allocation by delivering valuable insight into the overall performance of various carriers based on aggregated data.",
                  "cardImg": "carrier-performance-by-lane-and-customer",
                  "tags": ["Carrier"],
                  "reportFullName": "/Dashboard/Snowflake/CarrierPerformancebyLaneandCustomer.wls",
                  "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                  "isFavorited": false,
                  "isEditMode": true,
                  "showMoreInfo": false,
                  "allowAllSubgroup": false,
                  "permission": ["shipment:action:analytics:carrierperformancebyedit"]
                },
                {
                    "cardTitle": "Carrier Contract Management",
                    "cardDescription": "Provides instant visibility of the fulfillment status of signed carrier contracts. You can easily see how much the carrier contract has been fulfilled, and use data insights to make informed decisions for contract renewal. ",
                    "cardImg": "CarrierContractManagement",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/CarrierContractManagement.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:CarrierContractManagement:View", "shipment:action:analytics:CarrierContractManagement:Edit"]
                },
                {
                    "cardTitle": "Carrier Performance",
                    "cardDescription": "Provides information to support and optimize carrier routing and carrier allocation by delivering valuable insight into the overall transit time performance of various carriers based on data aggregated by Century",
                    "cardImg": "CarrierPerformanceByLane",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/CarrierPerformanceByLane.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:CarrierPerformanceByLane:View", "shipment:action:analytics:CarrierPerformanceByLane:Edit"]
                },
                {
                    "cardTitle": "Carrier Booking Management",
                    "cardDescription": "Provides an overview of your carrier space situation. You can leverage the view for carrier booking management and to drive conversations with your carriers.",
                    "cardImg": "carrier-booking-management-overview",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/CarrierBookingManagementOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:CarrierBookingManagement:view","shipment:action:analytics:CarrierBookingManagement:edit"]
                },
                {
                    "cardTitle": "Transit Activity",
                    "cardDescription": "Shows all relevant transit event data to the shipment/container level in a tabular format for easy extraction and manipulation.",
                    "cardImg": "TransitActivity",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/TransitActivity.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:transitactivity:view","shipment:action:analytics:transitactivity:edit"]
                },
                {
                    "cardTitle": "Transit Event Completeness",
                    "cardDescription": "Shows overall transit event completeness for each relevant shipment milestone across all shipments. A breakdown of containers by direct versus transshipment is also provided.",
                    "cardImg": "ESVCompletenessOverview",
                    "tags": ["Carrier"],
                    "reportFullName": "/Dashboard/Snowflake/ESVCompletenessOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:transiteventcompleteness:view","shipment:action:analytics:transiteventcompleteness:edit"]
                }

            ],
            "collectionHasReports": true,
            "showCollection": true,
            "showIfEmpty": false,
            "sequence": 9
        },
        {
            "collectionName": "Shipment",
            "reportDetails": [
                {
                    "cardTitle": "Inbound Vessel Calendar",
                    "cardDescription": "Provides a view of inbound flow by Discharge Port, ETA, and Vessel. You can see how many containers are coming your way and plan ahead for domestic transportation.",
                    "cardImg": "VesselCalendar",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/VesselCalendar.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:VesselCalendar:View", "shipment:action:analytics:VesselCalendar:Edit"]
                },
                {
                  "cardTitle": "Shipment Route Tracking",
                  "cardDescription": "Provides data extract of shipment route information to highlight any issues and rerouting information.",
                  "cardImg": "shipment-route-tracking",
                  "tags": ["Shipment"],
                  "reportFullName": "/Dashboard/ShipmentRoute/ShipmentRouteTracking.wls",
                  "catalogName": "/Dashboard/ShipmentRoute/CDS-SQLDR.cat",
                  "isFavorited": false,
                  "isEditMode": true,
                  "showMoreInfo": false,
                  "allowAllSubgroup": false,
                  "permission": ["shipment:action:analytics:shipmentroutetracking:edit"]
                },
                {
                    "cardTitle": "Customer Logistics",
                    "cardDescription": "Provides a high-level breakdown of weekly container count by arrival location, carrier, and load port. You can easily see container volumes leaving origin and arriving at destination to effectively allocate resources.",
                    "cardImg": "CustomerLogisctics",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/CustomerLogistics.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:CustomerLogistics:View", "shipment:action:analytics:CustomerLogistics:Edit"]
                },
                {
                    "cardTitle": "Customer Executive",
                    "cardDescription": "Provides a high-level overview of your company's supply chain, including Year-on-year tonnage, Container Usage, Carrier Utilization, etc.",
                    "cardImg": "CustomerExecutive",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/CustomerExecutiveOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:CustomerExecutive:View", "shipment:action:analytics:CustomerExecutive:Edit"]
                },
                {
                    "cardTitle": "Forward Planning",
                    "cardDescription": "Shows bookings and shipments that are planned for the coming weeks, and allows you to make informed decisions to allocate your resources efficiently.",
                    "cardImg": "ForwardPlanningDashboard",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/ForwardPlanningDashboard.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:ForwardPlanning:View", "shipment:action:analytics:ForwardPlanning:Edit"]
                },
                {
                    "cardTitle": "Freight Cost",
                    "cardDescription": "Provides a high-level view of the freight cost and trends over time. You can compare the freight rate of your carriers and analyze your spending on freight costs.",
                    "cardImg": "FreightCost",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/FreightCost.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["booking:action:analytics:FreightCost:View", "booking:action:analytics:FreightCost:Edit","shipment:action:analytics:FreightCost:View", "shipment:action:analytics:FreightCost:Edit"]
                },
                {
                    "cardTitle": "CFS Inbound Performance",
                    "cardDescription": "Shows booked versus received volume by vendor & FOB. You can also view the aggregated data for different timeframes to easily see the variance at a high-level.",
                    "cardImg": "CFSInboundPerformance",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/CFSInboundPerformance.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["booking:action:analytics:CFSInboundPerformance:View", "booking:action:analytics:CFSInboundPerformance:Edit"]
                },
                {
                    "cardTitle": "Container Activity",
                    "cardDescription": "Shows all relevant shipment data to the container level in a tabular format for easy extraction and manipulation.",
                    "cardImg": "ContainerActivity",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/ContainerActivity.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission" : ["purchase-order:action:analytics:ContainerActivity:View", "purchase-order:action:analytics:ContainerActivity:Edit", "shipment:action:analytics:ContainerActivity:View", "shipment:action:analytics:ContainerActivity:Edit"]
                },
                {
                    "cardTitle": "Shipment Activity",
                    "cardDescription": "Shows all relevant shipment data to the purchase order/item level in a tabular format for easy extraction and manipulation.",
                    "cardImg": "ShipmentActivity",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/ShipmentActivity.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:ShipmentActivity:View", "shipment:action:analytics:ShipmentActivity:Edit"]
                },
                {
                    "cardTitle": "Container Utilization",
                    "cardDescription": "Provides an overview of the container usage, allowing you to analyze this metric at different levels, and identify consolidation opportunities.",
                    "cardImg": "ContainerUtilization",
                    "tags": ["Shipment"],
                    "reportFullName": "/Dashboard/Snowflake/ContainerUtilization.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:ContainerUtilization:View", "shipment:action:analytics:ContainerUtilization:Edit"]
                }
            ],
            "collectionHasReports": true,
            "showCollection": true,
            "showIfEmpty": false,
            "sequence": 4
        },
        {
            "collectionName": "Purchase Order",
            "reportDetails": [
                {
                    "cardTitle": "Purchase Order Activity",
                    "cardDescription": "Shows all relevant purchase order data to the item level in a tabular format for easy extraction and manipulation.",
                    "cardImg": "PurchaseOrderActivity",
                    "tags": ["Purchase Order"],
                    "reportFullName": "/Dashboard/Snowflake/PurchaseOrderActivity.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["purchase-order:action:analytics:PurchaseOrderActivity:View","purchase-order:action:analytics:PurchaseOrderActivity:Edit","shipment:action:analytics:PurchaseOrderActivity:View","shipment:action:analytics:PurchaseOrderActivity:Edit"]
                }
            ],
            "collectionHasReports": false,
            "showCollection": false,
            "showIfEmpty": false,
            "sequence": 5
        },
        {
            "collectionName": "Booking",
            "reportDetails": [
                {
                    "cardTitle": "Booking Activity",
                    "cardDescription": "Shows all relevant booking data to the purchase order/item level in a tabular format for easy extraction and manipulation.",
                    "cardImg": "BookingActivity",
                    "tags": ["Booking"],
                    "reportFullName": "/Dashboard/Snowflake/BookingActivity.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["booking:action:analytics:BookingActivity:Edit","booking:action:analytics:BookingActivity:View","shipment:action:analytics:BookingActivity:View","shipment:action:analytics:BookingActivity:Edit"]
                }
            ],
            "collectionHasReports": false,
            "showCollection": false,
            "showIfEmpty": false,
            "sequence": 6
        },
        {
            "collectionName": "Vendor Management",
            "reportDetails": [
                {
                    "cardTitle": "Vendor Key Performance Indicators",
                    "cardDescription": "Provides an overview of your vendors' performance including booking submission, goods delivery, and document submission. You can leverage this view for vendor management.",
                    "cardImg": "VendorKPI",
                    "tags": ["Vendor Management"],
                    "reportFullName": "/Dashboard/Snowflake/VendorKeyPerformanceIndicators.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["purchase-order:action:analytics:VendorKeyPerformanceIndicators:Edit","purchase-order:action:analytics:VendorKeyPerformanceIndicators:View","shipment:action:analytics:VendorKeyPerformanceIndicators:Edit","shipment:action:analytics:VendorKeyPerformanceIndicators:View"]
                },
                {
                    "cardTitle": "Vendor Summary",
                    "cardDescription": "Provides a high-level overview of your vendors' tonnage at each origin. It also allows you to analyze your sourcing pattern and vendor management.",
                    "cardImg": "VendorSummary",
                    "tags": ["Vendor Management"],
                    "reportFullName": "/Dashboard/Snowflake/VendorSummary.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["purchase-order:action:analytics:VendorSummary:Edit","purchase-order:action:analytics:VendorSummary:View","shipment:action:analytics:VendorSummary:Edit","shipment:action:analytics:VendorSummary:View"]
                }
            ],
            "collectionHasReports": true,
            "showCollection": false,
            "showIfEmpty": false,
            "sequence": 7
        },
        {
            "collectionName": "End-to-End",
            "reportDetails": [
                {
                    "cardTitle": "Supply Chain Pulse",
                    "cardDescription": "Provides a snapshot of your supply chain including POs, Bookings, Containers, Vendors, and more. Plus, you can see a Year-on-year comparison of each metric.",
                    "cardImg": "SupplyChainPulse",
                    "tags": ["End-to-End"],
                    "reportFullName": "/Dashboard/Snowflake/SupplyChainPulse.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["purchase-order:action:analytics:SupplyChainPulse:View","purchase-order:action:analytics:SupplyChainPulse:Edit","shipment:action:analytics:SupplyChainPulse:View","shipment:action:analytics:SupplyChainPulse:Edit"]
                },
                {
                    "cardTitle": "Data Mart",
                    "cardDescription": "Shows all relevant purchase order, booking, shipment, and transit data to the purchase order/item level in a tabular format for easy extraction and manipulation.",
                    "cardImg": "DataMart",
                    "tags": ["End-to-End"],
                    "reportFullName": "/Dashboard/Snowflake/DataMart.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:DataMart:Edit","shipment:action:analytics:DataMart:View"]
                },
                {
                    "cardTitle": "Build Your Own Report",
                    "cardDescription": "A blank dashboard that you can easily customize to create anything you want!",
                    "cardImg": "BuildYourOwnReport",
                    "tags": ["End-to-End"],
                    "reportFullName": "/Dashboard/Snowflake/BuildYourOwnReport.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": false,
					"permission": ["shipment:action:analytics:buildyourownreport:Edit","shipment:action:analytics:buildyourownreport:View"]
                }
            ],
            "collectionHasReports": true,
            "showCollection": false,
            "showIfEmpty": false,
            "sequence": 8
        },
        {
            "collectionName": "Century",
            "reportDetails": [
                {
                    "cardTitle": "Forward Planning Volume",
                    "cardDescription": "Shows bookings and shipments for all Century accounts that are planned for the coming weeks.",
                    "cardImg": "forward-planning-volume",
                    "tags": ["Century"],
                    "reportFullName": "/Dashboard/Snowflake/ForwardPlanningVolume.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:ForwardPlanningVolume:View", "shipment:action:analytics:ForwardPlanningVolume:Edit"]
                },
                {
                    "cardTitle": "Century Express Executive Overview",
                    "cardDescription": "Provides a high-level overview of Century Express customer activity, including year-on-year tonnage, freight type breakdown, and origin office metrics.",
                    "cardImg": "century-express-executive-overview",
                    "tags": ["CenturyExpress"],
                    "reportFullName": "/Dashboard/Snowflake/CenturyExpressExecutiveOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:CenturyExpressExecutiveOverview:View", "shipment:action:analytics:CenturyExpressExecutiveOverview:Edit"]
                },
                {
                    "cardTitle": "Century Executive Overview",
                    "cardDescription": "Provides a high-level overview of Century customer activity, including year-on-year tonnage, freight type breakdown, and origin office metrics.",
                    "cardImg": "century-executive-overview",
                    "tags": ["Century"],
                    "reportFullName": "/Dashboard/Snowflake/ExecutiveOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:ExecutiveOverview:View", "shipment:action:analytics:ExecutiveOverview:Edit"]
                },
                {
                    "cardTitle": "Century Office Tonnage Overview",
                    "cardDescription": "Provides the overall tonnage of each Century origin by customer. You can see account coverage and Year-over-year (Fiscal year) evaluation of each account in a clean single view. ",
                    "cardImg": "century-office-tonnage-overview",
                    "tags": ["Century"],
                    "reportFullName": "/Dashboard/Snowflake/OriginTonnageOverview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:OriginTonnageOverview:View", "shipment:action:analytics:OriginTonnageOverview:Edit"]
                },
                {
                    "cardTitle": "Century Office Tonnage Overview by Origin",
                    "cardDescription": "Provides the overall tonnage of each Century origin office by customer. You can see account coverage and Year-over-year (Fiscal year) evaluation of each account in a clean single view. ",
                    "cardImg": "century-office-tonnage-overview-by-origin",
                    "tags": ["Century"],
                    "reportFullName": "/Dashboard/Snowflake/OriginTonnageOverviewbyOffice.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:OriginTonnageOverviewbyOffice:View", "shipment:action:analytics:OriginTonnageOverviewbyOffice:Edit"]
                },
                {
                    "cardTitle": "Century Office Supply Chain Pulse",
                    "cardDescription": "Provides a snapshot of Century metrics including POs, Bookings, Containers, Vendors, and more. Plus, you can see a Year-on-year comparison of each metric.",
                    "cardImg": "century-office-supply-chain-pulse",
                    "tags": ["Century"],
                    "reportFullName": "/Dashboard/Snowflake/SupplyChainPulseCenturyOffice.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": false,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:SupplyChainPulseCenturyOffice:View", "shipment:action:analytics:SupplyChainPulseCenturyOffice:Edit"]
                },
                {
                    "cardTitle": "Century Express Allocation",
                    "cardDescription": "Shows space allocated for Century Express customers by Account, Week, Load Port, & Discharge Port.",
                    "cardImg": "century-express-allocation",
                    "tags": ["CenturyExpress"],
                    "reportFullName": "/Dashboard/Snowflake/CenturyExpressAllocation.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["shipment:action:analytics:CenturyExpressAllocation:View", "shipment:action:analytics:CenturyExpressAllocation:Edit"]
                }
            ],
            "collectionHasReports": true,
            "showCollection": true,
            "showIfEmpty": false,
            "sequence": 3
        },
        {
            "collectionName": "Domestic",
            "reportDetails": [
                {
                    "cardTitle": "Domestic - Overview",
                    "cardDescription": "Provides overview of Domestic shipment data by Week, Discharge Port, Transload Facility, & Terminal.",
                    "cardImg": "domestic-control-tower-overview",
                    "tags": ["Domestic"],
                    "reportFullName": "/Dashboard/Snowflake/DomesticControlTower_Overview.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["domestic:action:analytics:DomesticOverview:EDIT"]
                },
                {
                    "cardTitle": "Domestic - Delivery Metrics",
                    "cardDescription": "Shows key metrics Days from Trailer Depart to Delivery, Trailer Count by Week, & Trailer Count by Transload Facility for Domestic shipments.",
                    "cardImg": "domestic-control-tower-delivery-metrics",
                    "tags": ["Domestic"],
                    "reportFullName": "/Dashboard/Snowflake/DomesticControlTower_DeliveryMetrics.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["domestic:action:analytics:DomesticDeliveryMetrics:EDIT"]
                },
                {
                    "cardTitle": "Domestic - Inbound Metrics",
                    "cardDescription": "Provides key domestic shipment transit time metrics by Discharge Port, Facility, & Terminal.",
                    "cardImg": "domestic-control-tower-inbound-metrics",
                    "tags": ["Domestic"],
                    "reportFullName": "/Dashboard/Snowflake/DomesticControlTower_InboundMetrics.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["domestic:action:analytics:DomesticInboundMetrics:EDIT"]
                },
                {
                    "cardTitle": "Domestic - Transload Metrics",
                    "cardDescription": "Shows breakdown of days between Trailer Ingate to Depart by Facility & Week and view of Container to Trailer Consolidation.",
                    "cardImg": "domestic-control-tower-transload-metrics",
                    "tags": ["Domestic"],
                    "reportFullName": "/Dashboard/Snowflake/DomesticControlTower_TransloadMetrics.wls",
                    "catalogName": "/Dashboard/Snowflake/DashboardMain.cat",
                    "isFavorited": false,
                    "isEditMode": true,
                    "showMoreInfo": false,
                    "allowAllSubgroup": true,
					"permission": ["domestic:action:analytics:DomesticTransloadMetrics:EDIT"]
                },
            ],
            "collectionHasReports": true,
            "showCollection": true,
            "showIfEmpty": false,
            "sequence": 10
        }
    ]
};