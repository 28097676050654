import { Observable } from 'rxjs';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColDef,
  ColGroupDef,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  ExcelStyle,
  GridApi,
  SortChangedEvent
} from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import _ from 'lodash';
import { combineLatest, map, of } from 'rxjs';

export interface PageDetails {
  gridName: string;
  pageName: string;
  tallyCard: string;
}

/*
Default Grid Functions for User Customization.- Each function
will trigger a call to Update the Redis cache for the user if necessary...

NOTE: Due to AG-Grid having different, but similar event objects, we need to pass the specific
event to [formatDataToCache], thus having 3 similar functions (onColumnMoved, onColumnRowGroupChanged,
onColumnVisibilityChanged).- Currently we are only using the columnVisible eventType, but we may perform
other actions based on different eventTypes in the future...
*/

@Injectable()
export class GridColumnService {
  cachedColumnDetails: any | undefined = [];

  private cacheRoute = '/user/preferences/page/';

  public excelStyles: ExcelStyle[] = [
    {
      id:'excelDateFormat',
      dataType: 'DateTime',
      numberFormat: {
        format: 'mm/dd/yy',
      },
    }
  ];

  constructor(private http: HttpClient, private appState: AppStateRepository) {}

  getColumnData(
    columns: (ColDef<any> | ColGroupDef<any>)[] | undefined,
    defaultColumns: ColDef[]
  ) {
    const columnData: any = [];
    columns?.forEach((x: any) => {
      defaultColumns.forEach((y: any) => {
        if (x.field == y.field) {
          y.sort = x.sort ?? null;
          y.sortIndex = x.sortIndex ?? null;
          y.rowGroup = x.rowGroup;
          y.rowGroupIndex = x.rowGroupIndex;
          y.hide = x.hide ?? false;
          y.pinned = x.pinned;
          columnData.push(y);
        }
      });
    });

    this.cachedColumnDetails = columns;
    _.forEach(this.cachedColumnDetails, (c: any) => {
      c.hide = c.hide ?? false;
    });
    defaultColumns[0].headerCheckboxSelection === true &&  defaultColumns[0].field === '' ? defaultColumns[0].hide = false : null;
    return columnData;
  }

  agGridStickyHeader() {
    const header = document.getElementsByClassName('ag-header');
    const rowGroup = document.getElementsByClassName('ag-column-drop-wrapper');
    for(let i = 0; i < header.length; i++){
      if(i === 0){
        const agGridHeader = <HTMLElement>header[i];
        const positionInfo = agGridHeader.getBoundingClientRect();
        const width = positionInfo.width;
        const fullWidth = '100%';
        const agGridRowGroup = <HTMLElement>rowGroup[i];
        const positionInfoRowGroup = agGridRowGroup.getBoundingClientRect();
        const top = positionInfoRowGroup.top;
        document.addEventListener("scroll", (event) => {
          if (window.scrollY > top) {
            agGridHeader.classList.add("stickyHeader");
            agGridRowGroup.classList.add("stickyRowGroup");
            agGridHeader.style.width = width + 'px';
            agGridRowGroup.style.width = width + 'px';
          } else {
            agGridHeader.classList.remove("stickyHeader");
            agGridRowGroup.classList.remove("stickyRowGroup");
            agGridHeader.style.width = fullWidth;
            agGridRowGroup.style.width = fullWidth;
          }
        });
      }
    }
  }

  addStickyHorizontalGridScroll(){
    const horiontalScroll = document.getElementsByClassName('ag-body-horizontal-scroll');
    const header = document.getElementsByClassName('ag-header');
    for(let i = 0; i < horiontalScroll.length; i++){
      if(i === 0){
        const agGridHorizongtalScroll = <HTMLElement>horiontalScroll[i];
        const agGridHeader = <HTMLElement>header[i];
        const positionInfo = agGridHeader.getBoundingClientRect();
        const width = positionInfo.width;
        agGridHorizongtalScroll.classList.add('stickyGridHorizontalScroll');
        agGridHorizongtalScroll.style.width = width + 'px';
      }
    }
  }

  onSortChanged(event: SortChangedEvent, details: PageDetails) {
    const cacheData = this.formatDataToCache(event, details);
    this.shouldUpdateCache(cacheData.gridColumnProperties, details, cacheData);
  }

  getChildColumnData(
    milestoneData: (ColDef<any> | ColGroupDef<any>)[] | undefined,
    defaultColumns: (ColDef<any> | ColGroupDef<any>)[]
  ) {
    let columnData: any = [];
    let milestoneObj ={};
    let mileStoneFinalobj:any=[];
    milestoneData?.forEach((parent:any)=>{
      columnData = [];
      parent.children?.forEach((x: any) => {
        defaultColumns.forEach((parentDefault: any) => {

          parentDefault.children.forEach((y:any)=>{
          if (x.field == y.field) {
            y.rowGroup = x.rowGroup;
            y.rowGroupIndex = x.rowGroupIndex;
            y.hide = x.hide ?? false;
            y.pinned = x.pinned;
            y.sort = x.sort,
            y.sortIndex = x.sortIndex,
            columnData.push(y);
          }
        });
        });
      });
      milestoneObj = {
        headerName: parent.headerName, headerClass: parent.headerClass,
      children:columnData
      }
      mileStoneFinalobj.push(milestoneObj);
    })

    return mileStoneFinalobj;
  }

  nameColumnHeaders(
    defaultColumns: (ColDef<any> | ColGroupDef<any>)[]
  ) {
    const lrm = this.appState.lrmData$;
    const colDefs = of(defaultColumns);
    return combineLatest([lrm,colDefs])
      .pipe(
        map(([lrm,colDefs]) => _.forEach(colDefs, x => {
          const isGroup = (colGroup: ColDef<any> | ColGroupDef<any>): colGroup is ColGroupDef<any> => (colGroup as ColGroupDef<any>).children !== undefined;
          if(isGroup(x)) {
            x.children = _.forEach(x.children, col => {
              const colDef:any = _.find(lrm, y => y.findDisplay === col.headerName);
              if(colDef) {
                col.headerName = colDef.replaceDisplay;
              }
            });
          } else {
            const colDef:any = _.find(lrm, y => y.findDisplay === x.headerName);
            if(colDef) {
              x.headerName = colDef.replaceDisplay;
            }
          }
        }))
    );
  }

  onColumnMoved(event: ColumnMovedEvent, details: PageDetails) {
    const cacheData = this.formatDataToCache(event, details);
    this.shouldUpdateCache(cacheData.gridColumnProperties, details, cacheData);
  }

  onColumnMoves(event: ColumnMovedEvent, details: PageDetails) {
    const cacheData = this.formatDataToSave(event, details);
    this.setUserPreference({path:details.pageName,data:cacheData.saveJson}).subscribe();
  }

  onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent, details: PageDetails) {
    const cacheData = this.formatDataToCache(event, details);
    this.shouldUpdateCache(cacheData.gridColumnProperties, details, cacheData);
  }

  onColumnRowGroupChanges(event: ColumnRowGroupChangedEvent, details: PageDetails) {
    const cacheData = this.formatDataToSave(event, details);
    this.setUserPreference({path:details.pageName,data:cacheData.saveJson}).subscribe();
  }

  onColumnVisibilityChanged(event: ColumnVisibleEvent, details: PageDetails) {
    const cacheData = this.formatDataToCache(event, details);
    this.shouldUpdateCache(cacheData.gridColumnProperties, details, cacheData);
  }

  onColumnVisibilityChanges(event: ColumnVisibleEvent, details: PageDetails) {
    const cacheData = this.formatDataToSave(event, details);
    this.setUserPreference({path:details.pageName,data:cacheData.saveJson}).subscribe();
  }
  onColumnPinned(event: ColumnPinnedEvent, details: PageDetails) {
    const cacheData = this.formatDataToCache(event, details);
    this.shouldUpdateCache(cacheData.gridColumnProperties, details, cacheData);
  }
  onColumnPinnes(event: ColumnPinnedEvent, details: PageDetails) {
    const cacheData = this.formatDataToSave(event, details);
    this.setUserPreference({path:details.pageName,data:cacheData.saveJson}).subscribe();
  }

  onSortChanges(event: SortChangedEvent, details: PageDetails) {
    const cacheData = this.formatDataToSave(event, details);
    this.setUserPreference({path:details.pageName,data:cacheData.saveJson}).subscribe();
  }
  // #region Private Functions...
  private createDictionary(details: any = {}) {
    return {
      [details.gridName]: JSON.stringify(details.gridColumnData),
    };
  }

  setUserPreference(cacheData: any): Observable<any> {
    return this.http.post("/user/preferences", cacheData);
  }

  private formatDataToSave(event: any, details: PageDetails) {
    // Get grid column properties from the event
    const gridColumnProperties = details.gridName ==='MilestoneSearchGrid' ? this.getChildGridColumnProperties(event) : this.getGridColumnProperties(event);
   const dictionary = {
    "savedData": JSON.stringify(gridColumnProperties),
   };
   return {
     gridColumnProperties: gridColumnProperties,
     saveJson: dictionary
   };
 }


  private formatDataToCache(event: any, details: PageDetails) {
     // Get grid column properties from the event
     const gridColumnProperties = details.gridName ==='MilestoneSearchGrid' ? this.getChildGridColumnProperties(event) : this.getGridColumnProperties(event);
     // Retrieve and parse the existing grid preference from local storage
     const existingGridPreference = localStorage.getItem("GridPreference");
     const cachedDictionary: Record<string, string | unknown> = {};

     if (existingGridPreference) {
         try {
             const parsedPreference = JSON.parse(existingGridPreference);
             Object.entries(parsedPreference).forEach(([key, value]) => {
                 cachedDictionary[key] = value;
             });
         } catch (error) {
             console.error("Error parsing existing GridPreference:", error);
         }
     }

    const gridName = details.tallyCard.replace(/\s/g, "");
    // Create a new dictionary by merging the existing cache and grid column properties
    const dictionary = {
      ...cachedDictionary,
      ...this.createDictionary({
        gridName,
        gridColumnData: gridColumnProperties
      })
    };

    // Store the updated dictionary in local storage
    localStorage.setItem("GridPreference", JSON.stringify(dictionary));
    return {
      gridColumnProperties: gridColumnProperties,
      cacheJson: dictionary
    };
  }

  private getChildGridColumnProperties(event: any) {
    const childColumnDefs: (ColDef<any> | ColGroupDef<any>)[] | undefined =
      event.api.getColumnDefs();
      let parentObj ={};
      let obj:any=[];
    let  gridColumnProperties: any = [];
    let sortedColumns: any;
    if (event.type === 'sortChanged'){
      sortedColumns = event.columnApi.getColumnState().filter(({ sort }: { sort: string | null }) => sort !== null)
      .map((s: any) => {
          return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    }

    childColumnDefs?.forEach((parent: any,index:any) => {
      gridColumnProperties = [] ;
      parent.children.forEach((column:any)=>{
      switch(event.type){
        case 'columnVisible':
          if (column.field === event.column?.getColId())
          column.hide = event.visible ? false : true;
          break;
        case 'columnPinned':
          if (column.field === event.column?.getColId())
          {
            column.pinned = event.pinned;
          }
          break;
        case 'sortChanged':
          if (sortedColumns.some(
              ({ colId }: { colId: string }) => colId === column.field)) {
          const sortedColumn = sortedColumns.find(
              ({ colId }: { colId: string }) => colId === column.field);
          if (sortedColumn) {
              column.sort = sortedColumn.sort;
              column.sortIndex = sortedColumn.sortIndex;
          }
        }
        break;
      }

      gridColumnProperties.push({

        field: column.field,
        rowGroup: column.rowGroup,
        rowGroupIndex: column.rowGroupIndex,
        hide: column.hide,
        pinned: column.pinned,
        sort: column.sort,
        sortIndex: column.sortIndex,
      });


    });
    parentObj = {
      headerName: parent.headerName, headerClass: parent.headerClass,
    children:gridColumnProperties
    }
    obj.push(parentObj);
  });

    return obj;
  }

  private getGridColumnProperties(event: any) {
    const columnDefs: (ColDef<any> | ColGroupDef<any>)[] | undefined =
      event.api.getColumnDefs();
    const gridColumnProperties: any = [];
    let sortedColumns: any;

    if (event.type === 'sortChanged'){
      sortedColumns = event.columnApi.getColumnState().filter(({ sort }: { sort: string | null }) => sort !== null)
      .map((s: any) => {
          return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    }

    columnDefs?.forEach((column: any) => {
      switch(event.type){
        case 'columnVisible':
          if (column.field === event.column?.getColId())
          column.hide = event.visible ? false : true;
          break;
        case 'columnPinned':
          if (column.field === event.column?.getColId())
          {
            column.pinned = event.pinned;
          }
          break;
        case 'sortChanged':
          if (sortedColumns.some(
              ({ colId }: { colId: string }) => colId === column.field)) {
          const sortedColumn = sortedColumns.find(
              ({ colId }: { colId: string }) => colId === column.field);
          if (sortedColumn) {
              column.sort = sortedColumn.sort;
              column.sortIndex = sortedColumn.sortIndex;
          }
        }
        break;
      }
      gridColumnProperties.push({
        field: column.field,
        rowGroup: column.rowGroup,
        rowGroupIndex: column.rowGroupIndex,
        hide: column.hide,
        pinned: column.pinned,
        sort: column.sort,
        sortIndex: column.sortIndex,
      });
    });
    return gridColumnProperties;
  }

  private shouldUpdateCache(
    columnDetails: (ColDef<any> | ColGroupDef<any>)[],
    details: PageDetails,
    cacheData: any
  ) {
    _.forEach(columnDetails, (c: any) => {
      c.hide = c.hide ?? false;
    });

    if (!_.isEqual(this.cachedColumnDetails, columnDetails))
      this.updateCache({
        pageName: details.pageName,
        cacheJson: cacheData.cacheJson
      });
  }

  private updateCache(cacheData: any) {
    this.appState.ghostedUserPreferences$.next({
      path: `pages/${cacheData.pageName}`,
      data: cacheData.cacheJson
    })
    this.http
      .post(this.cacheRoute + cacheData.pageName, cacheData.cacheJson)
      .subscribe();
  }

  // #endregion Private Functions...

  public getColumnPreferences(activeCard:string, columnDefs: ColDef[] ): ColDef[] {
    const result = localStorage.getItem('GridPreference') ?? '';
    const activeCardPref = JSON.parse(result)?.[activeCard.replace(/\s/g, '')];
    const cols = this.getColumnData(
      JSON.parse(activeCardPref ?? '[]') || [],
      columnDefs
    );
    return cols;
  }

  public clearFilter(gridApi: GridApi) {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged();
  }

}
