import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainMenuService } from '../main-header-nav-bar/main-menu.service';
import { Observable, Subject, ReplaySubject, tap, takeUntil, map } from 'rxjs';
import { WorkFlowHistory } from '@cds-ui/data-access';
import { VibeExceptionModalService } from '../vibe-exception-modal/vibe-exception-modal.service';
import moment from 'moment';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { convertObservableToBehaviorSubject } from '@cds-ui/shared/core-rx';
import { TitleCasePipe } from '@cds-ui/ui-directive';

@Component({
  selector: 'cds-ui-exceptions-pane',
  templateUrl: './exceptions-pane.component.html',
  styleUrls: ['./exceptions-pane.component.scss'],
})
export class ExceptionsPaneComponent implements OnDestroy {
  constructor(
    private appState: AppStateRepository,
    private router: Router, 
    private _mainMenuService: MainMenuService,
    private VibeExceptionModalService: VibeExceptionModalService,
    private route:ActivatedRoute
  ) { }

  private destory$$ = new Subject<void>();
  private environment$$ = convertObservableToBehaviorSubject(this.appState.environment$, null);
  public workflowHistory$$ = new ReplaySubject<WorkFlowHistory[]>();
   readonly titlecase  = new TitleCasePipe();

  public workflowHistory$ = this.workflowHistory$$.pipe(
    map(x => x.map(w => ({
      ...w, label: (w.validationLabel ?? w.validationName).toUpperCase()
                                                          .replace(`${w.milestoneName}_`, '') ?? "--",
      errorMessage: w.errorMessage?.replace(/(<|&lt;)br\s*\/*(>|&gt;)/ig, '')
    }))
    ),
    map(x => x.map(w => ({
      ...w, label: w.label[0].toUpperCase()
                 + w.label.toLowerCase()
                          .replace(/_/g, " ")
                          .slice(1)
                          .toLowerCase()
    }))),
    map(x => x.sort((a, b) => a.validationLogCreatedDate == null 
                            ? -1 
                            : b.validationLogCreatedDate == null 
                            ? 1 
                            : moment(b.validationLogCreatedDate)
                                .diff(moment(a.validationLogCreatedDate))).reverse())
  );

  public exceptionData$ = this.workflowHistory$.pipe(
    map(x=>x.filter(y=> y.approved || y.decliend || y.pending)),
    map(x => x?.map(y => ({ ...y, label: this.titlecase?.transform(y?.label) })) ?? [])
  );

  public workflowHistoryCount$ = this.workflowHistory$.pipe(map(x => x.length));
  public count$ = this.workflowHistory$$.pipe(map(x => x.length ?? 0))

  @Input() cardHeaderName = "Pending Exception";
  @Input() viewMoreShow = false;
  @Input() set data$(stream: Observable<WorkFlowHistory[]>) {
    stream.pipe(
      takeUntil(this.destory$$),
      tap(x => this.workflowHistory$$.next(x))
    )
    .subscribe();
  }

  ngOnDestroy(): void {
    this.destory$$.next();
    this.destory$$.complete();
  }

  navigateToLegacyVibe() {

    const url = `${this.environment$$.getValue()?.urls.legacyServer}/#/pagelayout?moduleCd=Vibe&configName=WorkflowSwitchboard&alert_id=80690711`;
    this._mainMenuService.setLegacyUrl({ url: url, menu: { displayName: 'VIBE Workflow Switchboard' } });
    this.router.navigateByUrl('legacy-vms-container');
  }

  viewMore(){
    this.VibeExceptionModalService.show(
      {
        title: `VIBE History for Booking #${this.route.snapshot.params['bookingKey'] as string}`,
      }
    )
  }
}
