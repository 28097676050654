import { GridOptions } from "@ag-grid-community/core";

type GridOptionsObject = Record<(keyof GridOptions), undefined>;

export const agGridOptionsProxy: GridOptionsObject = {
    statusBar: undefined,
    sideBar: undefined,
    suppressContextMenu: undefined,
    preventDefaultOnContextMenu: undefined,
    allowContextMenuWithControlKey: undefined,
    suppressMenuHide: undefined,
    enableBrowserTooltips: undefined,
    tooltipShowDelay: undefined,
    tooltipHideDelay: undefined,
    tooltipMouseTrack: undefined,
    popupParent: undefined,
    copyHeadersToClipboard: undefined,
    copyGroupHeadersToClipboard: undefined,
    clipboardDeliminator: undefined,
    clipboardDelimiter: undefined,
    suppressCopyRowsToClipboard: undefined,
    suppressCopySingleCellRanges: undefined,
    suppressLastEmptyLineOnPaste: undefined,
    suppressClipboardPaste: undefined,
    suppressClipboardApi: undefined,
    suppressCutToClipboard: undefined,
    columnDefs: undefined,
    defaultColDef: undefined,
    defaultColGroupDef: undefined,
    columnTypes: undefined,
    maintainColumnOrder: undefined,
    suppressFieldDotNotation: undefined,
    headerHeight: undefined,
    groupHeaderHeight: undefined,
    floatingFiltersHeight: undefined,
    pivotHeaderHeight: undefined,
    pivotGroupHeaderHeight: undefined,
    allowDragFromColumnsToolPanel: undefined,
    suppressMovableColumns: undefined,
    suppressColumnMoveAnimation: undefined,
    suppressDragLeaveHidesColumns: undefined,
    suppressRowGroupHidesColumns: undefined,
    colResizeDefault: undefined,
    suppressAutoSize: undefined,
    autoSizePadding: undefined,
    skipHeaderOnAutoSize: undefined,
    components: undefined,
    frameworkComponents: undefined,
    reactUi: undefined,
    suppressReactUi: undefined,
    editType: undefined,
    singleClickEdit: undefined,
    suppressClickEdit: undefined,
    readOnlyEdit: undefined,
    stopEditingWhenCellsLoseFocus: undefined,
    enterMovesDown: undefined,
    enterMovesDownAfterEdit: undefined,
    enableCellEditingOnBackspace: undefined,
    undoRedoCellEditing: undefined,
    undoRedoCellEditingLimit: undefined,
    defaultCsvExportParams: undefined,
    suppressCsvExport: undefined,
    defaultExcelExportParams: undefined,
    suppressExcelExport: undefined,
    excelStyles: undefined,
    quickFilterText: undefined,
    cacheQuickFilter: undefined,
    excludeHiddenColumnsFromQuickFilter: undefined,
    excludeChildrenWhenTreeDataFiltering: undefined,
    enableCharts: undefined,
    chartThemes: undefined,
    customChartThemes: undefined,
    chartThemeOverrides: undefined,
    enableChartToolPanelsButton: undefined,
    suppressChartToolPanelsButton: undefined,
    chartToolPanelsDef: undefined,
    loadingCellRenderer: undefined,
    loadingCellRendererFramework: undefined,
    loadingCellRendererParams: undefined,
    loadingCellRendererSelector: undefined,
    localeText: undefined,
    masterDetail: undefined,
    keepDetailRows: undefined,
    keepDetailRowsCount: undefined,
    detailCellRenderer: undefined,
    detailCellRendererFramework: undefined,
    detailCellRendererParams: undefined,
    detailRowHeight: undefined,
    detailRowAutoHeight: undefined,
    context: undefined,
    alignedGrids: undefined,
    tabIndex: undefined,
    rowBuffer: undefined,
    valueCache: undefined,
    valueCacheNeverExpires: undefined,
    enableCellExpressions: undefined,
    suppressParentsInRowNodes: undefined,
    suppressTouch: undefined,
    suppressFocusAfterRefresh: undefined,
    suppressAsyncEvents: undefined,
    suppressBrowserResizeObserver: undefined,
    suppressPropertyNamesCheck: undefined,
    suppressChangeDetection: undefined,
    debug: undefined,
    overlayLoadingTemplate: undefined,
    loadingOverlayComponent: undefined,
    loadingOverlayComponentFramework: undefined,
    loadingOverlayComponentParams: undefined,
    suppressLoadingOverlay: undefined,
    overlayNoRowsTemplate: undefined,
    noRowsOverlayComponent: undefined,
    noRowsOverlayComponentFramework: undefined,
    noRowsOverlayComponentParams: undefined,
    suppressNoRowsOverlay: undefined,
    pagination: undefined,
    paginationPageSize: undefined,
    paginationAutoPageSize: undefined,
    paginateChildRows: undefined,
    suppressPaginationPanel: undefined,
    pivotMode: undefined,
    pivotPanelShow: undefined,
    pivotColumnGroupTotals: undefined,
    pivotRowTotals: undefined,
    pivotSuppressAutoColumn: undefined,
    suppressExpandablePivotGroups: undefined,
    functionsReadOnly: undefined,
    aggFuncs: undefined,
    suppressAggFuncInHeader: undefined,
    suppressAggAtRootLevel: undefined,
    aggregateOnlyChangedColumns: undefined,
    suppressAggFilteredOnly: undefined,
    removePivotHeaderRowWhenSingleValueColumn: undefined,
    animateRows: undefined,
    enableCellChangeFlash: undefined,
    cellFlashDelay: undefined,
    cellFadeDelay: undefined,
    allowShowChangeAfterFilter: undefined,
    domLayout: undefined,
    ensureDomOrder: undefined,
    enableRtl: undefined,
    suppressColumnVirtualisation: undefined,
    suppressMaxRenderedRowRestriction: undefined,
    suppressRowVirtualisation: undefined,
    rowDragManaged: undefined,
    suppressRowDrag: undefined,
    suppressMoveWhenRowDragging: undefined,
    rowDragEntireRow: undefined,
    rowDragMultiRow: undefined,
    rowDragText: undefined,
    fullWidthCellRenderer: undefined,
    fullWidthCellRendererFramework: undefined,
    fullWidthCellRendererParams: undefined,
    embedFullWidthRows: undefined,
    groupDisplayType: undefined,
    groupDefaultExpanded: undefined,
    autoGroupColumnDef: undefined,
    groupMaintainOrder: undefined,
    groupSelectsChildren: undefined,
    groupAggFiltering: undefined,
    groupIncludeFooter: undefined,
    groupIncludeTotalFooter: undefined,
    groupSuppressBlankHeader: undefined,
    groupSelectsFiltered: undefined,
    showOpenedGroup: undefined,
    groupRemoveSingleChildren: undefined,
    groupRemoveLowestSingleChildren: undefined,
    groupHideOpenParents: undefined,
    groupAllowUnbalanced: undefined,
    rowGroupPanelShow: undefined,
    groupRowRenderer: undefined,
    groupRowRendererFramework: undefined,
    groupRowRendererParams: undefined,
    suppressMakeColumnVisibleAfterUnGroup: undefined,
    treeData: undefined,
    rowGroupPanelSuppressSort: undefined,
    groupRowsSticky: undefined,
    rememberGroupStateWhenNewData: undefined,
    pinnedTopRowData: undefined,
    pinnedBottomRowData: undefined,
    rowModelType: undefined,
    rowData: undefined,
    immutableData: undefined,
    asyncTransactionWaitMillis: undefined,
    suppressModelUpdateAfterUpdateTransaction: undefined,
    datasource: undefined,
    cacheOverflowSize: undefined,
    infiniteInitialRowCount: undefined,
    serverSideInitialRowCount: undefined,
    serverSideStoreType: undefined,
    suppressServerSideInfiniteScroll: undefined,
    cacheBlockSize: undefined,
    maxBlocksInCache: undefined,
    maxConcurrentDatasourceRequests: undefined,
    blockLoadDebounceMillis: undefined,
    purgeClosedRowNodes: undefined,
    serverSideDatasource: undefined,
    serverSideSortAllLevels: undefined,
    serverSideFilterAllLevels: undefined,
    serverSideSortOnServer: undefined,
    serverSideFilterOnServer: undefined,
    serverSideSortingAlwaysResets: undefined,
    serverSideFilteringAlwaysResets: undefined,
    viewportDatasource: undefined,
    viewportRowModelPageSize: undefined,
    viewportRowModelBufferSize: undefined,
    alwaysShowHorizontalScroll: undefined,
    alwaysShowVerticalScroll: undefined,
    debounceVerticalScrollbar: undefined,
    suppressHorizontalScroll: undefined,
    suppressScrollOnNewData: undefined,
    suppressScrollWhenPopupsAreOpen: undefined,
    suppressAnimationFrame: undefined,
    suppressMiddleClickScrolls: undefined,
    suppressPreventDefaultOnMouseWheel: undefined,
    scrollbarWidth: undefined,
    rowSelection: undefined,
    rowMultiSelectWithClick: undefined,
    suppressRowDeselection: undefined,
    suppressRowClickSelection: undefined,
    suppressCellSelection: undefined,
    suppressCellFocus: undefined,
    suppressMultiRangeSelection: undefined,
    enableCellTextSelection: undefined,
    enableRangeSelection: undefined,
    enableRangeHandle: undefined,
    enableFillHandle: undefined,
    fillHandleDirection: undefined,
    suppressClearOnFillReduction: undefined,
    sortingOrder: undefined,
    accentedSort: undefined,
    unSortIcon: undefined,
    suppressMultiSort: undefined,
    alwaysMultiSort: undefined,
    multiSortKey: undefined,
    suppressMaintainUnsortedOrder: undefined,
    icons: undefined,
    rowHeight: undefined,
    rowStyle: undefined,
    rowClass: undefined,
    rowClassRules: undefined,
    suppressRowHoverHighlight: undefined,
    suppressRowTransform: undefined,
    columnHoverHighlight: undefined,
    deltaSort: undefined,
    treeDataDisplayType: undefined,
    functionsPassive: undefined,
    enableGroupEdit: undefined,
    getContextMenuItems: undefined,
    getMainMenuItems: undefined,
    postProcessPopup: undefined,
    processCellForClipboard: undefined,
    processHeaderForClipboard: undefined,
    processGroupHeaderForClipboard: undefined,
    processCellFromClipboard: undefined,
    sendToClipboard: undefined,
    processDataFromClipboard: undefined,
    isExternalFilterPresent: undefined,
    doesExternalFilterPass: undefined,
    getChartToolbarItems: undefined,
    createChartContainer: undefined,
    navigateToNextHeader: undefined,
    tabToNextHeader: undefined,
    navigateToNextCell: undefined,
    tabToNextCell: undefined,
    localeTextFunc: undefined,
    getLocaleText: undefined,
    getDocument: undefined,
    paginationNumberFormatter: undefined,
    groupRowAggNodes: undefined,
    getGroupRowAgg: undefined,
    isGroupOpenByDefault: undefined,
    initialGroupOrderComparator: undefined,
    defaultGroupOrderComparator: undefined,
    processSecondaryColDef: undefined,
    processSecondaryColGroupDef: undefined,
    processPivotResultColDef: undefined,
    processPivotResultColGroupDef: undefined,
    getDataPath: undefined,
    getChildCount: undefined,
    getServerSideGroupLevelParams: undefined,
    getServerSideStoreParams: undefined,
    isServerSideGroupOpenByDefault: undefined,
    isApplyServerSideTransaction: undefined,
    isServerSideGroup: undefined,
    getServerSideGroupKey: undefined,
    getBusinessKeyForNode: undefined,
    getRowNodeId: undefined,
    getRowId: undefined,
    resetRowDataOnUpdate: undefined,
    processRowPostCreate: undefined,
    isRowSelectable: undefined,
    isRowMaster: undefined,
    fillOperation: undefined,
    postSort: undefined,
    postSortRows: undefined,
    getRowStyle: undefined,
    getRowClass: undefined,
    getRowHeight: undefined,
    isFullWidthCell: undefined,
    isFullWidthRow: undefined,
    onToolPanelVisibleChanged: undefined,
    onToolPanelSizeChanged: undefined,
    onPasteStart: undefined,
    onPasteEnd: undefined,
    onColumnVisible: undefined,
    onColumnPinned: undefined,
    onColumnResized: undefined,
    onColumnMoved: undefined,
    onColumnValueChanged: undefined,
    onColumnPivotModeChanged: undefined,
    onColumnPivotChanged: undefined,
    onColumnGroupOpened: undefined,
    onNewColumnsLoaded: undefined,
    onGridColumnsChanged: undefined,
    onDisplayedColumnsChanged: undefined,
    onVirtualColumnsChanged: undefined,
    onColumnEverythingChanged: undefined,
    onComponentStateChanged: undefined,
    onCellValueChanged: undefined,
    onCellEditRequest: undefined,
    onRowValueChanged: undefined,
    onCellEditingStarted: undefined,
    onCellEditingStopped: undefined,
    onRowEditingStarted: undefined,
    onRowEditingStopped: undefined,
    onUndoStarted: undefined,
    onUndoEnded: undefined,
    onRedoStarted: undefined,
    onRedoEnded: undefined,
    onFilterOpened: undefined,
    onFilterChanged: undefined,
    onFilterModified: undefined,
    onChartCreated: undefined,
    onChartRangeSelectionChanged: undefined,
    onChartOptionsChanged: undefined,
    onChartDestroyed: undefined,
    onCellKeyDown: undefined,
    onCellKeyPress: undefined,
    onGridReady: undefined,
    onFirstDataRendered: undefined,
    onGridSizeChanged: undefined,
    onModelUpdated: undefined,
    onVirtualRowRemoved: undefined,
    onViewportChanged: undefined,
    onBodyScroll: undefined,
    onBodyScrollEnd: undefined,
    onDragStarted: undefined,
    onDragStopped: undefined,
    onPaginationChanged: undefined,
    onRowDragEnter: undefined,
    onRowDragMove: undefined,
    onRowDragLeave: undefined,
    onRowDragEnd: undefined,
    onColumnRowGroupChanged: undefined,
    onRowGroupOpened: undefined,
    onExpandOrCollapseAll: undefined,
    onPinnedRowDataChanged: undefined,
    onRowDataChanged: undefined,
    onRowDataUpdated: undefined,
    onAsyncTransactionsFlushed: undefined,
    onStoreRefreshed: undefined,
    onCellClicked: undefined,
    onCellDoubleClicked: undefined,
    onCellFocused: undefined,
    onCellMouseOver: undefined,
    onCellMouseOut: undefined,
    onCellMouseDown: undefined,
    onRowClicked: undefined,
    onRowDoubleClicked: undefined,
    onRowSelected: undefined,
    onSelectionChanged: undefined,
    onCellContextMenu: undefined,
    onRangeSelectionChanged: undefined,
    onSortChanged: undefined,
    onColumnRowGroupChangeRequest: undefined,
    onColumnPivotChangeRequest: undefined,
    onColumnValueChangeRequest: undefined,
    onColumnAggFuncChangeRequest: undefined,
    api: undefined,
    columnApi: undefined
};