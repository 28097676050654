<ng-template #template>
    <div id="modalDrag" cdkDrag>
      <div class="modal-header bg-transparent d-flex justify-content-between align-items-center p-4" cdkDragHandle>
            <h2 class="m-0">{{config.title}}</h2>
            <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
                <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
                <span class="visually-hidden">Close</span>
            </button>
        </div>
        <div class="modal-body d-flex flex-grow-1 custom-height-dropdown decline-counter-modal">
            <ag-grid-angular
                style="height: 55vh;width: 105vh;"
                [class]="gridClass | async"
                [rowData]="rowData"
                [suppressRowGroupHidesColumns]="true"
                [columnDefs]="defaultColumns"
                [gridOptions]="gridOptions"
                [pagination]="true"
                [paginationPageSize]="10"
                [animateRows]="true"
                [overlayLoadingTemplate]="loadingTemplate"
                [rowGroupPanelShow]="'always'"
                [suppressRowGroupHidesColumns]="true"
                [suppressDragLeaveHidesColumns]="true"
                [suppressRowClickSelection]="true"
                (gridReady)="onGridReady($event)"
                (columnResized)="gridOptions?.onColumnResized($event)"
            >
            </ag-grid-angular>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-cancel" (click)="modalRef?.hide()">{{config.btnOne}}</button>
            <button type="button" class="btn btn-submit" (click)="onDeclineCounterClick()"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>{{config.btnTwo}}</button>
            </div>
    </div>
    </ng-template>