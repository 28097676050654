<div *ngIf="(showLogoOnly | async) === false" class="top-nav bg-gray d-flex align-items-center">
  <a [routerLink]="['dashboard/portal']" id="logo"></a>
  <div class="searchBar">
    <input #filter [ngModel]="search" (ngModelChange)="searchQuery($event)" type="search"
      [placeholder]="placeholderText" class="form-control systemSeachInput ms-5 d-inline-block align-middle"
      id="globalSystemSeach" autocomplete="off" />
    <button type="button" class="btn btn-success btn-sm fw-bold align-middle ms-2 d-inline-block align-middle"
      data-bs-toggle="modal" data-bs-target="#search-modal" (click)="onAdvanceClick()"><span
        class="material-icons mi-18 align-middle">manage_search</span> Advanced</button>

    <div class="ms-5 loading" *ngIf="onLoad">Loading...</div>
    <ng-container *ngIf="!showMultipleSearchPopup">
      <div *ngIf="suggestions$ | async as list">
        <div *ngIf="list.length; else noData">
          <ul class="dropdown-menu" [ngClass]="list.length ? 'show-search-dropdown':''" #menu>
            <div *ngFor="let keyData of list">
              <li><span class="dropdown-item dropdown-head">{{keyData?.name === 'ShipKey' ? 'Ship Key' :
                  keyData?.name}}</span></li>
              <li class="dropdown-submenu" *ngFor="let menu of keyData.data">
                <div class="dropstart">
                  <a (click)="selectMenuItem(menu)" class="dropdown-item d-flex align-items-center has-submenu"
                    aria-expanded="false">{{menu?.keyValue}}
                    <span class="material-icons text-success" *ngIf="disableQuickSearchMenu(menu)">chevron_right</span>
                  </a>
                  <ul class="dropdown-menu" *ngIf="disableQuickSearchMenu(menu)">
                    <li><span class="dropdown-item dropdown-head">Quick Searches</span></li>
                    <div *ngFor="let searchVal of keySearchResults">
                      <div *ngIf="menu.keyName === searchVal.key">
                        <li *ngFor="let search of searchVal.result">
                          <a (click)="selectQuickSearchMenu(search.name,menu.keyValue,keyData.name)" role="button"
                            class="dropdown-item">{{search?.name}}</a>
                        </li>
                      </div>
                    </div>
                    <ng-container>
                      <div
                        *featurePermission="['container:action:container-status-event:view', 'inbound-planning:action:plan:container:edit']">
                        <li><span class="dropdown-item dropdown-head">Quick Actions</span></li>
                        <li *featurePermission="'container:action:container-status-event:view'">
                          <a *ngIf="menu.keyName === 'Container'"
                            (click)="selectQuickActionMenu('Container Status' , menu, keyData.name)" role="button"
                            class="dropdown-item">Container Status
                            Events</a>
                        </li>
                        <li *featurePermission="'inbound-planning:action:plan:container:edit'">
                          <a (click)="selectQuickSearchMenu('Inbound Planning Edit' , menu.keyValue, keyData.name)"
                            role="button" class="dropdown-item">Inbound Planning Edit</a>
                        </li>
                      </div>
                    </ng-container>
                  </ul>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <ng-template #noData>
          <div class="ms-5 noResults">No Results Found</div>
        </ng-template>

      </div>
    </ng-container>

    <ng-container *ngIf="showMultipleSearchPopup">
      <ul class="dropdown-menu" [ngClass]="showMultipleSearchPopup ? 'show-search-dropdown':''">
        <li><span class="dropdown-item dropdown-head">Quick Searches</span></li>
        <div *ngFor="let searchVal of keySearchResults">
          <div *ngIf="keyName === searchVal.key">
            <li class="dropdown-submenu" *ngFor="let search of searchVal.result">
              <a (click)="selectQuickSearchMenu(search.name,'',keyName)" role="button"
                class="dropdown-item">{{search?.name}}</a>
            </li>
          </div>
        </div>
        <ng-container>
          <div *featurePermission="['inbound-planning:action:plan:container:edit']">
            <li><span class="dropdown-item dropdown-head">Quick Actions</span></li>
            <li *featurePermission="'inbound-planning:action:plan:container:edit'">
              <a (click)="selectQuickSearchMenu('Inbound Planning Edit' , '', keyName)" role="button"
                class="dropdown-item">Inbound Planning Edit</a>
            </li>
          </div>
        </ng-container>
        <div *ngIf="!keyName" class="dropdown-item dropdown-head quickSearchNR">No Results Found</div>
      </ul>
    </ng-container>
  </div>

  <div class="top-nav-meta d-flex gap-6 align-items-center ms-auto">
    <cds-ui-company-selector [cacheData$]="userPreferences$"></cds-ui-company-selector>
    <ng-container *ngIf="(shouldUseBuyerFilter$ | async) === true">
      <div *ngIf="(buyersList$ | async) as buyers">
        <div class="dropdown" *ngIf="buyers && buyers.available && buyers.available.length > 1">
          <button class="btn btn-blue dropdown-toggle" type="button" id="buyerSeletor" data-bs-toggle="dropdown"
            data-bs-auto-close="outside" aria-expanded="false">
            <strong *ngIf="buyers.active.length === 1;then singleDivision else allDivision"></strong>
            <ng-template #allDivision>Selected {{ buyers.active.length }} Buyers</ng-template>
            <ng-template #singleDivision>{{ buyers.active[0].buyerCode }} - {{ buyers.active[0].buyerName
              }}</ng-template>
          </button>
          <div class="dropdown-menu dropdown-menu-with-search flex-column" aria-labelledby="buyerSeletor"
            data-bs-auto-close="outside" style="max-height: calc(50vh);">
            <div class="p-3">
              <input class="w-100 form-control" type="text" placeholder="Search..."
                (input)="filterBuyerChange(searchBuyers)" [(ngModel)]="searchBuyers">
            </div>

            <div class="flex-grow-1" style="overflow-y: auto;">
              <div class="dropdown-item" (click)="onSelectAllBuyer(buyers.filtered, buyers.active)">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="select-all-buyers"
                    [indeterminate]="includeInBuyerFilter(buyers.active, buyers.filtered) === 'some'"
                    [checked]="includeInBuyerFilter(buyers.active, buyers.filtered) === 'all'">
                  <label class="form-check-label" for="select-all-buyers">
                    (Select All)
                  </label>
                </div>
              </div>

              <div class="dropdown-item" *ngFor="let buyer of buyers.filtered"
                (click)="onBuyerSelect(buyer, buyers.active)">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="select-buyers-{{buyer.buyerCode}}"
                    [checked]="isActiveBuyer(buyer.buyerCode, buyers.active)">
                  <label class="form-check-label" for="select-buyers-{{buyer.buyerCode}}">
                    <span [innerHTML]="buyer.display | highlightSearch: searchBuyers"></span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="dropdown" *ngIf="user$ | async as user">
      <button class="btn btn-link link-secondary d-flex gap-2 align-items-center text-decoration-none"
        id="accountDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="material-icons mi-32" *ngIf="!user.hasGhosted;else ghostedIcon">account_circle</span>
        <ng-template #ghostedIcon>
          <span class="material-icons mi-32">supervised_user_circle</span>
        </ng-template>
        <span>{{ user.userId }} - {{ user.subgroupCode }}</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="accountDropdown">
        <ng-container *globalFeaturePermission="['user:action:user:ghost', 'user:action:subgroup:ghost']">
          <li>
            <a class="dropdown-item text-secondary"
              (click)="resetGhostingStatus(user?.orginalUserId, user?.orginalSubgroupCode)">{{ user.orginalUserId }} -
              {{ user.orginalSubgroupCode }}</a>
            <hr class="dropdown-divider" />
            <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#ghostingModal"
              (click)="refreshGhostedHistory()">Ghost User</a>
          </li>
        </ng-container>
        <li *ngFor="let settingMenu of settingList$ | async">
          <ng-container *deprecatedFeaturePermission="settingMenu.subscription">
            <a class="dropdown-item" [routerLink]="[settingMenu.url]">{{ settingMenu.name }}</a>
          </ng-container>
        </li>
        <li>
          <ng-container
            *globalFeaturePermission="['user:action:user-preference:saveghostedpreferences']; callback:saveUserPreferenceKeys$$">
            <div class="" *ngIf="showSaveBtn">
              <a class="dropdown-item text-warning" (click)="saveUserPreferences()">Save Page Preferences</a>
            </div>
          </ng-container>
        </li>

        <li>
          <div class="form-check form-switch theme-switch">
            <label class="form-check-label" for="flexSwitchCheckChecked">Dark Mode</label>
            <input class="form-check-input" title="theme switch" type="checkbox" id="flexSwitchCheckChecked"
              (change)="lightThemeSwitch($event)" [checked]="isDarkMode$ | async">
          </div>
        </li>
        <li>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
    <div class="dropdown">
      <ng-container *deprecatedFeaturePermission="createButtonSubscriptionList$ | async">
        <button class="btn btn-success dropdown-toggle" type="button" id="createButtonHeader" data-bs-toggle="dropdown"
          aria-expanded="false">
          Create
        </button>
      </ng-container>
      <ul class="dropdown-menu" aria-labelledby="createButtonHeader">
        <li *ngFor="let menu of createButtonList$ | async">
          <ng-container *deprecatedFeaturePermission="menu.subscription">
            <a class="dropdown-item" [routerLink]="[menu.url]">{{ menu.name }}</a>
          </ng-container>
        </li>
      </ul>
    </div>
    <a [href]="legacyVMSURL" target="_self"
      class="d-flex align-items-center text-decoration-none link-secondary label"><span class="me-1">VMS</span>
      <span class="material-icons mi-24">launch</span></a>
  </div>
</div>

<div *ngIf="(showLogoOnly | async)" class="top-nav bg-gray d-flex align-items-center" style="padding-left: 1rem;">
  <a [routerLink]="['dashboard/portal']" alt="Logo" id="logo"></a>
</div>

<cds-ui-container-status-modal></cds-ui-container-status-modal>
<cds-ui-advance-search-modal></cds-ui-advance-search-modal>