import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface PoItemEventModalConfig {
    title: string,
    data:any
}

export enum PoItemDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface PoItemModalEvent {
    type: PoItemDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class PoItemModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<PoItemModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === PoItemDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === PoItemDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === PoItemDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === PoItemDialogueEvent.OnHidden));
  public onClosingPoItemRemove$ = this.event$.pipe(filter((x) => x.type === PoItemDialogueEvent.OnClosingRemove));
  public onClosingPoItemRemoved$ = this.event$.pipe(filter((x) => x.type === PoItemDialogueEvent.OnClosingRemoved));

  show(config: PoItemEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: PoItemModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingPoItem(params?: any) {
    this.event$.next({
      type: PoItemDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingPoItemRemoved() {
    this.event$.next({
      type: PoItemDialogueEvent.OnClosingRemoved,
    });
  }
}