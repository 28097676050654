/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  AnalyticsModalService,
  AnalyticsScheduleDialogueEvent,
  AnalyticsScheduleModalConfig
} from './analytics-modal.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AnalyticsCollectionQueryService } from '../analytics-collection/analytics-collection.query';
import { ConfirmationModalService } from '../confirmation-modal/confirmation-modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cds-ui-analytics-modal',
  templateUrl: './analytics-modal.component.html',
  styleUrls: ['./analytics-modal.component.scss']
})
export class AnalyticsModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('AnalyticsScheduleModal')
  analyticsScheduleModal!: TemplateRef<any>;

  // #region private variables
  private destroy$ = new Subject<void>();
  private selectedReport: any;
  private readonly subscriptions = new Subscription();

  // #endregion private variables

  // #region public variables
  public config!: any;
  public modalRef!: BsModalRef;

  // #endregion public variables

  constructor(
    private analyticsModalService: AnalyticsModalService,
    private collectionQueryService: AnalyticsCollectionQueryService,
    private ConfirmationModalService: ConfirmationModalService,
    private modalservice: BsModalService,
    private toastrService: ToastrService
  ) {}

  ngAfterViewInit(): void {
    this.subscribeShow();
    this.handler(
      this.modalservice.onShow,
      AnalyticsScheduleDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      AnalyticsScheduleDialogueEvent.OnShown
    );
    this.onResetClickConfirmed();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.modalRef?.hide();
  }

  onDeleteScheduleClick(config: any) {
    debugger;
    this.selectedReport = config;

    this.ConfirmationModalService.show({
      title: 'Confirmation',
      message: 'Are you sure you want to remove this schedule?',
      btnOne: 'Yes',
      btnTwo: 'No'
    });
  }

  onResetClickConfirmed() {
    this.subscriptions.add(
      this.ConfirmationModalService.onClosingConfirmationRemove$.subscribe({
        next: () => {
          this.collectionQueryService
            .deleteScheduledReports(this.selectedReport.taskId)
            .subscribe((result) => {
              console.log(result);
              this.toastrService.success('Report Schedule Removed...');
              this.ConfirmationModalService.setClosingConfirmationRemoved();
              this.close();
            });
        },
        error: () => console.log('Delete Operation Unsuccessful')
      })
    );
  }

  private handler(
    handler: EventEmitter<any>,
    type: AnalyticsScheduleDialogueEvent
  ) {
    handler
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        this.analyticsModalService.handle({ type: type, params: {} })
      );
  }

  private subscribeShow() {
    this.analyticsModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: any) => {
        if (this.modalRef) this.modalRef.hide();
        this.config = config as AnalyticsScheduleModalConfig;
        this.modalRef = this.modalservice.show(this.analyticsScheduleModal, {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-xl'
        });
      });
  }
}