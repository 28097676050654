export const keySearchResults = [
  {
    key: 'Container',
    result: [
      { name: 'Container Search' },
      { name: 'Inbound Planning Search' },
      { name: 'Purchase Order Search' },
      { name: 'Shipment Detail Search' },
      { name: 'Supply Chain Milestone Search'}
    ],
  },
  {
    key: 'ShipKey',
    result: [
      { name: 'Container Search' },
      { name: 'Inbound Planning Search' },
      { name: 'Purchase Order Search' },
      { name: 'Shipment Detail Search' },
      { name: 'Supply Chain Milestone Search'}
    ],
  },
  {
    key: 'PO',
    result: [
      { name: 'Container Search' },
      { name: 'Inbound Planning Search' },
      { name: 'Purchase Order Search' },
      { name: 'Shipment Detail Search' },
      { name: 'Supply Chain Milestone Search'}
    ],
  },
  {
    key: 'Item',
    result: [
      // { name: 'Container Search' },
      { name: 'Inbound Planning Search' },
      { name: 'Purchase Order Search' },
      { name: 'Shipment Detail Search' },
      { name: 'Supply Chain Milestone Search'}
    ],
  },
  {
    key: 'MasterBL',
    result: [
      { name: 'Container Search' },
      { name: 'Inbound Planning Search' },
      { name: 'Purchase Order Search' },
      { name: 'Shipment Detail Search' },
      { name: 'Supply Chain Milestone Search'}
    ],
  },
  {
    key: 'HouseBL',
    result: [
      { name: 'Container Search' },
      { name: 'Inbound Planning Search' },
      { name: 'Shipment Detail Search' },
      { name: 'Supply Chain Milestone Search' }
    ],
  },
  {
    key: 'BookingKey',
    result: [
      { name: 'Supply Chain Milestone Search'}
    ],
  },
];
