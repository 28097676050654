import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { tap, BehaviorSubject, Subject, takeUntil, switchMap, Observable, map, withLatestFrom, combineLatest, debounceTime } from 'rxjs';
import { FeaturePermissionRepository } from '../data-source/feature-permission.repository';
import { distinctUntilArrayItemChanged } from '@ngneat/elf';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import _ from 'lodash';


@Directive({
    selector: '[featurePermission]'
})
export class FeaturePermissionDirective implements OnInit, OnDestroy {
    private callback$$ = new Subject<string[]>();
    private companyFilter$$ = new BehaviorSubject<string[]>([]);
    private featureKeys$$ = new BehaviorSubject<string[]>([]);
    private cancel$$ = new Subject<void>();
    private destroy$$ = new Subject<void>();

    @Input() set featurePermissionCompanyCodes(filter: string | string[] | Observable<string | string[]> | null) 
    {
        if(filter instanceof Object && !Array.isArray(filter)) {
            filter.pipe(
                takeUntil(this.destroy$$),
                tap(x => this.emitCompanyFilter(x))
            ).subscribe();

            return;
        }

        this.emitCompanyFilter(filter);
    }

    @Input() set featurePermissionCallback(callback$: Subject<string[]> | null) 
    {
        this.callback$$ = callback$ ?? new Subject();
    }

    @Input("featurePermission") set query(query: string | string[] | null | Observable<string | string[] | null>) {
        if(query instanceof Object && !Array.isArray(query)) {
            query.pipe(
                takeUntil(this.destroy$$),
                tap(x => this.emitQueryRequest(x))
            ).subscribe();

            return;
        }

        this.emitQueryRequest(query);
    }

    private emitCompanyFilter(filter: string | string[] | null) {
        const companyCodes = !filter
            ? []
            : Array.isArray(filter)
                ? filter
                : [filter];

        this.companyFilter$$.next(companyCodes);
    }

    private emitQueryRequest(query: string | string[] | null) {
        const featureKeys = !query
            ? []
            : Array.isArray(query)
                ? query
                : [query];

        this.featureKeys$$.next(featureKeys);
    }

    private createEmbeddView(list: string[]) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef, { permissions: list });
    }

    constructor(
        private appState: AppStateRepository,
        private templateRef: TemplateRef<{ permissions: string[] }>,
        private viewContainer: ViewContainerRef,
        private repo: FeaturePermissionRepository) { }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
        this.callback$$.complete();
    }

    ngOnInit(): void {
        combineLatest([
            this.featureKeys$$,
            this.companyFilter$$
        ]).pipe(
            debounceTime(100),
            takeUntil(this.destroy$$),
            tap(_ => this.cancel$$.next()),
            map(([x,_]) => x),
            switchMap(x => this.repo.query(x)
                                    .pipe(
                                        takeUntil(this.destroy$$),
                                        takeUntil(this.cancel$$)
                                    )
            ),
            withLatestFrom(this.companyFilter$$, this.appState.activeCompany$),
            map(([permissions, companyFilter, activeCompany]) => ({
                permissions,
                filter: companyFilter?.length
                      ? companyFilter
                      : activeCompany.map(x => x.companyCode)
            })),
            map(x => !!x.filter?.length
                   ? _.concat(
                        x.permissions.filter(y => !x.filter.some(c => y.optOut.includes(c))),
                        x.permissions.filter(y => x.filter.some(c => y.optIn.includes(c)))
                    )
                   : x.permissions
            ),
            map(x => x.map(i => i.permissionKey)),
            distinctUntilArrayItemChanged(),
            tap(x => x.length
                   ? this.createEmbeddView(x)
                   : this.viewContainer.clear()
            ),
            tap(x => this.callback$$.next(x))
        )
        .subscribe();
    }
}