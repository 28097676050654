/**
 * ### Response when calling command
 * 
 * Note: IActionEvent must be an array
 * 
 * e.g.
 * ```
 * return this.http.post<IActionEvent<DangerousGoodsTemplate>[]>(...);
 * ```
 */
export interface IActionEvent<T>
{
    type: string;
    instance: T; 
}