<ng-template #template>
    <div class="modal-content" id="modalDrag" cdkDrag>
        <div class="modal-header" cdkDragHandle>
          <h2 class="modal-title m-0">Add Container Status Event</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form"  class="row g-3">
          <h3 class="text-purple mb-0 pb-0">Event Details</h3>
            <div class="col-md-6">
              <label for="inputEmail4" class="form-label">Ship Key:</label>
              <input type="text" formControlName="shipKey" class="form-control" id="inputAddress" readonly>
            </div>
            <div class="col-6">
              <label for="inputAddress" class="form-label">Container #:</label>
              <input type="text" formControlName="containerNo" class="form-control" id="inputAddress" readonly>
            </div>

            <div class="col-md-12">
              <label for="inputZip" class="form-label">Event Type:</label>
              <select class="form-select" formControlName="eventType" (change)="statusSelection($event)">
                  <option value="">Select Event</option>
                  <option *ngFor="let v of (statusList$ | async)" [value]="v?.statusCode" > {{v?.statusName}} </option>
              </select>
            </div>

            <div class="col-6">
              <label for="inputAddress2" class="form-label">Event Date:</label>
              <input  id="startDate" placeholder="mm/dd/yyyy" formControlName="eventDate" class="form-control calender-icon" bsDatepicker
              [minDate]="minDate" [maxDate]="maxDate" (input)="onDateChange()" [bsConfig]="datePickerConfig" />
            </div>
            <div class="col-md-6">
              <label for="inputState" class="form-label">Event Location:</label>
              <select class="form-select" id="dd2" formControlName="eventLocation" [attr.disabled]="!isContainer ? 'disabled' : null">
                  <option value="">Select Location</option>
                  <option *ngFor="let v of (locationList$ | async)" [value]="v.locationCode"> {{v.locationName}}</option>
              </select>
            </div>

            <div class="d-flex align-items-center mt-2" *ngIf="!isContainer && dateCondition()">
              <span class="material-icons text-danger float-start me-2">error_outline</span>
              <small class="text-white">
                The event entered is scheduled for a future date. Please double-check the date before proceeding.
              </small>
            </div>

            <div class="col-md-12">
              <label for="inputState" class="form-label">Comment:</label>
              <textarea class="form-control" formControlName="comments" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal" (click)="modalRef?.hide()">Cancel</button>
          <button type="button" class="btn btn-submit" [disabled]="!form.valid" (click)="onSave()">Save</button>
        </div>
      </div>
</ng-template>
