import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboundPlanningService } from '@cds-ui/domain/inbound-planning/domain-core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil, tap } from 'rxjs';
import { CommentsDialogueEvent, CommentsEventModalConfig, CommentsModalService } from './comments-modal.service';
import moment from 'moment';

@Component({
  selector: 'cds-ui-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss'],
})
export class CommentsModalComponent implements OnInit {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: CommentsEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  commentsForm = this.fb.group({
    commentField:['', Validators.required]
  });

  constructor(
    private modalservice: BsModalService,
    private inboundPlanningService: InboundPlanningService,
    private commentsModalService: CommentsModalService,
    private appStateRepository: AppStateRepository,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.commentsModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as CommentsEventModalConfig;

        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      CommentsDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      CommentsDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      CommentsDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      CommentsDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: CommentsDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.commentsModalService.handle({ type: type })
      );
  }

  formatDateToUTC(date: string){
    return  date ? moment.utc(date).format('MM/DD/yyyy') : null;
  }

  onSubmit(){
    const postBodyPayload = {
      shipKey: this.config.message.shipKey,
      containerNumber: this.config.message.containerNumber,
      comment: this.commentsForm.value.commentField
    }
    this.inboundPlanningService.updateCommentsData(postBodyPayload)
    .pipe(takeUntil(this.destroy$),
      tap((response)=>{
        this.modalRef.hide();
        this.inboundPlanningService.updateCommentsFieldLabelSubject$.next({id:this.config.message.uniqueId, showComments:this.config.message.showComments});
        this.toastrService.success('Data updated successfully');
        this.commentsForm.get('commentField')?.reset();
      })
      ).subscribe();
  }

  closeModal(){
    this.modalRef.hide();
    this.commentsForm.get('commentField')?.reset();
  }

  onConfirmationClick() {
    this.commentsModalService.setClosingConfirmation(this.config);
  }

  private subscribeOverwrittenExisting() {
    this.commentsModalService.onClosingConfirmationRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
