import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { DashboardTemplateConfig } from './models/dashboard-model';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
  constructor(private http: HttpClient) {}

  getTemplate() {
    return of([] as DashboardTemplateConfig[]);
  }

  //TODO: Ask what is purpose of this file? How is it intended to be different from dashboard.query.ts
}