<div class="w-100 me-7 mb-3">
    <div class="d-flex justify-content-between">
        <h6 class="status-heading fs-sm">{{ title }}</h6>
        <a *ngIf="title == 'Container ETA Statuses'" (click)="selectItem({label: 'Is Show All', count: 0, totalValue: 0, color: '', isShowAll: true})" class="fs-6">View All</a>
      </div>
    <h6 class="status-heading fs-sm"></h6>
    <div class="progress rounded-pill mb-3 hoverTallyCard">
        <div class=" progress-bar {{segment.color}}" role="progressbar" *ngFor="let segment of (segments$ | async);"
            attr.aria-valuenow="{{segment.count}}" aria-valuemin="0"
            style="width: {{ segment.count * 100 / ((total$ | async) ?? 1) }}%" [attr.aria-valuemax]="total$ | async"
            (click)="selectItem(segment)">
        </div>
    </div>
    <div class="d-flex flex-wrap fs-sm align-items-center fw-bold">
        <span class="d-flex align-items-center me-3 mb-1" *ngFor="let segment of (segments$ | async)">
            <span class="status-legend rounded-circle {{segment.color}} d-block me-2"></span> {{segment.label}}
            ({{segment.count}})</span>
    </div>
</div>
