<ng-template #template>
    <div id="modalDrag" cdkDrag>
      <div class="modal-header pb-0" cdkDragHandle>
          <h3 class="modal-title">{{config.title}}</h3>
          <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
        </div>

       
        <div class="modal-body">
         
          <div class="d-flex flex-wrap justify-content-start gap-3" *ngIf="config?.type == 'po'">
            <div *ngFor="let val of config?.data?.messagePurchaseOrders"><a (click)="pONavigation(val, config?.data?.companyCode)" class="font-monospace">{{val?.pO}}</a></div>
          </div>

          <div class="d-flex flex-wrap justify-content-start gap-3" *ngIf="config?.type == 'buyer'">
            <div *ngFor="let val of config?.data?.messagePurchaseOrders"><a  class="font-monospace">{{val?.buyer}}</a></div>
          </div>

          <div class="d-flex flex-wrap justify-content-start gap-3" *ngIf="config?.type == 'division'">
            <div *ngFor="let val of config?.data?.messagePurchaseOrders"><a  class="font-monospace">{{val?.division}}</a></div>
          </div>

          <div class="d-flex flex-wrap justify-content-start gap-3" *ngIf="config?.type == 'department'">
            <div *ngFor="let val of config?.data?.messagePurchaseOrders"><a  class="font-monospace">{{val?.department}}</a></div>
          </div>

          <div class="d-flex flex-wrap justify-content-start gap-3" *ngIf="config?.type == 'eventcode'">
            <div *ngFor="let val of config?.data?.messagePurchaseOrders"><a  class="font-monospace">{{val?.eventCode ?? '--'}}</a></div>
          </div>

          <div class="d-flex flex-wrap justify-content-start gap-3" *ngIf="config?.type == 'itemNumbers'">
            <div *ngFor="let val of config?.data?.messagePurchaseOrders"><a (click)="itemNavigation(val, config?.data?.companyCode)" class="font-monospace">{{val?.item}}</a></div>
          </div>
           
        </div>
      </div>
  </ng-template>