<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-grow-1">
        <span>{{config.message}}</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary text-light" (click)="modalRef?.hide()">{{config.buttonText}}</button>
    </div>
  </div>
</ng-template>