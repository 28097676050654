import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface DeclineCounterEventModalConfig {
    title: string,
    message?: string,
    btnOne:string,
    btnTwo:string,
    data:any,
    dropDownData:any
}

export enum DeclineCounterDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface DeclineCounterModalEvent {
    type: DeclineCounterDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class DeclineCounterModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<DeclineCounterModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === DeclineCounterDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === DeclineCounterDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === DeclineCounterDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === DeclineCounterDialogueEvent.OnHidden));
  public onClosingDeclineCounterRemove$ = this.event$.pipe(filter((x) => x.type === DeclineCounterDialogueEvent.OnClosingRemove));
  public onClosingDeclineCounterRemoved$ = this.event$.pipe(filter((x) => x.type === DeclineCounterDialogueEvent.OnClosingRemoved));

  show(config: DeclineCounterEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: DeclineCounterModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingDeclineCounter(params?: any) {
    this.event$.next({
      type: DeclineCounterDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingDeclineCounterRemoved() {
    this.event$.next({
      type: DeclineCounterDialogueEvent.OnClosingRemoved,
    });
  }
}