import { Route } from '@angular/router';
import { AuthGuard } from '@cds-ui/shared/auth';
import { CallbackComponent } from './callback.component';

const guardedRoutes = [
    { path: '', redirectTo: 'dashboard/portal', pathMatch: 'full' },
    {
        path: 'item',
        loadChildren: () =>
            import('../domain/item/item.module').then((m) => m.ItemModule),
    },
    {
        path: 'ghosting',
        loadChildren: () =>
            import('../domain/sharing/ghosting/ghosting.module').then((m) => m.GhostingModule),
    },
    {
        path: 'analytics',
        loadChildren: () =>
            import('../domain/analytics/analytics.module').then((m) => m.AnalyticsModule),
    },
    {
        path: 'booking',
        loadChildren: () =>
            import('../domain/booking/booking.module').then((m) => m.BookingModule),
    },
    {
        path: 'container',
        loadChildren: () =>
            import('../domain/container/container.module').then((m) => m.ContainerModule),
    },
    {
      path: 'view',
      loadChildren: () =>
        import('../domain/subpersona-features/subpersona-features.module').then((m) => m.SubpersonaFeaturesModule)
    },
    {
      path: 'inbound-planning',
      loadChildren: () =>
          import('../domain/inbound-planning/inbound-planning.module').then((m) => m.InboundPlanningModule),
    },
    {
      path: 'message',
      loadChildren: () =>
          import('../domain/message-center/message-center.module').then((m) => m.MessageCenterModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('../domain/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'map',
        loadChildren: () =>
            import('../domain/map/map.module').then((m) => m.MapModule),
    },
    {
        path: 'po',
        loadChildren: () =>
            import('../domain/purchase-order/purchase-order.module').then((m) => m.PurchaseOrderModule),
    },
    {
        path: 'purchase-order',
        loadChildren: () =>
            import('../domain/purchase-order/purchase-order.module').then((m) => m.PurchaseOrderModule),
    },
    {
        path: 'sample',
        loadChildren: () =>
            import('../domain/sample/sample.module').then((m) => m.SampleModule),
    },
    {
        path: 'shipping',
        loadChildren: () =>
            import('../domain/shipping/shipping.module').then((m) => m.ShippingModule),
    },
    {
      path: 'transload',
      loadChildren: () =>
          import('../domain/transload/transload.module').then((m) => m.TransloadModule),
    },
    {
        path: 'voyage',
        loadChildren: () =>
            import('../domain/voyage/voyage.module').then((m) => m.VoyageModule),
    },
    {
        path: 'legacy',
        loadChildren: () =>
            import('../domain/legacy-vms-container/legacy-vms-container.module').then((m) => m.LegacyVmsContainerModule),
    },
    {
      path: 'global-search',
      loadChildren: () =>
          import('../domain/global-search/global-search.module').then((m) => m.GlobalSearchModule),
    },
    {
        path: 'mother-vessel',
        loadChildren: () =>
            import('../domain/mother-vessel-monitoring/mother-vessel.module').then((m) => m.MotherVesselModule),
      },

    {
        path: 'search',
        loadChildren: () =>
            import('../domain/sharing/search/search.module').then((m) => m.SearchModule),
    },
    {
        path: 'vendor',
        loadChildren: () =>
            import('../domain/vendor/vendor.module').then((m) => m.VendorModule),
    },
    {
        path: 'vibe',
        loadChildren: () =>
            import('../domain/vibe/vibe.module').then((m) => m.VibeModule),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('../domain/authorization/authorization.module').then((m) => m.AuthorizationModule),
    },
    {
        path: 'diag',
        loadChildren: () =>
            import('../diag/diagnostics.module').then((m) => m.DiagnosticsModule),
    },
    {
        path: 'compliance',
        loadChildren: () =>
            import('../domain/compliance/compliance.module').then((m) => m.ComplianceModule),
    },
    {
        path: 'origin',
        loadChildren: () =>
            import('../domain/manifest/manifest.module').then((m) => m.ManifestModule),
    },
];

export const routes: Route[] = [
    {
        path: '',
        canActivate: [AuthGuard],
        children: guardedRoutes
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'diag',
        loadChildren: () =>
            import('../diag/diagnostics.module').then((m) => m.DiagnosticsModule),
    }
];
