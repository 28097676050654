import { map, mergeMap, first, from, groupBy, Observable, pipe, toArray, UnaryFunction, combineLatestWith } from "rxjs";

export function groupByMap<T, K, R>(
    key: (value: T) => K,
    project: (value: [T, T[]], index: number) => R
): UnaryFunction<Observable<T[]>, Observable<R[]>> {
    return pipe(
        mergeMap(x =>
            from(x).pipe(
                groupBy(key),
                mergeMap(group => 
                    group.pipe(
                        first(),
                        combineLatestWith(group.pipe(toArray())),
                        map(project),
                    )
                ),
                toArray()
            )
        )
    );
};