/* eslint-disable @typescript-eslint/no-explicit-any */
import { convertObservableToBehaviorSubject, filterNullish } from '@cds-ui/shared/core-rx';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { Component, ElementRef, OnDestroy, Input, OnInit, ViewChild } from '@angular/core';
import { GhostingDialogService } from '../ghosting-dialog/ghosting-dialog.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, map, Observable, of, shareReplay, startWith, Subject, takeUntil, tap, take, lastValueFrom, switchMap, withLatestFrom } from 'rxjs';
import { Buyer, GetContainerGridGlobalSearchGQL, GetMileStoneGridSearchGQL, GetPurchaseOrderSearchGridGQL, GetInboundContainerGridGQL, GetShipmentGridGlobalSearchGQL, Company } from '@cds-ui/data-access';
import { ActivatedRoute, Router } from '@angular/router';
import { MainMenuService, SystemSearch } from '../main-header-nav-bar/main-menu.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { createButtonList, settingList } from './man-header-menu.map';
import { ContainerStatusModalService } from '../container-status-modal/container-status-modal.service'
import { keySearchResults } from './key-search-results';
import { Apollo } from 'apollo-angular';
import { AuthService } from '@cds-ui/shared/auth';
import { AdvanceSearchModalService } from '../..';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SaveUserPreferenceModalService } from '../save-user-preference-modal/save-user-preference-modal.service';

@Component({
  selector: 'cds-ui-main-header-menu',
  templateUrl: './main-header-menu.component.html',
  styleUrls: ['./main-header-menu.component.scss']
})
export class MainHeaderMenuComponent implements OnInit, OnDestroy {
  @Input() logoSrc: string | undefined;
  @Input() showLogoOnly!: Observable<boolean>;
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('filter') filter!: ElementRef;

  private readonly destroy$$ = new Subject<void>();

  public environment$$ = convertObservableToBehaviorSubject(
    this.appState.environment$,
    null
  );

  public noResult = false;
  public placeholderText = 'Search Containers, POs, Bookings, Shipments, BLs..';
  public typeaheadLoading!: boolean;
  public checkGhostedUser! : boolean;
  public legacyVMSURL: string =
    this.environment$$.getValue()?.urls.legacyServer ?? '';
  public suggestions$!: Observable<SystemSearch[]>;
  public containerGridData$!: Observable<any>;
  public poGridData$!: Observable<any>;
  public search!: string;
public preferencePayload = {}
public hasGhostedUser!:boolean;
  public keyNames!: string;
  public searchBuyers: string | null = null;
  public showSaveBtn: boolean = true;

  keyName!: string;
  public showMultipleSearchPopup = false;
  public keySearchResults = keySearchResults;
  inputQueryKey!: string;
  inputQueryKeyArr!: any[];
  public isDarkMode$ = this.appState.appTheme$.pipe(map((x) => x === 'dark'));

  public features$ = this.appState.features$;
  public user$ = this.appState.currentUser$;

  onLoad = false;
  isMenuOpen = false;
  cacheData: any;
  public userPreferences$ = new Subject<{divisionSelector?: string, colorTheme?: string}>();

  public activeCompanies$ = this.appState.activeCompany$.pipe(
    filterNullish(),
    shareReplay(1)
  );

  public activeBuyers$ = this.appState.activeBuyer$.pipe(
    filterNullish(),
    shareReplay(1)
  );

  public searchBuyers$ = new Subject<string>();
  public buyersList$ = combineLatest([
    this.appState.availableBuyers$,
    this.appState.activeBuyer$.pipe(filterNullish(), shareReplay(1)),
    this.searchBuyers$.pipe(startWith(''))
  ]).pipe(
    map(([available, active, query]) => ({
      active: active,
      available: available,
      query: query,
    })),
    map((x) => ({
      ...x,
      filtered: x.available
        .map((i) => ({ display: `${i.buyerCode} - ${i.buyerName}`, ...i }))
        .filter((i) =>
          x.query === ''
            ? true
            : i.display.match(new RegExp(x.query ?? '', 'gi'))
        ),
    })),
    shareReplay(1)
  );

  public saveUserPreferenceKeys$$ = new Subject<string[]>();
  public selectedBuyers: Array<string> = [];
  public settingList$ = of(settingList).pipe(shareReplay(1));
  public createButtonList$ = of(createButtonList).pipe(shareReplay(1));
  public createButtonSubscriptionList$ = this.createButtonList$.pipe(
    map((x) => x.map((c) => c.subscription)),
    shareReplay(1)
  );

  public shouldUseBuyerFilter$ = combineLatest([
    this.appState.currentUser$,
    this.activatedRoute.queryParams,
    this.appState.usingBuyerFilter$,
  ]).pipe(
    map(
      ([c, x, y]) =>
        (c.persona === 'cds-origin' || ((x ?? false) && y)) ?? false
    ),
    shareReplay(1)
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    public appState: AppStateRepository,
    private apollo: Apollo,
    private authService: AuthService,
    private ContainerStatusModalService: ContainerStatusModalService,
    private ghostingDialogService: GhostingDialogService,
    private mainMenuService: MainMenuService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private advanceSearchModalService:AdvanceSearchModalService,
    private toastrService: ToastrService,
    private http: HttpClient,
    private SaveUserPreferenceModalService: SaveUserPreferenceModalService,
  ) {}

  ngOnInit(): void {

    this.saveUserPreferenceKeys$$
    .pipe(takeUntil(this.destroy$$),
    withLatestFrom(this.user$),
    tap(([permission, user]) => {
      setTimeout(() => {
        this.showSaveBtn = permission.length && user.hasGhosted ? true : false;
      },500);
    })).subscribe();

    this.appState.activeBuyer$.pipe(
      tap(x => this.selectedBuyers = x.map(i => i.buyerCode))
    ).subscribe();

    this.userPreferences$.pipe(
      takeUntil(this.destroy$$),
      tap((pref) => this.cacheData = pref)
    ).subscribe();

    this.mainMenuService
      .getUserPreference('global/headers')
      .pipe(
        tap((result: any) => {
          this.userPreferences$.next(result);
        }),
        map((result: any) => result.colorTheme),
        tap((colorTheme: string | undefined) => {
          if (colorTheme !== undefined) {
            this.isDarkMode$ = this.appState.appTheme$.pipe(
              map((x) => (x == 'dark' ? true : false))
            );
            this.appState.setThemeMode(colorTheme == 'dark' ? 'dark' : 'light');
          }
        })
      )
      .subscribe();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  includeInBuyerFilter(active: Buyer[], filtered: Buyer[]) {
    const selectedSome = active
      .map((x) => x.buyerCode)
      .some((i) => filtered.map((x) => x.buyerCode).includes(i));

    const result = selectedSome
      ? active.length === filtered.length
        ? 'all'
        : 'some'
      : 'none';

    return result;
  }

  filterBuyerChange(input: string | null) {
    this.searchBuyers$.next(input ?? '');
  }

  saveUserPreferences(){
    this.SaveUserPreferenceModalService.show({
      title: 'Confirmation',
      message: "Are you sure you want to overwrite this user's page settings?",
      btnOne: 'Yes',
      btnTwo: 'No',
    });
    this.confirmationModal();
  }

  confirmationModal(){
    this.SaveUserPreferenceModalService.onClosingSaveUserPreferenceRemove$
      .pipe(takeUntil(this.destroy$$),
      map(_ => {
        const x = this.appState.ghostedUserPreferences$.value;
        return {
          path: x.path,
          data: x.data,
          AllowUpdateGhostedUserPreference: true
        }
      }),
      
      switchMap(x => this.http.post('/user/preferences', x)),
      tap(_ => {
        this.SaveUserPreferenceModalService.setClosingSaveUserPreferenceRemoved();
      })).subscribe();
  }

  searchQuery(e: string) {
    this.keyNames =
      '["ShipKey,BookingKey,PO,Container,Vessel,Port,Item,FCR,MasterBL,HouseBL"]';
    let queryArr = [];
    this.inputQueryKey = e;
    if (e.includes(', ')) {
      queryArr = e.split(', ');
      this.inputQueryKeyArr = queryArr;
      this.showMultipleSearchPopup = true;
      this.getKeyName(this.keyNames, queryArr[0]);
    } else if (e.includes(' ')) {
      queryArr = e.split(' ');
      this.inputQueryKeyArr = queryArr;
      this.showMultipleSearchPopup = true;
      this.getKeyName(this.keyNames, queryArr[0]);
    } else if (e.includes(',')) {
      queryArr = e.split(',');
      this.inputQueryKeyArr = queryArr;
      this.showMultipleSearchPopup = true;
      this.getKeyName(this.keyNames, queryArr[0]);
    } else {
      this.showMultipleSearchPopup = false;
      this.search = e;
      if (this.search.replace(/\s+/g, '').length > 2) {
        this.onLoad = true;
        this.suggestions$ = this.mainMenuService
          .getSystemSearchValueData(
            this.keyNames,
            this.search.replace(/\s+/g, '')
          )
          .pipe(
            map((val) => {
              this.onLoad = false;
              const searchData: any[] = [];
              if (val.length) {
                const keyName = Array.from(new Set(val.map((el) => el.keyName)));
                keyName.forEach((x) => {
                  searchData.push({
                    name: x,
                    data: val.filter((y) => y.keyName === x),
                  });
                });
              }
              return searchData;
            })
          );
      } else {
        this.suggestions$ = new Observable<[]>();
        this.onLoad = false;
      }
    }
  }

  getKeyName(keyNames: string, query: string) {
    this.keyName = '';
    this.mainMenuService
      .getSystemSearchValueData(keyNames, query)
      .pipe(
        takeUntil(this.destroy$$),
        tap((val) => {
          this.keyName = val[0].keyName;
        })
      )
      .subscribe();
  }

  selectMenuItem(e: any) {
    if (e.queryString) {
      this.search = '';
      this.router.navigateByUrl(e.queryString);
      (document.getElementById('globalSystemSeach') as HTMLInputElement).value =
        '';
      this.suggestions$ = new Observable<[]>();
    } else {
      this.search = e.keyValue;
      this.suggestions$ = new Observable<[]>();
    }
  }

  disableQuickSearchMenu(menu: any) {
    return menu.keyName !== 'FCR' && menu.keyName !== 'Vessel';
  }

  async selectQuickSearchMenu(name: string, value?: any, keyType?: string) {
    const htmlElement = document?.getElementById(
      'globalSystemSeach'
    ) as HTMLInputElement;
    htmlElement.value = '';
    this.suggestions$ = new Observable<[]>();
    this.showMultipleSearchPopup = false;
    let arr = [];
    if (typeof value === 'string') {
      arr.push(value.replace(/\(.+?\)/, ''));
    }
    arr = value === '' ? this.inputQueryKeyArr : arr;
    let obj = {};
    switch(keyType) {
      case 'ShipKey':
        obj = { shipKeys: arr }
        break;
      case 'Container':
        obj = { containerNumbers: arr}
        break;
      case 'PO':
        obj = name === "Supply Chain Milestone Search" ? { purchaseOrderNumbers: arr } : { poNumbers: arr }
        break;
      case 'Item':
        obj = { itemNumbers: arr }
        break;
      case 'MasterBL':
        obj =  { masterBlNumbers: arr } 
        break;
      case 'HouseBL':
        obj =  { houseBlNumbers: arr } 
        break;
      case 'BookingKey':
        obj = { bookingKeys: arr }
        break;
      default:
        console.log('keyType not added:' + keyType);
        break;
    }

    // if ALL subgroup, use all comp codes
    const user$ = this.appState.currentUser$.pipe(take(1));
    const user =  await lastValueFrom(user$);
    const activeCompanies$ = this.appState.activeCompany$;
    
    if(user.orginalSubgroupCode?.toLowerCase() === "all") {
      this.appState.activeCompany$ = this.appState.availableCompanies$;
    }
    
    // GetPurchaseOrderItemsGrid
    if (name === 'Purchase Order Search') {
      const htmlElement = document?.getElementById(
        'globalSystemSeach'
      ) as HTMLInputElement;
      htmlElement.value = '';
      this.suggestions$ = new Observable<[]>();
      new GetPurchaseOrderSearchGridGQL(this.apollo)
        .fetch(
          {
            filter: obj
          },
          { fetchPolicy: 'no-cache' }
        ).pipe(takeUntil(this.destroy$$), map((a: any) => a?.data?.purchaseOrderItemSummariesForSearch)).subscribe(x => {
          this.mainMenuService.porGridSearchSubject$.next(x);
            this.router.navigate(['/global-search/po-search'], { queryParams: { filter: 'PO' } });
        });
    } else if (name === 'Container Search') {
      new GetContainerGridGlobalSearchGQL(this.apollo)
        .fetch(
          {
            filter: obj
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          takeUntil(this.destroy$$),
          map((a: any) => a?.data?.containerSummaries)
        )
        .subscribe((res) => {
          this.mainMenuService.containerGridSearchSubject$.next(of(res));
          this.router.navigate(['/global-search/container-search'], { queryParams: { filter: 'container' } });
        });
    } else if (name === 'Shipment Detail Search') {
      new GetShipmentGridGlobalSearchGQL(this.apollo)
        .fetch(
          {
            filter: obj
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          takeUntil(this.destroy$$),
          map((a) => a?.data?.shipmentItemSummary)
        )
        .subscribe((res) => {
          this.mainMenuService.shipmentGridSearchSubject$.next(res);
          this.router.navigate(['/global-search/shipment-search'],{ queryParams: { filter: 'shipment' } });
        });
    } else if (name === 'Inbound Planning Search') {
      new GetInboundContainerGridGQL(this.apollo)
        .fetch(
          {
            filter: obj
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(
          takeUntil(this.destroy$$),
          map((a) => a?.data?.inboundContainerSummaries)
        )
        .subscribe((res) => {
          this.mainMenuService.inboundGridSearchSubject$.next(of(res));
          this.router.navigateByUrl('/global-search/inbound-search');
        });
    } else if (name === 'Supply Chain Milestone Search') {
      new GetMileStoneGridSearchGQL(this.apollo)
      .fetch(
        {
          filter: obj
        },
        { fetchPolicy: 'no-cache' }
    )
    .pipe(
      takeUntil(this.destroy$$),
      map((a) => a?.data?.supplyChainMilestoneSummaries),
      map(a=> ({
        nodes: a?.nodes?.map(x=> ({
          ...x,
          poKey: `${x?.purchaseOrderNumber}-${x?.itemNumber}-${x?.color ?? ''}-${x?.size ?? ''}`
        })) ?? [],
        purchaseOrderItem: a?.purchaseOrderItem?.map(x => ({
          ...x,
          poKey: `${x?.poNumber}-${x?.itemNumber}-${x?.color ?? ''}-${x?.size ?? ''}`
        })) ?? [],
        data: a,
      })),
      map(a => {
        const poItemLookup = new Map(
          a?.purchaseOrderItem?.map(b => [b.poKey, b]) ?? []
        );
        const combinedItems = a?.nodes?.map(node => {
          const poItem = poItemLookup.get(node.poKey) || {};
          return { ...poItem, ...node };
        }) ?? [];
        return {
          poItems: combinedItems,
          data: a?.data,
        };
      }),
    )
    .subscribe((res) => {
      this.mainMenuService.mileStoneGridSearchSubject$.next(res);
      this.router.navigate(['/global-search/milestone-search'], { queryParams: { filter: 'milestone' } });
    });
    } else if (name === 'Inbound Planning Edit') {
      window.localStorage.setItem('statusFlag', 'show');
      this.mainMenuService.inboundGridEditSearchSubject$.next(obj);
      this.router.navigateByUrl('/inbound-planning/inbound-details');
    }

    // if ALL subgroup, set active comp codes back to what they were
    if(user.orginalSubgroupCode?.toLowerCase() === "all") {
      this.appState.activeCompany$ = activeCompanies$;
    }
  }

  selectQuickActionMenu(name: string, value?: any, keyType?: string) {
    if (name === 'Container Status') {
      this.ContainerStatusModalService.show(
        {
          title: "Container Status Events",
          data: value
        }
      )
    }
  }
  
  selectedSearchItem(e: TypeaheadMatch) {
    if (e.item.queryString) {
      this.router.navigateByUrl(e.item.queryString);
      (document.getElementById('globalSystemSeach') as HTMLInputElement).value =
        '';
    }
  }

  preventClick(event: Event) {
    event.preventDefault();
  }

  onSelectAllBuyer(filtered: Buyer[], active: Buyer[]) {
    const state = this.includeInBuyerFilter(filtered, active);
    if (state === 'some' || state === 'none') {
      this.appState.setActiveBuyers(filtered);
      this.appState.setSelectAllFilter(true);
    } else {
      this.appState.setActiveBuyers([]);
      this.appState.setSelectAllFilter(false);
    }
  }

  lightThemeSwitch(event: any) {
    this.appState.setThemeMode(event.target.checked ? 'dark' : 'light');

    this.setUserPreference(event.target.checked ? 'dark' : 'light');
  }

  setUserPreference(themeName: string) {
    const cacheData = {
      ...this.cacheData,
      ...{ colorTheme: themeName }
    };

    const dictionary = {
      path: 'global/headers',
      data: cacheData
    };

    this.mainMenuService.setUserPreference(dictionary).subscribe();
  }

  onBuyerSelect(buyer: Buyer, active: Buyer[]) {
    const checked = active.filter((x) => x.buyerCode === buyer.buyerCode);

    if (!checked.length) {
      this.appState.setActiveBuyers(active.concat([buyer]));
    } else {
      this.appState.setActiveBuyers(
        active.filter((x) => x.buyerCode != buyer.buyerCode)
      );
    }
    this.appState.setSelectAllFilter(false);
  }

  isActiveBuyer(buyerCode: string, activeBuyer: Buyer[]) {
    return activeBuyer.some((e) => e.buyerCode === buyerCode);
  }

  refreshGhostedHistory() {
    this.ghostingDialogService.getGhostUser(true);
  }

  logout() {
    this.authService.logout();
  }

  resetGhostingStatus(userId: any, subgroupId: any) {
    this.ghostingDialogService
      .getGhostUserValidate(userId, subgroupId)
      .subscribe((response) => {
        if (response) {
          this.authService
            .ghostUserFromAuthServer(userId, subgroupId)
            .subscribe(() => {
              this.router
                .navigate(['ghosting/ghosting-confirmation'])
                .then(() => window.location.reload());
            });
        }
      });
  }

  onAdvanceClick(){
    this.advanceSearchModalService.show(
      {
        title: "Container Status Events",
        message: "header"
      }
    )
  }
  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
