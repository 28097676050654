import { Component, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  EditColumnDateDialogueEvent, EditColumnDateEventModalConfig, EditColumnDateModalService } from './edit-column-date.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import moment from 'moment';

@Component({
  selector: 'cds-ui-edit-column-date',
  templateUrl: './edit-column-date.component.html',
  styleUrls: ['./edit-column-date.component.scss'],
})
export class EditColumnDateComponent {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: EditColumnDateEventModalConfig;
  @ViewChild('template') template!: TemplateRef<HTMLTemplateElement>;
  public form = new FormGroup({
    columnDate: new FormControl('',Validators.required)
  });
  public noResult = false;
  datePickerConfig: any;
  public position = true;
  minDate!: Date;
  maxDate!: Date;

  constructor(
    private modalservice: BsModalService,
    private editColumnDateModalService: EditColumnDateModalService,
    private appStateRepository: AppStateRepository,
  ) {}

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.editColumnDateModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.datePickerConfig = Object.assign(
          {},
          {
            containerClass: 'theme-dark-blue',
            adaptivePosition: this.position,
            isAnimated: true
          }
        );
        this.config = error as EditColumnDateEventModalConfig;
        this.form.reset();
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setDate(new Date().getDate() - 60);
        this.maxDate.setDate(new Date().getDate());
      });
    this.handler(this.modalservice.onShow,EditColumnDateDialogueEvent.OnShow);
    this.handler(this.modalservice.onShown,EditColumnDateDialogueEvent.OnShown);
    this.handler(this.modalservice.onHide,EditColumnDateDialogueEvent.OnHide);
    this.handler(this.modalservice.onHidden,EditColumnDateDialogueEvent.OnHidden);
  }

  handler(handler: EventEmitter<any>,type: EditColumnDateDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.editColumnDateModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  close(){
    this.modalRef.hide();
    this.form.reset();
  }

  private subscribeOverwrittenExisting() {
    this.editColumnDateModalService.onClosingEditColumnDateRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => this.modalRef.hide());
  }

  dateFormatter(params: any): string | any {
    return moment.utc(params).format('YYYY-MM-DD');
  }

  save() {
    let payLoad : saveColumnDatePayload[] = [];
    const statusCodeBasedOnDate = this.config.label === 'Actual Vessel Arrival' ? 'VA' : 'IG';
    this.config.data.forEach((obj:saveEditColumnDate) => {
      const data = {
        shipKey: obj.shipKey,
        containerNumber: obj.containerNumber,
        unLocationCode: obj?.data?.unLocCode ? obj?.data?.unLocCode : obj?.data?.dischargePortCode,
        statusCode: statusCodeBasedOnDate,
        statusDate: this.dateFormatter(this.form?.get('columnDate')?.value) +'T00:00:00Z'
      }
      payLoad.push(data)
    })
    this.editColumnDateModalService.setClosingEditColumnDate(payLoad);
  }
}


export interface saveEditColumnDate {
  shipKey: string,
  containerNumber: string,
  data: any,
  statusCode: string,
  statusDate: string
}

export interface saveColumnDatePayload {
  shipKey: string,
  containerNumber: string,
  unLocationCode: string,
  statusCode: string,
  statusDate: string
}