<ng-template #template>
    <div id="modalDrag" cdkDrag>
        <div class="modal-header title-pd" cdkDragHandle>
            <h3 class="modal-title">{{config.title}}</h3>
            <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
        </div>
        <div class="modal-body">
            <form class="row g-3" [formGroup]="changeReqForm">
                <div class="row pb-15">
                    <div class="col-md-6" *ngIf="!this.isDisableField.isDisableChangeFOB">
                        <div>
                            <label class="form-label">Change FOB</label>
                            <input type="text" class="form-control custom-date" id="inputAddress"
                                formControlName="changeFOB">
                        </div>
                        <div class="text-warning" *ngIf="fobErrorMsg">A Change Request for this FOB is already Pending.
                        </div>
                    </div>
                    <div class="col-md-6" *featurePermission="['poi-poc:action:po-change-request:showinitiatedby']">
                        <label for="initiatedBy" class="form-label">Initiated By</label>
                        <select class="form-select custom-date" id="initiatedBy" (change)="initiatedSelection($event)"
                            formControlName="initiatedBy">
                            <option *ngFor="let initiated of config.data1" [value]="initiated">{{initiated}}</option>
                        </select>
                    </div>
                </div>
                <div class="row pb-15">
                    <div class="col-md-6" *ngIf="!this.isDisableField.isDisableChangeShipDate">
                        <div>
                            <label class="form-label">Change Ship Date</label>
                            <input #input type="text" tabindex="1" formControlName="changeShipDate"
                                placeholder="Select Date" class="form-control custom-date" bsDatepicker
                                [bsConfig]="datePickerConfig">
                        </div>
                        <div class="text-warning" *ngIf="shipDateErrorMsg">A Change Request for this Ship Date is
                            already Pending.</div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <label class="form-label">Cancel Purchase Order</label>
                            <select class="form-select" id="cancel" formControlName="cancel">
                                <option value="False"></option>
                                <option value="True">Yes</option>
                            </select>
                        </div>
                        <div class="text-warning" *ngIf="cancelErrorMsg">A Change Request to cancel this Purchase Order
                            is already Pending.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="initiatedBy" class="form-label">Note</label>
                        <textarea class="note" formControlName="note"></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal"
                (click)="close()">{{config.btnTwo}}</button>
            <button type="button" class="btn btn-submit" (click)="onPocChangeRequestClick()"
                [disabled]="changeReqForm.invalid || (this.changeReqForm.get('changeFOB')?.value === '' && this.changeReqForm.get('changeShipDate')?.value === '' && this.changeReqForm.get('cancel')?.value === 'False')">{{config.btnOne}}</button>
        </div>
    </div>
</ng-template>