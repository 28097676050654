import { Injectable, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface NotficationModalEvent {
    type: EventType,
    modalRef: BsModalRef
}

export interface NotificationModalConfig {
    title: string,
    message: string,
    buttonText: string
}

export enum EventType {
    OnShow,
    OnShown,
    OnHide,
    OnHidden
}

@Injectable({ providedIn: 'root' })
export class NotificationModalService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private event$: Subject<NotficationModalEvent> = new Subject();
    private showSubject$ = new Subject();

    public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
    public onShow = this.event$.pipe(filter(x => x.type === EventType.OnShow));
    public onShown = this.event$.pipe(filter(x => x.type === EventType.OnShown));
    public onHide = this.event$.pipe(filter(x => x.type === EventType.OnHide));
    public onHidden = this.event$.pipe(filter(x => x.type === EventType.OnHidden));

    show(config: NotificationModalConfig): void {
        this.showSubject$.next(config);
    }

    handle(event: NotficationModalEvent): void {
        this.event$.next(event);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}