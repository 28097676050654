import moment from "moment";

export enum GridValueType {
    String = 'String',
    Date = 'Date',
}

/**
 * Ag Grid Value Formatter
 * 
 * null value will be formatted into `--`
 * 
 * @param params 
 * @param type 
 * @returns 
 */
export const GridValueFormatter = (
    params: any,
    type = GridValueType.String
) => {
    if (!params.value && params?.data?.childrenMapped?.mapped) {return ''}
    if (!params.value) { return ''; }
    switch(type){
        case GridValueType.String:
            return params.value
            break
        case GridValueType.Date:
            return moment.utc(params.value).format('MM/DD/YYYY')
            break
    }
}