<ng-template #AnalyticsScheduleModal>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-3" cdkDragHandle>
        <h2 class="modal-title m-0">Schedules</h2>
        <button (click)="close()" class="bg-transparent border-0 p-0">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <!-- Leaving this here in case we do wish to go with ag-grid in the future... -->
    <!-- <div class="modal-body pt-0 mt-4">
        <div class="dropdown">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                Bulk Actions
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><span class="dropdown-item" (click)="onDeleteClick()">Remove Schedule</span></li>
            </ul>
        </div>
        <div class="grid-wrapper ag-theme-alpine-dark tableHeight">
            <div class="card">
                <div class="card-body px-5 pb-4">
                    <ag-grid-angular [class]="gridClass | async" [rowData]="config.value" rowSelection="multiple"
                        [rowGroupPanelShow]="'always'" [suppressRowGroupHidesColumns]="true" [columnDefs]="columnDefs"
                        [gridOptions]="gridOptions" [enableRangeSelection]="true" [pagination]="true" [paginationPageSize]="50" [animateRows]="true"
                        [overlayLoadingTemplate]="loadingTemplate" [suppressDragLeaveHidesColumns]="true"
                        [suppressRowClickSelection]="true" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div> -->
    <div class="modal-body pt-0 mt-4">
        <div class="container-fluid">
            <div class="row">
                <table class="table table-dark table-striped table-borderless">
                    <thead class="text-purple">
                        <th>Name</th>
                        <th>Schedule Name</th>
                        <th>Next Run Time (EST)</th>
                        <th>Schedule Type</th>
                        <th>Schedule Details</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of config.value">
                            <td>{{c.reportName}}</td>
                            <td>{{c.scheduleName}}</td>
                            <td>{{c.nextRunTime}}</td>
                            <td>{{c.scheduleType}}</td>
                            <td>{{c.scheduleDetails}}</td>
                            <td>
                                <button class="bg-transparent border-0 p-0" (click)="onDeleteScheduleClick(c)">
                                    <span
                                        class="material-icons mi-24 text-danger align-middle">remove_circle_outline</span>
                                    <span class="visually-hidden">Close</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</ng-template>