import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { GetPurchaseOrderGQL, PoKeyInput, PurchaseOrder } from '@cds-ui/data-access';
import { catchError, map, of, throwError } from 'rxjs';
import { PoDetailPaneUpdate, PurchaseOrderUpdate } from '../purchase-order.map';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderApiService {
    constructor(private http: HttpClient, private apollo: Apollo) { }

    public getPurchaseOrder(poKey: PoKeyInput){
        return new GetPurchaseOrderGQL(this.apollo).fetch({poKey}, {fetchPolicy: 'no-cache'})
            .pipe(
                map(res => res.data.purchaseOrder as PurchaseOrder)
            )
    }
    public updatePoHeader(data: PoDetailPaneUpdate) {
        const purchaseOrder = data.purchaseOrderNumber;
        return this.http.patch(`/purchaseOrders/${purchaseOrder}`, data).pipe(
            map(x => x),
            catchError(err => {
                return of(null),
                    throwError(() => err)
            })
        );
    }
    public updatePoDetails(data: PurchaseOrderUpdate) {
        const purchaseOrder = data.purchaseOrderNumber;
        return this.http.patch(`/purchaseOrders/${purchaseOrder}/items`, data).pipe(
            map(x => x),
            catchError(err => {
                return of(null),
                    throwError(() => err)
            })
        );
    }

    public getOriginCountriesByCompany() {
        return this.http.get(`/purchaseOrders/getOriginCountriesByCompany`,).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 
    public getShipMethodList() {
        return this.http.get(`/purchaseOrders/getShipMethodListByCompany`).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 
    public getCurrencyCodes() {
        return this.http.get(`/purchaseOrders/getCurrencyCodes`).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 

    public getPoPaymentCodesByCompany() {
        return this.http.get(`/purchaseOrders/getPOPaymentCodesByCompany`).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 

    
    public getCartonsUOMByCompany() {
        return this.http.get(`/purchaseOrders/getCartonsUOMByCompany`).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 

    public getOriginPort(companyCode: string) {
        return this.http.get(`/internal/location/dcLocations?companyCode=${companyCode}&locationType=FOB_PORT,FOB`).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    }

    public getPoStatus() {
        return this.http.get(`/advancedSearch/getPoStatus`).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 
    public getVendorList(search: string) {
        const params = new HttpParams({fromString: `keyValue=${search}`});
        return this.http.get(`/advancedSearch/getVendorList`,{ params: params }).pipe(
            map(x => x),
            catchError(err => {
                return of([]),
                    throwError(() => err)
            })
        );
    } 

}