import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VibeMileStoneQueryService {
  public milestone = new Subject<any>();
  constructor(private http: HttpClient) {}

}
