import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filterNullish } from '@cds-ui/shared/core-rx';
import { BehaviorSubject, Observable, Observer, ReplaySubject, Subject, debounceTime, delay, distinctUntilChanged, filter, map, mergeMap, of, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { ToastrService } from 'ngx-toastr';
import { Currency, OriginCountries, OriginLocation, Payment, PoStatus, ShipMethod, VendorList, form, poPanel } from './detail-pane-edit-modal.type';
import { DetailPaneEditModalService } from './detail-pane-edit-modal-modal.service';
import moment from 'moment';
import { PurchaseOrderApiService } from '@cds-ui/purchase-order/domain-core';

@Component({
  selector: 'cds-ui-detail-pane-edit-modal',
  templateUrl: './detail-pane-edit-modal.component.html',
  styleUrls: ['./detail-pane-edit-modal.component.scss'],
})
export class DetailPaneEditModalComponent implements OnInit, OnDestroy {
  @ViewChild('template') templateRef!: TemplateRef<HTMLDivElement>;
  public isLoading$ = this.appState.isLoading$;
  private modalRef$$ = new ReplaySubject<BsModalRef>(1);
  private destroy$$ = new Subject<void>();
  private isSaving$$ = new Subject<boolean>();
  public paneType$$ = new BehaviorSubject<string>('');
  vendorList$!: Observable<VendorList[]>;
  public poPanelTypes = poPanel;
  search?: string;
  
  private paneData$ = this.editModalService.paneData$.pipe(
    takeUntil(this.destroy$$),
    filterNullish(),
    shareReplay(1)
  )
  public paneType$ = this.paneData$.pipe(
    takeUntil(this.destroy$$),
    tap(x=> this.paneType$$.next(x.type)),
   ).subscribe();

  public data$ = this.paneData$.pipe(map(x=> ({...x.data, shipMode: x?.data?.shipMode?.code as never })), shareReplay(1));


  public shipMethodList$ = this.poService.getShipMethodList().pipe(
    map(x=> x as ShipMethod[]),
    map(x=> x.map(y=> ({ text: y.transportTypeName, value: y.transportTypeCode }))),
  
  )
  public statusList$ = this.poService.getPoStatus().pipe(
    map(x=> x as PoStatus[]),
    map(x=> x.map(y=> ({ text: y.statusName, value: y.statusCode }))),
  )
  
  public shipOriginCountryList$ = this.poService.getOriginCountriesByCompany().pipe(
    map(x=> x as OriginCountries[]),
    map(x=> x.map(y=> ({text: y.countryName, value: y.countryCode}))),
)
  public paymentCodes$ = this.poService.getPoPaymentCodesByCompany().pipe(
    map(x=> x as Payment[]),
    map(x=> x.map(y=> ({text: y.paymentCode, value: y.paymentCode}))),
  )
  public currenctCodes$ = this.poService.getCurrencyCodes().pipe(
    map(x => x as Currency[]),
    map(x=> x.map(y=> ({text: y.currencyCode, value: y.currencyCode}))),
  )
   public originPortList$ = this.data$.pipe(
    map(x=> x.company.companyCode),
    mergeMap(x=> this.poService.getOriginPort(x)),
    map(x => x as OriginLocation[]),
    map(x=> x.map(y=> ({ text: y.locationName, value: y.locationName }))),
   )

  public form = form;
 
  datePickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue', adaptivePosition: true, isAnimated: true });

  constructor(
    private appState: AppStateRepository,
    private modalservice: BsModalService,
    private toastrService: ToastrService,
    private editModalService: DetailPaneEditModalService,
    private poService: PurchaseOrderApiService
  ) {}
  
  ngOnInit(): void {
    this.subscribeBindFormSingal();
    this.subscribeShowSingal();
    this.subscribeHideSingal();
    this.searchQuery()
  }


  onVendorSelect(event: any) {
    event && this.form.controls.vendor.patchValue(event.item.vendor, { onlySelf: true,  emitEvent: false});
    event && this.form.controls.vendorCode.patchValue(event.item.vendorCode);
   }
   
  searchQuery(){
    this.vendorList$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.search);
    }).pipe(
      filter(x=> !!x.length && x.length > 2),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((x: string) => this.poService.getVendorList(x)),
      map(x=> x as VendorList[]),
      map(x=> x.map(y=> ({...y, vendor : `${y.vendorCode} - ${y.vendorName}`})))
    );
  }
 replaceDashesWithSpacesAndCapitalize(input: string): string {
    return input.split('-').map(word =>         
            word.charAt(0).toUpperCase() + word.slice(1)  
        ).join(' ');            
}
  private subscribeBindFormSingal() {
    this.editModalService.onShow$
      .pipe(
        takeUntil(this.destroy$$),
        withLatestFrom(this.data$),
        filterNullish(),
        delay(200),
        map(([_, x])=> ({...x, buyerCode: x?.buyer?.buyerName, 
          revisedShipDate: this.dateFormat(x.revisedShipDate), 
          poCancelledDate : this.dateFormat(x.poCancelledDate),
          vendor : x?.vendor ? `${x?.vendor?.vendorCode} - ${x?.vendor?.vendorName}` : null,
          vendorCode: x?.vendor?.vendorCode,
        })),
        tap(x => {
          this.form.patchValue(x as never )
        })
      )
      .subscribe();
  }

  private subscribeShowSingal() {
    this.editModalService.onShow$
      .pipe(
        takeUntil(this.destroy$$),
        map((_) =>
          this.modalservice.show(this.templateRef, {
            backdrop: false,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-lg',
          })
        ),
        tap((x) => this.modalRef$$.next(x))
      )
      .subscribe();
  }

  public onSave() {
    const values = this.form.getRawValue()
    of(values).pipe(
      filter(x=> !!x),
      map(x=> ({
        ...x,
        poCancelledDate: this.dateFormatSave(x?.poCancelledDate),
        revisedShipDate: this.dateFormatSave(x?.revisedShipDate),
        firstShipDate: this.dateFormatSave(x?.firstShipDate),
        lastShipDate: this.dateFormatSave(x?.lastShipDate),
        inWarehouseDate: this.dateFormatSave(x?.inWarehouseDate),
      })),
      withLatestFrom(this.data$),
      switchMap(([x, data]) => this.poService.updatePoHeader({
        ...x,
        purchaseOrderNumber: data?.poNumber,
        companyCode: data?.company?.companyCode,
      } as never )),
      tap({
        next:(()=>  {
          setTimeout(()=>{
            this.editModalService.refreshPoHeaderSignels$$.next()
            this.toastrService.success("Details updated successfully.");
          },1000)
          this.onCancel();
            }),
            error:(()=> this.toastrService.error("Details update unsuccessful."))
          })
    ).subscribe()
  }

  dateFormat(params: string): string | null {
    return params && params?.indexOf('--') == -1 ?  moment.utc(params).format('MM/DD/YYYY') : null;
  }

  dateFormatSave(params: string): string | null {
    const formatedDate = params && typeof params === 'object'  ? moment(params).format('MM/DD/YYYY') : params && params?.indexOf('--') == -1 ? moment(params).format('MM/DD/YYYY') : null;
    return formatedDate  ? moment.utc(formatedDate?.toLocaleString()).format('YYYY-MM-DD') + 'T00:00:00Z' : null;
  }

  public onCancel() {
    this.editModalService.cancel();
  }

  private subscribeHideSingal() {
    this.editModalService.onHide$
      .pipe(
        takeUntil(this.destroy$$),
        withLatestFrom(this.modalRef$$),
        tap(([_, ref]) => ref.hide()),
      )
      .subscribe();
  }
  
  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

}
