import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface ReviewEventModalConfig {
    title: string,
    message?: string,
    btnOne:string,
    btnTwo:string
}

export enum ReviewDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface ReviewModalEvent {
    type: ReviewDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class ReviewModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<ReviewModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === ReviewDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === ReviewDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === ReviewDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === ReviewDialogueEvent.OnHidden));
  public onClosingReviewRemove$ = this.event$.pipe(filter((x) => x.type === ReviewDialogueEvent.OnClosingRemove));
  public onClosingReviewRemoved$ = this.event$.pipe(filter((x) => x.type === ReviewDialogueEvent.OnClosingRemoved));

  show(config: ReviewEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: ReviewModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingReview(params?: any) {
    this.event$.next({
      type: ReviewDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingReviewRemoved() {
    this.event$.next({
      type: ReviewDialogueEvent.OnClosingRemoved,
    });
  }
}