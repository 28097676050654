import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface PocWithdrawalEventModalConfig {
    title: string,
    message?: string,
    data:any;
    count?:any,
    btnOne:string,
    btnTwo:string
}

export enum PocWithdrawalDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface PocWithdrawalModalEvent {
    type: PocWithdrawalDialogueEvent,
    params?: any,
    note?: any
}

@Injectable({ providedIn: 'root' })

export class PocWithdrawalModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<PocWithdrawalModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public noteText = "";

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === PocWithdrawalDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === PocWithdrawalDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === PocWithdrawalDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === PocWithdrawalDialogueEvent.OnHidden));
  public onClosingPocWithdrawalRemove$ = this.event$.pipe(filter((x) => x.type === PocWithdrawalDialogueEvent.OnClosingRemove));
  public onClosingPocWithdrawalRemoved$ = this.event$.pipe(filter((x) => x.type === PocWithdrawalDialogueEvent.OnClosingRemoved));

  show(config: PocWithdrawalEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: PocWithdrawalModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingPocWithdrawal(params?: any, note?: any) {
    this.event$.next({
      type: PocWithdrawalDialogueEvent.OnClosingRemove,
      params: params,
      note: note
    });
  }

  setClosingPocWithdrawalRemoved() {
    this.event$.next({
      type: PocWithdrawalDialogueEvent.OnClosingRemoved
    });
  }
}