<div class="w-100 text-center" style="height:100%;">
    <button (click)="btnClickedHandler($event)" [disabled]="(isLoading$ | async)" class="bg-transparent border-0 p-0">
        
        <span aria-hidden="true" class="material-icons  text-secondary" style="vertical-align: middle;"
            *ngIf="(buttonType === 'removeItem') && (isSaveLoading$ | async) === false">delete_forever</span>
            
        <span  aria-hidden="true" class="material-icons mi-28 text-white" style="vertical-align: middle;"
            *ngIf="(buttonType === 'addItem') && (isSaveLoading$ | async) === false">add_circle</span>
        <i class="spinner-grow spinner-grow-sm" style="margin-left:3px;" role="status" *ngIf="isSaveLoading$ | async"></i>
    </button>
</div>