import { OperatorFunction } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ColDef, GridReadyEvent } from '@ag-grid-community/core';

export function hideChildSelectionOnGroupBy(): OperatorFunction<GridReadyEvent<any>, ColDef[]> {
    return source$ => source$.pipe(
        debounceTime(10),
        map(gridReady => gridReady?.api as any),
        map(api => {
            const columnDefs: ColDef[] = api?.getColumnDefs() ?? [];
            const groupedData = api?.getModel()?.rowsToDisplay?.some((node: any) => node?.group ?? false);
            return columnDefs?.map((colDef: ColDef) => ({
                ...colDef,
                hide: !!colDef.headerCheckboxSelection && groupedData
            })) as ColDef[];
        }),
    )
}