<ng-container *ngIf="(bookingStatusActivityData$ | async) as bookingStatusActivityData">
<!--BOOKING ACTIVITY-->
<div class="card">
  <div class="card-header bg-transparent border-dark border-2 mx-5 px-0 pt-4">
    <div class="d-flex align-items-center">
    <h2 class="m-0">Activity and Notes</h2>
    <span class="badge border border-primary rounded-pill mx-2 fw-normal">{{ bookingStatusActivityData?.length }}</span>
    </div>
  </div>
  <div class="card-body px-5 pb-4" *ngIf="(bookingStatusActivityData?.length ?? 0) > 0">
    <div class="message-cards overflow-auto">
    <div *ngFor="let m of bookingStatusActivityData; odd as isOdd; even as isEven; count as count" [ngClass]="{'bg-gray-700': isEven, 'bg-gray-600': isOdd }" class="card message-card"  >
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="fs-sm fw-bold mb-2 w-25">{{m?.status}}</h5>
        <div class="fs-sm w-25">{{m?.user}}</div>
        <div class="fs-sm font-monospace">{{getFormattedDateTime(m.activityDate)}}</div>
      </div>
      <p class="message-card-message fs-sm mt-2 mb-0">{{m?.comments}}</p>
    </div>
    </div>
    </div>
    <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700" *ngIf="!(isLoading$ | async)  &&  ((bookingStatusActivityData?.length ?? 0) === 0)">
      <p>
        <span class="material-icons mi-64 text-purple">
          <span class="material-icons-outlined">cloud_off</span>
        </span>
      </p>
      <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p></div>
  </div>
  <!--//BOOKING ACTIVITY-->
</ng-container>