import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface EditColumnDateEventModalConfig {
    title: string,
    label?: string,
    message?: string,
    data:any;
    count?:any,
    btnOne:string,
    btnTwo:string,
}

export enum EditColumnDateDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

export interface EditColumnDateModalEvent {
    type: EditColumnDateDialogueEvent,
    params?: any,
    data?: any
}

@Injectable({ providedIn: 'root' })

export class EditColumnDateModalService implements OnDestroy {

  constructor(private http: HttpClient) { }

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<EditColumnDateModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === EditColumnDateDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === EditColumnDateDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === EditColumnDateDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === EditColumnDateDialogueEvent.OnHidden));
  public onClosingEditColumnDateRemove$ = this.event$.pipe(filter((x) => x.type === EditColumnDateDialogueEvent.OnClosingRemove));
  public onClosingEditColumnDateRemoved$ = this.event$.pipe(filter((x) => x.type === EditColumnDateDialogueEvent.OnClosingRemoved));

  show(config: EditColumnDateEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: EditColumnDateModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingEditColumnDate(params?: any, data?: any) {
    this.event$.next({
      type: EditColumnDateDialogueEvent.OnClosingRemove,
      params: params,
      data: data
    });
  }

  setClosingColumnDateRemoved() {
    this.event$.next({
      type: EditColumnDateDialogueEvent.OnClosingRemoved
    });
  }
}