import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, ReplaySubject, tap, takeUntil, map } from 'rxjs';
import { Location } from '@angular/common';
import { ApolloError } from '@apollo/client/errors';

@Component({
  selector: 'cds-ui-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnDestroy {
  constructor(
    private location:Location
  ) { }

  private destory$$ = new Subject<void>();

  public errorMessage$$ = new ReplaySubject<ApolloError>();

  @Input() set error$(stream: Observable<ApolloError>) {
    stream.pipe(
      takeUntil(this.destory$$),
      tap(x => this.errorMessage$$.next(x))
    )
    .subscribe();
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.destory$$.next();
    this.destory$$.complete();
  }


}
