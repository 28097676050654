import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { VesselViewmoreDialogueEvent, VesselViewmoreEventModalConfig, VesselViewmoreModalService } from './vessel-viewmore-modal.service';
// import { PageRemoveShippingMarksDialogueEvent, PageRemoveShippingMarksEventModalConfig, PageRemoveShippingMarksModalService,  } from './page-remove-shipping-marks-modal.service';

@Component({
  selector: 'cds-ui-vessel-viewmore-modal',
  templateUrl: './vessel-viewmore-modal.component.html',
  styleUrls: ['./vessel-viewmore-modal.component.scss'],
})
export class VesselViewmoreModalComponent implements OnInit {
  
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: VesselViewmoreEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private VesselViewmoreModalService: VesselViewmoreModalService,
    private appStateRepository: AppStateRepository
  ) {}

  ngOnInit(): void {}  

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.VesselViewmoreModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as VesselViewmoreEventModalConfig;
        
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered modal-xl'
        });
      });

    this.handler(
      this.modalservice.onShow,
      VesselViewmoreDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      VesselViewmoreDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      VesselViewmoreDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      VesselViewmoreDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: VesselViewmoreDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.VesselViewmoreModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onVesselViewmoreClick() {
    this.VesselViewmoreModalService.setClosingVesselViewmore(this.config);
  }

  sidePanelDateFormat(value: any): string | any {
    if (value) {
      return moment.utc(value.toLocaleString()).format("MM/DD/YYYY");
    }
  }

  private subscribeOverwrittenExisting() {
    this.VesselViewmoreModalService.onClosingVesselViewmoreRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}
