export interface ColumnItem {
  displayName: string;
  route: [moduleCd: string, configName: string, url: string];
}

export interface DashboardTemplateConfig {
  templateName: string;
  formName: string;
  pageName: string;
  content: FavoriteColumn;
}

export interface Favorites {
  column: [FavoriteColumn];
}

export interface FavoriteColumn {
  sequence: number;
  columnHeader: string;
  columnItem: [ColumnItem];
}

export interface StaticCards {
  Id: string;
  count: number;
  information: string;
  percentage: number;
}

export interface WidgetData {
  Id: string;
  Title: string;
  NewTitle: string;
}

export interface Message {
  createdBy: string;
  sendersDate: string;
  messageSubject: string;
  messageTxt: string;
}