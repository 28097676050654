import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Observable, withLatestFrom, Subject, catchError, combineLatest, combineLatestWith, debounceTime, filter, iif, map, of, shareReplay, switchMap, take, takeUntil, tap, ReplaySubject, share } from "rxjs";
import { AgGridEvent, ColDef, ColumnApi, ColumnMovedEvent, ColumnPinnedEvent, ColumnRowGroupChangedEvent, ColumnVisibleEvent, GridApi, GridOptions, RowDataUpdatedEvent, GridReadyEvent, PaginationChangedEvent, GridSizeChangedEvent, ColumnResizedEvent, SortChangedEvent } from '@ag-grid-community/core';
import { DomainModelSetting, QueryDomainModelSettingsGQL } from "@cds-ui/data-access";
import { AgGridAngular } from '@ag-grid-community/angular';
import { Apollo } from "apollo-angular";
import { AppStateRepository } from "@cds-ui/shared/core-state";
import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { distinctUntilArrayItemChanged } from "@ngneat/elf";
import { agGridOptionsProxy } from "./ag-grid-options-proxy";
import { AgGridCustomToolbarComponent } from "./ag-grid-custom-toolbar/ag-grid-custom-toolbar.component";
import { defaultPaginationChanged } from "../settings/default-grid-options";
import _ from "lodash";
import { defaultColumns , EventSourceType} from "./ag-grid-custom-default-columns";

export interface CustomGridOptions extends GridOptions { }

export interface CustomColDef extends ColDef {
    domainKey?: string[] | string | undefined | null;
}

@Directive({
    selector: "ag-grid-angular"
})
export class AgGridCustomDirective implements AfterViewInit, OnInit, OnDestroy {
    private destroy$$ = new Subject<void>();
    private gridEvent$$ = new Subject<{ type: string, event: AgGridEvent, updatePerference?: boolean }>();
    private gridApi$$ = new ReplaySubject<{ gridApi: GridApi, columnApi: ColumnApi }>(1);
    private initColumnDefination$$ = new ReplaySubject<CustomColDef[]>(1);

    private path = this.location.path();
    private pageName = this.path.split('/')
                                .slice(0, (this.path.split('/').indexOf('view') + 1) || this.path.split('/').length)
                                .filter(x => x)
                                .join('-');

    private colDefUserPreference$$ = new ReplaySubject<CustomColDef[]>(1);
    private filterName$$ = new BehaviorSubject<string | null | undefined>(null);
    private preventDefaultPaginationChange$$ = new BehaviorSubject<boolean>(false);
    private uiColResizeEvent$$ = new BehaviorSubject<boolean>(false);
    private eventType$$ = new BehaviorSubject<string>('');
    private gridName$$ = new BehaviorSubject<string | null>(null);
    private hideRestoreButton$$ = new BehaviorSubject<boolean>(false);
    private domainModelInactived$$ = new BehaviorSubject<string[]>([]);
    private domainModelLocalCache$$ = new BehaviorSubject<{ comp: string[], value: DomainModelSetting[] }>({ comp: [], value: [] });
    @Output() overRideResetUserPreferenceEvent = new EventEmitter<any>();
    private cacheRoute = `/user/preferences/page/${this.pageName}`;

    private lrm$ = this.appState.lrmData$;

    private initColumnDefination$ = this.initColumnDefination$$.pipe(
        shareReplay(1),
        map(x => _.cloneDeep(x))
    );

    private gridId$ = combineLatest([this.gridName$$, this.filterName$$]).pipe(
        filter(([gridName, _]) => !!gridName),
        distinctUntilArrayItemChanged(),
        map(([gridName, filterName]) => `${this.toKebabCase(gridName ?? '')}:${this.toKebabCase(filterName ?? 'default')}`),
        shareReplay(1)
    );

    private domainModelSetting$ = this.initColumnDefination$.pipe(
        takeUntil(this.destroy$$),
        filter(x => !!x.length),
        map(x => x.map(c => c.domainKey).flat() as string[]),
        map(x => x.map(c => c.slice(0, c.indexOf(':')))),
        map(x => [...new Set(x)]),
        withLatestFrom(this.appState.activeCompany$.pipe(map(x => x.map(c => c.companyCode))), this.domainModelLocalCache$$),
        switchMap(([x, comp, cache]) => iif(
            () => !!x.length,
                iif(() => _.isEqual(cache.comp.sort(), comp.sort()),
                    this.domainModelLocalCache$$.pipe(map(x => x.value)),
                    new QueryDomainModelSettingsGQL(this.apollo).fetch({ filter: x }).pipe(
                        map(x => x.data.domainSetting as DomainModelSetting[]),
                        tap(x => this.domainModelLocalCache$$.next({comp: comp, value: x }))
                    ),
                ),
            of([])
        ))
    );

    private localUserPreferenceCache$ = of(sessionStorage.getItem('GridUserPreference')).pipe(
        map(x => JSON.parse(x ? x : '{}') as { [gridName: string]: string }),
        map(x => {
            Object.entries(x).forEach(([key, value]) => {
                x[key] = JSON.parse(value);
            });

            return x;
        }),
        catchError(err => of({})),
        map(x => x as unknown as { [gridName: string]: CustomColDef[] }),
        shareReplay(1)
    );

    private serverUserPreference$ = this.http.get<{ [gridName: string]: string }>(this.cacheRoute).pipe(
        map(x => {
            Object.entries(x).forEach(([key, value]) => {
                x[key] = JSON.parse(value);
            });

            return x as unknown as { [gridName: string]: CustomColDef[] };
        }),
        shareReplay(1)
    );

    private colDefUserPreference$ = combineLatest([this.localUserPreferenceCache$, this.gridId$]).pipe(
        takeUntil(this.destroy$$),
        switchMap(([x, gridName]) => iif(() => !!x[gridName], of(x), this.serverUserPreference$)),
        map(x => x as { [gridName: string]: CustomColDef[] }),
        combineLatestWith(this.initColumnDefination$, this.gridId$, this.lrm$),
        map(([x, initCol, gridId, lrm]) => {
            const lrmColDef = this.columnDefLrmCompare(initCol,lrm);
            const initColDef = this.columnDefToUserPreference(lrmColDef);
            const availbleCol = initColDef.map(c => c.field);
            const userPreference = x[gridId]?.filter(c => availbleCol.includes(c.field)) ?? initColDef;

            x[gridId] = _.assignIn(initColDef, userPreference);
            return x[gridId];
        })
    );

    private applyDomainSetting$ = combineLatest([this.domainModelSetting$, this.initColumnDefination$, this.colDefUserPreference$, this.appState.availableCompanies$.pipe(map(x => x.map(c => c.companyCode)))]).pipe(
        take(1),
        map(([setting, colDef, userPreference, activeCompanies]) => {
            const isActive = setting.filter(c => (c.isActive && !_.intersection(activeCompanies, c.companyDomainConfigSettings.isInactive).length)
                || (!c.isActive && _.intersection(activeCompanies, c.companyDomainConfigSettings.isActive).length))
                .map(x => x.domainKey);
            const isInactive = setting.filter(c => (!c.isActive && !_.intersection(activeCompanies, c.companyDomainConfigSettings.isActive).length)
                || (c.isActive && _.intersection(activeCompanies, c.companyDomainConfigSettings.isInactive).length))
                .map(x => x.domainKey);

            const isInactiveDef = colDef.filter(x => _.intersection(isInactive, x.domainKey).length).map(x => ({ ...x, hide: true, suppressColumnsToolPanel: true }));
            const isActiveDef = colDef.filter(x => _.intersection(isActive, x.domainKey).length).map(x => ({ ...x, hide: false, suppressColumnsToolPanel: false }));
            const isInactiveDefFields = isInactiveDef.map(x => x.field);

            const updatedDef = isInactiveDef.concat(isActiveDef);

            const updatedDefDict = Object.fromEntries(updatedDef.map(x => [x.field, x]));
            const userPerfDict = Object.fromEntries(userPreference.map(x => [x.field, x]));

            const updatedProp = colDef.map(x => updatedDefDict[x.field ?? Math.random()] ?? x)
                .map(x => isInactiveDefFields.includes(x.field)
                    ? x
                    : _.assignInWith(x, userPerfDict[x.field ?? Math.random()] ?? {}, (o, s) => s ?? o)
                );

            return {
                inactived: isInactiveDef.filter(x => x.field).map(x => x.field as string),
                colDef: _.sortBy(updatedProp, x => _.findIndex(Object.keys(userPerfDict), y => x.field === y))
            }

        }),
        combineLatestWith(this.gridApi$$),
        tap(([def, grid]) => grid.gridApi.setColumnDefs(_.cloneDeep(def.colDef))),
        tap(([def, _]) => this.domainModelInactived$$.next(def.inactived))
    );

    @Input() set gridName(name: string | Observable<string>) {
        if (name instanceof Object) {
            name.pipe(
                takeUntil(this.destroy$$),
                tap(x => this.gridName$$.next(x))
            ).subscribe();
        }
        else {
            this.gridName$$.next(name);
        }
    };

    @Input() set hideRestoreButton$(value: boolean | Observable<boolean>) {
        if (value instanceof Observable) {
            value.pipe(
                takeUntil(this.destroy$$),
                tap(x => this.hideRestoreButton$$.next(x))
            ).subscribe();
        }
        else {
            this.hideRestoreButton$$.next(value);
        }
    };

    @Input() set filterName(name: string | Observable<string> | null | undefined) {
        if (name instanceof Object) {
            name.pipe(
                takeUntil(this.destroy$$),
                tap(x => this.filterName$$.next(x))
            ).subscribe();
        }
        else {
            this.filterName$$.next(name);
        }
    };
    @Input() set preventDefaultPaginationChange$(value: boolean | Observable<boolean>) {
        if (value instanceof Observable) {
            value.pipe(
                takeUntil(this.destroy$$),
                tap(x => this.preventDefaultPaginationChange$$.next(x))
            ).subscribe();
        }
        else {
            this.preventDefaultPaginationChange$$.next(value);
        }
    };

    //#region ag-grid event proxy override
    onColumnVisibleProxy(event: ColumnVisibleEvent, originalFn: Function) {
        originalFn();

        this.gridEvent$$.next({ type: event.type, event: event, updatePerference: true });
    }

    onColumnPinnedProxy(event: ColumnPinnedEvent, originalFn: Function) {
        originalFn();
        this.gridEvent$$.next({ type: event.type, event: event, updatePerference: true });
    }

    onColumnMovedProxy(event: ColumnMovedEvent, originalFn: Function) {
        originalFn();
        this.gridEvent$$.next({ type: event.type, event: event, updatePerference: true });
    }

    onColumnRowGroupChangedProxy(event: ColumnRowGroupChangedEvent, originalFn: Function) {
        originalFn();
        this.gridEvent$$.next({ type: event.type, event: event, updatePerference: true });
    }

    onSortChangedProxy(event: SortChangedEvent, originalFn: Function) {
        originalFn();
        this.gridEvent$$.next({ type: event.type, event: event, updatePerference: true });
    }

    onRowDataUpdatedProxy(event: RowDataUpdatedEvent, originalFn: Function) {
        event.columnApi.autoSizeColumns(
            event.columnApi.getColumns()?.map(c => c.getColId()) ?? [],
            false
        );
        originalFn();
        this.gridEvent$$.next({ type: event.type, event: event });
    }
    onColumnResizedProxy(event: ColumnResizedEvent, originalFn: Function){
        this.eventType$$.next(event.type);
        const uiColResize =  !!(event.source == EventSourceType.uiColumnResized);
        uiColResize && this.uiColResizeEvent$$.next(uiColResize)
        originalFn()
}

    onGridReadyProxy(event: GridReadyEvent, originalFn: Function) {
        const columnDef = ((event.api.getColumnDefs() ?? []) as CustomColDef[])
            .map(c => ({
                ...c,
                hide: c.hide ?? false,
                domainKey: !c.domainKey
                    ? []
                    : Array.isArray(c.domainKey)
                        ? c.domainKey
                        : [c.domainKey]
            }));
        this.gridApi$$.next({ gridApi: event.api, columnApi: event.columnApi });
        this.initColumnDefination$$.next(columnDef);

        originalFn();
    }

    onPaginationChangedProxy(event: PaginationChangedEvent, originalFn: Function) {
        this.eventType$$.next(event.type);
        combineLatest([
            this.uiColResizeEvent$$.pipe(debounceTime(50)),
            this.preventDefaultPaginationChange$$
        ])
        .pipe(
             filter(([r,p])=> ((!r || !p) && (this.eventType$$?.value == EventSourceType.paginationChanged))),
              tap(_=> defaultPaginationChanged(event)),
         ).subscribe()

         originalFn()
    }
    //#endregion

    //#region angular component lifecycle
    ngOnInit(): void {
        this.setupGrid();
        this.setupToolbar();

        this.loadUserPreference();
        this.listenToApplyDomainSetting();
        this.listenGridEventForPreferenceUpdate();
        this.listenUserPreferenceUpdated();
    }

    ngAfterViewInit(): void {
        const gridOptionsProps = Object.keys(agGridOptionsProxy) as (keyof GridOptions)[];

        gridOptionsProps.forEach((key) => {
            const proxyFn = Object.getPrototypeOf(this)[`${key}Proxy`];

            if (proxyFn && typeof proxyFn === 'function') {
                const proxyGridOptions = (this.grid.gridOptions as any)[key];
                (this.grid.gridOptions as any)[key] =
                    (e: any) => proxyFn.call(this, e, () => { if (proxyGridOptions) proxyGridOptions(e) });
            }
        });

        (this.grid.gridOptions as any)["defaultColDef"] = defaultColumns;
    }


    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }
    //#endregion

    //#region custom functions
    loadUserPreference(): void {
        this.colDefUserPreference$.pipe(
            takeUntil(this.destroy$$),
            take(1),
            tap(x => this.colDefUserPreference$$.next(x))
        ).subscribe();
    }

    setupGrid(): void {
        this.gridApi$$.pipe(
            takeUntil(this.destroy$$),
            take(1),
            tap(grid => grid.gridApi.setColumnDefs([]))
        ).subscribe();

        this.gridName$$.pipe(
            takeUntil(this.destroy$$),
            switchMap(x => iif(() => !x,
                of(`${this.pageName}-grid-${this.findIndex()}`),
                of(x)
            )),
            tap(x => this.gridName$$.next(x))
        ).subscribe();
    }

    listenToApplyDomainSetting(): void {
        combineLatest([
            this.gridId$,
            this.appState.activeCompany$.pipe(map(x => x.map(c => c.companyCode)), distinctUntilArrayItemChanged()),
            this.gridEvent$$.pipe(filter(x => x.type === 'rowDataUpdated'))
        ]).pipe(
            takeUntil(this.destroy$$),
            map(([x]) => x),
            switchMap(_ => this.applyDomainSetting$)
        )
            .subscribe();
    }

    listenGridEventForPreferenceUpdate(): void {
        this.gridEvent$$.pipe(
            takeUntil(this.destroy$$),
            debounceTime(500),
            filter(x => !!x.updatePerference),
            map(x => x.event.api.getColumnDefs() ?? [] as CustomColDef[]),
            filter(x => !!x.length),
            map(x => this.columnDefToUserPreference(x)),
            tap(x => this.colDefUserPreference$$.next(x))
        )
            .subscribe();
    }

    setupToolbar(): void {
        const componentRef = this.viewContainerRef.createComponent(AgGridCustomToolbarComponent);

        this.gridId$.pipe(
            takeUntil(this.destroy$$),
            tap(x => componentRef.instance.gridId = x))
            .subscribe();

        componentRef.instance.resetUserPreferenceEvent.pipe(
            takeUntil(this.destroy$$),
            switchMap(x => this.http.delete(this.cacheRoute).pipe(map(_ => x))),
            tap(x => x.hide()),
            withLatestFrom(this.initColumnDefination$, this.gridApi$$),
            tap(([_, __, grid]) => grid.gridApi.setFilterModel(null)),
            tap(([_, __, grid]) => grid.gridApi.onFilterChanged()),
            tap(([_, __, grid]) => grid.columnApi.setRowGroupColumns([])),
            tap(([_, __, grid]) => grid.columnApi.resetColumnState()),
            map(([__, initCol]) => initCol.map((x: CustomColDef) => ({
                ...x,
                rowGroup: _.isUndefined(x.rowGroup) || _.isNull(x.rowGroup)
                    ? false
                    : x.rowGroup
            })
            )),
            tap(x => this.colDefUserPreference$$.next(x)),
            tap(x=>this.overRideResetUserPreferenceEvent.emit(true)),
            switchMap(_ => this.applyDomainSetting$)
        ).subscribe();

        const host = this.element.nativeElement;
        this.renderer.insertBefore(
            host.parentElement.parentElement.parentElement,
            componentRef.location.nativeElement,
            host.parentElement.parentElement
        );

        this.hideRestoreButton$$.pipe(
            takeUntil(this.destroy$$),
            tap(x => componentRef.instance.hideRestoreButton = x))
            .subscribe();
    }

    listenUserPreferenceUpdated() {
        this.colDefUserPreference$$
            .pipe(
                takeUntil(this.destroy$$),
                distinctUntilArrayItemChanged(),
                combineLatestWith(this.localUserPreferenceCache$, this.domainModelInactived$$, this.gridId$),
                map(([activeGridCache, localUserPreferenceCacheStore, inactivedByDomainSetting, gridId]) => {
                    const hasLocalCache = !!localUserPreferenceCacheStore[gridId]?.length;
                    const existingLocalCache = _.cloneDeep(localUserPreferenceCacheStore) ?? {};
                    const existingGridLocalCache = _.cloneDeep(localUserPreferenceCacheStore[gridId]) ?? [];

                    if (hasLocalCache) {
                        activeGridCache = activeGridCache.map(x => inactivedByDomainSetting.includes(x.field ?? Math.random().toString())
                            ? existingGridLocalCache.find(c => x.field === c.field)
                            ?? x
                            : x) as CustomColDef[];
                    }

                    localUserPreferenceCacheStore[gridId] = activeGridCache;

                    const activeResult = _.reduce(
                        Object.keys(localUserPreferenceCacheStore),
                        (result, value, key) => {
                            result[value] = JSON.stringify(activeGridCache);
                            return result;
                        },
                        {} as { [gridName: string]: string }
                    )

                    const prevResult = _.reduce(
                        Object.keys(existingLocalCache),
                        (result, value) => {
                            result[value] = JSON.stringify(existingLocalCache[value]);
                            return result;
                        },
                        {} as { [gridName: string]: string }
                    )

                    return {
                        updatedJsonFormat: activeResult,
                        prevJsonFormat: prevResult
                    };
                }),
                tap(x => sessionStorage.setItem('GridUserPreference', JSON.stringify(x.updatedJsonFormat))),
                debounceTime(1000),
                filter(x => !_.isEqual(x.prevJsonFormat, x.updatedJsonFormat)),
                tap(x => this.appState.ghostedUserPreferences$.next({
                    path: `pages/${this.pageName}`,
                    data: x.updatedJsonFormat
                  })),
                switchMap(x => this.http.post(this.cacheRoute, x.updatedJsonFormat))
            )
            .subscribe();
    }

    columnDefToUserPreference(col: CustomColDef[]) {
        return col.map(c => ({
            headerName: c.headerName,
            field: c.field,
            rowGroup: c.rowGroup,
            rowGroupIndex: c.rowGroupIndex,
            hide: c.hide,
            pinned: c.pinned,
            sort:c.sort,
            sortIndex:c.sortIndex,
            sortingOrder:c.sortingOrder
        }))
    }

    columnDefLrmCompare(initCol: CustomColDef[], lrm: { findDisplay: any; replaceDisplay: any; }) {
        return _.forEach(initCol, x => {
            const colDef:any = _.find(lrm, y => y.findDisplay === x.headerName);
            if(colDef) {
              x.headerName = colDef.replaceDisplay;
            }
        });
    }

    toKebabCase(input: string | null) {
        return !input
            ? input
            : input.trim()
                .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                ?.join('-')
                .toLowerCase() ?? input.trim().toLowerCase();
    }

    findIndex() {
        const host = this.element.nativeElement;
        const id = Math.random().toString();
        host.setAttribute("custom-anchor-id", id);

        const collection = document.getElementsByTagName(host.tagName);

        for (let i = 0; i < collection.length; i++) {
            if (collection[i].getAttribute("custom-anchor-id") === id)
                return i
        }

        return -1;
    }
    //#endregion

    constructor(
        private readonly grid: AgGridAngular,
        private readonly apollo: Apollo,
        private readonly appState: AppStateRepository,
        private readonly http: HttpClient,
        private readonly location: Location,
        private readonly element: ElementRef,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly renderer: Renderer2
    ) { }
}
