import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainHeaderMenuComponent } from './main-header-menu/main-header-menu.component';
import { MainHeaderNavBarComponent } from './main-header-nav-bar/main-header-nav-bar.component';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { DragAndDropWithUploadComponent } from './drag-and-drop-with-upload/drag-and-drop-with-upload.component';
import { GhostingDialogComponent } from './ghosting-dialog/ghosting-dialog.component';
import { TextAreaAutoResizeDirective } from './textarea-autoresize.directive';
import { TextOverflowTooltipDirective } from './textoverflow-tooltip.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2CompleterModule } from 'ng2-completer';
import { AnalyticsCardComponent } from './analytics-card/analytics-card.component';
import { AnalyticsModalComponent } from './analytics-modal/analytics-modal.component';
import { ProgressbarCompositionComponent } from './progressbar-composition/progressbar-composition.component';
import { CardCountComponent } from './card-count/card-count.component';
import { VerticalTimelineComponent } from './vertical-timeline/vertical-timeline.component';
import { HorizontalTimelineComponent } from './horizontal-timeline/horizontal-timeline.component';
import { MessagesPaneComponent } from './messages-pane/messages-pane.component';
import { ExceptionsPaneComponent } from './exceptions-pane/exceptions-pane.component';
import { ScannedDocumentsModalComponent } from './scanned-documents-modal/scanned-documents-modal.component';
import { ScannedDocumentsComponent } from './scanned-documents/scanned-documents.component';
import { BookingStatusActivityComponent } from './booking-status-activity/booking-status-activity.component';
import { SharedUiDirectiveModule } from '@cds-ui/ui-directive';
import { VibeExceptionModalComponent } from './vibe-exception-modal/vibe-exception-modal.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { ActivityFeedViewMoreModalComponent } from './activity-feed-viewmore-modal/activity-feed-viewmore-modal.component';
import { ActivityPanelComponent } from './activity-panel/activity-panel.component';
import { ActivityPanelViewMoreDetailModalComponent } from './activity-panel/activity-panel-view-more-details-modal/activity-panel-view-more-detail-modal.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AddContainerStatusModalComponent } from './add-container-status-modal/add-container-status-modal.component';
import { ContainerStatusModalComponent } from './container-status-modal/container-status-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DemurrageDetentionPaneComponent } from './demurrage-detention-pane/demurrage-detention-pane.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DemurrageDetentionModalComponent } from './demurrage-detention-modal/demurrage-detention-modal.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdvanceSearchModalComponent } from './advance-search-modal/advance-search-modal.component';
import { GlobalGridComponent } from './global-grid/global-grid.component';
import { GlobalGridHeaderComponent } from './global-grid-header/global-grid-header.component';
import { SharedUiGridDirectiveModule } from '@cds-ui/shared/ui-grid';
import { VesselScheduleViewPaneComponent } from './vessel-schedule-view-pane/vessel-schedule-view-pane.component';
import { LrmReplacePipe } from './lrm-replace.pipe';
import { DetailPaneEditModalComponent } from './detail-pane-edit-modal/detail-pane-edit-modal.component';
import { ShippingViewContainersGridModalComponent } from './shipping-view-containers-grid-modal/shipping-view-containers-grid-modal.component';
import { PoItemModalComponent } from './po-item-modal/po-item-modal.component';
import { InputLimitCharactersDirective } from './input-limit-characters.directive';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule,
    ModalModule.forRoot(),
    RouterModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    SharedUiDirectiveModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SharedUiGridDirectiveModule
  ],
  declarations: [
    CompanySelectorComponent,
    MainHeaderMenuComponent,
    MainHeaderNavBarComponent,
    DragAndDropDirective,
    DragAndDropWithUploadComponent,
    GhostingDialogComponent,
    TextAreaAutoResizeDirective,
    TextOverflowTooltipDirective,
    InputLimitCharactersDirective,
    AnalyticsCardComponent,
    AnalyticsModalComponent,
    ProgressbarCompositionComponent,
    CardCountComponent,
    HorizontalTimelineComponent,
    VerticalTimelineComponent,
    ActivityFeedViewMoreModalComponent,
    MessagesPaneComponent,
    ExceptionsPaneComponent,
    ScannedDocumentsModalComponent,
    ScannedDocumentsComponent,
    VibeExceptionModalComponent,
    BookingStatusActivityComponent,
    ActivityPanelComponent,
    ActivityPanelViewMoreDetailModalComponent,
    ErrorPageComponent,
    AddContainerStatusModalComponent,
    ContainerStatusModalComponent,
    DemurrageDetentionPaneComponent,
    DemurrageDetentionModalComponent,
    GlobalGridComponent,
    GlobalGridHeaderComponent,
    VesselScheduleViewPaneComponent,
    AdvanceSearchModalComponent,
    DetailPaneEditModalComponent,
    LrmReplacePipe,
    ShippingViewContainersGridModalComponent,
    PoItemModalComponent
   ],
  exports: [
    MainHeaderMenuComponent,
    MainHeaderNavBarComponent,
    DragAndDropWithUploadComponent,
    GhostingDialogComponent,
    AnalyticsCardComponent,
    AnalyticsModalComponent,
    ProgressbarCompositionComponent,
    CardCountComponent,
    HorizontalTimelineComponent,
    VerticalTimelineComponent,
    ActivityFeedViewMoreModalComponent,
    MessagesPaneComponent,
    ExceptionsPaneComponent,
    ScannedDocumentsModalComponent,
    ScannedDocumentsComponent,
    VibeExceptionModalComponent,
    BookingStatusActivityComponent,
    ActivityPanelComponent,
    ErrorPageComponent,
    DemurrageDetentionPaneComponent,
    DemurrageDetentionModalComponent,
    GlobalGridComponent,
    GlobalGridHeaderComponent,
    VesselScheduleViewPaneComponent,
    AdvanceSearchModalComponent,
    DetailPaneEditModalComponent,
    LrmReplacePipe,
    ShippingViewContainersGridModalComponent,
    PoItemModalComponent
  ],
})
export class SharedUiKitModule {}
