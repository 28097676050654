export interface AppTheme {
    theme: AppThemeOptions
}

export interface AppThemeOptions {
    grid: string;
    apptheme: 'dark' | 'light'
}

export class AppThemeSettingBuilder {
    public static build() : AppThemeOptions {
        return {
            grid: 'ag-theme-alpine-dark',
            apptheme: 'dark'
        };
    }
}