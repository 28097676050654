import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class GhostingDialogService {

  private ghostUser$ = new BehaviorSubject(null);
  public  getGhostUser$ = this.ghostUser$.asObservable();

  constructor(private http: HttpClient) {}

  // HttpClient API get() method
  getUserGhostHistory(): Observable<any> {
    return this.http
      .get('/menu/view/GetUserGhostHistory');
  }

  getGhostUserValidate(userId:any,subgroupCd:any): Observable<any> {
    return this.http
      .get('/menu/view/GetGhostUserValidate?UserId=' + userId + '&SubGroupCd=' + subgroupCd);
  }

  getGhostUser(data:any){
    this.ghostUser$.next(data);
  }

}
