import { AfterViewInit, Component, EventEmitter, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { NotificationModalConfig, NotificationModalService, EventType } from './notification-modal.service';

@Component({
    selector: 'cds-ui-notification-modal',
    templateUrl: './notification-modal.component.html',
})
export class NotificationModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild("template") template!: TemplateRef<any>;
    private destroy$: Subject<void> = new Subject();
    public modalRef!: BsModalRef;
    public config!: NotificationModalConfig;

    constructor(private modalservice: BsModalService, private notificationModalService: NotificationModalService) { }

    ngAfterViewInit(): void {
        this.notificationModalService.show$
            .pipe(takeUntil(this.destroy$))
            .subscribe(error => {
                if (this.modalRef) {
                    this.modalRef.hide();
                }

                this.config = error as NotificationModalConfig;
                this.modalRef = this.modalservice.show(this.template, { backdrop: false, ignoreBackdropClick: true, class:'modal-dialog-centered' });
            });

        this.handler(this.modalservice.onShow, EventType.OnShow);
        this.handler(this.modalservice.onShown, EventType.OnShown);
        this.handler(this.modalservice.onHide, EventType.OnHide);
        this.handler(this.modalservice.onHidden, EventType.OnHidden);
    }

    handler(handler: EventEmitter<any>, type: EventType) {
        handler.pipe(takeUntil(this.destroy$))
               .subscribe(_ => this.notificationModalService.handle({ type: type, modalRef: this.modalRef }));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}