import { take, Observable } from 'rxjs';
import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { ScannedDocument, ScannedDocumentsModalService } from './scanned-documents.service';
@Component({
    selector: 'cds-ui-scanned-documents-modal',
    templateUrl: './scanned-documents-modal.component.html',
    styleUrls: ['./scanned-documents-modal.component.scss'],
})
export class ScannedDocumentsModalComponent implements AfterViewInit, OnDestroy {
    private destroy$ = new Subject<void>();
    public scannedDocUrl = 'https://svc.centuryvms.com/docushare/Reports_PDF_Viewer.aspx?userid=LoadScannedDocs&type=docushare&doc_id=';
    public scannedDocumentsDetail$!: Observable<ScannedDocument[]>;
    public modalRef!: BsModalRef;
    @ViewChild("scannedDocumentModal") template!: TemplateRef<HTMLDivElement>;

    constructor(
        private modalservice: BsModalService,
        private scannedDocumentsModalService: ScannedDocumentsModalService
    ) { }

    ngAfterViewInit(): void {

        this.scannedDocumentsModalService.show$
            .pipe(takeUntil(this.destroy$))
            .subscribe(documents$ => {

                if (this.modalRef) this.modalRef.hide();

                this.scannedDocumentsDetail$ = documents$;

                documents$
                .pipe(
                    takeUntil(this.modalservice.onHidden),
                    take(1)
                 )
                .subscribe(list=>{
                  if (list.length == 1) {
                    window.open(this.scannedDocUrl + list[0].docHandle, '_blank');
                }
                else {
                    this.modalRef = this.modalservice.show(
                        this.template, { backdrop: false, ignoreBackdropClick: true, class:'modal-dialog-centered' });
                }
                });
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
