import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeprecatedFeaturePermissionDirective } from './deprecated-feature-permission-directive/deprecated-feature-permission.directive';
import { EnhancedDropdownDirective } from './enhanced-dropdown-directive/enhanced-dropdown.directive';
import { EnhancedFormControlDirective } from './enhanced-form-control-directive/enhanced-form-control.directive';
import { SpinnerDirective } from './spinner-directive/spinner.directive';
import { CompanyFilterDirective } from './company-filter-directive/company-filter.directive';
import { IndeterminateDirective } from './checkbox-set-indeterminate-directive/checkbox-set-indeterminate.directive';
import { HighlightSearchPipe } from './highlight-search-pipe/hightlight-search.pipe';
import { TitleCasePipe } from './title-case-pipe/title-case.pipe';
import { ScrollLockDirective } from './scroll-lock-directive/scroll-lock.directive';
import { GlobalFeaturePermissionDirective } from './feature-permission-directive/global-feature-permission/global-feature-permission.directive';
import { FeaturePermissionDirective } from './feature-permission-directive/feature-permission/feature-permission.directive';
import { buyerFilterDirective } from './buyer-filter-directive/buyer-filter.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    GlobalFeaturePermissionDirective,
    FeaturePermissionDirective,
    DeprecatedFeaturePermissionDirective,
    EnhancedDropdownDirective,
    EnhancedFormControlDirective,
    CompanyFilterDirective,
    SpinnerDirective,
    IndeterminateDirective,
    HighlightSearchPipe,
    TitleCasePipe,
    ScrollLockDirective,
    buyerFilterDirective
  ],
  exports: [
    GlobalFeaturePermissionDirective,
    FeaturePermissionDirective,
    DeprecatedFeaturePermissionDirective,
    EnhancedDropdownDirective,
    EnhancedFormControlDirective,
    CompanyFilterDirective,
    SpinnerDirective,
    HighlightSearchPipe,
    TitleCasePipe,
    ScrollLockDirective,
    buyerFilterDirective
  ]
})
export class SharedUiDirectiveModule { }
