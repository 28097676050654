<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header w-100 pt-5" cdkDragHandle>
      <h2 class="pull-left">Locations Dates Expanded</h2>
      <button class="bg-transparent border-0 pull-right" (click)="modalRef?.hide()">
        <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
        <span class="visually-hidden">Close</span>
      </button>
    </div>
    <div class="modal-body w-70 pt-0" *ngFor="let date of data">
      <div class="d-flex bg-gray-600 pt-3 px-5">
        <div class="w-25">
          <span class="label">{{date.displayName}}</span>
          <p class="fw-bold">{{date.location ?? '--'}}</p>
        </div>
        <div class="w-25">
          <span class="label">Planned</span>
          <p class="fw-bold">{{(date.planned) ?? '--' }}</p>
        </div>
        <div class="w-25">
          <span class="label">Current Plan</span>
          <span *ngIf="date.managedBy == 'ETAI' && date?.displayName == 'Vessel Arrive'" class="material-icons mi-16 ms-2" tooltip="ETAI">computer</span>
          <span *ngIf="date.managedBy == 'Customer' && date?.displayName == 'Vessel Arrive'" class="material-icons mi-16 ms-2" tooltip="Customer Managed ETA">person</span>
          
          <p class="fw-bold">{{(date.currentPlan) ?? '--' }}</p>
        </div>
        <div class="w-25">
          <span class="label">Actual</span>
          <p class="fw-bold">{{(date.actual) ?? '--' }}</p>
        </div>
      </div>
    </div>
  </div>
  </ng-template>
  