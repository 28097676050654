/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, EventEmitter, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { PocDeclineDialogueEvent, PocDeclineEventModalConfig, PocDeclineModalService } from './poc-decline-modal.service';

@Component({
  selector: 'cds-ui-poc-decline-modal',
  templateUrl: './poc-decline-modal.component.html'
})
export class PocDeclineModalComponent implements AfterViewInit, OnDestroy {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: PocDeclineEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  PocDeclineValue = '';
  public PocDeclineForm = new FormGroup({
    PocDeclineField: new FormControl('', Validators.required),
  });
  constructor(
    private modalservice: BsModalService,
    private PocDeclineModalService: PocDeclineModalService,
    private appStateRepository: AppStateRepository
  ) {}

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.PocDeclineModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.PocDeclineValue = '';
        this.config = error as PocDeclineEventModalConfig;

        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered',
        });
      });

    this.handler(this.modalservice.onShow, PocDeclineDialogueEvent.OnShow);
    this.handler(this.modalservice.onShown, PocDeclineDialogueEvent.OnShown);
    this.handler(this.modalservice.onHide, PocDeclineDialogueEvent.OnHide);
    this.handler(this.modalservice.onHidden, PocDeclineDialogueEvent.OnHidden);
  }

  handler(handler: EventEmitter<any>, type: PocDeclineDialogueEvent) {
    handler
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.PocDeclineModalService.handle({ type: type }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  initiatedSelection(event: any) {
    this.PocDeclineValue = event.target.value;
  }
  close() {
    this.modalRef.hide();
    this.PocDeclineValue = '';
  }

  onPocDeclineClick(note: any) {
    this.PocDeclineModalService.setClosingPocDecline(
      this.PocDeclineValue,
      note
    );
  }

  private subscribeOverwrittenExisting() {
    this.PocDeclineModalService.onClosingPocDeclineRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}