import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class VibeExceptionModalService implements OnDestroy{
  private destroy$$ = new Subject<void>();
  private show$$ = new Subject<{ title:string }>();
  private hide$$ = new Subject<void>();

  public onShow$ = this.show$$.pipe(takeUntil(this.destroy$$));
  public onHide$ = this.hide$$.pipe(takeUntil(this.destroy$$));

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  show(config : { title: string }): void {
    this.show$$.next(config);
  }
  
  hide(): void {
    this.hide$$.next();
  }
}