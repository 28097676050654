import { ICellRendererParams, ValueFormatterParams } from "@ag-grid-community/core";
import { CustomColDef, LanguageResourceModule, NavigationLinkRendererComponent } from "@cds-ui/shared/ui-grid";
import moment from "moment";


const dateFormatter = (params: any) => {
    if (params.value) {
      return moment.utc(params.value).format('MM/DD/YYYY');
    }

    return '';
  }
  
export const columns: CustomColDef[] = [
  {
    headerName: LanguageResourceModule.PurchaseOrderNumber,
    field: 'poNumber',
    cellRenderer: NavigationLinkRendererComponent,
    cellRendererParams: {
      link: 'purchaseOrderNumber',
      companyCode: ['companyCode'],
      purchaseOrderNumber: ['poNumber'],
      modal: true
  }
  },
  {
    headerName: LanguageResourceModule.item,
    field: 'itemNumber'
  },
  {
    headerName: 'BPO #',
    field: 'allocationPurchaseOrderNumber',
    domainKey: 'container:container-plan-aggregate:items:allocation-purchase-order-number',
    valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' 
  },
  {
    headerName: 'DPO #',
    field: 'destinationAllocationPurchaseOrderNumber',
    domainKey: 'container:container-plan-aggregate:items:destination-allocation-purchase-order-number',
    valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' 
  },
  {
    headerName: LanguageResourceModule.shipKey,
    field: 'shipKey',
  },
  {
    headerName: LanguageResourceModule.containerNumber,
    field: 'containerNumber',
    cellRenderer: NavigationLinkRendererComponent,
    cellRendererParams: {
      link: 'containerNumber',
      containerNumber: ['containerNumber'],
      shipKey: ['shipKey'],
      modal: true
    }
  },
  {
    headerName: LanguageResourceModule.sealNumber,
    field: 'sealNumber',
  },
  {
    headerName: LanguageResourceModule.carrier,
    field: 'carrier',
  },
  {
    headerName: LanguageResourceModule.vessel,
    field: 'vessel',
  },
  {
    headerName: LanguageResourceModule.voyageNumber,
    field: 'voyage',
  },
  {
    headerName: LanguageResourceModule.estOriginDepart,
    field: 'estOriginDepart',
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
    },
  },
  {
    headerName: LanguageResourceModule.originPort,
    field: 'originPort',
  },  
  {
    headerName: LanguageResourceModule.motherPort,
    field: 'motherPort',
  },
  {
    headerName: LanguageResourceModule.estMotherDepart,
    field: 'estMotherDepart',
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
    },
  },
  {
    headerName: LanguageResourceModule.estDischarge,
    field: 'estDischarge',
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
    },
  },
  {
    headerName: LanguageResourceModule.finalDestination,
    field: 'finalDestination',
  },
  {
    headerName: 'BPO DC',
    field: 'allocationPurchaseOrderDestination',
    domainKey: 'container:container-plan-aggregate:items:allocation-purchase-order-destination',
    valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' 
  },
  {
    headerName: LanguageResourceModule.estFinalDestination,
    field: 'estFinalDestination',
    valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
      },
  },
  {
    headerName: LanguageResourceModule.dischargePort,
    field: 'dischargePort',
  },  
  {
    headerName: LanguageResourceModule.containerSize,
    field: 'containerSize',
  },
  {
    headerName: LanguageResourceModule.freightType,
    field: 'freightType',
  },
  {
    headerName: LanguageResourceModule.volume,
    field: 'volume',
  },
  {
    headerName: LanguageResourceModule.weight,
    field: 'weight',
  },
  {
    headerName: LanguageResourceModule.qty,
    field: 'units',
  },
  {
    headerName: LanguageResourceModule.unitCost,
    field: 'unitFirstCost',
  },
  {
    headerName: LanguageResourceModule.costOfGoodsSold,
    field: 'costOfGoodSold',
    cellRenderer: (params: ICellRendererParams) =>  getFormatNumber(params)
  }, 
  {
    headerName: LanguageResourceModule.hblNumber,
    field: 'hbl',
  },
  {
    headerName: LanguageResourceModule.mblNumber,
    field: 'masterBL',
  },
  { headerName: 'Entry #', field: 'entryNumber' },
  {
    headerName: LanguageResourceModule.commercialInvoiceNumber,
    field: 'commercialInvoiceNumber',
  },
  {
    headerName: LanguageResourceModule.vendor,
    field: 'vendor',
  }
  ];
  

  const getFormatNumber = (params: any) => {
    if (params.value) {
      return parseFloat(params.value.toFixed(2));
    }

    return '';
  }
