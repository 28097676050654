import { CellClickedEvent, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowDataUpdatedEvent} from '@ag-grid-community/core';
import { AfterViewInit, Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GetContainerStatusDetailGQL } from '@cds-ui/data-access';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { NavigationLinkRendererComponent } from '@cds-ui/shared/ui-grid';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AddContainerStatusModalService } from '../add-container-status-modal/add-container-status-modal.service';
import { ContainerStatusDialogueEvent, ContainerStatusEventModalConfig, ContainerStatusModalService } from './container-status-modal.service';


@Component({
  selector: 'cds-ui-container-status-modal',
  templateUrl: './container-status-modal.component.html',
  styleUrls: ['./container-status-modal.component.scss'],
})
export class ContainerStatusModalComponent implements OnInit, AfterViewInit {
  
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: ContainerStatusEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  gridResponse$!: Observable<any>;

  public columnDefs = 
  [
    {
      headerName: 'Ship Key',
      field: "shipKey",
    },
   {
     headerName: 'Container#',
     field: "containerNumber",
   },
   {
     headerName: 'Last Event Date',
     field: "statusDate",
     valueFormatter: this.dateFormatter,
      filterParams: {
        valueFormatter: this.dateFormatter,
      },
   },
   {
    headerName: 'Last Event Location',
    field: "locationName",
  },
   {
     headerName: 'Last Event',
     field: "description",
   },
   {
    headerName: '',
    field: "addEvent",
    cellRenderer: NavigationLinkRendererComponent,
    onCellClicked: (event: CellClickedEvent) => {
      this.createHyperLink(event);
    }
  },
  //  {
  //    headerName: 'PO DC',
  //    field: "statusCode",
  //   //  sortable: true,
  //  },
  
 ];

  public gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      minWidth: 200,
      wrapText: true,     // <-- HERE
      autoHeight: true,
      suppressMenu: true
    },
  };
  private gridApi!: GridApi;
  public gridColumnApi!: ColumnApi;
  private readonly subscriptions = new Subscription();
  constructor(
    private modalservice: BsModalService,
    private ContainerStatusModalService: ContainerStatusModalService,
    private addContainerStatusModalService: AddContainerStatusModalService,
    private appStateRepository: AppStateRepository,
    private apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.onAddEventSave();
  }  

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.ContainerStatusModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as ContainerStatusEventModalConfig;
       
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered modal-xl'
        });

        this.getGridData(this.config.data.queryString.split('/')[2],
        this.config.data.queryString.split('/')[3])
      });

    this.handler(
      this.modalservice.onShow,
      ContainerStatusDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      ContainerStatusDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      ContainerStatusDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      ContainerStatusDialogueEvent.OnHidden
    );
  }

  getGridData(ship: any, container: any) {
    this.gridResponse$ = new GetContainerStatusDetailGQL(this.apollo).fetch({
        shipKey: ship,
        containerNumber: container
    }, { fetchPolicy: 'no-cache' }).pipe(
        map(x => x.data.intermodalContainer.map((y: any) => {
            if (y.events.length === 0) {
                return null; 
            }
            const lastEvent = y.events[y.events.length - 1];
            return {
                "containerNumber": lastEvent.containerNumber,
                "locationName": lastEvent.locationName,
                "shipKey": lastEvent.shipKey,
                "statusDate": lastEvent.statusDate,
                "description": lastEvent.status.statusName,
                "statusCode": lastEvent.status.statusCode,
                "addEvent": 'Add Event'
            };
        })),
        map(containers => containers.filter(container => container !== null))
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll(params.columnApi, false);
  }

  private autoSizeAll(api: ColumnApi, skipHeader: boolean) {
    const allColumnIds:any = api.getColumns()?.map(response => response.getColId());
    api.autoSizeColumns(allColumnIds, skipHeader);
  }

  public RefreshColumn() {
    this.gridApi.setFilterModel(null);
    this.gridColumnApi.setRowGroupColumns([]);
    this.gridColumnApi.resetColumnState();
    this.gridApi.onFilterChanged();
  }
  
  onGridBound(event: RowDataUpdatedEvent) {
    this.autoSizeAll(event.columnApi, false);
  }
  
  dateFormatter(params: any): string | any {
    if (params.value) {
      return moment.utc(params.value).format('MM/DD/YYYY');
    }
  }
  

  createHyperLink(params: CellClickedEvent) {
    console.log(params)
    this.addContainerStatusModalService.show(
      {
        title: "Container Status Events",
        data: params.data
      }
    )

  }

  
  handler(handler: EventEmitter<any>,type: ContainerStatusDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.ContainerStatusModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onContainerStatusClick() {
    this.ContainerStatusModalService.setClosingContainerStatus(this.config);
  }

  private subscribeOverwrittenExisting() {
    this.ContainerStatusModalService.onClosingContainerStatusRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }


  onAddEventSave() {
    this.subscriptions.add(
    this.addContainerStatusModalService.onClosingAddContainerStatusRemove$.subscribe(val => {
      this.getGridData(val.params.shipKey , val.params.containerNo )
    }))
  }
}
