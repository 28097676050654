import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface CommentsEventModalConfig {
    title: string,
    message?: any,
    btnOne:string,
    btnTwo:string
}

export enum CommentsDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved
}

interface CommentsModalEvent {
    type: CommentsDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class CommentsModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<CommentsModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === CommentsDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === CommentsDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === CommentsDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === CommentsDialogueEvent.OnHidden));
  public onClosingConfirmationRemove$ = this.event$.pipe(filter((x) => x.type === CommentsDialogueEvent.OnClosingRemove));
  public onClosingConfirmationRemoved$ = this.event$.pipe(filter((x) => x.type === CommentsDialogueEvent.OnClosingRemoved));

  show(config: CommentsEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: CommentsModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingConfirmation(params?: any) {
    this.event$.next({
      type: CommentsDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingConfirmationRemoved() {
    this.event$.next({
      type: CommentsDialogueEvent.OnClosingRemoved,
    });
  }
}
