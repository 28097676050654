import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class MessageCenterService {

  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  public messageTypeSubject$ : Subject<any> = new Subject();
  public messageTypeDataSource$ = this.messageTypeSubject$.asObservable();

  public messagesSubject$ : BehaviorSubject<any> = new BehaviorSubject('Inbox');
  public messagesDataSource$ = this.messagesSubject$.asObservable();

  public draftDataSubject$ : Subject<any> = new Subject();
  public draftDataSource$ = this.draftDataSubject$.asObservable();

  public inboxArchiveDataSubject$ : Subject<any> = new Subject();
  public inboxArchiveDataSource$ = this.inboxArchiveDataSubject$.asObservable();

  public replySelectedMessageSubject$ : Subject<any> = new Subject();
  public replySelectedMessageDataSource$ = this.replySelectedMessageSubject$.asObservable();

  public refreshMessagePaneComponentSubject$ = new BehaviorSubject<any>('');
  public refreshMessagePaneComponentDataSource$ = this.refreshMessagePaneComponentSubject$.asObservable();

 deleteDraft(draftObj:any): Observable<any>{
    return this.http.post(`messages/deleteDraftMessages`, draftObj);
 }

 sendDraft(draftObj:any): Observable<any>{
  return this.http.post(`messages/send`, draftObj);
 }
 
//  sendDraftMessages(draftObj:any): Observable<any>{
//    return this.http.post(`messages/sendDraftMessages`, draftObj);
//   }

 saveToDraft(draftObj:any): Observable<any>{
  return this.http.post(`messages`, draftObj);
 }

 replyToMessages(draftObj:any): Observable<any>{
  return this.http.post(`messages/reply`, draftObj);
 }

// commenting this code block and other codes so that when we change from Normal APIs to New Domain Driven APIs we will need this code block.
 replyToMultipleMessage(draftObj:any): Observable<any>{
  return this.http.post(`messages/replyMultiple`, draftObj);
 }

}
