import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetContainerDetailGQL } from '@cds-ui/data-access';
import { filterNullish } from '@cds-ui/shared/core-rx';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { map, mergeMap, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { DemurrageDetentionModalService } from '../demurrage-detention-modal/demurrage-detention-modal.service';

interface FeaturePermissionData {
  showDemurrageColumns: boolean, showDetentionColumns: boolean
}
@Component({
  selector: 'cds-ui-demurrage-detention-pane',
  templateUrl: './demurrage-detention-pane.component.html',
  styleUrls: ['./demurrage-detention-pane.component.scss'],
})
export class DemurrageDetentionPaneComponent implements OnInit {
  @Input() demurrage:any;
  @Input() featurePermissions: FeaturePermissionData | undefined;
  demurrageData:any;
  detentionData:any;
  private destroy$$ = new Subject<void>();
  constructor(private demurrageDetentionModalService: DemurrageDetentionModalService, 
    private route: ActivatedRoute,
    private apollo: Apollo) {}

  dndModalData:any;
  ngOnInit(): void {
    this.demurrageData = this.demurrage.find((x: { chargeType: string; })=>x.chargeType == 'Demurrage');
    this.detentionData = this.demurrage.find((x: { chargeType: string; })=>x.chargeType == 'Detention');

    this.route.params.pipe(
      takeUntil(this.destroy$$),
      map(params => ({ shipKey: params['shipKey'], containerNumber: params['containerNumber'] })),
      mergeMap(params => new GetContainerDetailGQL(this.apollo).fetch(params, { fetchPolicy: 'no-cache' })),
      map(x => x.data.intermodalContainer),
      filterNullish(),
      shareReplay(1)
    ).subscribe(x=> this.dndModalData = x[0]);
  }
  yellowBar(value:any){
    return (value <= 3 &&  value >= 1);
  }
  blueBar(value:any){
    return value > 3;
  }
  redBar(value:any){
    return value <= 0;
  }
  days(value: any) {
    if(value > 1){
      return 'days';
    }else if(value == 1){
      return 'day'
    }else if(value == -1){
      return 'day'
    }else if(value < -1){
      return 'days'
    }else{
      return 'days'
    }
  }

  viewMore(){
    this.demurrageDetentionModalService.show(this.dndModalData?.charges)
  }
}
