<div class="date-Clear" *ngIf="fieldType == 'Change Due Date' || fieldType == 'Change Ship Date' || fieldType == 'Change VCW Begin'">
    <input #input type="text" (click)="onKeyDown($event)" tabindex="1" placeholder="Select Date"
        class="form-control custom-date" [(ngModel)]="value" bsDatepicker readonly [bsConfig]="datePickerConfig"
        placement="top">
    <span *ngIf="value" class="material-icons mi-24" (click)="clearDate()">close</span>
</div>

<div
    *ngIf="fieldType == 'Change Quantity' || fieldType ==  'Change Price' || fieldType ==  'Change Pack Qty' || fieldType ==  'Change Packs Per Case' || fieldType ==  'Change Retail Price'">
    <input #input (keydown)="onKeyDown($event)" [(ngModel)]="value" style="width: 100%;height: 35px;" />
</div>

<div
    *ngIf="fieldType == 'Change Factory Number' || fieldType == 'Change Fabric Packing' || fieldType =='Change Origin Country/Region' || (fieldType ==  'Change FOB' && changeLevel != 'Item')">
    <input #input [(ngModel)]="value" style="width: 100%;height: 35px;" />
</div>

