import { DashBoardCards } from "./global-tally-card-attributes.map";

export const TallyCardTemplate = [
  {
    category: 'container',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.AtOriginPort.labelName,
      id: '1',
      count: '',
      totalValue: 0,
      color: DashBoardCards.AtOriginPort.color,
      description: DashBoardCards.AtOriginPort.description,
      milestones: DashBoardCards.AtOriginPort.milestoneName,
      permissions: ['container:action:container:list']
    }
  },
  {
    category: 'container',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.OnWater.labelName,
      id: '2',
      count: '',
      totalValue: 0,
      color: DashBoardCards.OnWater.color,
      description: DashBoardCards.OnWater.description,
      milestones: DashBoardCards.OnWater.milestoneName,
      permissions: ['container:action:container:list']
    }
  },
  {
    category: 'container',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.AtDischargePort.labelName,
      id: '3',
      count: '',
      totalValue: 0,
      color: DashBoardCards.AtDischargePort.color,
      description: DashBoardCards.AtDischargePort.description,
      milestones: DashBoardCards.AtDischargePort.milestoneName,
      permissions: ['container:action:container:list']
    }
  },
  {
    category: 'container',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.FinalMile.labelName,
      id: '4',
      count: '',
      totalValue: 0,
      color: DashBoardCards.FinalMile.color,
      description: DashBoardCards.FinalMile.description,
      milestones: DashBoardCards.FinalMile.milestoneName,
      permissions: ['container:action:container:list']
    }
  },
  {
    category: 'container',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.Indeterminate.labelName,
      id: '5',
      count: '',
      totalValue: 0,
      color: DashBoardCards.Indeterminate.color,
      description: DashBoardCards.Indeterminate.description,
      milestones: DashBoardCards.Indeterminate.milestoneName,
      permissions: ['container:action:container:list']
    }
  },
  {
    category: 'purchase',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.PoNotBooked.labelName,
      id: '6',
      count: '',
      totalValue: 0,
      color: DashBoardCards.PoNotBooked.color,
      description: DashBoardCards.PoNotBooked.description,
      milestones: DashBoardCards.PoNotBooked.milestoneName,
      permissions: ['user:ui:customer-dashboard:posnotbooked']
    }
  },
  {
    category: 'purchase',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.BookingInProgress.labelName,
      id: '7',
      count: '',
      totalValue: 0,
      color: DashBoardCards.BookingInProgress.color,
      description: DashBoardCards.BookingInProgress.description,
      milestones: DashBoardCards.BookingInProgress.milestoneName,
      permissions: ['user:ui:customer-dashboard:bookingsinprogress']
    }
  },
  {
    category: 'purchase',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.LateShipments.labelName,
      id: '8',
      count: '',
      totalValue: 0,
      color: DashBoardCards.LateShipments.color,
      description: DashBoardCards.LateShipments.description,
      milestones: DashBoardCards.LateShipments.milestoneName,
      permissions: ['user:ui:customer-dashboard:lateshipments']
    }
  },
  {
    category: 'purchase',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.OnSchedule.labelName,
      id: '9',
      count: '',
      totalValue: 0,
      color: DashBoardCards.OnSchedule.color,
      description: DashBoardCards.OnSchedule.description,
      milestones: DashBoardCards.OnSchedule.milestoneName,
      permissions: ['user:ui:customer-dashboard:onschedule']
    }
  },
  {
    category: 'shipment',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.UpdatedEta.labelName,
      id: '10',
      count: '',
      totalValue: 0,
      color: DashBoardCards.UpdatedEta.color,
      description: DashBoardCards.UpdatedEta.description,
      milestones: DashBoardCards.UpdatedEta.milestoneName,
      permissions: ['user:ui:customer-dashboard:updatedeta']
    }
  },
  {
    category: 'shipment',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.NewAdvice.labelName,
      id: '11',
      count: '',
      totalValue: 0,
      color: DashBoardCards.NewAdvice.color,
      description: DashBoardCards.NewAdvice.description,
      milestones: DashBoardCards.NewAdvice.milestoneName,
      permissions: ['user:ui:customer-dashboard:newadvices']
    }
  },
  {
    category: 'shipment',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.ScannedDocs.labelName,
      id: '12',
      count: '',
      totalValue: 0,
      color: DashBoardCards.ScannedDocs.color,
      description: DashBoardCards.ScannedDocs.description,
      milestones: DashBoardCards.ScannedDocs.milestoneName,
      permissions: ['user:ui:customer-dashboard:scanneddocuments']
    }
  },
  {
    category: 'shipment',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.InProcess.labelName,
      id: '13',
      count: '',
      totalValue: 0,
      color: DashBoardCards.InProcess.color,
      description: DashBoardCards.InProcess.description,
      milestones: DashBoardCards.InProcess.milestoneName,
      permissions: ['user:ui:customer-dashboard:inprocess']
    }
  },
  {
    category: 'purchaseOrderManagement',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.Submitted.labelName,
      id: '14',
      count: '',
      totalValue: 0,
      color: DashBoardCards.Submitted.color,
      description: DashBoardCards.Submitted.description,
      milestones: DashBoardCards.Submitted.milestoneName,
      permissions: ['poi-poc:action:po-change-requests-pending:view']
    }
  },
  {
    category: 'purchaseOrderManagement',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.Accepted.labelName,
      id: '15',
      count: '',
      totalValue: 0,
      color: DashBoardCards.Accepted.color,
      description: DashBoardCards.Accepted.description,
      milestones: DashBoardCards.Accepted.milestoneName,
      permissions: ['poi-poc:action:po-change-requests-approved:view']
    }
  },
  {
    category: 'purchaseOrderManagement',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.IsPurchaseOrdersPending.labelName,
      id: '16',
      count: '',
      totalValue: 0,
      color: DashBoardCards.IsPurchaseOrdersPending.color,
      description: DashBoardCards.IsPurchaseOrdersPending.description,
      milestones: DashBoardCards.IsPurchaseOrdersPending.milestoneName,
      permissions: ['poi-poc:action:po-pending-acknowledgement:view']
    }
  },
  {
    category: 'purchaseOrderManagement',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.IsPurchaseOrderChangesPending.labelName,
      id: '17',
      count: '',
      totalValue: 0,
      color: DashBoardCards.IsPurchaseOrderChangesPending.color,
      description: DashBoardCards.IsPurchaseOrderChangesPending.description,
      milestones: DashBoardCards.IsPurchaseOrderChangesPending.milestoneName,
      permissions: ['poi-poc:action:po-changes-pending-acknowledgement:view']
    }
  },
  {
    category: 'purchaseOrderManagement',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.IsCancelled.labelName,
      id: '18',
      count: '',
      totalValue: 0,
      color: DashBoardCards.IsCancelled.color,
      description: DashBoardCards.IsCancelled.description,
      milestones: DashBoardCards.IsCancelled.milestoneName,
      permissions: ['poi-poc:action:cancelled-pos:view']
    }
  },
  {
    category: 'inbound',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.InboundToPort.labelName,
      id: '19',
      count: '',
      totalValue: 0,
      color: DashBoardCards.InboundToPort.color,
      description: DashBoardCards.InboundToPort.description,
      milestones: DashBoardCards.InboundToPort.milestoneName,
      permissions: ['user:ui:customer-dashboard:inboundtoport']
    }
  },
  {
    category: 'inbound',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.AtPort.labelName,
      id: '20',
      count: '',
      totalValue: 0,
      color: DashBoardCards.AtPort.color,
      description: DashBoardCards.AtPort.description,
      milestones: DashBoardCards.AtPort.milestoneName,
      permissions: ['user:ui:customer-dashboard:atport']
    }
  },
  {
    category: 'inbound',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.InboundToDc.labelName,
      id: '21',
      count: '',
      totalValue: 0,
      color: DashBoardCards.InboundToDc.color,
      description: DashBoardCards.InboundToDc.description,
      milestones: DashBoardCards.InboundToDc.milestoneName,
      permissions: ['user:ui:customer-dashboard:inboundtodc']
    }
  },
  {
    category: 'compliance',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.ItemMissingExpiredNumber.labelName,
      id: '22',
      count: '',
      totalValue: 0,
      color: DashBoardCards.ItemMissingExpiredNumber.color,
      description: DashBoardCards.ItemMissingExpiredNumber.description,
      milestones: DashBoardCards.ItemMissingExpiredNumber.milestoneName,
      permissions: [
        'user:ui:customer-dashboard:itemsmissingorexpiredhtsnumber',
      ]
    }
  },
  {
    category: 'transload',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.TransloadInboundToPort.labelName,
      id: '23',
      count: '',
      totalValue: 0,
      color: DashBoardCards.TransloadInboundToPort.color,
      description: DashBoardCards.TransloadInboundToPort.description,
      milestones: DashBoardCards.TransloadInboundToPort.milestoneName,
      permissions: ['transload:action:transload:list']
    }
  },
  {
    category: 'transload',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.TransloadAtPort.labelName,
      id: '24',
      count: '',
      totalValue: 0,
      color: DashBoardCards.TransloadAtPort.color,
      description: DashBoardCards.TransloadAtPort.description,
      milestones: DashBoardCards.TransloadAtPort.milestoneName,
      permissions: ['transload:action:transload:list']
    }
  },
  {
    category: 'transload',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.TransloadAtTransloadFacility.labelName,
      id: '25',
      count: '',
      totalValue: 0,
      color: DashBoardCards.TransloadAtTransloadFacility.color,
      description: DashBoardCards.TransloadAtTransloadFacility.description,
      milestones: DashBoardCards.TransloadAtTransloadFacility.milestoneName,
      permissions: ['transload:action:transload:list']
    }
  },
  {
    category: 'transload',
    showOnMyDay: true,
    attributes: {
      label: DashBoardCards.TransloadInboundToDc.labelName,
      id: '26',
      count: '',
      totalValue: 0,
      color: DashBoardCards.TransloadInboundToDc.color,
      description: DashBoardCards.TransloadInboundToDc.description,
      milestones: DashBoardCards.TransloadInboundToDc.milestoneName,
      permissions: ['transload:action:transload:list']
    }
  }
];