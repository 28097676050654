import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject, map, shareReplay, takeUntil, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { EditTransloadModalConfig, EditTransloadFacilityModalService } from './edit-transload-facility-modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { result } from 'lodash';


@Component({
  selector: 'cds-ui-edit-transload-facility-modal',
  templateUrl: './edit-transload-facility-modal.component.html',
  styleUrls: ['./edit-transload-facility-modal.component.scss'],
})
export class EditTransloadFacilityModalComponent implements OnInit {

  private destroy$: Subject<void> = new Subject();
  private configDataSubject$$ = new Subject<EditTransloadModalConfig>();
  public configData$ = this.configDataSubject$$.asObservable().pipe(shareReplay(1));
  public modalRef!: BsModalRef;
  public config!: EditTransloadModalConfig;
  public form = new FormGroup({
    facilityType: new FormControl('',Validators.required)
  });
  @ViewChild('template') template!: TemplateRef<any>;
  data = [];
  facilityList :any = [];
  facilitySelectedList :any = [];
  selectedFacility: string = '';
  public result:any;
  constructor(
    private modalservice: BsModalService,
    private editTransloadFacilityModalService: EditTransloadFacilityModalService,
    private appStateRepository: AppStateRepository,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.configData$.pipe(
      takeUntil(this.destroy$)).subscribe(x=> {this.facilitySelectedList = x.data});
    this.editTransloadFacilityModalService.getTransloadFacility().subscribe(data => {
      this.facilityList = data
    });
  }

  selectFacilityChange(val: any) {
    this.selectedFacility = val.target.value;
  }

  closeModal() {
    this.form.reset();
    this.modalRef.hide();
  }

  save() {
    const reqBody: any = [];
    
    this.result = this.facilitySelectedList.filter((x:any)=>x.transloadFacility === this.selectedFacility);
    
    if(!this.result.length ){  
     
      this.facilitySelectedList.forEach((obj:any)=>
            reqBody.push({
          "shipKey": obj.shipKey,
            "containerNumber": obj.containerNumber,
            "transloadFacilityCode": this.selectedFacility
          })        
        );
      
      this.editTransloadFacilityModalService.saveTransloadFacility(reqBody).subscribe((response) => {       
          this.toastrService.success('Data updated successfully');
          this.form.reset();
          this.editTransloadFacilityModalService.refreshGridSubject$.next(true);
          this.modalRef?.hide();        
      },
        (error) => {
          if (error) {
            this.modalRef?.hide();
          }
        });

    }else {      
      this.toastrService.error('These containers are already assigned to the selected Transload Facility.');
    }
    
  }



  ngAfterViewInit(): void {
    this.editTransloadFacilityModalService.show$
      .pipe(takeUntil(this.destroy$),
        tap(x => this.configDataSubject$$.next(x as EditTransloadModalConfig))
      ).subscribe((_) => {
        if (this.modalRef) this.modalRef.hide();
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
      });      
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
