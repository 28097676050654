import { Component, Input, OnInit } from '@angular/core';
import { ActivityFeedViewMoreModalService } from '../activity-feed-viewmore-modal/activity-feed-viewmore-modal.service';

@Component({
  selector: 'cds-ui-vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.scss'],
})
export class VerticalTimelineComponent implements OnInit {
  constructor(private activityFeedViewMoreModalService:ActivityFeedViewMoreModalService) {}

  @Input('verticalTimelineHeaderName') verticalTimelineHeaderName:any = 'Activity Feed';
  @Input('verticalTimelineData') verticalTimelineData:any = '';
  @Input('viewMoreDisplay') viewMoreDisplay:any='';
  ngOnInit(): void {}
  onActivityFeedViewMore() {    
     this.activityFeedViewMoreModalService.show(
      {
        title: "All Activity",
        data: this.verticalTimelineData
      }
    )
  }
}
