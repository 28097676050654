import {  ValueFormatterParams, ICellRendererParams, GridReadyEvent, ColumnApi, GridApi } from '@ag-grid-community/core';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PoItemDialogueEvent, PoItemEventModalConfig, PoItemModalService } from './po-item-modal.service';
import { select } from '@ngneat/elf';
import { CustomColDef, GridColumnService, NavigationLinkRendererComponent } from '@cds-ui/shared/ui-grid';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'cds-ui-po-item-modal',
  templateUrl: './po-item-modal.component.html',
  styleUrls: ['./po-item-modal.component.scss'],
})
export class PoItemModalComponent implements AfterViewInit, OnDestroy {
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: PoItemEventModalConfig;
  @ViewChild('template') template!: TemplateRef<HTMLTemplateElement>;
  public gridClass = this.appStateRepository.theme$.pipe(select((x) => x.grid));
  @Input() shipmentInput$!: Observable<any>;
  gridData :any;
  gridApi!:GridApi;
  gridColumnApi!:ColumnApi;
  constructor(
    private modalservice: BsModalService,
    private PoItemModalService: PoItemModalService,
    private appStateRepository: AppStateRepository,
    private http: HttpClient,
    private gridColumnService: GridColumnService
  ) {}

  defaultColDef = {
    suppressMenu: false,
    suppressMovable: false,
    enableRowGroup: true,
  
    resizable: true,
  };

  public defaultColumns: CustomColDef[] = [
    { headerName: 'PO #', field: 'poNumber',
      cellRenderer: NavigationLinkRendererComponent,
      cellRendererParams: {
        link: 'purchaseOrderNumber',
        companyCode: ['companyCode'],
        purchaseOrderNumber: ['poNumber'],
        modal: true
      }
    },
    { headerName: 'Item #',field: 'itemNumber',
      cellRenderer: NavigationLinkRendererComponent,
      cellRendererParams: {
        link: 'itemNumber',
        companyCode: ['companyCode'],
        itemNumber: ['itemNumber'],    
        modal: true
      }
    },
    { headerName: 'Description', field: 'itemDescription'},
    { headerName: 'Color', field: 'color', valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' },
    { headerName: 'Size', field: 'size', valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : ''  },
    {
      headerName: 'BPO #',
      field: 'allocationPurchaseOrderNumber',
      domainKey: 'container:container-plan-aggregate:items:allocation-purchase-order-number',
      valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' 
    },
    {
      headerName: 'BPO DC',
      field: 'allocationPurchaseOrderDestination',
      domainKey: 'container:container-plan-aggregate:items:allocation-purchase-order-destination',
      valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' 
    },
    {
      headerName: 'DPO #',
      field: 'destinationAllocationPurchaseOrderNumber',
      domainKey: 'container:container-plan-aggregate:items:destination-allocation-purchase-order-number',
      valueFormatter: (params: ValueFormatterParams) => params.value ? params.value : '' 
    },
    { headerName: 'Qty', field: 'units' },
    { headerName: 'Cartons', field: 'cartons' }
  ];


  ngOnInit(): void {}  
  
  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.PoItemModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as PoItemEventModalConfig;
        this.updateCartonsField();
        this.gridData = this.config.data.items;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered modal-xl'
        });
      });  

    this.handler(
      this.modalservice.onShow,
      PoItemDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      PoItemDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      PoItemDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      PoItemDialogueEvent.OnHidden
    );
  }

  updateCartonsField(){
    this.shipmentInput$.pipe(takeUntil(this.destroy$)).subscribe(shipmentInputData => {
      if (shipmentInputData && shipmentInputData.shipmentItems && this.config && this.config.data && this.config.data.items) {
        this.config.data.items.forEach((item: any) => {
          const matchingShipmentItem = shipmentInputData.shipmentItems.find((shipmentItem: any) =>
            shipmentItem.purchaseOrderNumber === item.poNumber &&
            shipmentItem.itemNumber === item.itemNumber &&
            shipmentItem.itemSize === item.size &&
            shipmentItem.itemColor === item.color
          );
          if (matchingShipmentItem) {
            item.packages = matchingShipmentItem.cartons;
          }
        });
      }
    });
  } 

  handler(handler: EventEmitter<any>,type: PoItemDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.PoItemModalService.handle({ type: type })
      );
  }

  onPoItemClick() {
    this.PoItemModalService.setClosingPoItem(this.config);
  }

  private subscribeOverwrittenExisting() {
    this.PoItemModalService.onClosingPoItemRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
  
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnService.nameColumnHeaders(this.defaultColumns)
    .subscribe({
      next:()=>{
        this.gridApi.setColumnDefs(this.defaultColumns);
        this.autoSizeAll(this.gridColumnApi,false);
      }
    });
 }

  private autoSizeAll(api: ColumnApi, skipHeader: boolean) {
    const allColumnIds: any = api
      .getColumns()
      ?.map((column) => column.getColId());
    api.autoSizeColumns(allColumnIds, skipHeader);
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
