import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { AbstractControl, ValidatorFn } from "@angular/forms";

//import { ValidatorCustom } from './validator-custom';


export class ValidatorCustom {
    static maxLinesValidator(limit: number): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
          const exceeded = control?.value?.length && control?.value?.split('\n').length > limit;
          return exceeded ? {'maxLines': {value: true}} : null;
      };
  }
    
  }

export const demurrageDetentionFormGroup = new FormGroup({
    id: new FormControl<string | null>(''),
    companyCode:new FormControl('', Validators.required),
    vendorCode: new FormControl<string | null>('', Validators.required),    
    name: new FormControl<string | null>('', Validators.required),
    template: new FormControl<string | null>('', [Validators.required,ValidatorCustom.maxLinesValidator(20)]),
});




