export interface PoDetailPaneUpdate {
    purchaseOrderNumber: string | null;
    companyCode: string | null;
    buyerCode: string | null;
    poCancelledDate: string | null;
    currencyCode: string | null;
    eventCode: string | null;
    deliveryDestination: string | null;
    division: string | null;
    finalDestination: string | null;
    originLocation: string | null;
    misc1: string | null;
    misc2: string | null;
    misc3: string | null;
    purchaseOrderStatus: string | null;
    paymentCode: string | null;
    shipMode: string | null;
    shipOriginCountry: string | null;
    poSpecialInstructions: string | null;
    vendorCode: string | null;
    supplierReferenceNumber: string | null;
    revisedShipDate: string | null;
    firstShipDate: string | null; 
    lastShipDate: string | null; 
    inWarehouseDate: string | null; 
}
interface PurchaseOrderItem {
    purchaseOrderNumber: string | null;
    companyCode: string | null;
    itemNumber: string | null;
    color: string | null;
    size: string | null;
    htsus: string | null;
    packages: number;
    cartonsUOM: string | null;
    lastShipDate: string | null; 
    firstShipDate: string | null;
    manufactureItemNumber: string | null;
    misc1: string | null;
    originCountry: string | null;
    unitFirstCost: number;
    unitPrice: number;
    units: number;
    purchaseOrderStatus: string | null;
    instoreDate: string | null; 
}
export interface PurchaseOrderUpdate {
    purchaseOrderNumber: string;
    companyCode: string;
    items: PurchaseOrderItem[]
}