import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DemurrageDetentionModalService } from './demurrage-detention-modal.service';
import { filterNullish } from '@cds-ui/shared/core-rx';
import { BehaviorSubject, ReplaySubject, Subject, filter, map, of, shareReplay, takeUntil, tap, withLatestFrom } from 'rxjs';
import { CommandError } from '@cds-ui/data-access';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { demurrageDetentionFormGroup } from './demurrage-detention-modal';
import { ToastrService } from 'ngx-toastr';
import { any } from 'underscore';
import { TitleCasePipe } from '@cds-ui/ui-directive';
import moment from 'moment';

@Component({
  selector: 'cds-ui-demurrage-detention-modal',
  templateUrl: './demurrage-detention-modal.component.html',
  styleUrls: ['./demurrage-detention-modal.component.scss'],
})
export class DemurrageDetentionModalComponent implements OnInit, OnDestroy {
  @ViewChild('template') templateRef!: TemplateRef<HTMLDivElement>;
  public form = demurrageDetentionFormGroup;
  public isLoading$ = this.appState.isLoading$;
  private modalRef$$ = new ReplaySubject<BsModalRef>(1);
  private destroy$$ = new Subject<void>();
  titlecase = new TitleCasePipe();
  private isSaving$$ = new Subject<boolean>();
  private formError$$ = new BehaviorSubject<CommandError | null>(null);
  demurrageData : any;
  detentionData : any;

  public activeCompanyList$ = this.appState.activeCompany$.pipe(
    map(x => x.length === 1
      ? x.find(_ => true)?.companyCode
      : '')
  );

  constructor(
    private editModalService: DemurrageDetentionModalService,
    private appState: AppStateRepository,
    private modalservice: BsModalService,
    private toastrService: ToastrService
  ) {}
  
  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  ngOnInit(): void {
    
    this.subscribeBindFormSingal();
    this.subscribeShowSingal();
    this.subscribeHideSingal();
  }
  private subscribeBindFormSingal() {
    this.editModalService.onShow$
      .pipe(
        takeUntil(this.destroy$$),
        filterNullish(),
      )
      .subscribe((x:any) => {
        this.demurrageData = x.find((x: { chargeType: string; })=>x.chargeType == 'Demurrage')
        this.detentionData = x.find((x: { chargeType: string; })=>x.chargeType == 'Detention')
      });
  }

  private subscribeShowSingal() {
    this.editModalService.onShow$
      .pipe(
        takeUntil(this.destroy$$),
        map((_) =>
          this.modalservice.show(this.templateRef, {
            backdrop: false,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-lg',
          })
        ),
        tap((x) => this.modalRef$$.next(x))
      )
      .subscribe();
  }

  chargeStart(val:number, total:number) {
    if(val < 2 && total == 1){
      return 'Charge Start Date'
    }else{
      return `Charge Start Date Tier ${val}`
    }
  }

  removeNegativeValue(val:number) {
    return Math.abs(val);
  }

  getStartEvent(code:string, name:string, date:string) {
    let finalCode = '';
    let finalName = '';
    let finalDate = '';

    if(!(code || name || date)){
      return '--'
    } else {
      if(code) {
        finalCode = `${code} - `
      }
      if(name) {
        finalName = name
      }
      if(date) {
        finalDate = ` (${moment.utc(date).format("MM/DD/YYYY")})`
      }
      if(!(date)){
        finalDate = ' (Not Received)'
      }
      return `${finalCode}${finalName}${finalDate}`;
    }
  }

  public onCancel() {
    this.editModalService.cancel();
  }

  private subscribeHideSingal() {
    this.editModalService.onHide$
      .pipe(
        takeUntil(this.destroy$$),
        withLatestFrom(this.modalRef$$),
        tap(console.log),
        tap(([_, ref]) => ref.hide()),
        tap(([_, __]) => this.form.reset())
      )
      .subscribe();
  }
}
