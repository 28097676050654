import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class dragAndDropWithUploadService {

  constructor(private http: HttpClient) {}

  updateFile(fileBlob:any,obj:any): Observable<any> {
    return this.http
      .post('/bookings/' + obj.bookingKey + '/documents/' + obj.docType + '/' + obj.docName,fileBlob);
  }

}
