import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { ScannedDocumentsModalService } from '../scanned-documents-modal/scanned-documents.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cds-ui-scanned-documents',
  templateUrl: './scanned-documents.component.html',
  styleUrls: ['./scanned-documents.component.scss'],
})
export class ScannedDocumentsComponent implements OnInit {
  constructor(private scannedDocumentsModalService:ScannedDocumentsModalService,
    private route:ActivatedRoute ) {}
  public scannedDocUrl = 'https://svc.centuryvms.com/docushare/Reports_PDF_Viewer.aspx?userid=LoadScannedDocs&type=docushare&doc_id=';
  @Input() scannedDocs$!:Observable<any>;

  ngOnInit(): void {}

  onViewMore(){
    this.scannedDocumentsModalService.show(this.scannedDocs$)
  }
  
}
