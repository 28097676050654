<ng-container *ngIf="(shouldUseCompFilter$ | async) === true">
  <div *ngIf="(companies$ | async) as companies">
    <div class="dropdown" *ngIf="companies.available.length > 1 && !!companies.active">
      <button class="btn btn-blue dropdown-toggle" type="button" id="companySeletor" data-bs-toggle="dropdown"
        data-bs-auto-close="outside" aria-expanded="false">
        <strong *ngIf="companies.active.length === 1;then singleDivision else allDivision"></strong>
        <ng-template #allDivision>Selected {{ companies.active.length }} companies</ng-template>
        <ng-template #singleDivision>{{ companies.active[0].companyCode }} - {{ companies.active[0].companyName
          }}</ng-template>
      </button>
      <div class="dropdown-menu dropdown-menu-with-search" aria-labelledby="companySeletor"
        data-bs-auto-close="outside">
        <div class="flex-column d-flex" style="max-height: calc(50vh);">
          <div class="p-3">
            <input class="w-100 form-control" type="text" placeholder="Search..."
              (input)="filterCompanyChange(searchCompanies)" [(ngModel)]="searchCompanies">
          </div>

          <div class="flex-grow-1" style="overflow-y: auto;">
            <div class="dropdown-item" (click)="onSelectAllCompany(companies.filtered, companies.active)">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="select-all-companies"
                  [indeterminate]="includeInFilter(companies.active, companies.filtered) === 'some'"
                  [checked]="includeInFilter(companies.active, companies.filtered) === 'all'"
                  (click)="preventClick($event)">
                <label class="form-check-label">
                  (Select All)
                </label>
              </div>
            </div>

            <div class="dropdown-item" *ngFor="let company of companies.filtered"
              (click)="onCompanySelect(company, companies.active)">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="select-companies-{{company.companyCode}}"
                  [checked]="isActiveCompany(company.companyCode, companies.active)" (click)="preventClick($event)">
                <label class="form-check-label" for="select-companies-{{company.companyCode}}">
                  <span [innerHTML]="company.display | highlightSearch: searchCompanies"></span>
                </label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>