<ng-template #template>
    <div id="modalDrag" class="modal-content" cdkDrag>
      <div class="modal-header pb-0" cdkDragHandle>
        <h3 class="modal-title">{{config.title}}</h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <form class="row g-3" [formGroup]="commentsForm" (ngSubmit)="onSubmit()">
      <div class="modal-body">
          <div class="col-md-12" *ngIf="config.message.showComments">
            <div class="message-cards overflow-auto">
              <div class="card message-card border" *ngFor="let item of config.message.data | async">
                <div class="message-card-meta d-flex align-items-center mb-2">
                  <h6 class="flex-fill fs-sm fw-bold m-0">{{item.createdBy}}</h6>
                  <div class="fs-sm font-monospace">{{formatDateToUTC(item.commentDate)}}</div>
                </div>
                <p class="message-card-message fs-sm mb-0">{{item.comment}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-3">
            <h3 class="text-purple mb-0 pb-0">Add Comment</h3>
            <textarea class="form-control" formControlName="commentField" id="inboundPlanningCommentField" rows="2"></textarea>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="closeModal()">{{config.btnOne}}</button>
        <button type="submit" class="btn btn-submit" (click)="onConfirmationClick()"
          [disabled]="!commentsForm.valid"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2"
            role="status"></i>{{config.btnTwo}}</button>
      </div>
    </form>
    </div>
</ng-template>
