import { PaginationChangedEvent } from "@ag-grid-community/core";

/**
 * Default settings to auto resize column upon data changed
 * 
 * Ref: https://www.ag-grid.com/javascript-data-grid/grid-events/#reference-pagination-paginationChanged
 * 
 * @param event : PaginationChangedEvent
 * @param skipHeader: default false. When true, ignore header during resize
 */
export const defaultPaginationChanged = (event: PaginationChangedEvent, skipHeader = false) => {
    const allColumnIds: string[] = []
    const columns = event?.columnApi?.getColumns()
    if(columns != null){
      columns?.forEach((column) => {
        allColumnIds?.push(column?.getId());
      });
    
      event?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
    }
}