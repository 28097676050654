import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { SaveUserPreferenceDialogueEvent, SaveUserPreferenceEventModalConfig, SaveUserPreferenceModalService } from './save-user-preference-modal.service';
// import { PageRemoveShippingMarksDialogueEvent, PageRemoveShippingMarksEventModalConfig, PageRemoveShippingMarksModalService,  } from './page-remove-shipping-marks-modal.service';

@Component({
  selector: 'cds-ui-save-user-preference-modal',
  templateUrl: './save-user-preference-modal.component.html',
  styleUrls: ['./save-user-preference-modal.component.scss'],
})
export class SaveUserPreferenceModalComponent implements OnInit {
  
  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: SaveUserPreferenceEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private SaveUserPreferenceModalService: SaveUserPreferenceModalService,
    private appStateRepository: AppStateRepository
  ) {}

  ngOnInit(): void {}  

  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.SaveUserPreferenceModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as SaveUserPreferenceEventModalConfig;
        
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      SaveUserPreferenceDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      SaveUserPreferenceDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      SaveUserPreferenceDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      SaveUserPreferenceDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>,type: SaveUserPreferenceDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.SaveUserPreferenceModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSaveUserPreferenceClick() {
    this.SaveUserPreferenceModalService.setClosingSaveUserPreference(this.config);
  }

  private subscribeOverwrittenExisting() {
    this.SaveUserPreferenceModalService.onClosingSaveUserPreferenceRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}
