import { Component, ElementRef, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild, ViewChildren,ChangeDetectorRef,AfterViewChecked } from '@angular/core';
import { of, Subject } from 'rxjs';
import { NotificationModalService } from '../notification-modal/notification-modal.service';
import { dragAndDropWithUploadService } from './drag-and-drop-with-upload.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cds-ui-drag-and-drop-with-upload',
  templateUrl: './drag-and-drop-with-upload.component.html',
  styleUrls: ['./drag-and-drop-with-upload.component.scss'],
})
export class DragAndDropWithUploadComponent
  implements OnInit, AfterViewChecked, OnChanges
{
  @Input('fileType') FileTypesAccepted!: any[];
  @Output() sendFileData: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileDropRef') fileDropRef!: ElementRef;
  @Input('documentTypeSelected') documentTypeSelected: any = "";

  files: any[] = [];
  documentText: string = '';
  checkduplicatedFileName: any[] = [];
  newUploadedFileName: string = '';
  newuploadeddocumenttype: string = '';
  progressDone: any = false;
  bookingKey: any;

  constructor(
    private notificationModalService: NotificationModalService,
    private cdr: ChangeDetectorRef,
    private dragAndDropWithUploadService: dragAndDropWithUploadService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    
  }

  ngOnChanges() {
      if(this.documentTypeSelected) {
          this.documentTypeSelected.subscribe( (doc: string) => {
        this.documentText = doc;
      });
      }
      
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  

  validateUploadedFilesReceived(index: number): boolean {
    let isFileSizeAccepted = this.getFileSize(index);
    const fileType = this.files[index]?.name.split('.').pop();
    let regex = /^[A-Za-z0-9 ]+$/;
    const fileName = this.files[index]?.name.split('.')[0];
    let isValid = regex.test(fileName);
    if (!isFileSizeAccepted) {
      this.toastrService.clear();
      this.toastrService.error('The maximum supported file size is 20 MB');
      return false;
    } else if (!isValid) {
      this.toastrService.clear();
      this.toastrService.error(
        'The file name cannot contain special character'
      );
      return false;
    }
    return true;
  }

  validateFilesReceived(files: Array<any>): boolean {
    const fileType = files[0]?.name.split('.').pop();
    if (!this.FileTypesAccepted.includes(fileType)) {
      this.notificationModalService.show({
        title: "Can't Upload File",
        message: 'Please upload the file in csv or xlsx',
        buttonText: 'Close',
      });
      return false;
    }
    return true;
  }

  uploadFilesSimulator(index: number): boolean {
    let isValidInput: boolean = false;
    if (!this.validateUploadedFilesReceived(index)) {
      isValidInput = false;
    } else {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.files[index].item.progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].item.progress += 5;
            }
          }, 200);
        }
      }, 1000);
      isValidInput = true;
    }
    return isValidInput;
  }

  uploadFilesSimulatorForDocumentUpload(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].item.progress === 100) {
            this.progressDone = true;
            clearInterval(progressInterval);
            this.uploadFilesSimulatorForDocumentUpload(index + 1);
          } else {
            this.files[index].item.progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
  }

  prepareFilesList(files: Array<any>) {
    if (this.documentText == '') {
      if (this.validateFilesReceived(files)) {
        this.sendFileData.emit(files);
        this.fileDropRef.nativeElement.value = '';
      }
    } else {
      for (const item of files) {
        item.progress = 0;
        this.files.push({ item: item, documentText: this.documentText });
      }
      this.newUploadedFileName = this.files[0].item.name;
      this.newuploadeddocumenttype = this.files[0].documentText;
      this.checkduplicatedFileName.push(this.files);

      for (let i = 1; i < this.checkduplicatedFileName.length; i++) {
        for (const filenames of this.checkduplicatedFileName) {
          if (
            this.newUploadedFileName == filenames[0].item.name &&
            this.newuploadeddocumenttype == filenames[0].documentText
          ) {
            this.checkduplicatedFileName.splice(i, 1);
          }
        }
      }
      this.uploadFilesSimulatorForDocumentUpload(0);
    }
  }

  getFileSize(index: number) {
    if (this.files[index]?.size / 1024 / 1024 > 20) {
      return false;
    }
    return true;
  }

  changeFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }  

  onCancelClick()
  {
    if(this.files.length){
      if(this.files.filter(x=>x.item.progress != 100)?.length){
        this.toastrService.error(
          'There is a file uploading'
        );
      }
    }
  }
  uploadFileOnServer() {
    if(this.files.length){
      if(this.files.filter(x=>x.item.progress != 100)?.length){
        this.toastrService.error(
          'There is a file uploading'
        );
      }else{
        let obj: any = {};
        obj.bookingKey = this.bookingKey;
        obj.docName = this.files[0]?.name.split('.')[0];
        obj.docType = this.documentText;
        let blobFile: any;
        this.changeFile(this.files[0]).then((response) => {
          blobFile = response;
          this.dragAndDropWithUploadService.updateFile(blobFile, obj).subscribe((response) => {
              this.toastrService.success('File Uploaded Successfully On Server');
            });
        });
      }
    }
  }



  removeUploadedDocs(i: number) {

    // if (this.files.length <= 1) return;

    this.files.splice(i, 1);

  }

}
