import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-051984}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Century_Distribution_Systems,_Incorporated}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{VMS}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{VMS}_need_to_be_licensed___{VMS}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_February_2025}____[v3]_[01]_MTczOTQwNDgwMDAwMA==753f31bab7b0b860d298a4dd0f8c0ce7'
);

//  https://www.ag-grid.com/angular-data-grid/modules/#installing-ag-grid-modules
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  InfiniteRowModelModule,
  RowGroupingModule,
  MenuModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  ClipboardModule,
  RichSelectModule,
  SetFilterModule,
  MasterDetailModule,
  RangeSelectionModule
]);

// The following has been moved to the AppComponent
// constructor because Prod Mode needs to be set
// after config is read from the assets folder.
// if (environment.production) {
//   enableProdMode();
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
