/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { tap, BehaviorSubject, map, Subject, takeUntil, take, combineLatest, filter, shareReplay, timeout, catchError, merge, debounceTime, mergeMap } from 'rxjs';
import _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Company } from '@cds-ui/data-access';
import { CompanyFilterRepository } from './datasource/company-filter.repository';

@Directive({ selector: '[companyFilter]' })
export class CompanyFilterDirective implements OnInit, AfterViewInit, OnDestroy {
    private readonly destroy$$ = new Subject<void>();
    private readonly request$$ = new Subject<Company[]>();
    private readonly localCache$ = combineLatest([
        this.appState.currentUser$,
        this.appState.availableCompanies$,
        this.companyFilterState.lastSelectedCompany$
    ])
    .pipe(
        map(([u, a, l]) => !!l.length
                         ? l 
                         : u.subgroupCode?.toLowerCase() === 'all' 
                            ? [a.find(_ => true)] 
                            : a),
        map(x => x as Company[])
    );

    private readonly savedUserPerference$ = this.request$$.pipe(
        timeout(2000),
        catchError(() => {
            return merge(this.request$$, this.localCache$).pipe(
                takeUntil(this.request$$)
            )
        })
    );

    private readonly nonFilteredRequest$$ = new BehaviorSubject<string[]>([]);
    private readonly nonFilterGlobalRequest = this.appState.bypassCompanyFilterRequest;
    private readonly nonFilteredRequest$ = this.nonFilteredRequest$$.pipe(
        map(x => x.concat(this.nonFilterGlobalRequest)),
        shareReplay(1)
    );

    private readonly shouldUseFilter$ = this.appState.currentUser$.pipe(
        map(() => true),
    );

    @Input("companyFilter") set query(query: string | string[] | null) {
        const filteredRequest = !query
            ? []
            : Array.isArray(query)
                ? query
                : [query];

        this.nonFilteredRequest$$.next(filteredRequest);
    }

    private param$ = combineLatest([
        this.appState.availableCompanies$,
        this.appState.initState$,
        this.shouldUseFilter$,
        this.nonFilteredRequest$,
        this.appState.currentUser$.pipe(
            mergeMap(x => x.hasGhosted
                        ? this.localCache$
                        : this.savedUserPerference$)
        ),
    ])
        .pipe(
            filter(([x, i]) => !!x.length && !!i),
            map(([companies, _, shouldFilter, nonFilterRequest, perferences]) => ({
                shouldFilter,
                nonFilterRequest,
                companies,
                perferences
            })),
            map(param => ({
                filterRequests: param.shouldFilter
                    ? param.nonFilterRequest
                    : [],
                companies: param.perferences.length
                         ? _.intersectionBy(param.companies, param.perferences, 'companyCode').length === param.perferences.length
                            ? param.perferences
                            : param.companies
                         : param.companies,
                shouldFilter: param.shouldFilter
            })),
            tap(x => this.appState.setActiveCompanies(x.companies)),
            tap(x => this.appState.setNonFilteredRequest(x.filterRequests)),            
        );

    constructor(
        private templateRef: TemplateRef<{ permissions: string[] }>,
        private viewContainer: ViewContainerRef,
        private appState: AppStateRepository,
        private companyFilterState: CompanyFilterRepository,
        private http: HttpClient
    ) { }

    ngAfterViewInit(): void {
            this.param$
            .pipe(
                debounceTime(100),
                takeUntil(this.destroy$$),
                take(1),
                tap(() => this.viewContainer.createEmbeddedView(this.templateRef))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.appState.activeCompany$.pipe(
            take(1),
            tap(x => this.companyFilterState.setLastSelectedCompany(x)),
            tap(() => this.appState.setActiveCompanies("*"))
        )
        .subscribe();

        this.destroy$$.next();
        this.destroy$$.complete();
    }

    ngOnInit(): void {
        this.appState.currentUser$.pipe(
            take(1),
            filter(x => !x.hasGhosted),
            mergeMap(_ => this.http.get<{ divisionselector: string } | null>("/user/preferences?path=global/headers").pipe(
                map(x => !!x?.divisionselector
                       ? JSON.parse(x.divisionselector)?.selectedCompanies
                       : null
                ),
                tap(x => this.request$$.next(x ?? [])),
                tap(() => this.request$$.complete())
            ))
        )
        .subscribe();

    }
}