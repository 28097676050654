import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cds-ui-horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  styleUrls: ['./horizontal-timeline.component.scss'],
})
export class HorizontalTimelineComponent implements OnInit {

  constructor() {}

  @Input('timelineData') timelineData:any;


  ngOnInit(): void {}

  getCompletedStatus(item: any, index: number): boolean {
    if (!!item.Event || item.Status === 'Completed') {
      return true;
    }
    for (let i = index + 1; i < this.timelineData.length; i++) {
      if (!!this.timelineData[i].Event || this.timelineData[i].Status === 'Completed') {
        return true;
      }
    }
    return false;
  }
}
