import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { FormBuilder } from '@ngneat/reactive-forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { PoBuyerMultipleDialogueEvent, PoBuyerMultipleService, PoBuyerMultipleModalConfig } from './po-buyer-multiple-modal.service';
import { Router } from '@angular/router';


@Component({
  selector: 'cds-ui-po-buyer-multiple-modal',
  templateUrl: './po-buyer-multiple-modal.component.html',
  styleUrls: ['./po-buyer-multiple-modal.component.scss'],
})
export class PoBuyerMultipleComponent {

  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: PoBuyerMultipleModalConfig;
  public poBuyerMultipleForm = new FormGroup({});
  @ViewChild('template') template!: TemplateRef<any>;
  pocWithdrawalValue = "";
  
  constructor(
    private modalservice: BsModalService,
    private poBuyerMultipleService: PoBuyerMultipleService,
    private appStateRepository: AppStateRepository,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  withdrawalForm() {
    // this.poBuyerMultipleForm = this.fb.group({
    //   withdrawNote: ['', Validators.required]
    // })
  }
  ngAfterViewInit() {
    this.withdrawalForm();
    this.subscribeOverwrittenExisting();
    this.poBuyerMultipleService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error:any) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.poBuyerMultipleForm.reset();
        if(error.type == 'po'){
          const uniqueArray = [...new Map(error.data.messagePurchaseOrders.map((item:any) => [item.pO, item])).values()];
          error.data.messagePurchaseOrders = uniqueArray;
        }
        this.config = error as PoBuyerMultipleModalConfig;
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered'
        });
      });

    this.handler(
      this.modalservice.onShow,
      PoBuyerMultipleDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      PoBuyerMultipleDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      PoBuyerMultipleDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      PoBuyerMultipleDialogueEvent.OnHidden
    );
  }

  pONavigation(data:any, companyCode:string) {
    const link = `purchase-order/view/${companyCode}/${data?.pO}`;
    this.router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  itemNavigation(data:any, companyCode:string) {
    const link = `item/item-detail/${companyCode}/${data?.item}`;
    this.router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  handler(handler: EventEmitter<any>,type: PoBuyerMultipleDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.poBuyerMultipleService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
 
  close(){
    this.modalRef.hide()
    this.poBuyerMultipleForm.reset();
  }

  onBuyerMultipleClick(note: any) {
    this.poBuyerMultipleService.setClosingPoByerMultiple(this.poBuyerMultipleForm.value.withdrawNote,note);
  }

  private subscribeOverwrittenExisting() {
    this.poBuyerMultipleService.onClosingPoBuyerMultipleRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }
}

