import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ActivityPanel } from '../models/acivity-panel.model';

@Injectable({ providedIn: 'root' })
export class ActivityPanelViewMoreModalService {
  private show$$ = new Subject<void>();
  private cancel$$ = new Subject<void>();

  public onShow$ = this.show$$.asObservable();
  public onCancel$ = this.cancel$$.asObservable();
  public activityPanelData$$ = new BehaviorSubject<ActivityPanel[]>([]);
  public activityPanelData$ = this.activityPanelData$$.asObservable();


  show(): void {
    this.show$$.next();
  }

  cancel(): void {
    this.cancel$$.next();
  }

}
