<ng-template #template>
	<div id="modalDrag" cdkDrag>
	  <div class="modal-header" cdkDragHandle>
		<h3 class="modal-title">Edit Transload Facility</h3>
		<button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
	  </div>
	  <div class="modal-body">
		<form [formGroup]="form" class="row g-3">
			<div class="col-md-12">
				<label for="editTransloadFacility" class="form-label">Transload Facility</label>
				<select  class="form-select" id="editTransloadFacility" aria-label="Edit Transload Facility" formControlName="facilityType" (change)="selectFacilityChange($event)">
					<option value="null">Select Option</option>
					<option class="ng-star-inserted" *featurePermission ="['transload:action:transload-facility:show-na']" value="N/A" >N/A</option>
					<option class="ng-star-inserted" *ngFor="let v of facilityList" [value]="v?.transloadFacilityCode" > {{v?.transloadFacilityCode}} </option>
				</select>
			 </div>
		</form>
	  </div>
	  <div class="modal-footer">
		<button type="button" class="btn bg-purple-300" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Save</button>
	  </div>
	</div>
  </ng-template>