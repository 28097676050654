<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header p-3" cdkDragHandle>
      <h2 class="m-0">All Activity</h2>
      <button class="bg-transparent border-0 p-0" (click)="onCancel()">
        <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
        <span class="visually-hidden">Close</span>
      </button>
    </div>
    <div class="modal-body pt-0 mt-4">
      <div class="container-fluid">
        <div class="row">
          <table class="table table-dark table-striped table-borderless">
            <thead class="text-purple">
              <th>Provider</th>
              <th>Status</th>
              <th>Date</th>
              <th>Location</th>
              <th>ISA Sent Date</th>
              <th>Sender ID</th>
            </thead>
            <tbody>
              <ng-container *ngIf="(data$ | async); let rowData">
                <tr *ngFor="let activityFeed of rowData">
                  <td>{{activityFeed?.providerDescription ?? '--'}}</td>
                  <td>{{activityFeed?.eventStatus ?? '--'}}</td>
                  <td>{{dateFormatter(activityFeed?.statusDate) ?? '--'}}</td>
                  <td>{{activityFeed?.locationName ?? '--'}}</td>
                  <td>{{dateFormatter(activityFeed?.isaSentDate) ?? '--'}}</td>
                  <td>{{activityFeed?.senderId ?? '--'}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>