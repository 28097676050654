import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface SaveUserPreferenceEventModalConfig {
    title: string,
    message?: string,
    btnOne:string,
    btnTwo:string
}

export enum SaveUserPreferenceDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface SaveUserPreferenceModalEvent {
    type: SaveUserPreferenceDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class SaveUserPreferenceModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<SaveUserPreferenceModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === SaveUserPreferenceDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === SaveUserPreferenceDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === SaveUserPreferenceDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === SaveUserPreferenceDialogueEvent.OnHidden));
  public onClosingSaveUserPreferenceRemove$ = this.event$.pipe(filter((x) => x.type === SaveUserPreferenceDialogueEvent.OnClosingRemove));
  public onClosingSaveUserPreferenceRemoved$ = this.event$.pipe(filter((x) => x.type === SaveUserPreferenceDialogueEvent.OnClosingRemoved));

  show(config: SaveUserPreferenceEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: SaveUserPreferenceModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingSaveUserPreference(params?: any) {
    this.event$.next({
      type: SaveUserPreferenceDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingSaveUserPreferenceRemoved() {
    this.event$.next({
      type: SaveUserPreferenceDialogueEvent.OnClosingRemoved,
    });
  }
}