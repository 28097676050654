<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
        <h2 class="m-0">{{ title$ | async }}</h2>
        <button class="bg-transparent border-0 p-0" (click)="onHide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="card">
        <div class="card-body px-0 py-0">
            <ag-grid-angular style="height: 50vh;" [class]="gridClass | async" [rowData]="data$ | async"
                [columnDefs]="defaultColumns" [defaultColDef]="defaultColDef" [pagination]="true"
                [suppressDragLeaveHidesColumns]="true" [paginationPageSize]="10" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
  </div>
</ng-template>