/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionDetails,
  ScheduledReport
} from './models/analytics-collection.model';
import _ from 'lodash';
import { Observable, tap } from 'rxjs';
import { ReportDetails } from '../analytics-card/models/analytics-card.model';
import { AppStateRepository } from '@cds-ui/shared/core-state';

declare const $: any;

export interface PersonaInfo {
  personaId: number;
  personaName: string;
  personaParentId: number;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsCollectionQueryService {
  allCollectionsRoute = '/user/permissions/analytics-library/allReports';
  userReportsRoute = '/analytics/userReports';
  sharedReportsRoute = '/analytics/sharedReports';
  baseRedisKey = 'analytics:analytics-library:collections';
  preferencesRoute = '/user/preferences/page/';

  logiServerLogoutUrl!: string;

  constructor(private appState: AppStateRepository, private http: HttpClient) {
    this.appState.environment$
      .pipe(
        tap((e) => {
          this.logiServerLogoutUrl = e.urls.logiServerLogout;
        })
      )
      .subscribe();
  }

  public getAllReports(collections: CollectionDetails[]) {
    let reports: any = [];

    _.forEach(collections, (collection) => {
      reports.push(collection.reportDetails);
    });
    reports = _.flattenDepth(reports);
    reports = _.sortBy(reports, (r) => r.cardTitle);
  }

  // #region GET...
  public getAllCollections(): Observable<any> {
    return this.http.get(this.allCollectionsRoute);
  }

  public getScheduledReports() {
    const scheduledReports: ScheduledReport[] = [];
    this.http
      .get<any>(`/analytics/scheduledReports`)
      .subscribe((scheduleReportInfo) => {
        // Cycle through resultset of Scheduled Reports...
        _.forEach(scheduleReportInfo, (scheduledReport) => {
          const scheduledEmails: any = [];
          _.forEach(scheduledReport.publish.toEmail.reportEmails, (x) => {
            let configuredEmails: any;

            // #region COMMENTS...

            /* This section is to account for the way Logi returns ScheduledReport Objects.
            If there is multiple emails configured, it is returned as JSON-object:
            to: '{"emails":["jsanchioni@cds-net.com"],"users":[],"groups":[],"roles":[]}',
            
            If there is only one email, it is returned as just a string:
            to: 'abc@abccompany.com',

            Also worth NOTING:
            I use _.includes() here because currently in Typescript, there is no CLEAN way to validate
            that a value returned is in valid format to be parsed using JSON.parse.- In the case we are
            accounting for here, "abc@abccompany.com" would fail and throw an error.- I did not want to
            use a TRY/CATCH block for this as it would potentially mean parsing the value 2x.- Other
            solutions called for writing complicated, unmaintainable RegEx, or using JSON prototype library
            that comes with Typescript (which can be very expensive)... */

            // #endregion COMMENTS...
            if (_.includes(x.to, 'emails')) {
              configuredEmails = JSON.parse(x.to);

              _.forEach(configuredEmails.emails, (e) => {
                scheduledEmails.push(e);
              });
            } else {
              configuredEmails = x.to;
              scheduledEmails.push(configuredEmails);
            }
          });

          const reportInfo: ScheduledReport = {
            reportName: _.trimStart(
              scheduledReport.reportInfo.report.replace('.wls', ''),
              '/Dashboard/Snowflake/'
            ),
            scheduleName: scheduledReport.name,
            nextRunTime: scheduledReport.nextRunTime,
            scheduleType: scheduledReport.timeCondition.periodically.date.type,
            scheduleDetails: scheduledEmails,
            taskId: scheduledReport.id
          };

          scheduledReports.push(reportInfo);
        });
      });

    return scheduledReports;
  }

  public getUserCollections(): Observable<any> {
    return this.http.get(this.preferencesRoute + this.baseRedisKey);
  }

  public getReports(collectionName:string) {
    let reportDetails: any = [];
    const apiRoute:string = collectionName === "My Analytics" ? this.userReportsRoute :
                            collectionName === "My Shared Analytics" ? this.sharedReportsRoute :
                            "";
    this.http.get<any>(apiRoute).subscribe((results) => {
      _.forEach(results, (reportCollection) => {
        const details: ReportDetails = {
          cardTitle: reportCollection.displayName.replace('.wls', ''),
          cardDescription: reportCollection.description,
          cardImg: 'MyAnalyticsDefault',
          tags: [],
          reportFullName: reportCollection.path,
          catalogName: reportCollection.linkedCatalog,
          isFavorited: false,
          isEditMode: true,
          showMoreInfo: false,
          allowAllSubgroup: false,
          permission: []
        };
        reportDetails.push(details);
      });
      if (reportDetails.length !== 0) {
        reportDetails = _.flattenDepth(reportDetails);
        reportDetails = _.sortBy(reportDetails, (r) => r.cardTitle);
      }
    });

    const reports = {
      collectionName: collectionName,
      reportDetails: reportDetails,
      collectionHasReports: reportDetails.length !== 0 ? true : false,
      showCollection: true,
      showIfEmpty: false,
      sequence: 2,
      lastUpdated: new Date()
    };
    return reports;
  }

  // #endregion GET...

  // #region DELETE...
  public deleteScheduledReports(taskId: string): Observable<any> {
    return this.http.delete<any>(`/analytics/scheduledReports/${taskId}`);
  }

  // #endregion DELETE...

  // #region UPDATE...

  public updateUserCollections(
    collectionDetails: CollectionDetails[]
  ): Observable<any> {
    const dictionary = { Collections: JSON.stringify(collectionDetails)};
    this.appState.ghostedUserPreferences$.next({
      path: `pages/${this.baseRedisKey}`,
      data: dictionary
    })
    return this.http.post(
      this.preferencesRoute + this.baseRedisKey,
      dictionary
    );
  }

  // #endregion UPDATE...

  // #region AJAX Calls...

  public logoutLogiSession() {
    /* NOTE: We are making a JQuery AJAX call here instead of using the Angular HttpClient because we
    need to make a JSONP request to the Logi Server.- Since this is a cross-domain request, we need to
    bypass CORS Cross-Site restrictions (we don't control the session cookie that gets created by Logi
    or we could potentially resolve this in the server configuration)... */
    $.ajax({
      url: this.logiServerLogoutUrl,
      dataType: 'jsonp',
      success: (response: any) => {
        console.log('Logi Server Response:', response);
      },
    });
  }

  // #endregion AJAX Calls...

  // #endregion Api Calls for Features...
}