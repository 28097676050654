<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header title-pd" cdkDragHandle>
      <h3 class="modal-title">{{config.title}}</h3>
      <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="form" class="row g-3">
        <div class="row">
          <div class="col-md-12">
            <label class="form-label">{{config.label}}</label>
            <input  id="startDate" 
                    placeholder="Select Date" 
                    formControlName="columnDate"
                    class="form-control calender-icon" 
                    bsDatepicker
                    autocomplete="off"
                    [minDate]="minDate" 
                    [maxDate]="maxDate"
                    [bsConfig]="datePickerConfig" />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn bg-purple-300" data-bs-dismiss="modal" (click)="close()">{{config.btnTwo}}</button>
      <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">{{config.btnOne}}</button>
    </div>
  </div>
</ng-template>