import { Component, Input, OnInit } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import moment from 'moment';
import { Observable } from 'rxjs';
import { BookingStatusActivity } from './mix-ins/booking-status-activity-types';

@Component({
  selector: 'cds-ui-booking-status-activity',
  templateUrl: './booking-status-activity.component.html',
  styleUrls: ['./booking-status-activity.component.scss'],
})
export class BookingStatusActivityComponent implements OnInit {
  public isLoading$ = this.appStateRepository.isLoading$;
  constructor(private appStateRepository: AppStateRepository) {}

  @Input('bookingStatusActivityData$') bookingStatusActivityData$! :Observable<BookingStatusActivity[]>;

  ngOnInit(): void {}

  public getFormattedDateTime(date?: string, isTimeReuired?: boolean) {
    return date ? moment.utc(date).isValid() ? isTimeReuired ? moment.utc(date).format('MM/DD/yyyy h:mm:ss A') : moment.utc(date).format('MM/DD/yyyy') : null : null;
	}

}
