import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { addEntities, deleteEntities, getAllEntities, getEntity, hasEntity, selectAllEntities, updateEntities } from "@ngneat/elf-entities";
import { map, tap, catchError, of, filter, Subject } from "rxjs";
import { DashboardApiService } from "./dashboard.api";
import { DashboardTemplateConfig } from "./models/dashboard-model";
import { templateStore } from "./stores/dashboard-template.store";

enum DashboardTemplateUiEvent {
}

interface DashboardTemplateAction {
    type: DashboardTemplateUiEvent,
    data: DashboardTemplateConfig
}

@Injectable({ providedIn: 'root' })
export class DashboardTemplateRepository implements OnDestroy {

    private destroy$ = new Subject<void>();
    private event$ = new Subject<DashboardTemplateAction>();

    //public onSaveInit$ = this.event$.pipe(filter(x => x.type === DashboardTemplateUiEvent.OnSaveInit));
    //public onSave$ = this.event$.pipe(filter(x => x.type === DashboardTemplateUiEvent.OnSave));
    //public onSaved$ = this.event$.pipe(filter(x => x.type === DashboardTemplateUiEvent.OnSaved));
    //public onSaveConflict$ = this.event$.pipe(filter(x => x.type === DashboardTemplateUiEvent.OnSaveConflict));

    //public onInitApply$ = this.event$.pipe(filter(x => x.type === DashboardTemplateUiEvent.OnInitApply));
    //public onApply$ = this.event$.pipe(filter(x => x.type === DashboardTemplateUiEvent.OnApply));

    templates$ = templateStore.pipe(selectAllEntities(), map(x => this.dropdownMapper(x as never)));

    constructor(private apiService: DashboardApiService) {
        const mock = { "templateName": "Mock data", "content": { "originLocation": "90061", "deliveryLocation": null, "finalDestination": null, "vendorCode": "30009743", "vendorReferences": null, "shipper": null, "shipMode": "O", "freightType": "CY/CY", "fcrRemarks": null, "exportLicense": false, "solidWoodPacking": false }};
        templateStore.update(addEntities(mock as unknown as DashboardTemplateConfig))
    }

    private dropdownMapper(templates: DashboardTemplateConfig[]) {
        const arr = templates.map(i => ({ value: i.templateName, display: i.templateName }));
        arr.unshift({ value: '', display: 'Prefill from Template' });

        return arr;
    }

    public getKeys() {
        return this.dropdownMapper(templateStore.query(getAllEntities()));
    }

    public get(templateName: string) {
        return templateStore.query(getEntity(templateName));
    }

    public has(templateName: string) {
        return templateStore.query(hasEntity(templateName));
    }

    /*public saveInit(formName: string, pageName: string, bookingTemplate: BookingTemplate) {
        this.event$.next({ type: BookingTemplateUiEvent.OnSaveInit, data: { templateName: '', formName, pageName, content: bookingTemplate } });
    }

    public save(config: BookingTemplateConfig) {
        return this.has(config.templateName ?? '')
             ? this.event$.next({ type: BookingTemplateUiEvent.OnSaveConflict, data: config })
             : this.create(config)
                   .subscribe(_ => this.saved(config));
    }

    public saved(config: BookingTemplateConfig) {
        this.event$.next({ type: BookingTemplateUiEvent.OnSaved, data: config });
    }

    public confirmSave(bookingTemplate: BookingTemplateConfig) {
        this.update(bookingTemplate)
                 .subscribe(_ => this.saved(bookingTemplate));
    }

    public initApply(config: BookingTemplateConfig) {
        this.event$.next({ type: BookingTemplateUiEvent.OnInitApply, data: config });
    }

    public confirmApply(config: BookingTemplateConfig) {
        this.event$.next({ type: BookingTemplateUiEvent.OnApply, data: config });
    }

    private create(template: BookingTemplateConfig) {
        return this.apiService.createTemplate(template)
            .pipe(
                map(event => event.instance),
                map(result => ({ ...template, ...result })),
                tap(result => templateStore.update(addEntities(result))),
                catchError((err: HttpErrorResponse) => {
                    templateStore.update(deleteEntities(template.templateName));
                    throw err;
                })
            );
    }

    private update(template: BookingTemplateConfig) {
        return this.apiService.updateTemplate(template)
            .pipe(
                map(event => event.instance),
                map(result => ({ ...template, ...result })),
                tap(result => templateStore.update(updateEntities(result.templateName, result))),
                catchError((err: HttpErrorResponse) => {
                    templateStore.update(updateEntities(template.templateName, template));
                    throw err;
                })
            )
    }*/

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
