<div *ngIf="!isWidget; else widgetTemplate">
  <div class="row mx-0 gap-3">
    <div [ngClass]="{'activeTallyCard' : activeIndex === i}"
      class="tracker-card card bg-transparent border col hoverTallyCard"
      *ngFor="let card of cards$ | async; let i = index" (click)="selectCard(card, i)" 
      [id]="'tally-card-' + card.label.split(' ').join('-').toLowerCase()">
      <div class="card-header bg-transparent d-flex align-items-end mx-3 px-0">
        <h1 class="m-0 font-monospace">{{ card.count }}
          <ng-template [ngIf]="card.totalValue">
            <span style="font-size:12px;">${{ card.totalValue | number:'1.0-0'.toLocaleString() }}</span>
          </ng-template>
        </h1>        
      </div>
      <div class="card-body">
        <h5 class="border-bottom pb-2">{{ card.label }}</h5>
        <p class="fs-md m-0">{{ card.description }}</p>
      </div>
    </div>
  </div>
</div>

<ng-template #widgetTemplate>
  <ul class="list-unstyled tally-list tally-list-item" *ngFor="let card of cards$ | async, let i = index" (click)="selectCard(card, i)">
    <li class=" d-flex align-items-end mb-3 px-1 py-2 hoverTallyCard" [id]="card.id ?? ('widget-count' + card.label.split(' ').join('-').toLowerCase())">
      <span class="fs-sm tally-list-heading"><span class="tally-heading-content">{{card.label}}</span></span>
      <span class="border-spacer flex-grow-1"></span>
      <span class="tally-list-value font-monospace">{{ card.count }}</span>
    </li>
  </ul>
</ng-template>