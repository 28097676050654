<ag-grid-angular
    [class]="gridClass | async"
    [domLayout]="domLayout"
    [rowData]="dataSource$ | async"
    rowSelection="multiple"
    [rowGroupPanelShow]="'always'"
    [suppressRowGroupHidesColumns]="true"
    [columnDefs]="column$ | async"
    [gridOptions]="gridOptions"
    [enableRangeSelection]="true"
    [pagination]="true"
    [animateRows]="true"
    [suppressScrollOnNewData]="true"
    [overlayLoadingTemplate]="loadingTemplate"
    [suppressDragLeaveHidesColumns]="true"
    [suppressRowClickSelection]="true"
    (cellValueChanged)="onCellValueChanged($event)"
    (cellEditingStopped)="onCellEditingStopped($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReady)="onGridReady($event)"
    [frameworkComponents]="frameworkComponents"
    [getMainMenuItems]="getMainMenuItems"
    >
</ag-grid-angular>
