import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { groupByMap } from '@cds-ui/shared/core-rx';
import { Router } from '@angular/router';
import { AppStateRepository } from '@cds-ui/shared/core-state';

export interface NavigationMenuItem {
  DisplayGroup: string
  DisplayItemName: string,
  NavigationName: string,
  ItemUrl: string,
  ItemUrlGroup: string
}

export interface IFeaturesAssignedForUserData {
  pageFeatureId: number,
  pageName: string,
  pageFeatureName: string,
  personaId: number,
  personaName: string
}

export interface SystemSearchResult {
  keyValue: string,
  keyName: string,
  displayName: string,
  queryString: string
}

export interface NavigationConfiguration extends NavigationMenuItem {
  subMenu: NavigationMenuItem[]
}

export interface SystemSearch {
  name:string,
  data:SystemSearchResult[]
}

export interface NavigationLegacyMenuItem {
  displayName: string;
  megaMenuSequence: number;
  route?: route;
  subMenu?: subMenu[];
}
export interface subMenu extends subMenuHeaderItems {
  subMenuHeader?: string;
  subMenuHeaderItems?: subMenuHeaderItems[];
}
export interface subMenuHeaderItems {
  columnHeader?: string;
  configName?: string;
  displayName?: string;
  moduleCd?: string;
  sequence?: number;
  url?: string;
}
export interface megaMenu {
  mainItem: mainItem;
  subMenu: subMenuHeaderItems;
}
export interface mainItem {
  displayName: string;
  megaMenuSequence: number;
  route?: route;
}
export interface route {
  moduleCd: string;
  configName: string;
}

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {

  constructor(private http: HttpClient, private router: Router, private appState: AppStateRepository) { }

  private legacyUrl$ = new Subject<any>();
  public getLegacyUrl$ = this.legacyUrl$.asObservable();

  public containerGridSearchSubject$ = new BehaviorSubject<any>(null);
  public containerGridSearchDataSource$ = this.containerGridSearchSubject$.asObservable();

  public porGridSearchSubject$ = new BehaviorSubject<any>(null);
  public porGridSearchDataSource$ = this.porGridSearchSubject$.asObservable();

  public shipmentGridSearchSubject$ = new BehaviorSubject<any>(null);
  public shipmentGridSearchDataSource$ = this.shipmentGridSearchSubject$.asObservable();

  public mileStoneGridSearchSubject$ = new BehaviorSubject<any>(null);
  public mileStoneGridSearchDataSource$ = this.mileStoneGridSearchSubject$.asObservable();
  
  public inboundGridSearchSubject$ = new BehaviorSubject<any>(null);
  public inboundGridSearchDataSource$ = this.inboundGridSearchSubject$.asObservable();

  public inboundGridEditSearchSubject$ = new BehaviorSubject<any>(null);
  public inboundGridEditSearchDataSource$= this.inboundGridEditSearchSubject$.asObservable();

  // HttpClient API get() method
  getTopNavigationMenu(): Observable<NavigationMenuItem[]> {
    return this.http
      .get<{ NavigationName: NavigationConfiguration[] }>('/menu/view/GetNavigationMenuForUser')
      .pipe(
        map(data => data.NavigationName),
        map(data => data.map(x => x.subMenu
          ? { ...x, ...x.subMenu, DisplayGroup: x.DisplayItemName }
          : { ...x, DisplayGroup: x.DisplayItemName })
        ),

        map(x => x.map(s => ({ ...s, ItemUrl: `/${s.ItemUrl.replace(/%2F/g, '/')}` }))),
        map(x => x.map(i => ({ ...i, ItemUrlGroup: i.ItemUrl.split('/')[1] }))),
        map(x => x as NavigationMenuItem[])
      );
  }

  getLegacyNavigationMenu() {
    return this.http
      .get<{ megamenu: megaMenu[] }>('/pagelayout/view/GetMenuForLegacy')
      .pipe(
        map(data => data.megamenu),
        map(data => data.map(x => ({ megaMenuDisplayName: x.mainItem.displayName, megaMenuSequence: x.mainItem.megaMenuSequence, route: x.mainItem.route ?? '', ...x.subMenu }))),
        groupByMap(
          x => x.megaMenuSequence + (x.columnHeader ?? ''),
          ([first, group]) => ({
            displayName: first.megaMenuDisplayName,
            megaMenuSequence: first.megaMenuSequence,
            route: first.route,
            subMenu: [{
              subMenuHeader: first.columnHeader,
              subMenuHeaderItems: group
            }]
          })
        ),
        groupByMap(
          x => x.displayName,
          ([first, group]) => ({
            ...first,
            subMenu: group.flatMap(x => x.subMenu)
          })
        )
      );
  }

  getSystemSearchValueData(keyNames: string, query: string): Observable<SystemSearchResult[]> {
    return this.http.get<SystemSearchResult[]>(
      `search/suggestions?jsonSearchOptions=${keyNames}&keyValue=${query}`
    );
  }

  getCreateBookingFeatureForUser(): Observable<IFeaturesAssignedForUserData[]> {
    return this.http.get<IFeaturesAssignedForUserData[]>('menu/view/GetFeaturesAssignedForUser?PageName=global');
  }

  getPendoInfo(): Observable<any> {
    return this.http
      .get('/pagelayout/view/GetPendoInfo');
  }

  setLegacyUrl(url: any) {
    this.legacyUrl$.next(url);
  }

getUserPreference(path:string): Observable<any> {
  return this.http.get(`/user/preferences?path=${path}`);
}

setUserPreference(cacheData: any): Observable<any> {
  return this.http.post("/user/preferences", cacheData);
}

getPoStatus(): Observable<any> {
  return this.http.get(`/advancedSearch/getPoStatus`);
}

getShipmentStatus(): Observable<any> {
  return this.http.get(`/advancedSearch/getShipmentStatusCodes`);
}

getBookingStatus(): Observable<any> {
  return this.http.get(`/advancedSearch/getBookingStatus`);
}
getVendorList(key:any): Observable<any> {
  return this.http.get(`/advancedSearch/getVendorList?keyValue=${key}`);
}


  advanceSearch(payLoad:any): Observable<any> {
    return this.http.post(`/advancedSearch/getAdvancedSearchResults`,payLoad);
  }


}
