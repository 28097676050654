<!-- Top navigation menu -->
<nav class="navbar navbar-expand-lg navbar-dark bg-gray-600">
    <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup" *ngIf="(routerUrlGroup$ | async) as url">
            <ul class="navbar-nav nav w-100">
                <li class="nav-item">
                    <a class="nav-link"
                        [ngClass]="{ active: menuMapping['my-day'].activeUrlFragment.indexOf(url) >= 0 }"
                        [routerLink]="menuMapping['my-day'].url">{{ menuMapping['my-day'].name }}</a>
                </li>

                <ng-container *globalFeaturePermission="permissionQuery$; callback: menuPermission$$"></ng-container>
                <li class="nav-item" *ngFor="let item of (menuPermission$ | async)"
                    [ngClass]="!item.subMenu ? '' : 'dropdown'">
                    <a class="nav-link" id="{{item.name}}" *ngIf="!item.subMenu; else subMenuTemplate"
                        [ngClass]="{ active: item.activeUrlFragment.indexOf(url) >= 0 }" [routerLink]="[item.url]">{{ item.name }}</a>
                    <ng-template #subMenuTemplate>
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false" [ngClass]="{ active: item.activeUrlFragment.indexOf(url) >= 0 }"
                            id="{{item.name}}">{{ item.name }}</a>
                        <ul class="dropdown-menu" [attr.aria-labelledby]="item.name">
                            <li *ngFor="let subMenuItem of item.subMenu;">
                                <a class="dropdown-item" [routerLink]="subMenuItem.url"
                                    [ngClass]="{ active: subMenuItem.activeUrlFragment.indexOf(url) >= 0 }">{{ subMenuItem.name }}</a>
                            </li>
                        </ul>
                    </ng-template>
                </li>

                <li class="nav-item" [ngClass]="'dropdown'">
                    <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false" [ngClass]="{ active: ((menuMapping['analyticsLibrary'].activeUrlFragment.indexOf(url) >= 0) || 
                                                                    (menuMapping['analyticsReporting'].activeUrlFragment.indexOf(url) >= 0)) }"
                        id="{{menuMapping['analyticsLibrary'].group}}">{{ menuMapping['analyticsLibrary'].group }}</a>
                    <ul class="dropdown-menu" [attr.aria-labelledby]="menuMapping['analyticsLibrary'].group">
                        <li>
                            <a class="dropdown-item" [routerLink]="menuMapping['analyticsLibrary'].url"
                                [ngClass]="{ active: menuMapping['analyticsLibrary'].activeUrlFragment.indexOf(url) >= 0 }">{{ menuMapping['analyticsLibrary'].name }}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" [routerLink]="menuMapping['analyticsReporting'].url"
                                [ngClass]="{ active: menuMapping['analyticsReporting'].activeUrlFragment.indexOf(url) >= 0 }">{{ menuMapping['analyticsReporting'].name }}</a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item ms-auto">
                    <a (mouseenter)="openMoreMenu()" id="moreMenu" class="nav-link label d-flex align-items-center text-decoration-none px-4 link-secondary">
                        <span class="material-icons mi-24">menu</span>&nbsp;More
                        <span class="material-icons mi-24">chevron_right</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!-- offcanvas sidebar menu -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="moreMenuRight" aria-labelledby="moreMenuRightLabel">
  <div class="offcanvas-header">
      <h5 class="h2" id="moreMenuRightLabel">More</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body overflow-visible">
      <div class="list-group" *ngIf="(megaMenuMappedData$ | async) as megaMenuMappedData">
          <div class="dropstart" *ngFor="let mainMenu of megaMenuMappedData">
          <a (click)="navigateMainMenu(mainMenu)" title="" class="list-group-item list-group-item-action d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span class="material-icons text-success">chevron_left</span>{{mainMenu?.displayName}}</a>
              <ul class="dropdown-menu dropdown-three-col" *ngIf="mainMenu?.subMenu && mainMenu?.subMenu?.length">
                  <li *ngFor="let menu of mainMenu?.subMenu" class="sub-menu">
                      <div class="dropstart">
                        <span class="fw-bold text-purple">{{menu?.subMenuHeader}}</span>
                        <ul class="list-unstyled" *ngIf="menu?.subMenuHeaderItems && menu?.subMenuHeaderItems?.length">
                            <li *ngFor="let subMenu of menu.subMenuHeaderItems">
                                <a (click)="navigateSubMenu(subMenu)" role="button"
                                class="dropdown-item">{{subMenu?.displayName}}</a>
                            </li>
                        </ul>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</div>
