import { Injectable, OnDestroy } from '@angular/core';
import { ContainerFinalDestinationSchedule, Edi315Event, IntermodalContainer, IntermodalLeg, Maybe, Shipment } from '@cds-ui/data-access';
import moment from 'moment';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface LocationDateEventModalData {
  data: any
}

export enum LocationDateDialogueEvent {
  OnShow,
  OnShown,
  OnHide,
  OnHidden,
  OnClosingRemove,
  OnClosingRemoved
}

interface LocationDateModalEvent {
  type: LocationDateDialogueEvent,
  params?: any
}

@Injectable({ providedIn: 'root' })

export class LocationDateModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<LocationDateModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<any> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === LocationDateDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === LocationDateDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === LocationDateDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === LocationDateDialogueEvent.OnHidden));
  public onClosingLocationDateRemove$ = this.event$.pipe(filter((x) => x.type === LocationDateDialogueEvent.OnClosingRemove));
  public onClosingLocationDateRemoved$ = this.event$.pipe(filter((x) => x.type === LocationDateDialogueEvent.OnClosingRemoved));

  show(finalDestinationSchedule: ContainerFinalDestinationSchedule | null | undefined, events: Edi315Event[], intermodalLegs: Maybe<IntermodalLeg[]>,yardArriveSchedule:any = null): void {
    this.showSubject$.next(this.getLocationDateData(finalDestinationSchedule, events, intermodalLegs,yardArriveSchedule));
  }

  handle(event: LocationDateModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingLocationDate(params?: any) {
    this.event$.next({
      type: LocationDateDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingLocationDateRemoved() {
    this.event$.next({
      type: LocationDateDialogueEvent.OnClosingRemoved,
    });
  }

  getLocationDateData(finalDestinationSchedule: ContainerFinalDestinationSchedule | null | undefined, events: Edi315Event[], intermodalLegs: Maybe<IntermodalLeg[]>,yardArriveSchedule:any = null) {
    const feederLeg = (intermodalLegs ?? []).find(l => l.loadSequence === "F1");
    const motherLeg = (intermodalLegs ?? []).find(l => l.loadSequence === "M");

    const isShowMotherDepartInfo = feederLeg?.departPort?.locationName == null || (motherLeg?.departPort?.locationName && feederLeg?.departPort?.locationName == motherLeg?.departPort?.locationName);

    return [{
      displayName: "Feeder Port Depart",
      location: isShowMotherDepartInfo ? motherLeg?.departPort?.locationName : feederLeg?.departPort?.locationName,
      planned: this.showAsUtcDate(isShowMotherDepartInfo ? motherLeg?.departPortSchedule?.publishedDepartureDate : feederLeg?.departPortSchedule?.estimatedDepartureDate),
      currentPlan: this.showAsUtcDate(isShowMotherDepartInfo ? motherLeg?.departPortSchedule?.estimatedDepartureDate : feederLeg?.departPortSchedule?.estimatedDepartureDate),
      actual: this.showAsUtcDate(isShowMotherDepartInfo ? motherLeg?.departPortSchedule?.actualDepartureDate : feederLeg?.departPortSchedule?.actualDepartureDate)
    }, {
      displayName: "Mother Port Depart",
      location: motherLeg?.departPort?.locationName,
      planned: this.showAsUtcDate(motherLeg?.departPortSchedule?.publishedDepartureDate),
      currentPlan: this.showAsUtcDate(motherLeg?.departPortSchedule?.estimatedDepartureDate),
      actual: this.showAsUtcDate(motherLeg?.departPortSchedule?.actualDepartureDate)
    }, {
      displayName: "Vessel Arrive",
      location: motherLeg?.arrivePort?.locationName,
      planned: this.showAsUtcDate(motherLeg?.arrivePortSchedule?.publishedArriveDate),
      currentPlan: this.showAsUtcDate(motherLeg?.arrivePortSchedule?.estimatedArriveDate),
      actual: this.showAsUtcDate(motherLeg?.arrivePortSchedule?.actualArriveDate),
      managedBy:motherLeg?.managedBy
    }, {
      displayName: "Final Arrive",
      location: finalDestinationSchedule?.port?.locationName,
      planned: this.showAsUtcDate(finalDestinationSchedule?.publishedArrivalDate),
      currentPlan: this.showAsUtcDate(finalDestinationSchedule?.estimatedArrivalDate),
      actual: this.showAsUtcDate(yardArriveSchedule ? yardArriveSchedule?.actualArrivalDate : finalDestinationSchedule?.actualArrivalDate)
    },{
      displayName: yardArriveSchedule ? "Rail Arrive" : '',
      location: yardArriveSchedule?.port?.locationName,
      planned: this.showAsUtcDate(yardArriveSchedule?.publishedArrivalDate),
      currentPlan: this.showAsUtcDate(yardArriveSchedule?.estimatedArrivalDate),
      actual: this.showAsUtcDate(yardArriveSchedule ? finalDestinationSchedule?.actualArrivalDate : yardArriveSchedule?.actualArrivalDate)
    }
   ];
  }

  showAsUtcDate(date: any) {
    return date ? moment.utc(date).format("MM/DD/YYYY") : null;
  }
}