import { CellClickedEvent, CellEditorSelectorResult, ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, ICellEditorParams, ILargeTextEditorParams } from '@ag-grid-community/core';
import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { DeclineCounterDialogueEvent, DeclineCounterEventModalConfig, DeclineCounterModalService } from './decline-counter-modal.service';
import { select } from '@ngneat/elf';
import moment from 'moment';
import { counterDeclineEditorComponent } from '@cds-ui/shared/ui-grid';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'cds-ui-decline-counter-modal',
  templateUrl: './decline-counter-modal.component.html',
  styleUrls: ['./decline-counter-modal.component.scss'],
})
export class DeclineCounterModalComponent implements OnInit {

  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  public modalRef!: BsModalRef;
  public config!: DeclineCounterEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;
  public rowData: any[] = [];
  public dropDownValues:any;
  gridClass = this.appStateRepository.theme$.pipe(select((x) => x.grid));
  loadingTemplate = `<span class="ag-overlay-loading-center">Loading...</span>`;
  public gridApi!: GridApi;
  gridColumnApi!: ColumnApi;
  stopEditing:boolean = true;
  companyCode:any;
  public gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      suppressMenu: false,
      filter: true,
      sortable: true,
      enableRowGroup: true,
      filterParams: {
        trimInput: true,
      },

    }
  };

  public defaultColumns: ColDef[] = [
    {
      headerName: 'Item #', field: 'item'
    },
    {
      headerName: 'Field', field: 'changeType'
    },
    {
      headerName: 'Original Value', field: 'originalValue',
      cellEditorParams:(params:ICellEditorParams) => {
        if(params.data.fieldChanged == 'Change Due Date' || params.data.fieldChanged == 'Change Ship Date' || params.data.fieldChanged == 'Change VCW Begin'){
          return this.dateFormatter(params.value);
        }else{
          return params.value;
        }
      }
    },
    {
      headerName: 'Change Value', field: 'changeValue',
      cellEditorParams:(params:ICellEditorParams) => {
        if(params.data.fieldChanged == 'Change Due Date' || params.data.fieldChanged == 'Change Ship Date' || params.data.fieldChanged == 'Change VCW Begin'){
          return this.dateFormatter(params.value);
        }else{
          return params.value;
        }
      }
    },
    {
      headerName: 'Counter', field: 'counter',
      singleClickEdit: true, cellClass: "ag-cell-editable",
      editable: true,
      cellRenderer: this.valueFormatterCouter.bind(this) ,
      minWidth: 200,
      cellEditorParams:(params: ICellEditorParams) =>{
        if(params.data.fieldChanged == 'Change Due Date' || params.data.fieldChanged == 'Change Ship Date' || params.data.fieldChanged == 'Change VCW Begin'){
          this.gridOptions.stopEditingWhenCellsLoseFocus = false;
        }else{
          this.gridOptions.stopEditingWhenCellsLoseFocus = true;
        }
        return {isType: params.data.fieldChanged, changeLevel: params.data.changeLevel, dropData:this.dropDownValues}
     },
      cellEditorSelector: this.cellEditorSelector,
    },
    {
      headerName: 'Note', field: 'note', 
      singleClickEdit: true, 
      cellClass: "ag-cell-editable",
      cellEditor: 'agLargeTextCellEditor',
      editable: true, minWidth: 400,
      cellEditorParams:(params: ICellEditorParams)=> {
        this.gridOptions.stopEditingWhenCellsLoseFocus = true;
     return {  rows: 5,
        cols: 45} as ILargeTextEditorParams
      },
    }
  ];
  constructor(
    private modalservice: BsModalService,
    private declineCounterModalService: DeclineCounterModalService,
    private appStateRepository: AppStateRepository,
    private toastrService:ToastrService
  ) { }

  ngOnInit(): void { }

  cellEditorSelector(
    params: ICellEditorParams<any>
  ): CellEditorSelectorResult | undefined {
    
    if (params.data.changeType === 'Cancel' || (params.data.changeType === 'Change FOB' && params.data.changeLevel === 'Item') ||
    params.data.changeType === 'Change Ship Method' || params.data.changeType === 'Initiated By') {
      return {
        component:'agRichSelectCellEditor',
        params: {
          values:params.data.changeType === 'Cancel' ? ['True' , 'False'] : params.data.changeType === 'Change FOB' ?
            params.data.dropData.changeFobData : params.data.changeType === 'Change Ship Method' ? 
            params.data.dropData.shipMethod :  params.data.dropData.initiatedData,
        },
        popup: true,
        popupPosition:'under'
      };
    }else{
      return {
        component: counterDeclineEditorComponent,
      };
    }
  }
  
  ngAfterViewInit(): void {
    this.subscribeOverwrittenExisting();
    this.appStateRepository.availableCompanies$.subscribe((resp) => {
      this.companyCode = resp.some(c => c.companyCode === '110') ? true : false;
     })
    this.declineCounterModalService.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error: any) => {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.config = error as DeclineCounterEventModalConfig;
        this.dropDownValues = error.dropDownData;
        this.gridOptions.stopEditingWhenCellsLoseFocus = false;
        this.rowData = error.data.map((x: any) => {
          return {
            ...x,
            counter: '',
            note: '',
            dropData:error.dropDownData
          }
        });
        console.log(this.rowData ,'this.rowData ')
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
      });

    this.handler(
      this.modalservice.onShow,
      DeclineCounterDialogueEvent.OnShow
    );
    this.handler(
      this.modalservice.onShown,
      DeclineCounterDialogueEvent.OnShown
    );
    this.handler(
      this.modalservice.onHide,
      DeclineCounterDialogueEvent.OnHide
    );
    this.handler(
      this.modalservice.onHidden,
      DeclineCounterDialogueEvent.OnHidden
    );
  }

  handler(handler: EventEmitter<any>, type: DeclineCounterDialogueEvent) {
    handler.pipe(takeUntil(this.destroy$))
      .subscribe((_) =>
        this.declineCounterModalService.handle({ type: type })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDeclineCounterClick() {
    this.gridApi.stopEditing();
    this.gridOptions.stopEditingWhenCellsLoseFocus = false;
    if(this.rowData.filter(x=>!x.counter).length){
      this.toastrService.error('Counter Value Is Required');
    }if(this.rowData.filter(x=>!x.note).length){
      this.toastrService.error('Note Value Is Required');
    }
    if(this.rowData.filter(x=>!x.note || !x.counter).length == 0)    
    {
      this.declineCounterModalService.setClosingDeclineCounter(this.rowData);
    }

  }

  private subscribeOverwrittenExisting() {
    this.declineCounterModalService.onClosingDeclineCounterRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.modalRef.hide());
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll(params.columnApi, false);
  }

  private autoSizeAll(api: ColumnApi, skipHeader: boolean) {
    const allColumnIds: any = api
      .getColumns()
      ?.map((column) => column.getColId());
    api.autoSizeColumns(allColumnIds, skipHeader);
  }

  getRowData() {
    const rowData: any[] = [];
    this.gridApi.forEachNode((node) => rowData.push({ ...node.data }));
    return rowData;
  }

  dateFormatter(params: any): string | any {
    if (params) {
      return moment.utc(params).format('MM/DD/YYYY');
    }
  }

  valueFormatterCouter(params:any):string | any {
    if(params.data){
      if(params.data.fieldChanged == 'Change Due Date' || params.data.fieldChanged == 'Change Ship Date' || params.data.fieldChanged == 'Change VCW Begin'){
        return this.dateFormatter(params.value);
      }else{
        return params.value;
      }
    }
    return;
  }
}
