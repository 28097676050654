<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="card">
        <ag-grid-angular style="height: 50vh;" [class]="gridClass | async" [rowData]="gridData"
      [columnDefs]="defaultColumns" [defaultColDef]="defaultColDef"  [pagination]="true" [enableRangeSelection]="true" [suppressDragLeaveHidesColumns]="true"
      [paginationAutoPageSize]="true" (gridReady)="onGridReady($event)"
      >  
    </ag-grid-angular>
    </div>
  </div> 
</ng-template>