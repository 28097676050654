<ng-template #template>
 <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-grow-1 font-details">
        <span>{{config.message}}</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="modalRef?.hide()">{{config.btnTwo}}</button>
        <button type="button" class="btn btn-submit" (click)="onReviewClick()"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>{{config.btnOne}}</button>
        <!-- text-light ms-2 -->
       </div>
 </div>
</ng-template>