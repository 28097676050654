import { Pipe, PipeTransform } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import _ from 'lodash';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'lrmReplace'
})
export class LrmReplacePipe implements PipeTransform {

  constructor(private appState: AppStateRepository){}

  transform(value: any, args?: any): Observable<any> {
    return this.appState.lrmData$.pipe(map(lrm => {
      const match =  _.find(lrm, a => value === a?.findDisplay);
      if(match){
        value = match?.replaceDisplay;
        return value;
      }else {
        return value;
      }
    }));
  }

}
