import { Component, Input, OnInit } from '@angular/core';
import { TitleCasePipe } from '@cds-ui/ui-directive';

@Component({
  selector: 'cds-ui-vessel-schedule-view-pane',
  templateUrl: './vessel-schedule-view-pane.component.html',
  styleUrls: ['./vessel-schedule-view-pane.component.scss'],
})
export class VesselScheduleViewPaneComponent implements OnInit {
  constructor() {}
 @Input() vesselData:any;
 
titlecase = new TitleCasePipe();
ngOnInit(): void {}
  
portVal(name:string){
  return this.titlecase.transform(name.split(',')[0]);
}

}
