export class DashBoardCards {
    private constructor(public readonly labelName: string, public readonly description: string, public readonly color: string, public readonly milestoneName: string) { }

    public static readonly AtOriginPort = new DashBoardCards('At Origin Port', 'Containers with a most current status event indicating it is at the origin port.','bg-warning','ContainerAtOriginPort');
    public static readonly OnWater = new DashBoardCards('On Water', 'Containers with a most current status event indicating it is on the water.','bg-info','ContainerOnWater');
    public static readonly AtDischargePort = new DashBoardCards('At Discharge Port', 'Containers with a most current status event indicating it is at the discharge port.','bg-danger','ContainerAtDischargePort');
    public static readonly FinalMile = new DashBoardCards('Final Mile', 'Containers with a most current status event indicating it is in it’s final mile delivery.','bg-success','ContainerAtFinalMile');
    public static readonly Indeterminate = new DashBoardCards('Indeterminate', 'Containers with no status events to determine it’s location or current status.','','Indeterminate');

    public static readonly PoNotBooked = new DashBoardCards('POs Not Booked', 'PO’s missing their milestone dates','','');
    public static readonly BookingInProgress = new DashBoardCards('Bookings In Progress', 'PO’s associated to bookings in the last 30 days that have not moved to a shipment','','');
    public static readonly LateShipments = new DashBoardCards('Late Shipments', 'PO’s late against their milestone dates','','');
    public static readonly OnSchedule = new DashBoardCards('On Schedule', 'PO’s meeting their milestone dates','','');

    public static readonly UpdatedEta = new DashBoardCards('Updated ETA', 'Shipments with new ETA date where Est. Arrival in last 7 or next 30 days.','','isUpdatedETA');
    public static readonly NewAdvice = new DashBoardCards('New Advices', 'Shipment Advices not yet printed.','','isNewAdvice');
    public static readonly ScannedDocs = new DashBoardCards('Scanned Documents', 'Documents pending acknowledgement.','','isScannedDocument');
    public static readonly InProcess = new DashBoardCards('In Process', 'All shipments that have not been completed.','','isInProcess');

    public static readonly InboundToPort = new DashBoardCards('Inbound to Port', 'Shipments arriving in 14 days','','1-Inbound to Port');
    public static readonly AtPort = new DashBoardCards('At Port', 'Shipments arrived at port','', '2-At Port');
    public static readonly InboundToDc = new DashBoardCards('Inbound to DC', 'Shipments outgated from Terminal','','3-Inbound to DC');

    public static readonly Submitted = new DashBoardCards('PO Change Requests Pending', 'PO change requests pending action.','','');
    public static readonly Accepted = new DashBoardCards('PO Change Requests Approved', 'PO change requests approved.','','');
    public static readonly IsPurchaseOrdersPending = new DashBoardCards('POs Pending Acknowledgement', 'Newly issued PO’s pending vendor acknowledgement.','','');
    public static readonly IsPurchaseOrderChangesPending = new DashBoardCards('PO Changes Pending Acknowledgement', 'Newly issued changed PO’s pending vendor acknowledgement.','','');
    public static readonly IsCancelled = new DashBoardCards('Cancelled POs', 'PO’s that were cancelled and are pending vendor acknowledgement.','','');
    
    public static readonly ItemMissingExpiredNumber = new DashBoardCards('Items Missing or Expired HTS Number', 'Items are missing a harmonized tariff code or have an expired code.','','Items Missing or Expired HTS Number');
    public static readonly TransloadInboundToPort = new DashBoardCards('Inbound to Port', 'Shipments arriving in 14 days and planned for transload.','','');
    public static readonly TransloadAtPort = new DashBoardCards('At Port', 'Shipments arrived at port and planned for transload.','','');
    public static readonly TransloadAtTransloadFacility = new DashBoardCards('At Transload Facility', 'Shipments arrived at transload facility.','','');
    public static readonly TransloadInboundToDc = new DashBoardCards('Inbound to DC', 'Shipments departed transload facility.','','');
  }

export const tempInbound = [
  {
    id: '19',
    label: DashBoardCards.InboundToPort.labelName,
    descriptions: DashBoardCards.InboundToPort.description,
    count: 0,
    totalValue: 0
  },
  {
    id: '20',
    label: DashBoardCards.AtPort.labelName,
    descriptions: DashBoardCards.AtPort.description,
    count: 0,
    totalValue: 0
  },
  {
    id: '21',
    label: DashBoardCards.InboundToDc.labelName,
    descriptions: DashBoardCards.InboundToDc.description,
    count: 0,
    totalValue: 0
  }
];