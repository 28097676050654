<ng-template #template>
  <div id="modalDrag" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" cdkDrag>
        <div class="modal-header p-3 mb-3" cdkDragHandle>
          <h2 class="m-0">Create DDP Container</h2>
          <button class="bg-transparent border-0 p-0" (click)="closeModal()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="modal-body p-0 m-0 ddp-modal-body">
          <div class="grid-wrapper">
            <div class="d-flex justify-content-end mx-3">
              <div class="d-flex gap-2">
                <span><p  class="msgText" *ngIf="!containerStatus">Please Enter Container Number</p></span>
                <a target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" (click)="saveData()"
                  title="Save"><button type="button" class="btn btn-outline-primary text-light d-flex"
                    alt="Edit Data"><span class="material-icons mi-28">save</span> </button></a>
                <a target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" (click)="addRow()"
                  title="Add Row"><button type="button" class="btn btn-outline-primary text-light d-flex"
                    alt="Edit Data"><span class="material-icons mi-28">add_circle_outline</span> </button></a>
              </div>
            </div>
              <ag-grid-angular style="width: 100%; height: 500px;" [class]="gridClass | async" [singleClickEdit]="true"
                [columnDefs]="defaultColumns" [rowData]="[]" [defaultColDef]="defaultColDef" [pagination]="true"
                [paginationPageSize]="50" [enableRangeSelection]="true" (gridReady)="onGridReady($event)"
                (CellValueChanged)="onCellValueChanged($event)">
              </ag-grid-angular>
          </div>
        </div>

  </div>
</ng-template>