import { AfterViewInit, Component, EventEmitter, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { SaveDialogueEvent, SaveModalConfig, SaveModalService } from './save-modal.service';

@Component({
    selector: 'cds-ui-save-modal',
    templateUrl: './save-modal.component.html',
    styleUrls: ['./save-modal.component.scss'],
})

export class SaveModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('templateSaveDialogue') templateSaveDialogue!: TemplateRef<any>;
    @Output() messageEvent = new EventEmitter<string>();

    private destroy$: Subject<void> = new Subject();
    public modalRef!: BsModalRef;
    public config!: SaveModalConfig;

    public isLoading$ = this.appStateRepository.isLoading$;

    constructor(
        private modalservice: BsModalService,
        private saveModalService: SaveModalService,
        private appStateRepository: AppStateRepository) { }

    ngAfterViewInit(): void {
        this.subscribeShow();
        this.subscribeSaved();
        this.subscribeSaveError();

        this.handler(this.modalservice.onShow, SaveDialogueEvent.OnShow);
        this.handler(this.modalservice.onShown, SaveDialogueEvent.OnShown);
        this.handler(this.modalservice.onHide, SaveDialogueEvent.OnHide);
        this.handler(this.modalservice.onHidden, SaveDialogueEvent.OnHidden);
    }

    private subscribeShow() {
        this.saveModalService.show$
            .pipe(takeUntil(this.destroy$))
            .subscribe((config) => {
                if (this.modalRef) this.modalRef.hide();

                this.config = config as SaveModalConfig;
                this.modalRef = this.modalservice.show(this.templateSaveDialogue, { backdrop: false, ignoreBackdropClick: true });
            });
    }

    private subscribeSaved() {
        this.saveModalService.onClosingSaved$
            .pipe(takeUntil(this.destroy$))
            .subscribe(_ => this.modalRef.hide());
    }

    private subscribeSaveError() {
        this.saveModalService.onClosingSaveError$
            .pipe(takeUntil(this.destroy$))
            .subscribe(_ => {
                this.modalRef.hide();
            });
    }

    private handler(handler: EventEmitter<any>, type: SaveDialogueEvent) {
        handler.pipe(takeUntil(this.destroy$))
               .subscribe((_) => this.saveModalService.handle({ type: type, params: {} }));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSaving() {
        this.saveModalService.setClosingSave();
    }

    onDontSave() {
        this.saveModalService.setClosingDontSave();
        this.modalRef.hide();
    }

    onCancel() {
        this.saveModalService.setCancel();
        this.modalRef.hide();
    }
}

