/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';
import { ScheduledReport } from '../analytics-collection/models/analytics-collection.model';

export interface AnalyticsScheduleModalConfig {
  title: string;
  key: string;
  value: ScheduledReport[];
  //   value: any[];
}

export enum AnalyticsScheduleDialogueEvent {
  OnShow,
  OnShown,
  OnClosingRemove,
  OnClosingRemoved,
}

interface AnalyticsScheduleModalEvent {
  type: AnalyticsScheduleDialogueEvent;
  params?: any;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsModalService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  private event$: Subject<AnalyticsScheduleModalEvent> = new Subject();
  private showSubject$ = new Subject();

  public show$: Observable<unknown> = this.showSubject$.pipe(
    takeUntil(this.destroy$)
  );
  public onShow$ = this.event$.pipe(
    filter((x) => x.type === AnalyticsScheduleDialogueEvent.OnShow)
  );
  public onShown$ = this.event$.pipe(
    filter((x) => x.type === AnalyticsScheduleDialogueEvent.OnShown)
  );
  public onClosingConfirmationRemove$ = this.event$.pipe(
    filter((x) => x.type === AnalyticsScheduleDialogueEvent.OnClosingRemove)
  );
  public onClosingConfirmationRemoved$ = this.event$.pipe(
    filter((x) => x.type === AnalyticsScheduleDialogueEvent.OnClosingRemoved)
  );

  handle(event: AnalyticsScheduleModalEvent): void {
    this.event$.next(event);
  }

  setClosingConfirmation(params?: any) {
    this.event$.next({
      type: AnalyticsScheduleDialogueEvent.OnClosingRemove,
      params: params,
    });
  }

  setClosingConfirmationRemoved() {
    this.event$.next({
      type: AnalyticsScheduleDialogueEvent.OnClosingRemoved,
    });
  }

  show(config: AnalyticsScheduleModalConfig): void {
    this.showSubject$.next(config);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}