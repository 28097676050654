import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter, BehaviorSubject } from 'rxjs';

export interface PocDeclineEventModalConfig {
    title: string,
    message?: string,
    data:any;
    count?:any,
    btnOne:string,
    btnTwo:string
}

export enum PocDeclineDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface PocDeclineModalEvent {
    type: PocDeclineDialogueEvent,
    params?: any,
    note?: any
}

@Injectable({ providedIn: 'root' })

export class PocDeclineModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<PocDeclineModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;
  public refreshHistoryClose$$ = new BehaviorSubject(false);
  public refreshHistoryClose$ = this.refreshHistoryClose$$.asObservable();
  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === PocDeclineDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === PocDeclineDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === PocDeclineDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === PocDeclineDialogueEvent.OnHidden));
  public onClosingPocDeclineRemove$ = this.event$.pipe(filter((x) => x.type === PocDeclineDialogueEvent.OnClosingRemove));
  public onClosingPocDeclineRemoved$ = this.event$.pipe(filter((x) => x.type === PocDeclineDialogueEvent.OnClosingRemoved));

  show(config: PocDeclineEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: PocDeclineModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingPocDecline(params?: any, note?: any) {
    this.event$.next({
      type: PocDeclineDialogueEvent.OnClosingRemove,
      params: params,
      note: note
    });
  }

  setClosingPocDeclineRemoved() {
    this.event$.next({
      type: PocDeclineDialogueEvent.OnClosingRemoved,
    });
  }
}