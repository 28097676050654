<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header" cdkDragHandle>
		<h2 class="m-0">Container Status Events</h2>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()"></button>
	  </div>
	  <div class="modal-body p-0 m-0">
		<div class="grid-wrapper">
			<div class="d-flex justify-content-end mx-3">
				 <div class="d-flex gap-2">
					<!--
             [suppressDragLeaveHidesColumns]="false"
                    [suppressRowClickSelection]="false"
                    [rowGroupPanelShow]="'always'"
                    [suppressRowGroupHidesColumns]="false"
                     [pagination]="true"
                    [paginationPageSize]="15"
                     <a data-bs-toggle="modal" data-bs-target="#add-event-modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Add"><button type="button" class="btn btn-outline-primary text-light d-flex" alt="Add Line"><span class="material-icons mi-28">post_add</span></button></a> -->
					<!-- <a data-bs-toggle="tooltip" data-bs-placement="top" title="Reset to Default"><button (click)="RefreshColumn()" type="button" class="btn btn-outline-primary text-light d-flex" alt="Reset to Default"><span class="material-icons mi-28">settings_backup_restore</span></button></a> -->
				</div>
			</div>
			<div class="ag-theme-alpine-dark">
                <ag-grid-angular
                    [rowData]="gridResponse$ | async"
                    [columnDefs]="columnDefs"
                    [gridOptions]="gridOptions"
                    [enableRangeSelection]="true"
                    [pagination]="true"
                    [paginationPageSize]="15"
                    (gridReady)="onGridReady($event)"
                    (rowDataUpdated)="onGridBound($event)"
                    >
            </ag-grid-angular>
            </div>
		</div>
	  </div>
  </div>
</ng-template>


<cds-ui-add-container-status-modal></cds-ui-add-container-status-modal>
