import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GridsterItem } from "angular-gridster2";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserPerferenceRepository {

    private route = "/user/preferences";
    public layoutConfig = new BehaviorSubject<any>({});

    constructor(private http: HttpClient) { }

    public get(category: string, key: string) {
        return this.http.get<any>(`${this.route}/${category}/${key}`);
    }

    public update(category: string, key: string, layout: { [id: string] : GridsterItem[] }) {
        return this.http.post<any>(`${this.route}/${category}/${key}`, layout);
    }

    public reset(category: string, key: string) {
        return this.http.delete<any>(`${this.route}/${category}/${key}`);
    }

}