/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, BehaviorSubject, catchError, throwError, Subject, tap, map, forkJoin } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { FinalDeliveryLocation, InboundPlanning, InlandTrucker } from '../models/inbound-planning.model';
import { ToastrService } from 'ngx-toastr';
import { GetTransloadInboundToDcGQL, TransloadTrailerSummaryFilterInput } from '@cds-ui/data-access';
import { Apollo } from 'apollo-angular';

export const quickMenuData = [
  {
    tallyCardName: 'Inbound to Port',
    menuList: [
      {
        menuName: 'Print Container Manifest',
        permissionKey: 'container:action:manifest:print'
      },
      {
        menuName: 'Send to Trucker',
        permissionKey: 'container:action:container:send-to-trucker'
      },
      {
        menuName: 'Send to Trucker Attachment',
        permissionKey: 'container:action:container:attachement:send-to-trucker'
      },
      {
        menuName: 'Send to Broker',
        permissionKey: 'container:action:container:send-to-broker'
      },
      {
        menuName: 'Create DDP Container',
        permissionKey: 'container:action:ddp-container:create'
      },
      {
        menuName: 'Calculate Drayage Cost',
        permissionKey: 'container:action:drayage-cost:calculate'
      },
      {
        menuName: 'Mark Container and Items Hot',
        permissionKey: 'container:action:container:mark-hot'
      },
      {
        menuName: 'Demurrage Report',
        permissionKey: 'container:action:container:demurrage'
      },
      {
        menuName: 'Detention Report',
        permissionKey: 'container:action:container:detention'
      },
    ]
  },
  {
    tallyCardName: 'At Port',
    menuList: [
      {
        menuName: 'Print Container Manifest',
        permissionKey: 'container:action:manifest:print'
      },
      {
        menuName: 'Send to Trucker',
        permissionKey: 'container:action:container:send-to-trucker'
      },
      {
        menuName: 'Send to Trucker Attachment',
        permissionKey: 'container:action:container:attachement:send-to-trucker'
      },
      {
        menuName: 'Send to Broker',
        permissionKey: 'container:action:container:send-to-broker'
      },
      {
        menuName: 'Create DDP Container',
        permissionKey: 'container:action:ddp-container:create'
      },
      {
        menuName: 'Calculate Drayage Cost',
        permissionKey: 'container:action:drayage-cost:calculate'
      },
      {
        menuName: 'Mark Container and Items Hot',
        permissionKey: 'container:action:container:mark-hot'
      },
      {
        menuName: 'Demurrage Report',
        permissionKey: 'container:action:container:demurrage'
      },
      {
        menuName: 'Detention Report',
        permissionKey: 'container:action:container:detention'
      }
    ]
  },
  {
    tallyCardName: 'Inbound to DC',
    menuList: [
      {
        menuName: 'Print Container Manifest',
        permissionKey: 'container:action:manifest:print'
      },
      {
        menuName: 'Send Empty Container Notice',
        permissionKey: 'container:action:container:empty-notice:send'
      },
      {
        menuName: 'Create DDP Container',
        permissionKey: 'container:action:ddp-container:create'
      },
      {
        menuName: 'Calculate Drayage Cost',
        permissionKey: 'container:action:drayage-cost:calculate'
      },
      {
        menuName: 'Mark Container and Items Hot',
        permissionKey: 'container:action:container:mark-hot'
      },
      {
        menuName: 'Demurrage Report',
        permissionKey: 'container:action:container:demurrage'
      },
      {
        menuName: 'Detention Report',
        permissionKey: 'container:action:container:detention'
      }
    ]
  }
];

@Injectable({ providedIn: 'root' })
export class InboundPlanningService {
  constructor(private http: HttpClient, private toastrService: ToastrService, private apollo: Apollo) {}

  public dataSubject$: Subject<any> = new Subject();
  public dataList$ = this.dataSubject$.asObservable();

  public inboundDCSub$: Subject<any> = new Subject();
  public inboundDcList$ = this.inboundDCSub$.asObservable();

  public dcSubject$ = new BehaviorSubject<any>('');
  public dcDataSource$ = this.dcSubject$.asObservable();
  public tallySubject$ = new BehaviorSubject<number>(14);
  public tallyDataSource$ = this.tallySubject$.asObservable();
  public updateHotFlagRowDataSubject$: Subject<any> = new Subject();
  public updateHotFlagDataSource$ =
    this.updateHotFlagRowDataSubject$.asObservable();
  public updateCommentsFieldLabelSubject$: Subject<any> = new Subject();
  public updateCommentsFieldLabelDataSource$ =
    this.updateCommentsFieldLabelSubject$.asObservable();
  public hideDCSubject$ = new BehaviorSubject<any>(true);
  public hideDCDataSource$ = this.hideDCSubject$.asObservable();
  public userPreferences$ = new Subject<any>();
  parentChannel = new BroadcastChannel('inboundOverview');
  childChannel = new BroadcastChannel('inboundDetail');
  headers = new HttpHeaders({ Accept: 'application/pdf' });
  options = {
    headers: this.headers,
    responseType: 'blob' as 'json'
  };

  public getInboundData(
    milestone: number,
    dcCode: string,
    hideDC?: boolean
  ): Observable<InboundPlanning> {
    return this.http
      .get<InboundPlanning>(
        `/containers/inboundPlanning/tallyCards?milestone=${milestone}&dcCodes=${dcCode}&getDcInbound=${hideDC}`
      )
      .pipe(
        tap((a) => this.dataSubject$.next(a)),
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      );
  }

  public getInboundAllData(
    milestone: number,
    dcCode: any,
    hideDC?: boolean
  ): Observable<any> {
    return forkJoin([
      this.http
      .get<InboundPlanning>(
        `/containers/inboundPlanning/tallyCards?milestone=${milestone}&dcCodes=${dcCode}&getDcInbound=${hideDC}`
      ),
      new GetTransloadInboundToDcGQL(this.apollo).fetch(
        {
          filter: {inboundToDc: true, transloadFacilities: ["All"],
          distributionCenters:dcCode != '' ? dcCode : ['All']}
        },
        { fetchPolicy: 'no-cache' }
      )
      ]).pipe(
       map(res => ({first:res[0],second:res[1].data.transloadTrailerSummaries,milestone:milestone})),
       tap((a) => this.dataSubject$.next(a)),
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      )
  }

  public printContainerManifest(data: any): Observable<any> {
    return this.http
      .post('/containers/report/manifest', data, this.options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      );
  }

  public sendToTrucker(data: any): Observable<any> {
    return this.http.post('events/containers/sendToTrucker', data).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastrService.error(error.error.text || error.error.error);
        return throwError(() => error);
      })
    );
  }

  public sendToTruckerAttachment(data: any): Observable<any> {
    return this.http.post('containers/email/toTrucker', data).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastrService.error(error.error.text || error.error.error);
        return throwError(() => error);
      })
    );
  }

  public sendToBroker(data: any): Observable<any> {
    return this.http.post('events/containers/sendToBroker', data);
  }

  public sendToBrokerEmail(data: any): Observable<any> {
    return this.http.post('containers/email/toBroker', data);
  }

  public printDo(data: any): Observable<any> {
    return this.http
      .post('containers/report/deliveryorder', data, this.options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      );
  }

  public markContainerItems(data: any): Observable<any> {
    return this.http.post('events/containers/markHot', data).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastrService.error(error.error.text || error.error.error);
        return throwError(() => error);
      })
    );
  }

  public sendEmptyContainerNotice(data: any): Observable<any> {
    return this.http.post('events/containers/sendEmptyNotice', data).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastrService.error(error.error.text || error.error.error);
        return throwError(() => error);
      })
    );
  }

  public calculateDrayageCost(data: any): Observable<any> {
    return this.http
      .patch('containers/inboundPlanning/calculateDrayageCost', data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      );
  }

  public getFinalDeliveryLocations(shipKey: string): Observable<FinalDeliveryLocation[]>{
    return this.http.get<FinalDeliveryLocation[]>(`containers/inboundPlanning/finalDeliveryLocations?shipKey=${shipKey}`);
  }

  public getDCLocations(companyCD:any,type:string = 'routingPkUp'){
    return this.http.get<any>(`internal/location/dcLocations?companyCode=${companyCD}&locationType=${type}`);
  }

  public getInlandTruckers(shipKey: string, finalDeliveryLocation: string): Observable<InlandTrucker[]>{
    return this.http.get<InlandTrucker[]>(`containers/inboundPlanning/inlandTruckers?shipKey=${shipKey}&finalDeliveryLocation=${finalDeliveryLocation}`);
  }

  getCommentsData(shipKey: string, containerNumber: string) {
    return this.http.get(
      `containers/inboundPlanning/comments?shipKey=${shipKey}&containerNumber=${containerNumber}`
    );
  }
  
  getTruckerList(){
    return this.http.get(
      `location/truckerlist`
    );
  }

  updateCommentsData(payload: any) {
    return this.http.post(`containers/inboundPlanning/comments`, payload);
  }

  public saveEditInbound(data: any) {
    return this.http.patch('internal/inboundPlanning/update', data);
  }

  public detentionReport(data: any): Observable<any> {
    return this.http
      .post('containers/report/detention', data, this.options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      );
  }

  public demurrageReport(data: any): Observable<any> {
    return this.http
      .post('containers/report/demurrage', data, this.options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastrService.error(error.error.text || error.error.error);
          return throwError(() => error);
        })
      );
  }
}
