import { DomLayoutType, GridOptions, GridReadyEvent, ColumnApi, GridApi, GetRowIdParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { GridColumnService } from '@cds-ui/shared/ui-grid';
import { select } from '@ngneat/elf';
import { ReplaySubject, Observable, tap, Subject } from 'rxjs';
import { GlobalGridService } from './global-grid.service';

@Component({
  selector: 'cds-ui-global-grid',
  templateUrl: './global-grid.component.html',
  styleUrls: ['./global-grid.component.scss']
})
export class GlobalGridComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private appState: AppStateRepository,
    private globalGridService: GlobalGridService,
    private gridColumnService: GridColumnService
    ) { }

  @Input('getMainMenuItems') getMainMenuItems!: any;

  @Input('gridType') gridType!: string;

  @Input('uniqueId') uniqueId!: string;

  @Input('gridOptions') customGridOptions!: GridOptions;

  @Input('frameworkComponents') frameworkComponents!: {};

  @Output('selectedRowData') selectedRowData: EventEmitter<any> = new EventEmitter();

  @Output('cellValueChangeEvent') cellValueChangeEvent: EventEmitter<any> = new EventEmitter();

  @Output('cellEditingStoppedEvent') cellEditingStoppedEvent: EventEmitter<any> = new EventEmitter();

  public domLayout: DomLayoutType = "autoHeight";

  public readonly loadingTemplate = `<span class="ag-overlay-loading-center">Loading...</span>`;

  public gridOptions!: GridOptions;

  private gridOptionsOverview: GridOptions = {
    paginationPageSize: 50,
    defaultColDef: {
      resizable: true,
      suppressMenu: false,
      filter: true,
      sortable: true,
      enableRowGroup: true,
      floatingFilter: true,
      filterParams: {
        trimInput: true,
      },
      editable: false
    }
  }

  private gridOptionsDetail: GridOptions = {
    paginationPageSize: 10,
    defaultColDef: {
      resizable: true,
      suppressMenu: false,
      filter: true,
      sortable: true,
      enableRowGroup: true,
      filterParams: {
        trimInput: true,
      },
      editable: false
    },
    stopEditingWhenCellsLoseFocus: true
  }

  private gridOptionsSearch: GridOptions = {
    paginationPageSize: 50,
    defaultColDef: {

      resizable: true,
      filter: true,
      enableRowGroup: true,
      sortable: true,
      filterParams: {
        trimInput: true,
      },
      editable: false
    },
    stopEditingWhenCellsLoseFocus: true
  }

  private gridOptionsModal: GridOptions = {
    paginationPageSize: 50,
    defaultColDef: {
      resizable: true,
      suppressMenu: false,
      filter: true,
      sortable: true,
      enableRowGroup: true,
      filterParams: {
        trimInput: true,
      },
      editable: false
    },
    stopEditingWhenCellsLoseFocus: true
  }

  public dataSource$ = new ReplaySubject<any>();

  public gridClass = this.appState.theme$.pipe(select((x) => x.grid));
  private gridApi!: GridApi;
  private gridColumnApi!: ColumnApi;
  private readonly destroy$ = new Subject<void>();

  public column$ = new ReplaySubject<any>();

  @Input()
  set columns$(stream: Observable<any>){
    stream.pipe(tap(a => {
      this.column$.next(a);
    })).subscribe();
  }

  @Input()
  set data$(stream: Observable<any>){
    stream.pipe(tap(a => {
      this.dataSource$.next(a);
    })).subscribe();
  }

  ngOnInit() {
    if(this.gridType === 'overview'){
      this.gridOptions = {...this.gridOptionsOverview, ...this.customGridOptions};
    }else if(this.gridType === 'detail'){
      this.gridOptions = this.gridOptionsDetail;
    }else if(this.gridType === 'modal'){
      this.gridOptions = this.gridOptionsModal;
    }else if(this.gridType === 'search'){
      this.gridOptions = this.gridOptionsSearch;
    }

    this.globalGridService.restoreGridData$.pipe(tap(_ => this.refreshGrid())).subscribe();

    this.globalGridService.fullScreenGridFeature$.pipe(tap(_ => this.fullscreenGrid())).subscribe();

    this.globalGridService.filterGridData$.pipe(tap(a => this.filterGrid(a))).subscribe();
  }

  onSelectionChanged(event: any) {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRowData.emit(selectedRows);
  }

  onCellValueChanged(params: any){
    this.cellValueChangeEvent.emit(params);
  }

  onCellEditingStopped(params: any){
    this.cellEditingStoppedEvent.emit(params);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi;
    this.globalGridService.gridApi({gridApi: this.gridApi, gridColumnApi: this.gridColumnApi, params: params});
    this.autoSizeAll(this.gridColumnApi, false);
    this.globalGridService.agGridStickyHeader(this.gridType);
    this.globalGridService.addStickyHorizontalGridScroll(this.gridType);
   }

   private autoSizeAll(api: ColumnApi, skipHeader: boolean) {
    const allColumnIds: any = api
      .getColumns()
      ?.map((column) => column.getColId());
    api.autoSizeColumns(allColumnIds, skipHeader);
  }

  refreshGrid(){
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
    this.gridColumnApi.setRowGroupColumns([]);
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.setColumnsPinned([], null);
   // this.gridColumnService.clearFilter(this.gridApi); causing issue in item detail certificate grid
  }

  filterGrid(data: any){
    this.gridApi.setQuickFilter(data);
  }

  fullscreenGrid(){
    document.getElementById('expander')?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.toggle("fullscreen");
    // Array.from(document.getElementsByClassName('expander')).forEach(element => {
    //   console.log("element",element);
    //   element?.parentElement?.parentElement?.parentElement?.parentElement?.classList.toggle("fullscreen");
    // });
  }

  public getRowId(params: GetRowIdParams<any>) {
      return params?.data?.uniqueId;
  }


  ngAfterViewInit() {
    if(this.gridType === 'detail'){
      this.gridOptions["defaultColDef"] = {...this.gridOptions["defaultColDef"], floatingFilter: false };
  }
}
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
