import { Directive, HostBinding, OnDestroy} from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[scrollLock]',
})
export class ScrollLockDirective implements OnDestroy{

  @HostBinding('class')
  elementClass = 'scroll-lock';

  private destroy$$ = new Subject<void>();

  constructor(private appState: AppStateRepository) { 
    this.appState.useScrollLock$.pipe(
      takeUntil(this.destroy$$),
      tap(x => this.elementClass = x 
                                 ? 'scroll-lock'
                                 : '')
    )
    .subscribe();
  }
  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}