import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'cds-ui-edit-icon-custom-header',
  templateUrl: './edit-icon-custom-header.component.html',
  styleUrls: ['./edit-icon-custom-header.component.scss'],
})
export class EditIconCustomHeaderComponent implements OnInit, IHeaderAngularComp {
 
  constructor() {}

  public params!: IHeaderParams;
  @ViewChild('menuButton', {read: ElementRef}) public menuButton:any;

  agInit(params: IHeaderParams): void {
    
    this.params = params;

  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton?.nativeElement);
  };

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }

  ngOnInit(): void {}
}
