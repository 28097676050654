import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import moment from 'moment';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface AddContainerStatusEventModalConfig {
    title: string,
    data:any
}

export enum AddContainerStatusDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface AddContainerStatusModalEvent {
    type: AddContainerStatusDialogueEvent,
    params?: any
}
@Injectable({
  providedIn: 'root'
})
export class AddContainerStatusModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<AddContainerStatusModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === AddContainerStatusDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === AddContainerStatusDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === AddContainerStatusDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === AddContainerStatusDialogueEvent.OnHidden));
  public onClosingAddContainerStatusRemove$ = this.event$.pipe(filter((x) => x.type === AddContainerStatusDialogueEvent.OnClosingRemove));
  public onClosingAddContainerStatusRemoved$ = this.event$.pipe(filter((x) => x.type === AddContainerStatusDialogueEvent.OnClosingRemoved));
  
  constructor(private http: HttpClient){}
  show(config: AddContainerStatusEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: AddContainerStatusModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingAddContainerStatus(params?: any) {
    this.event$.next({
      type: AddContainerStatusDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingAddContainerStatusRemoved() {
    this.event$.next({
      type: AddContainerStatusDialogueEvent.OnClosingRemoved,
    });
  }

  dateFormatter(params: any): string | any {
      return moment.utc(params).format('YYYY-MM-DD');
  }
  
  saveContainerStatus(value:any): Observable<any> {
    const reqBody = [{
      "shipKey": value.shipKey,
      "containerNumber": value.containerNo,
      "locationCode": value.eventLocation,
      "statusCode": value.eventType,
      "statusDate": this.dateFormatter(value.eventDate)+'T00:00:00Z',
      "comment": value.comments
    }]
    return this.http
      .post<any>(`/containers/${value.shipKey}/${value.containerNo}/statusEvents/customer` , reqBody)
      
  }
}
