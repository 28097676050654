<ng-container *globalFeaturePermission="['user:action:user:ghost', 'user:action:subgroup:ghost']; callback: ghostPermission$$"></ng-container>
<div class="modal fade" id="ghostingModal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
       <h5 class="modal-title" id="modalLabel">Ghosting Confirmation</h5>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div>
        <form [formGroup]="ghostingForm">
        <div class="mb-3">
            <label for="tf1" class="form-label">UserID</label>
            <ng2-completer formControlName="searchUserField" [inputClass]="'form-control'" [disableInput]="!(hasGhostUser$ | async)" #completerUserId [datasource]="datasourceUserId" [selectOnClick]="true" [minSearchLength]="3" [maxChars]="20" (selected)="onSelectedUserId($event)" required></ng2-completer>
            <div class="mt-2" *ngIf="!ghostingForm.get('searchUserField')?.valid">
              <div class="d-flex align-items-center" *ngIf="ghostingForm.get('searchUserField')?.hasError('required') && ghostingForm.get('searchUserField')?.dirty">
                <span class="material-icons mi-24 text-danger">error_outline</span>
                <span class="marginLeft"><small class="text-white">UserId is Required.</small></span>
              </div>
          </div>
          </div>
        <div class="mb-3">
            <label for="tf1" class="form-label">Subgroup</label>
            <ng2-completer formControlName="searchSubgroupField" [inputClass]="'form-control'" [disableInput]="!(hasGhostSubgroup$ | async)" #completerSubCd [datasource]="datasourceSubgroupCd" [selectOnClick]="true" [minSearchLength]="1" [maxChars]="3" (selected)="onSelectedSubgroupCd($event)"></ng2-completer>
            <div class="mt-2" *ngIf="!ghostingForm.get('searchSubgroupField')?.valid">
              <div class="d-flex align-items-center" *ngIf="ghostingForm.get('searchSubgroupField')?.hasError('required') && ghostingForm.get('searchSubgroupField')?.dirty">
                <span class="material-icons mi-24 text-danger">error_outline</span>
                <span class="marginLeft"><small class="text-white">Subgroup is Required.</small></span>
              </div>
          </div>
          </div>
      </form>
        <div class="row mt-5" *ngIf="ghostUserArr">
            <div class="col-md-4 mb-3" *ngFor="let item of ghostUserArr"><a (click)="populateGhostUser(item.userId,item.subgroupCd)" class="d-flex align-items-center text-decoration-none"><span class="material-icons mi-32" aria-hidden="true">portrait</span> <span class="text-decoration-underline ms-1">{{item.userId + ' (' + item.subgroupCd + ')'}}</span></a></div>
        </div>
        </div>
    </div>
    <div class="modal-footer pt-0">
        <button type="button" class="btn btn-submit" data-bs-dismiss="modal" (click)="ghostUser()" [disabled]="!ghostingForm.valid">Ghost User</button>
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
    </div>
  </div>
  </div>
</div>
