import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Directive({
  selector: '[uiInputLimitCharacters]'
})

export class InputLimitCharactersDirective {
  private maxLength$$ = new BehaviorSubject<number>(0);  
  @Input("uiInputLimitCharacters") set limitCharacters( length: number) {
           this.maxLength$$.next(length)
  }
  
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value.length >  this.maxLength$$.value) {
      input.value = input.value.slice(0, this.maxLength$$.value);
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value.length >= this.maxLength$$.value && !this.isAllowedKey(event)) {
      event.preventDefault();
    }

  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const pastedData = event.clipboardData?.getData('text') || '';
    const newValue = input.value + pastedData;
    if (newValue.length >this.maxLength$$.value) {
      event.preventDefault();
      input.value = newValue.slice(0, this.maxLength$$.value);
    }
  }

  private isAllowedKey(event: KeyboardEvent): boolean {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    return allowedKeys.includes(event.key);
  }
}
