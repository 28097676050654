import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { ReplaySubject, Subject, concatMap, defaultIfEmpty, filter, from, iif, map, of, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';

export interface ICellRendererParamsWithExtraProps extends ICellRendererParams {
  routePath: string;
  queryParam1: string;
  queryParam2: string;
  renderAsLinkWithoutClick: boolean;
  modal: boolean;
  closeModalIfExist?() : void;
  openNewTab: boolean;
  notVessel: boolean;
  link: string;
  companyCode: string[] | string;
  purchaseOrderNumber: string[] | string;
  itemNumber: string[] | string;
  shipKey: string[] | string;
  containerNumber: string[] | string;
  bookingKey: string[] | string;
  scheduleId: string[] | string;
  legacyURL: boolean;
  vmsPoKey: string[] | string;
}

@Component({
  selector: 'cds-ui-navigation-link-renderer',
  templateUrl: './navigation-link-renderer.component.html',
  styleUrls: ['./navigation-link-renderer.component.scss'],
})
export class NavigationLinkRendererComponent implements OnDestroy, ICellRendererAngularComp {
  constructor(private router: Router,private appState: AppStateRepository,
    ) {}
  private destroy$ = new Subject<void>();
  private routePath!: string;
  private queryParam1!: string;
  private queryParam2!: string;
  private renderAsLinkWithoutClick! : boolean;
  private modal! : boolean;
  public params!: ICellRendererParamsWithExtraProps;
  public notVessel!: boolean;
  private newTab$$  = new ReplaySubject(1);
  private newTab$ = this.newTab$$.asObservable();
  private environmentPrefixPath$ = this.appState.environment$.pipe(map(e=> e?.pathPrefix ?? '' ));


  // gets called once for each cell before the renderer is used
  agInit(params: ICellRendererParamsWithExtraProps): void {
    this.params = params;
    this.routePath = this.params.routePath;
    this.notVessel = this.params.notVessel;
    this.queryParam1 = this.params.queryParam1;
    this.queryParam2 = this.params.queryParam2;
    this.renderAsLinkWithoutClick = this.params?.renderAsLinkWithoutClick;
    this.modal = this.params?.modal;
    this.getRouteforLink(this.params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParamsWithExtraProps): boolean {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }

  getRouteforLink(params: ICellRendererParamsWithExtraProps){
    const paramsObj = params.node.group ? params?.node?.allLeafChildren[0]?.data : params?.data;
    const paramsObject: any = {};
    switch (params.link) {
      case 'containerNumber':
        paramsObject.containerNumber = this.getNestedValue(paramsObj, params?.containerNumber);
        paramsObject.shipKey = this.getNestedValue(paramsObj, params?.shipKey);
        this.routePath = `/container/view/${paramsObject?.shipKey}/${paramsObject?.containerNumber}`;
        break;
      case 'shipKey':
        paramsObject.shipKey = this.getNestedValue(paramsObj, params?.shipKey);
        this.routePath = `/shipping/view/${paramsObject?.shipKey}`;
        break;
      case 'purchaseOrderNumber':
        paramsObject.companyCode = this.getNestedValue(paramsObj, params?.companyCode);
        paramsObject.purchaseOrderNumber = this.getNestedValue(paramsObj, params?.purchaseOrderNumber);
        this.routePath = `/purchase-order/view/${paramsObject?.companyCode}/${paramsObject?.purchaseOrderNumber}`;
        break;
      case 'itemNumber':
        paramsObject.companyCode = this.getNestedValue(paramsObj, params?.companyCode);
        paramsObject.itemNumber = this.getNestedValue(paramsObj, params?.itemNumber);
        this.routePath = `/item/item-detail/${paramsObject?.companyCode}/${paramsObject?.itemNumber}`;
        break;
      case 'bookingKey':
        paramsObject.bookingKey = this.getNestedValue(paramsObj, params?.bookingKey);
        this.routePath = `/booking/view/${paramsObject?.bookingKey}`;
        break;
      case 'vessel':
        paramsObject.scheduleId = this.getNestedValue(paramsObj, params?.scheduleId);
        this.routePath = `/voyage/view/${paramsObject?.scheduleId}`;
        break;
      case 'changeType':
        paramsObject.companyCode = this.getNestedValue(paramsObj, params?.companyCode);
        paramsObject.purchaseOrderNumber = this.getNestedValue(paramsObj, params?.purchaseOrderNumber);
        paramsObject.vmsPoKey = this.getNestedValue(paramsObj, params?.vmsPoKey).trim();
        this.routePath = `/purchase-order/poc-details/${paramsObject?.companyCode}/${paramsObject?.purchaseOrderNumber}/${paramsObject?.vmsPoKey}`;
        break;
      default:
        break;
    }
  }

   getNestedValue (obj: any, fieldsArray: any) {
    if(!Array.isArray(fieldsArray)){
      return fieldsArray;
    }else {
      return fieldsArray.reduce((nestedObj, field) => {
        return nestedObj && nestedObj[field] !== undefined ? nestedObj[field] : undefined;
      }, obj);
    }
  }

  navigate(params:ICellRendererParamsWithExtraProps, event:any): void {
    if(this.renderAsLinkWithoutClick) return;
    if (this.params?.closeModalIfExist) this.params.closeModalIfExist();

    if(event.ctrlKey || this.modal){
      this.newTab$$.next(true);
    }else{
      this.newTab$$.next(false);
    }

    const params$ = of(params).pipe(
      takeUntil(this.destroy$),
      map(params => {
        const obj: any = {};
        if (!!this.queryParam1) {
          obj[this.queryParam1] = params.data[this.queryParam1];
        }
        if (!!this.queryParam2) {
          obj[this.queryParam2] = params.data[this.queryParam2];
        }
        return obj;
      })
    )

    const navigate$ = params$.pipe(
      takeUntil(this.destroy$),
      switchMap((obj) =>
        from(this.router.navigate([this.routePath], {
          queryParams: obj
        }))
      )
    );

    const openWindowWithQueryParams$ = params$
      .pipe(
        takeUntil(this.destroy$),
        filter(params => Object.keys(params).length > 0),
        map(params => Object.entries(params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number)}`).join('&')),
        map(queryParamString => `${this.routePath}?${queryParamString}`),
        defaultIfEmpty(this.routePath),
        withLatestFrom(this.environmentPrefixPath$),
        map(([url, path]) => params?.legacyURL ? url : window.location.origin + path + url),
        tap(url=> {
          setTimeout(()=>{
            window.open(url);
          });
        }));

    this.newTab$.pipe(
      takeUntil(this.destroy$),
      take(1),
      concatMap(newTab => iif(() => !!newTab, openWindowWithQueryParams$, navigate$))
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
 }
