import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cds-ui-global-grid-header',
  templateUrl: './global-grid-header.component.html',
  styleUrls: ['./global-grid-header.component.scss']
})
export class GlobalGridHeaderComponent implements OnInit {

  constructor() { }

  @Input('featurePermissionBtn') featurePermissionBtn: any;
  @Input('saveBtn') saveBtn!: boolean;
  @Input('isRowSelected') isRowSelected!: boolean;
  @Input ('gridHeaderActionButtonsToShow') gridHeaderActionButtonsToShow: any;
  @Input('gridTitle') gridTitle!: string;

  @Output('saveData') saveData: EventEmitter<any> = new EventEmitter();

  @Output('addRow') addRow: EventEmitter<any> = new EventEmitter();

  @Output('onDeleteClick') onDeleteClick: EventEmitter<any> = new EventEmitter();

  @Output('onSplitLineClick') onSplitLineClick: EventEmitter<any> = new EventEmitter();

  @Output('expandGrid') expandGrid: EventEmitter<any> = new EventEmitter();

  @Output('filterGridData') filterGridData: EventEmitter<any> = new EventEmitter();

  @Output('onRefreshClick') onRefreshClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {}

  saveDataMethod(){
    this.saveData.emit();
  }

  addRowMethod(){
    this.addRow.emit();
  }

  onDeleteClickMethod(){
    this.onDeleteClick.emit();
  }

  onSplitLineClickMethod(){
    this.onSplitLineClick.emit();
  }

  expandGridMethod(){
    this.expandGrid.emit();
  }

  filterGridDataMethod(data: any){
    this.filterGridData.emit(data);
  }

  onRefreshClickMethod(){
    this.onRefreshClick.emit();
  }

}
