<div class="card" id="card-vertical-timeline-info">
    <div class="card-header bg-transparent mx-5 pt-4 px-0 d-flex">
      <h2 class="w-50 mb-0">Activity Feed</h2>
      <div class="btn-group ms-auto" *ngIf="(displayViewMoreLink$ | async)">
        <a href="javascript:void(0)" (click)="onViewMoreClickEvent()">View More</a>
      </div>
    </div>
    <ng-container *ngIf="(activityPanelData$ | async); let data">
    <div class="card-body d-flex flex-column gap-2 px-5 pb-4 activity-list-scroll overflow-auto" *ngIf="(data?.length)?? 0 > 0 else noData">
      <ul class="activity-list">
        <li *ngFor="let item of data; let i = index; first as isFirst">
          <div class="activity-icon flex-shrink-0" aria-hidden="true">
            <span class="material-icons mi-16" *ngIf="isFirst; else checkIcon">check_circle</span>
            <ng-template #checkIcon><span class="material-icons mi-16">arrow_upward</span></ng-template>
          </div>
          <div class="activity-status">
            <p>{{item.eventStatus}} - {{item.locationName}}</p>
            <p class="activity-date">{{item.statusDate | date:'MM/dd/YYYY'}}</p>
          </div>
        </li>
      </ul>
    </div>
</ng-container>
    <ng-template #noData>
      <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
        <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">cloud_off</span></span></p>
        <p class="message-card-message fs-md mb-0 text-purple">No data to load right now.</p>
      </div>
    </ng-template>
  </div>
   <cds-activity-panel-view-more-detail-modal></cds-activity-panel-view-more-detail-modal>
  
