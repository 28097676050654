import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { devTools } from '@ngneat/elf-devtools';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback.component';
import { environment } from '../environments/environment';

import { SharedUiKitModule, SharedUiModalModule } from '@cds-ui/shared/ui-kit';
import { BaseUrlInterceptor } from '@cds-ui/shared/auth';
import { routes } from './app.routes';
import { ToastrModule } from 'ngx-toastr';
import { ErrorHandlerService } from './app-insights/error-handler.service';
import { MyMonitoringService } from './app-insights/logging.service';
import { GraphQLModule } from './graphql.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedUiDirectiveModule } from '@cds-ui/ui-directive';

const authSetting = {
  authority: environment.authServer.authority,
  authWellknownEndpointUrl: `${environment.authServer.authority}.well-known/openid-configuration`,
  redirectUrl: window.location.origin + `${environment.pathPrefix}/callback`,
  postLogoutRedirectUri: window.location.origin + `${environment.pathPrefix}/`,
  clientId: environment.authServer.clientId,
  scope: 'offline_access openid vms_identity vms_access',
  responseType: 'id_token token',
  startCheckSession: false,
  maxIdTokenIatOffsetAllowedInSeconds: 300,
  silentRenew: false,
  autoUserInfo: false,
  logLevel: LogLevel.None,
  configId: `oidc.user:viziv:${environment.authServer.authority}:${environment.authServer.clientId}`
};

@NgModule({
  declarations: [AppComponent, CallbackComponent],
  imports: [
    BrowserModule,
    SharedUiKitModule,
    SharedUiModalModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    AuthModule.forRoot({ config: authSetting }),
    GraphQLModule,
    SlickCarouselModule,
    SharedUiDirectiveModule
  ],
  providers: [
    { provide: 'AUTH_SERVER', useValue: environment.authServer },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: 'BASE_API_URL', useValue: environment.apiServer },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MyMonitoringService, useClass: MyMonitoringService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    if (!environment.production) {
      devTools();
    }
  }
}
