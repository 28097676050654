import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {  BehaviorSubject, Subject, map, shareReplay, takeUntil, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PrintSaModalConfig, PrintSaModalService } from './print-sa-modal.service';


@Component({
  selector: 'cds-ui-print-sa-modal',
  templateUrl: './print-sa-modal.component.html'
})
export class PrintSaModalComponent implements OnInit {
 
  private destroy$: Subject<void> = new Subject();
  private configDataSubject$$ = new Subject<PrintSaModalConfig>();
  public configData$ = this.configDataSubject$$.asObservable().pipe(shareReplay(1));
  public selectedNodes:any;
  public modalRef!: BsModalRef;
  public config!: PrintSaModalConfig;
  public offForMe=true
  public offForAny=false;
  public printStatus:boolean = false; 
  @ViewChild('template') template!: TemplateRef<any>;
  data = [];
  constructor(
    private modalservice: BsModalService,
    private printSaModalService: PrintSaModalService,
    private appStateRepository: AppStateRepository,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.configData$.pipe(
      tap(x => this.selectedNodes = x?.data?.length),      
      takeUntil(this.destroy$)).subscribe();
  }

  closeModal(){    
    this.modalRef.hide();
    this.printStatus = false;   
  }

  print() {
    this.configData$.pipe(
      map(inputArray => inputArray.data.flatMap((obj: any) => ({
        ...obj,
        AckOffForMe: this.offForMe,
        AckOffForEveryone: false,
        acknowledges: [
          {
            "shipKey": obj.shipKey,
            "rtype": obj.shipStatus
          }
        ]
      })))
    ).subscribe(x => this.data = x);
    this.printStatus = true;
    this.printSaModalService.printData(this.data).subscribe((response) => {
      if (response) {
        this.toastrService.success('Data updated successfully');
        let downloadURL = URL.createObjectURL(response);
        window.open(downloadURL);
        this.modalRef?.hide();
        this.printStatus = false;
        this.offForMe = true;  
        this.printSaModalService.afterPrint$.next("callData");
      }
    },
      (error) => {
        if (error) {
          this.toastrService.error("Printing Failed");
          this.modalRef?.hide();
          this.offForMe = true;  
        }
      });  
      
  }

  offFor(event: any, check: String) {
    if (check === "offForMe") {
      this.offForMe = event.currentTarget.checked;
    }
  }
  
  ngAfterViewInit(): void {
    this.printSaModalService.show$
      .pipe(takeUntil(this.destroy$),
        tap(x => this.configDataSubject$$.next(x as PrintSaModalConfig))
      ).subscribe((_) => {
        if (this.modalRef) this.modalRef.hide();
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered modal-xl'
        });
      });

  }
  ngOnDestroy(): void {
   
    this.destroy$.next();
    this.destroy$.complete();
  }

}
