<div class="card" id="card-exceptions-info">
  <div class="card-header bg-transparent mx-5 px-0 pt-4">
    <div class="d-flex align-items-center">
      <h2 class="m-0">{{cardHeaderName}}</h2>
      <div class="contents">
        <span class="badge border border-primary rounded-pill mx-2 fw-normal" *ngIf="(exceptionData$ | async)?.length ?? 0 as count" >{{count}}</span>
        <div class="btn-group ms-auto" *ngIf="(count$ | async) ?? 0 > 0 && viewMoreShow">
          <a href="javascript:void(0);" (click)="viewMore()" data-bs-toggle="modal" data-bs-target="#vibe-detail-modal">View
            More</a>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="((exceptionData$ | async)?.length ?? 0) > 0; else noData">
  <div class="card-body px-5 pb-4">
    <div class="message-cards overflow-auto">
      <div class="card message-card" [ngClass]="{'bg-gray-700': isEven, 'bg-gray-600': isOdd}"
        *ngFor="let item of (exceptionData$ | async); odd as isOdd; even as isEven; count as count">
        <div class="message-card-meta d-flex align-items-center mb-2">
          <h6 class="flex-fill fs-sm fw-bold m-0">{{item.label}}</h6>
          <ng-container [ngSwitch]="item.pending ? 'pending' : item.approved ? 'approved' : 'declined'">
            <ng-container *ngSwitchCase="'pending'">
              <span class="badge align-self-center bg-warning">Pending</span>
            </ng-container>
            <ng-container *ngSwitchCase="'approved'">
              <span class="badge align-self-center border border-primary">Approved</span>
            </ng-container>
            <ng-container *ngSwitchCase="'declined'">
              <span class="badge align-self-center border border-primary">Declined</span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="card-footer bg-transparent mx-5 px-0 pb-3">
    <a (click)="navigateToLegacyVibe()" class="btn btn-outline-primary text-light w-100">View All Exceptions</a>
  </div> -->
</ng-container>
  <ng-template #noData>
    <div class="card-body p-5 mt-3 mb-5 mx-5 text-center bg-gray-700">
      <p><span class="material-icons mi-64 text-purple"><span class="material-icons-outlined">verified</span></span></p>
      <p class="message-card-message fs-md mb-0 text-purple">Great news, no exceptions.</p>
    </div>
  </ng-template>
</div>
<cds-ui-vibe-exception-modal [workflowHistory$]="workflowHistory$"></cds-ui-vibe-exception-modal>
