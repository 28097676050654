<ng-template #template>
<div id="modalDrag" cdkDrag>
  <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4" cdkDragHandle>
    <h2 class="m-0">Demurrage & Detention</h2>
    <button class="bg-transparent border-0 p-0" *ngIf="(isLoading$ | async) === false" (click)="onCancel()">
      <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
      <span class="visually-hidden">Close</span>
    </button>
  </div>
  <div class="modal-body pt-0 mt-4 dndmodal">
    <div class="container-fluid">
        <div class="row" >
            <div *ngIf="demurrageData">
                <h4 class="text-purple ps-0">Demurrage</h4>
                <table class="table table-dark" >
                    <tbody>
                    <tr>
                        <td><span class="fw-bold">Start Event</span></td>
                        <td>{{getStartEvent(demurrageData?.containerChargeDetails[0]?.startEventStatusCode, demurrageData?.containerChargeDetails[0]?.startEventStatusName, demurrageData?.containerChargeDetails[0]?.startEventStatusDate)}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">End Event</span></td>
                        <td>{{getStartEvent(demurrageData?.containerChargeDetails[0]?.endEventStatusCode, demurrageData?.containerChargeDetails[0]?.endEventStatusName, demurrageData?.containerChargeDetails[0]?.endEventStatusDate)}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Free Time</span></td>
                        <td>{{demurrageData?.containerChargeDetails[0]?.freeTime ? demurrageData?.containerChargeDetails[0]?.freeTime + ' Days' : '--'}}</td>
                    </tr>
                    <ng-container *ngFor="let item of demurrageData?.containerChargeDetails; let i = index;">
                        <tr>
                            <td><span class="fw-bold">{{chargeStart(i+1, demurrageData?.containerChargeDetails.length)}}</span></td>
                            <td class="font-monospace">{{(item?.chargeStartDate ?? '--' | date:'MM/dd/YYYY')}}</td>
                        </tr>
                        <tr>
                            <td><span class="fw-bold">Time Left</span></td>
                            <td>
                                <span class="badge" [ngClass]="item?.tierDaysLeft <= 0 ? 'bg-danger' : (item?.tierDaysLeft > 0 && item?.tierDaysLeft < 5) ? 'bg-warning' : 'bg-success'">
                                    <span class="material-icons mi-12 material-icons-outlined" *ngIf="demurrageData?.isCompleted == true">
                                        timer_off
                                    </span> 
                                    {{removeNegativeValue(item?.tierDaysLeft)}} <span *ngIf="item?.tierDaysLeft < 0">Days Over</span><span *ngIf="item?.tierDaysLeft >= 0">Days Left</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td><span class="fw-bold">Charge Per Day</span></td>
                            <td class="font-monospace">{{item?.feeAmount ? '$' + item?.feeAmount : '--'}}</td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td><span class="fw-bold">Current Estimated Charges</span></td>
                        <td class="font-monospace">{{demurrageData?.chargeTotal ? '$' + demurrageData?.chargeTotal : '$0'}}</td>
                    </tr>
                    
                    <tr>
                        <td><span class="fw-bold">Carrier / Contract</span></td>
                        <td>{{demurrageData?.containerChargeDetails[0]?.carrierName | titlecase}} / {{demurrageData?.containerChargeDetails[0]?.contractNumber}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Day Type</span></td>
                        <td>{{demurrageData?.containerChargeDetails[0]?.isChargedByWorkingDays == true ? 'Business' : demurrageData?.containerChargeDetails[0]?.isChargedByWorkingDays == false ? 'Calendar' : '--'}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Start Event Location Other Than Discharge Port</span></td>
                        <td>{{demurrageData?.containerChargeDetails[0]?.isStartEventLocationOtherThanDischargePort == true ? 'True' : demurrageData?.containerChargeDetails[0]?.isStartEventLocationOtherThanDischargePort == false ? 'False' : '--'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>    
            <div *ngIf="detentionData">
                <h4 class="text-purple ps-0">Detention</h4>
                <table class="table table-dark">
                    <tbody>
                    <tr>
                        <td><span class="fw-bold">Start Event</span></td>
                        <td>{{getStartEvent(detentionData?.containerChargeDetails[0]?.startEventStatusCode, detentionData?.containerChargeDetails[0]?.startEventStatusName, detentionData?.containerChargeDetails[0]?.startEventStatusDate)}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">End Event</span></td>
                        <td>{{getStartEvent(detentionData?.containerChargeDetails[0]?.endEventStatusCode, detentionData?.containerChargeDetails[0]?.endEventStatusName, detentionData?.containerChargeDetails[0]?.endEventStatusDate)}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Free Time</span></td>
                        <td>{{detentionData?.containerChargeDetails[0]?.freeTime ? detentionData?.containerChargeDetails[0]?.freeTime + ' Days' : '--'}}</td>
                    </tr>
                    <ng-container *ngFor="let item of detentionData?.containerChargeDetails; let i = index;">
                        <tr>
                            <td><span class="fw-bold">{{chargeStart(i+1, detentionData?.containerChargeDetails.length)}}</span></td>
                            <td class="font-monospace">{{(item?.chargeStartDate ?? '--' | date:'MM/dd/YYYY')}}</td>
                        </tr>
                        <tr>
                            <td><span class="fw-bold">Time Left</span></td>
                            <td>
                                <span class="badge" [ngClass]="item?.tierDaysLeft <= 0 ? 'bg-danger' : (item?.tierDaysLeft > 0 && item?.tierDaysLeft < 4) ? 'bg-warning' : 'bg-success'">
                                    <span class="material-icons mi-12 material-icons-outlined" *ngIf="detentionData?.isCompleted == true">
                                        timer_off
                                    </span> 
                                    {{removeNegativeValue(item?.tierDaysLeft)}} <span *ngIf="item?.tierDaysLeft < 0">Days Over</span><span *ngIf="item?.tierDaysLeft >= 0">Days Left</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td><span class="fw-bold">Charge Per Day</span></td>
                            <td class="font-monospace">{{item?.feeAmount ? '$' + item?.feeAmount : '--'}}</td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td><span class="fw-bold">Current Estimated Charges</span></td>
                        <td class="font-monospace">{{detentionData?.chargeTotal ? '$' + detentionData?.chargeTotal : '$0'}}</td>
                    </tr>
                    
                    <tr>
                        <td><span class="fw-bold">Carrier / Contract</span></td>
                        <td>{{detentionData?.containerChargeDetails[0]?.carrierName | titlecase}} / {{detentionData?.containerChargeDetails[0]?.contractNumber}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Day Type</span></td>
                        <td>{{detentionData?.containerChargeDetails[0]?.isChargedByWorkingDays == true ? 'Business' : detentionData?.containerChargeDetails[0]?.isChargedByWorkingDays == false ? 'Calendar' : '--'}}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Start Event Location Other Than Discharge Port</span></td>
                        <td>{{detentionData?.containerChargeDetails[0]?.isStartEventLocationOtherThanDischargePort == true ? 'True' : detentionData?.containerChargeDetails[0]?.isStartEventLocationOtherThanDischargePort == false ? 'False' : '--'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</div>
</ng-template>