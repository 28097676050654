import { Directive, HostListener, ElementRef, OnInit, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[cdsUiTextOverflowTooltip]'
})
export class TextOverflowTooltipDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if(element.offsetWidth < element.scrollWidth){
        element.title = element.innerHTML;
        console.log(element.title,'title');
      }
    }, 500);
  }
}
