<ng-template #template>
  <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4">
    <h2 class="m-0"> Edit {{replaceDashesWithSpacesAndCapitalize(paneType$$.value)}}</h2>
    <button class="bg-transparent border-0 p-0" *ngIf="(isLoading$ | async) === false" (click)="onCancel()">
      <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
      <span class="visually-hidden">Close</span>
    </button>
  </div>
  <div class="modal-body d-flex flex-grow-1">
    <form [formGroup]="form" class="w-100" *ngIf="(data$ | async) as  activeData">
      <ng-container *ngIf="paneType$$.value === poPanelTypes.purchaseOrderInfo">
        <div class="row  mt-3">
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('Buyer' | lrmReplace) | async}}" placeholder=""
              formControlName="buyerCode" [formControl]="form.get('buyerCode')" />
          </div>
          <div class="col-6">
            <input id="po-event-code-input" type="text" class="form-control" labelText="{{('Event Code' | lrmReplace) | async}}" placeholder=""
              formControlName="eventCode" [formControl]="form.get('eventCode')" />
          </div>
        </div>
        <div class="row  mt-3">
          <div class="col-6">
            <input id="po-productCode-input" [uiInputLimitCharacters]="3" type="text" class="form-control" labelText="{{('Product Code' | lrmReplace) | async}}" placeholder=""
              formControlName="productCode" [formControl]="form.get('productCode')" />
          </div>
          <div class="col-6">
            <input id="po-division-input" type="text" class="form-control" labelText="{{('Division' | lrmReplace) | async}}" placeholder=""
              formControlName="division" [formControl]="form.get('division')" />
          </div>
          
        </div>
        <div class="row  mt-3">
          <div class="col-md-6">
            <select id="po-shi-method-dropdown" class="form-select" labelText="{{('Ship Method' | lrmReplace) | async}}" formControlName="shipMode"
              [appendSelectOne]="true" [optionsStream]="shipMethodList$"  [selectValue]=" activeData?.shipMode" [formControl]="form.get('shipMode')">
            </select>
          </div>
          <div class="col-md-6">
            <select id="po-shi-method-dropdown" class="form-select" labelText="{{('Status' | lrmReplace) | async}}" formControlName="purchaseOrderStatus"
              [appendSelectOne]="true" [optionsStream]="statusList$" [selectValue]="activeData?.purchaseOrderStatus" [formControl]="form.get('purchaseOrderStatus')">
            </select>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="paneType$$.value === poPanelTypes.poDates">
        <div class="row  mt-3">
          <div class="col-6">
            <input type="text" formControlName="poCancelledDate"  [formControl]="form.get('poCancelledDate')"
              labelText="{{('Cancel Date' | lrmReplace) | async}}" tabindex="1" placeholder="Select Date" class="form-control custom-date"
              bsDatepicker readonly [bsConfig]="datePickerConfig" placement="top">
          </div>
          <div class="col-6">
            <input type="text" formControlName="revisedShipDate" [formControl]="form.get('revisedShipDate')"
              labelText="{{('Revised Ship Date' | lrmReplace) | async}}" tabindex="1" placeholder="Select Date" class="form-control custom-date"
              bsDatepicker readonly [bsConfig]="datePickerConfig" placement="top">
          </div>
        </div>
        <div class="row  mt-3">
          <div class="col-6">
            <input type="text" formControlName="firstShipDate" [formControl]="form.get('firstShipDate')"
              labelText="{{('First Ship Date ' | lrmReplace) | async}}" tabindex="1" placeholder="Select Date" class="form-control custom-date"
              bsDatepicker readonly [bsConfig]="datePickerConfig" placement="top">
          </div>
          <div class="col-6">
            <input type="text" formControlName="lastShipDate" [formControl]="form.get('lastShipDate')"
              labelText="{{('Last Ship Date ' | lrmReplace) | async}}" tabindex="1" placeholder="Select Date" class="form-control custom-date"
              bsDatepicker readonly [bsConfig]="datePickerConfig" placement="top">
          </div>
        </div>
        <div class="row  mt-3">
          <div class="col-6">
            <input type="text" formControlName="inWarehouseDate"  [formControl]="form.get('inWarehouseDate')"
              labelText="{{('PO In DC' | lrmReplace) | async}}" tabindex="1" placeholder="Select Date" class="form-control custom-date" bsDatepicker
              readonly [bsConfig]="datePickerConfig" placement="top">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="paneType$$.value === poPanelTypes.locationInfo">
        <div class="row  mt-3">
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('DC' | lrmReplace) | async}}" placeholder=""
              formControlName="deliveryDestination" [formControl]="form.get('deliveryDestination')" />
          </div>
          <div class="col-6">
            <input id="po-event-code-input" type="text" class="form-control" labelText="{{('Final Destination' | lrmReplace) | async}}"
              placeholder="" formControlName="finalDestination" [formControl]="form.get('finalDestination')" />
          </div>
        </div>
        <div class="row  mt-3">
           <div class="col-md-6">
            <select id="po-shi-method-dropdown" class="form-select" labelText="{{('Origin Port' | lrmReplace) | async}}"
              formControlName="originLocation" [appendSelectOne]="true" [selectValue]="(activeData?.originLocation)" [optionsStream]="originPortList$"
              [formControl]="form.get('originLocation')">
            </select>
          </div>
          <div class="col-md-6">
            <select id="po-shi-method-dropdown" class="form-select" labelText="{{('Origin Country' | lrmReplace) | async}}"
              formControlName="shipOriginContury" [appendSelectOne]="true" [selectValue]="(activeData?.shipOriginContury)" [optionsStream]="shipOriginCountryList$"
              [formControl]="form.get('shipOriginContury')">
            </select>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="paneType$$.value === poPanelTypes.vendorInfo">
        <div class="row  mt-3">
          <div class="col-md-6">
            <input formControlName="vendor" id="detail-pane-edit-vendorCode" 
              [formControl]="form.get('vendor')"
              [typeahead]="vendorList$" [(ngModel)]="search" type="search" 
              [typeaheadAsync]="true"  [typeaheadMinLength]="3" [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="5"  typeaheadOptionField="vendor"
              (typeaheadOnSelect)="onVendorSelect($event)" placeholder="Search for Vendor" class="form-control" #vc
              labelText="{{('Vendor' | lrmReplace) | async}}"
               >
          </div>
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('Supplier Ref #' | lrmReplace) | async}}" placeholder=""
              formControlName="supplierReferenceNumber" [formControl]="form.get('supplierReferenceNumber')" />
          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="paneType$$.value === poPanelTypes.additionalInfo">
        <div class="row  mt-3">
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('MISC 1' | lrmReplace) | async}}" placeholder=""
              formControlName="misc1" [formControl]="form.get('misc1')" />
          </div>
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('MISC 2' | lrmReplace) | async}}" placeholder=""
              formControlName="misc2" [formControl]="form.get('misc2')" />
          </div>
        </div>
        <div class="row  mt-3">
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('MISC 3' | lrmReplace) | async}}" placeholder=""
              formControlName="misc3" [formControl]="form.get('misc3')" />
          </div>
          <div class="col-6">
            <input id="po-buyer-input" type="text" class="form-control" labelText="{{('Special Instructions' | lrmReplace) | async}}" placeholder=""
              formControlName="poSpecialInstructions" [formControl]="form.get('poSpecialInstructions')" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="paneType$$.value === poPanelTypes.paymentInfo">
        <div class="row  mt-3">
          <div class="col-md-6">
            <select id="po-payment-code-dropdown" class="form-select" labelText="{{('Payment Code' | lrmReplace) | async}}"
              formControlName="paymentCode" [appendSelectOne]="true"  [selectValue]="(activeData?.paymentCode)"  [optionsStream]="paymentCodes$"
              [formControl]="form.get('paymentCode')">
            </select>
          </div>
          <div class="col-md-6">
            <select id="po-currency-code-dropdown" class="form-select" labelText="{{('Currency Code' | lrmReplace) | async}}"
              formControlName="currencyCode" [appendSelectOne]="true"  [selectValue]="(activeData?.currencyCode)"  [optionsStream]="currenctCodes$"
              [formControl]="form.get('currencyCode')">
            </select>
          </div>
        </div>
      
      </ng-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" [disabled]="isLoading$ | async" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary ms-2" [disabled]="(isLoading$ | async)" (click)="onSave()"><i
        *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status">
      </i>Save</button>
  </div>
</ng-template>