import { AppStateRepository } from '@cds-ui/shared/core-state';
import { AfterViewInit, Component, EventEmitter, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import {DocItemsUploadedModalConfig,DocItemsUploadedModalService,DocItemsUploadedDialogueEvent } from './doc-items-uploaded-modal.service';

@Component({
    selector: 'cds-ui-doc-items-uploaded-modal',
    templateUrl: './doc-items-uploaded-modal.component.html',
    styleUrls : ['./doc-items-uploaded-modal.component.scss']
})

export class DocItemsUploadedModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('docItemUploadedDialogue') docItemUploadedDialogue!: TemplateRef<any>;
    private destroy$: Subject<void> = new Subject();
    public modalRef!: BsModalRef;
    public config!:DocItemsUploadedModalConfig;
    public isLoading$ = this.appStateRepository.isLoading$;

    constructor(
        private modalservice: BsModalService,
        private docItemsUploadedModalService: DocItemsUploadedModalService,
        private appStateRepository: AppStateRepository) { }

    ngAfterViewInit(): void {
        this.subscribeShow();
        this.handler(this.modalservice.onShow, DocItemsUploadedDialogueEvent.OnShow);
        this.handler(this.modalservice.onShown, DocItemsUploadedDialogueEvent.OnShown);
        this.handler(this.modalservice.onHide, DocItemsUploadedDialogueEvent.OnHide);
        this.handler(this.modalservice.onHidden,DocItemsUploadedDialogueEvent.OnHidden);
       
    }

    private subscribeShow() {
        this.docItemsUploadedModalService.show$
            .pipe(takeUntil(this.destroy$))
            .subscribe((config) => {
                if (this.modalRef) this.modalRef.hide();
                this.config = config as DocItemsUploadedModalConfig;
                this.modalRef = this.modalservice.show(this.docItemUploadedDialogue, { backdrop: false, ignoreBackdropClick: true,class:'modal-xl' });
            });
    }
   


      

    private handler(handler: EventEmitter<any>, type:DocItemsUploadedDialogueEvent) {
        handler.pipe(takeUntil(this.destroy$))
               .subscribe((_) => this.docItemsUploadedModalService.handle({ type: type, params: {} }));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCancel() {
        this.docItemsUploadedModalService.setCancel();
        this.modalRef.hide();
    }
}

