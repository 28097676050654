<ng-template #template>

    <!-- <div class="modal">
        <div class="modal-dialog modal-dialog-centered"> -->
          <div id="modalDrag" class="modal-content" cdkDrag>
            <div class="modal-header pb-0" cdkDragHandle>
              <h3 class="modal-title">{{config.title}}</h3>
              <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="form" class="row g-3">
              <h3 class="text-purple mb-0 pb-0">{{config.message}}</h3>
                <div class="col-md-12">
                  <label  class="form-label">Note (optional)</label>
                  <textarea formControlName="ReasonNote" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-cancel"  (click)="close()">{{config.btnTwo}}</button>
              <button type="button" class="btn btn-submit" (click)="onVibeClick()"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>{{config.btnOne}}</button>
            </div>
          </div>
        <!-- </div>
      </div> -->

    <!-- <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4">
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-grow-1">
        <span>{{config.message}}</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger fw-medium ms-2" (click)="onConfirmationClick()"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>{{config.btnOne}}</button>
        <button type="button" class="btn btn-outline-primary text-light ms-2" (click)="modalRef?.hide()">{{config.btnTwo}}</button>
    </div> -->
</ng-template>