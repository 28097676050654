/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import {
  CatalogName,
  LogiService,
  ReportName
} from '@cds-ui/domain/analytics/domain-core';
import { AuthService, User } from '@cds-ui/shared/auth';
import _ from 'lodash';
import { CollectionDetails } from '../analytics-collection/models/analytics-collection.model';
import {
  AnalyticsCollectionQueryService,
  PersonaInfo
} from '../analytics-collection/analytics-collection.query';
import { ToastrService } from 'ngx-toastr';
import { catchError, filter, map, mergeMap, of, take, tap, throwError } from 'rxjs';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { filterNullish } from '@cds-ui/shared/core-rx';

@Component({
  selector: 'cds-ui-analytics-card',
  templateUrl: './analytics-card.component.html',
  providers: [LogiService]
})
export class AnalyticsCardComponent {
  @Input() collectionDetails: CollectionDetails[] = [];
  @Input() reportDetails: any;
  @Input() personaInfo!: PersonaInfo;
  @Input() viewFavIcon: any;

  catalogName: CatalogName = { name: '' };
  reportName: ReportName = { name: '' };
  activeEditClass: any = {
    class: 'analytics-card-edit btn bg-lavender-300 active',
    text: 'edit'
  };
  inActiveEditClass: any = {
    class: 'analytics-card-edit btn bg-lavender-300',
    text: 'edit_outline'
  };
  activeFavoritesClass: any = {
    class: 'analytics-card-favorite btn bg-gray-800 active',
    text: 'star'
  };
  inActiveFavoritesClass: any = {
    class: 'analytics-card-favorite btn bg-gray-800',
    text: 'star_outline'
  };

  imagePath: any = {
    basePath: 'assets/img/analytics/',
    defaultImage: 'assets/img/analytics/AnalyticsDefault.png'
  };

  /* Params here is the params the user selects when running a Web Report... */
  params = {};
  pathPrefix!: string;
  url!: string;
  userInfo: any;

  private favoriteReports: CollectionDetails = {
    collectionHasReports: true,
    collectionName: 'My Favorites',
    reportDetails: [] as any[],
    showCollection: true,
    showIfEmpty: true,
    sequence: 1
  };

  constructor(
    private authService: AuthService,
    private appState: AppStateRepository,
    private collectionQueryService: AnalyticsCollectionQueryService,
    private toastrService: ToastrService,
    private logiService: LogiService
  ) {
    this.authService.user$
      .pipe(
        tap((res) => {
          this.userInfo = res;
          this.userInfo.subgroupCode = res.subgroup_code; 
        })
      )
      .subscribe();
    this.appState.environment$
      .pipe(
        tap((e) => {
          this.pathPrefix = e.pathPrefix;
        })
      )
      .subscribe();
  }

  loadReport(clickEvent: string) {
    this.reportName.name = this.reportDetails.reportFullName;
    this.catalogName.name = this.reportDetails.catalogName;

    /* Once we determine other params for reports, we need to pass them in here... */
    this.setReportParams(this.userInfo);

    window.open(
      `${window.location.origin}${this.pathPrefix}/analytics/report-viewer`,
      '_blank'
    );

    window.localStorage.setItem(
      'reportAnalyticsData',
      JSON.stringify({
        headerTitle: clickEvent === 'run' ? 'Report Viewer' : 'Report Editor',
        reportName: this.reportName,
        reportNameFull: this.reportDetails.cardTitle,
        catalogName: this.catalogName,
        params: this.params,
        type: clickEvent,
        isEdittable: this.reportDetails.isEditMode
      })
    );
  }

  setReportParams(
    userInfo: User,
    additionalParams: { [key: string]: string | any } = {}
  ) {
    const reportParams: any = {
      // This is the default param for all reports...
      ParentCompany: ''
    };

    reportParams.ParentCompany = userInfo.subgroupCode;
    if (Object.keys(additionalParams).length !== 0)
      _.forOwn(additionalParams, (value, key) => {
        reportParams[key] = value;
      });
    this.params = reportParams;
  }

  updateFavorites(addToFavorite: boolean) {
    /* This will return -1 if no Index is found... */
    let favoritesIndex = _.findIndex(this.collectionDetails, {
      collectionName: 'My Favorites',
    });

    /* This is for cases when we are adding/removing Favorites from the Collection-Dashboard (View All)
    page.- If we did not select the 'My Favorites' View All hyperlink, there will be no collection for favorites
    in the collectionDetails array.- In this case, we need to add to the collectionDetails array... */
    if (favoritesIndex === -1) {
      this.collectionDetails.push(this.favoriteReports);

      favoritesIndex = _.findIndex(this.collectionDetails, {
        collectionName: 'My Favorites',
      });
    }

    this.reportDetails.isFavorited = addToFavorite;
    const collectionFavorites: any[] = [];

    /* This is to change the star in each collection for the selected Report Card... */
    _.forEach(this.collectionDetails, (collection) => {
      _.forEach(collection.reportDetails, (report) => {
        if (report.cardTitle === this.reportDetails.cardTitle)
          /* Add or Remove the selected Report Card to/from the Favorites Array... */
          report.isFavorited = addToFavorite;

        /* Now we are filtering out the reports that are currently favorited within
        this collection.- We will use this to build the My Favorites collection to pass
        to Redis... */
        if (report.isFavorited) collectionFavorites.push(report);
      });
    });

    if (collectionFavorites)
      this.collectionDetails[favoritesIndex].reportDetails = _.uniqBy(
        collectionFavorites,
        'cardTitle'
      );

    this.collectionDetails[favoritesIndex].collectionHasReports =
      this.collectionDetails[favoritesIndex].reportDetails.length !== 0;

    /* Update Redis cache... */
    this.collectionQueryService
      .updateUserCollections([this.collectionDetails[favoritesIndex]])
      .subscribe(() => {});
  }

  validateReport(clickEvent: string) {
    /* Any additional validations we need to perform before executing the report
    can be placed here... */
    this.appState.currentUser$.pipe(
      map(x => x.subgroupCode),
      filterNullish(),
      take(1),
      mergeMap(subgroupCode => 
        (subgroupCode.toLowerCase() === 'all' && !this.reportDetails.allowAllSubgroup)
        ? throwError(() => new Error(`You are logged in as subgroup ALL and this report does not run for subgroup ALL.
        Please update your subgroup and then try to run this report again.`))
        : of(clickEvent)    
      ),
      tap(evnt => this.loadReport(evnt)),
    )
    .subscribe({
      error: err => this.toastrService.error(err.message, '', { timeOut: 3000})
    });
  }
}
