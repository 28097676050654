import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface OverwriteModalConfig {
    title: string,
    message: string
}

export enum OverwriteDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingOverwrite,
    OnClosingOverwrote,
    OnCanceled
}

interface OverwriteModalEvent {
    type: OverwriteDialogueEvent,
    params: any | undefined | null
}

@Injectable({ providedIn: 'root' })
export class OverwriteModalService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private event$: Subject<OverwriteModalEvent> = new Subject();
    private showSubject$ = new Subject(); 
    public overwriteDialogue$ = new Subject();

    public isDirty = false;
    public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));

    public onShow$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnShow));
    public onShown$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnShown));
    public onHide$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnHide));
    public onHidden$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnHidden));

    public OnClosingOverwrite$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnClosingOverwrite));
    public OnClosingOverwrote$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnClosingOverwrote));

    public onCanceled$ = this.event$.pipe(filter(x => x.type === OverwriteDialogueEvent.OnCanceled));
    
    show(config: OverwriteModalConfig): void {
        this.showSubject$.next(config);
    }

    handle(event: OverwriteModalEvent): void {
        this.event$.next(event);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    markAsDirty() {
        this.isDirty = true;
    }

    markAsPristine() {
        this.isDirty = false;
    }

    setClosingOverwrite(params: any = {}) {
        this.event$.next({ type: OverwriteDialogueEvent.OnClosingOverwrite, params });
    }

    setClosingOverwrote(params: any = {}) {
        this.isDirty = false;
        this.event$.next({ type: OverwriteDialogueEvent.OnClosingOverwrote, params });
    }  

    setCancel(params: any = {}) {
        this.event$.next({ type: OverwriteDialogueEvent.OnCanceled, params });
    }
}
