import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ResetGridQueryService {
  constructor(private http: HttpClient) {}


  public ResetGrid(page:string) {
    return this.http.delete<any>(
      `user/preferences/page/${page}`
    );
  }

}
