import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { pipe, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import {  ActivityFeedEventModalConfig, ActivityFeedViewMoreModalService } from './activity-feed-viewmore-modal.service';

@Component({
  selector: 'cds-ui-activity-feed-viewmore-modal',
  templateUrl: './activity-feed-viewmore-modal.component.html',
  styleUrls: ['./activity-feed-viewmore-modal.component.scss'],
})
export class ActivityFeedViewMoreModalComponent implements OnInit {

  public isLoading$ = this.appStateRepository.isLoading$;
  private destroy$: Subject<void> = new Subject();
  private configDataSubject$$ = new Subject<ActivityFeedEventModalConfig>();
  public  configData$ = this.configDataSubject$$.asObservable().pipe(shareReplay(1));
  public modalRef!: BsModalRef;
  public config!: ActivityFeedEventModalConfig;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private ViewmoreModalService: ActivityFeedViewMoreModalService,
    private appStateRepository: AppStateRepository
  ) {}

  ngOnInit(): void {
    this.configData$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngAfterViewInit(): void { 
    this.ViewmoreModalService.show$
      .pipe(takeUntil(this.destroy$),
      tap(x=>  this.configDataSubject$$.next(x as ActivityFeedEventModalConfig))
      ).subscribe((_) => {
        if (this.modalRef) this.modalRef.hide();       
        this.modalRef = this.modalservice.show(this.template, {
          backdrop: false,
          ignoreBackdropClick: true,
          class:'modal-dialog-centered modal-xl'
        });
      });

  }

  dateFormatter(date: any) {
      return date?moment.utc(date).format('MM/DD/YYYY'): null;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
