import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface ConfirmationEventModalConfig {
    title: string,
    message?: string,
    btnOne:string,
    btnTwo:string
    gridFlag?:string,
}

export enum ConfirmationDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface ConfirmationModalEvent {
    type: ConfirmationDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class ConfirmationModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<ConfirmationModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === ConfirmationDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === ConfirmationDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === ConfirmationDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === ConfirmationDialogueEvent.OnHidden));
  public onClosingConfirmationRemove$ = this.event$.pipe(filter((x) => x.type === ConfirmationDialogueEvent.OnClosingRemove));
  public onClosingConfirmationRemoved$ = this.event$.pipe(filter((x) => x.type === ConfirmationDialogueEvent.OnClosingRemoved));

  show(config: ConfirmationEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: ConfirmationModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingConfirmation(params?: any) {
    this.event$.next({
      type: ConfirmationDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingConfirmationRemoved() {
    this.event$.next({
      type: ConfirmationDialogueEvent.OnClosingRemoved,
    });
  }
}