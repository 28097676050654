declare const bootstrap: any;
import * as _ from 'underscore';

import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MainMenuService } from './main-menu.service';
import { filter, map, startWith, share, tap, of, Subject, shareReplay } from 'rxjs';
import { menuMapping } from './main-menu.map';
import { distinctUntilArrayItemChanged } from '@ngneat/elf';

@Component({
  selector: 'cds-ui-main-header-nav-bar',
  templateUrl: './main-header-nav-bar.component.html',
  styleUrls: ['./main-header-nav-bar.component.scss'],
})
export class MainHeaderNavBarComponent implements OnInit {

  public permissionQuery$ = of(['*:action:*:list']);
  public menuPermission$$ = new Subject<string[]>();
  public menuPermission$ = this.menuPermission$$.pipe(
    distinctUntilArrayItemChanged(),
    map(x => this.getMenuItems(x)),
    shareReplay(1)
  );
  public menuMapping = Object.entries(menuMapping)
    .map(([key, value]) => ({
      [key]: {
        ...value,
        activeUrlFragment: value.url.split("/").length < 3 ? value.url.split("/")[0] : value.url.split("/")[1]
      }
    }))
    .reduce((acc, item) => ({ ...acc, ...item }), {});

  public routerUrl$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(x => x as NavigationEnd),
    map(x => x.url),
    startWith(this.router.url)
  );

  public routerUrlGroup$ = this.routerUrl$.pipe(
    map(x => x.split('/').length <= 3 ? x.split('/')[1] : x.split('/')[2]),
  );

  public megaMenuMappedData$ = this.mainMenuService.getLegacyNavigationMenu().pipe(
    share(),
    tap(_ => this.legacySecondLevelMenuDropdown())
  );

  constructor(
    private mainMenuService: MainMenuService,
    private router: Router
  ) {}

  ngOnInit() {}

  openMoreMenu(){
    const offCanvasMenu = document.getElementById('moreMenuRight');
    const bsOffcanvas = new bootstrap.Offcanvas(offCanvasMenu);
    bsOffcanvas.show();
  }

  getMenuItems(permissions: string[]) {

    const menuItem = permissions.map(permission => this.menuMapping[permission])
      .filter(item => item !== undefined)
      .sort((a, b) => a.seq - b.seq);

    const result = menuItem.reduce((acc, item) => {
      const target = item.group
        ? acc.find(group => group.name === item.group)
        : acc;

      target?.activeUrlFragment?.push(item.activeUrlFragment);

      target?.subMenu?.push(item)
        ?? acc.push(item.group
          ? { seq: item.seq, name: item.group, subMenu: [item], activeUrlFragment: [item.activeUrlFragment] }
          : item);

      return acc;
    }, [] as any[]).sort((a, b) => a.seq - b.seq);

    return result;
  }

  legacySecondLevelMenuDropdown() {
    setTimeout(() => {
      const submenuTriggers = document.querySelectorAll('.has-submenu');
      submenuTriggers.forEach((smt: any) => {
        smt.addEventListener('show.bs.dropdown', (e: any) => {
          const openMenus = smt
            .closest('.dropdown-menu')
            .querySelectorAll('.dropdown-item.show');
          openMenus.forEach((om: any) => {
            const omDropdown = bootstrap.Dropdown.getInstance(om);
            omDropdown.hide();
          });
        });
        smt.addEventListener('click', (e: any) => {
          e.stopPropagation();
          e.preventDefault();
        });
      });

      const showShipperToggle = document.getElementById('show-shipper-toggle');
      const shipperDetail = document.querySelector('.shipper-detail');
      showShipperToggle?.addEventListener('click', () => {
        const that: any = this;
        if (that.checked) {
          shipperDetail?.classList.remove('d-none');
        } else {
          shipperDetail?.classList.add('d-none');
        }
      });
    }, 1000);
  }

  navigateMainMenu(mainMenu: any) {
    if (!mainMenu.route) return;

    this.router.navigateByUrl(
      `/legacy/pagelayout?moduleCd=${mainMenu.route.moduleCd}`
      + `&configName=${mainMenu.route.configName}&vName=${mainMenu.displayName}`);

    const offCanvas = document.querySelector('.offcanvas');
    bootstrap.Offcanvas.getInstance(offCanvas).hide();

  }

  navigateSubMenu(subMenu: any) {
    if (!subMenu) return;

    this.router.navigateByUrl(
      `/legacy/pagelayout?moduleCd=${subMenu.moduleCd}`
      + `&configName=${subMenu.configName}&vName=${subMenu.displayName}`
      + (subMenu?.url ?? ""));

    const offCanvas = document.querySelector('.offcanvas');
    bootstrap.Offcanvas.getInstance(offCanvas).hide();
  }
}
