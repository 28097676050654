<ng-template #template>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header pb-0" cdkDragHandle>
        <h3 class="modal-title">{{config.title}} Change Request</h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <form class="row g-3">
        <h3 class="text-purple mb-0 pb-0">{{config.count}} {{config.count === 1 ? 'Record' : 'Records'}} Selected to be {{config.title}}d</h3>
          <div class="col-md-12">
            <label class="form-label">Note (optional)</label>
            <textarea class="note" #note></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal" (click)="close()">{{config.btnTwo}}</button>
		    <button type="button" class="btn btn-submit" (click)="onPocDeclineClick(note.value)"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>{{config.btnOne}}</button>
      </div>
  </div>
</ng-template>