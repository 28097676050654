<a *ngIf="!hideRestoreButton">
    <button type="button" class="btn btn-outline-primary text-light d-flex" data-bs-toggle="tooltip" data-bs-placement="top"
        (click)="resetClick()" title="Reset to Default" id="{{gridId}}-reset-button">
        <span class="material-icons mi-28"> settings_backup_restore </span>
    </button>
</a>


<ng-template #template>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 p-4">
        <h2 class="m-0">Confirmation</h2>
        <button class="bg-transparent border-0 p-0" (click)="modalRef?.hide()">
            <span class="material-icons mi-24 text-gray-200">close</span>
            <span class="visually-hidden">Close</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-grow-1">
        <span>Do you want to restore your default view</span>
    </div>
    <div class="modal-footer">
        <button id="{{gridId}}-reset-confirm-button" type="button" class="btn btn-danger fw-medium ms-2" (click)="onConfirmationClick()"><i *ngIf="(isLoading$ | async)" class="spinner-grow spinner-grow-sm mx-2" role="status"></i>Yes</button>
        <button id="{{gridId}}-reset-cancel-button" type="button" class="btn btn-outline-primary text-light ms-2" (click)="modalRef?.hide()">No</button>
    </div>
</ng-template>
