export interface authServer {
  authority: string;
  clientId: string;
}

export interface appInsights {
  instrumentationKey: string;
}

export interface urls {
  logiServer: string;
  logiServerLogout: string;
  legacyApiServer: string;
}

export interface AppEnvironment {
  environment: Environment | null;
}

export interface Environment {
  production: boolean;
  pathPrefix: string;
  envName: string;
  uiServerUrl: string;
  uiServerUrlLocal: string;
  apiServer: string;
  authServer: authServer;
  appInsights: appInsights;
  disabledFeatures: string[];
  urls: urls;
}