import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';

export interface IRouterLinkRendererComponentOptions {
  link?: string;
  displayText: string;
  target?: string;
}

@Component({
  selector: 'cds-ui-nav-link-renderer',
  templateUrl: './nav-link-renderer.component.html',
})
export class NavLinkRendererComponent implements AgRendererComponent {
  public params!: IRouterLinkRendererComponentOptions;

  constructor(
    private ngZone: NgZone,
    private router: Router) { }

  agInit(params: any): void {
    this.params = params.routerLinkRendererComponentOptions(params);
  }

  refresh(params: any): boolean {
    return true;
  }

  navigate() {
    this.ngZone.run(() => {
        this.router.navigate([this.params.link]);
    });
}
}
