import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridCustomDirective } from './ag-grid-custom-directive/ag-grid-custom-directive';
import { AgGridCustomToolbarComponent } from './ag-grid-custom-directive/ag-grid-custom-toolbar/ag-grid-custom-toolbar.component';


@NgModule({
  imports: [CommonModule],
  declarations: [
    AgGridCustomDirective,
    AgGridCustomToolbarComponent
  ],
  exports: [
    AgGridCustomDirective
  ]
})
export class SharedUiGridDirectiveModule { }
