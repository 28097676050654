<div [id]="'report-card-' + reportDetails.cardTitle.split(' ').join('-').toLowerCase()" class="card">
    <!--Analytics Card Header Section...-->
    <div [ngSwitch]="reportDetails.cardImg" class="card-img-header">
        <div class="d-flex float-end ms-auto card-star">
            <span *ngIf="reportDetails.isEditMode"
                [class]="reportDetails.isEditMode? activeEditClass.class: inActiveEditClass"
                (click)="validateReport('edit')">
                <span class="material-icons mi-24 align-middle" aria-hidden="true">edit</span>
            </span>
            <span *ngIf="viewFavIcon">
                <span *ngIf="reportDetails.isFavorited" [class]="activeFavoritesClass.class"
                    (click)="updateFavorites(false)">
                    <span class="material-icons mi-24 align-middle"
                        aria-hidden="true">{{activeFavoritesClass.text}}</span>
                </span>
                <span *ngIf="!reportDetails.isFavorited" [class]="inActiveFavoritesClass.class"
                    (click)="updateFavorites(true)">
                    <span class="material-icons mi-24 align-middle"
                        aria-hidden="true">{{inActiveFavoritesClass.text}}</span>
                </span>
            </span>
        </div>
        <!--Images Section...-->
        <div class="image-zoom" (click)="validateReport('run')">
            <img *ngSwitchCase="reportDetails.cardImg" [src]="imagePath.basePath + reportDetails.cardImg + '.png'"
                class="card-img-top" alt="Card Image Cap">
            <img *ngSwitchDefault [src]="imagePath.defaultImage" class="card-img-top" alt="Card Image Cap">
        </div>
        <!--End Images Section...-->
    </div>
    <!--End Analytics Card Header Section...-->
    <!--Analytis Card Body Section...-->
    <div class="card-body" role="button" (click)="validateReport('run')">
        <h3 class="card-title">{{reportDetails.cardTitle}}</h3>
        <!-- <div class="d-flex gap-2">
            <span *ngFor="let t of reportDetails.tags"
                class="badge rounded-pill bg-primary px-3 d-inline-flex align-items-center"
                role="button">{{t.name}}</span>
        </div> -->
        <!-- <span *ngIf="reportDetails.showMoreInfo" class="btn border-primary border-2 bg-gray text-light">More
            Info</span> -->
        <p *ngIf="reportDetails.cardDescription !== ''" class="card-text">{{reportDetails.cardDescription}}</p>
    </div>
    <div class="card-footer pb-3 bg-gray" role="button" (click)="validateReport('run')">
        <div class="d-flex justify-content-start">
            <span class="btn btn-primary btn-sm fw-medium rounded-edge-btn">{{reportDetails.defaultTag}}</span>
        </div>
    </div>
</div>