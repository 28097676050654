import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { Maybe } from 'graphql/jsutils/Maybe';
import { BehaviorSubject, combineLatestWith, Observable, ReplaySubject, Subject, takeUntil, tap } from 'rxjs';

export interface DropdownOption {
    value: string | number | null | undefined;
    text: string | Maybe<string>;
}

@Directive({
    selector: '[optionsStream]',
})
export class EnhancedDropdownDirective implements OnInit, OnChanges, OnDestroy {
    private readonly dataSource$$ = new ReplaySubject<DropdownOption[]>(1);
    private readonly shouldAppendSelectOne$$ = new BehaviorSubject<boolean>(false);
    private readonly destroy$$ = new Subject<void>();

    @Input() placeholder = "Select One";

    @Input() isDisabled = false;
    @Input() selectValue!: string | number | null | undefined;
    @Input() hide = false;
    @Input() formControl!: FormControl<any> | null;
 
    @Input("optionsStream") set options(stream: Observable<DropdownOption[]>) {
      stream.pipe(
        takeUntil(this.destroy$$),
        tap(x => this.dataSource$$.next(x))
      )
      .subscribe();
    }

    @Input() set appendSelectOne(shouldAppend: boolean) {
        this.shouldAppendSelectOne$$.next(shouldAppend);
    }

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnDestroy(): void {
        this.destroy$$.next();
        this.destroy$$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const selectElement: HTMLSelectElement = this.el.nativeElement;
        if (selectElement.tagName !== 'SELECT') return;

        if(changes['formControl'] || changes['selectValue']) {
            this.selectValueOrDefaultFn();
        }

        if(changes['isDisabled']) {
            this.disableControlFn();
        }
    }

    ngOnInit(): void {
        const selectElement: HTMLSelectElement = this.el.nativeElement;

        if (selectElement.tagName !== 'SELECT') {
            console.warn('EnhancedDropdown directive must be applied to a <select> element.');
            return;
        }

        this.appendSelectOneFn();
    }

    private disableControlFn() {
        if (this.formControl) {
            this.isDisabled
                ? this.formControl.disable()
                : this.formControl.enable();
        }
        else {
            const selectElement: HTMLSelectElement = this.el.nativeElement;
            selectElement.disabled = this.isDisabled;
        }
    }

    private selectValueOrDefaultFn() {
        const selectElement: HTMLSelectElement = this.el.nativeElement;
        const existingValueOption = Array.from(selectElement.options).find(
            (option) => option.value?.toString() === this.selectValue?.toString()
        );

        const result = existingValueOption 
                     ? this.selectValue ?? ''
                     : Array.from(selectElement.options)
                            .map(x => x.value)
                            .find(_ => true);

        selectElement.value = result?.toString() ?? '';

        if(!this.formControl) return;
        this.formControl.setValue(result);
    }

    private appendSelectOneFn() {
        this.dataSource$$.pipe(
            takeUntil(this.destroy$$),
            combineLatestWith(this.shouldAppendSelectOne$$),
            tap(_ => {
                const selectElement: HTMLSelectElement = this.el.nativeElement;
                
                while (selectElement.options.length > 0) {
                    selectElement.remove(0);
                }
            }),
            tap(([x, appendSelectOne]) => {
                const selectElement: HTMLSelectElement = this.el.nativeElement;
                const options: HTMLOptionElement[] = [];

                const inputOptions = x.map(y => {
                    const option = this.renderer.createElement('option');
                    this.renderer.setProperty(option, 'textContent', y.text ?? '');
                    this.renderer.setAttribute(option, 'value', y.value?.toString() ?? '');

                    return option;
                });

                const shouldAppendSelectOne = ((appendSelectOne && x.length !== 1) || x.length == 1);
                const selectOneExists =  Array.from(selectElement.options).some(option => option.value === '')
                                      || Array.from(x).some(option => option.value === '');


                if (shouldAppendSelectOne && !selectOneExists) {
                    const defaultOption = this.renderer.createElement('option');
                    this.renderer.setProperty(defaultOption, 'textContent', this.placeholder);
                    this.renderer.setAttribute(defaultOption, 'value', '');
                    this.renderer.setAttribute(defaultOption, 'disabled', 'true');

                    options.push(defaultOption);
                }

                options.concat(inputOptions)
                       .forEach((r, i) => {
                            selectElement.add(r, selectElement.options[i]);
                        });

                if(!this.formControl) return;
                this.formControl?.markAsPristine();
            }),
            tap(_ => this.selectValueOrDefaultFn())
        )
        .subscribe();
    }
}