import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellEditorParams } from "@ag-grid-community/core";
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { filter, of, Subject, switchMap, takeUntil, tap } from "rxjs";

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
@Component({
  selector: 'IP-custom-datepicker.component',
  templateUrl: './IP-custom-datepicker.component.html' 
})
export class IPCustomDatePickerComponent implements OnInit, ICellRendererAngularComp, OnDestroy {
  value!: any;
  params: any;
  datePickerConfig:any;

  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  public isDecimalAllowed = false;
  bsValue = new Date();
  minDate!: Date;
  maxDate!: Date;
  private readonly destroy$$ = new Subject<void>();
  constructor(private toastrService: ToastrService) {
    
  }
  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;
  
  agInit(params: any): void {
    console.log(params, 'params')
    this.params = params;
    this.value = this.params?.value;
    if(params.isContainerLocation || params.inboundToDC || params.inboundToDC === null || params.inboundToDC === ''){
      this.minDate = new Date();
      this.maxDate = new Date();
      this.minDate.setDate(new Date().getDate() - 60);
      this.maxDate.setDate(new Date().getDate());
    } 
    else if(params.itemMasterPending || params.itemMasterPending === null || params.itemMasterPending === ''){
      this.minDate = new Date();
      this.minDate.setDate(new Date().getDate());
    }
    else if(params.atPort || params.atPort === null || params.atPort === ''){
      this.minDate = new Date();
      this.minDate.setDate(new Date().getDate() - 30);
    }
    else {
      this.minDate = new Date();
      this.maxDate = new Date();
      this.minDate.setDate(new Date().getDate() - 60);
      this.maxDate.setDate(new Date().getDate() + 60);
    }
  }

  dateChanged(event:any) {
    if(event) {
      if(!this.params.isContainerLocation && !this.params.fromItemMaster) {
        if(event > new Date()){
          this.toastrService.warning('The event entered is scheduled for a future date. Please double-check the date before proceeding.')
        }
        
      }
    }
  }
  getValue(): any {
    return this.value;
  }
  isPopUp(): boolean {
    return false;
  }
  refresh(params: any): boolean {
    return true;
  }
  ngOnInit(): void {
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue',adaptivePosition: true, isAnimated: true });
    this.setInitialState(this.params);
  }
  
  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE) {
      startValue = '';
    } else if (params.charPress) {
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      startValue = params.value;
      if (params.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }
  onKeyDown(event: any): void {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (
      !this.finishedEditingPressed(event)    
    ) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
      if (this.highlightAllOnFocus) {
        this.input.element.nativeElement.select();

        this.highlightAllOnFocus = false;
      } else {
        const length = this.input.element.nativeElement.value
          ? this.input.element.nativeElement.value.length
          : 0;
        if (length > 0) {
          this.input.element.nativeElement.setSelectionRange(length, length);
        }
      }
      this.input.element.nativeElement.focus();
    });
  }
  private deleteOrBackspace(event: any) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
  onClicked(): void {
    this.params.api.startEditingCell({
      rowIndex: this.params?.rowIndex,
      colKey: this.params?.column?.colId
    });

  }

  clearDate() {
    this.value = null;
  }

  onDateSelected(value: Date): void {
    of(this.params.node.setDataValue(this.params.colDef.field, value)).pipe(
      takeUntil(this.destroy$$),
      filter(()=> !!this.params.tirggerCellChangeEventOnDateSelect),
      switchMap(() => {
        const event = new CustomEvent('cellValueChanged', {
          detail: {
            oldValue: this.params.value,
            newValue: value,
            column: this.params.column,
            rowIndex: this.params.rowIndex,
            node: this.params.node
          }
      })
      return of(event)
    }),
      tap(() => {
        setTimeout(()=> {
          this.params.api.clearFocusedCell()
        },100)
      }),
      tap((event) => this.params.api.dispatchEvent(event)),
   ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}