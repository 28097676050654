<ng-template #docItemUploadedDialogue>
  <div id="modalDrag" cdkDrag>
    <div class="modal-header d-flex justify-content-between align-items-center bg-gray-700 px-4 pt-4 pb-0" cdkDragHandle>
        <h2 class="m-0">{{config.title}}</h2>
        <button class="bg-transparent border-0 p-0" *ngIf="(isLoading$ | async) === false" (click)="onCancel()">
            <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
            <span class="visually-hidden">Close</span>
        </button>
      
    </div>
    <div class="heading-border px-4">
        <hr>
    </div>
     
    <div class="modal-body d-flex flex-grow-1 flex-column">
        <div>
            <table class="table  table-borderless">
                <thead>
                    <tr>
                        <th class="label text-purple">PO #</th>
                        <th class="label text-purple">Item #</th>
                        <th class="label text-purple">Color</th>
                        <th class="label text-purple">Size</th>
                        <th class="label text-purple">Document Type</th>
                        <th class="label text-purple">Documents</th>
                    </tr>
                </thead>
               
                <tbody>
                    <tr *ngFor="let doc of config?.data">
                        <td>{{doc?.poNumber}}</td>
                        <td>{{doc?.itemNumber}}</td>
                        <td>{{doc?.color}}</td>
                        <td>{{doc?.size}}</td>
                        <td>{{doc?.bookingDocType}}</td>
                        <td>{{doc?.bookingDocName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success fw-medium" [disabled]="isLoading$ | async" (click)="onCancel()">Done</button>
    </div>
  </div>
</ng-template>