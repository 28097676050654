<ng-template #template>
	<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	  <div class="modal-header p-3">
		<h2 class="m-0">Print Ship Advice</h2>
		<button class="bg-transparent border-0 p-0" (click)="closeModal()">
		  <span class="material-icons mi-24 text-gray-200" aria-hidden="true">close</span>
		  <span class="visually-hidden">Close</span>
		</button>
	  </div>
	  <div class="modal-dialog modal-dialog-centered modal-xl mb-0">
		<div class="modal-content">
		  <div class="modal-body">
			<form class="row g-2">
			  <h3 class="text-purple mb-0 pb-5">{{this.selectedNodes}} {{this.selectedNodes > 1 ? 'Records to be Printed' : 'Record to be Printed' }}</h3>
			  <div class="col">
				<div class="form-check form-switch">
				  <input class="form-check-input" type="checkbox" id="removeForMe" [checked]="offForMe" (change)="offFor($event,'offForMe')">
				  <label class="form-check-label" for="removeForMe">Acknowledge Off List</label>
				</div>
			  </div>
			</form>
		  </div>
		  <div class="modal-footer">
			<button type="button" class="btn btn-cancel" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
			<button type="button" class="btn btn-submit" (click)="print()">{{printStatus ? 'Printing' : 'Print'}}</button>
		  </div>
		</div>
	  </div>
	</div>
  </ng-template>