/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, enableProdMode, Input, OnDestroy, Renderer2, ViewContainerRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@cds-ui/shared/auth';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import { MainMenuService } from '@cds-ui/shared/ui-kit';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { startWith, map, Subject, filter, tap, delay, timer, take, takeUntil, switchMap, interval, takeWhile, timeout, Subscription, debounce, debounceTime, fromEvent, combineLatest, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { Environment } from '@cds-ui/shared/core-state';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { filterNullish } from '@cds-ui/shared/core-rx';
import { CompanySelectorComponent } from 'libs/shared/ui-kit/src/lib/company-selector/company-selector.component';

declare let pendo: any;
export var uiConfig: Environment;

@Component({
  selector: 'cds-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  @Input() logoSrc = './assets/img/logo.svg';

  private destroy$: Subject<void> = new Subject();
  
  public reportName: string;
  public isAuth$ = this.oidcSecurityService.isAuthenticated$.pipe(
    map((x) => x.isAuthenticated),
    startWith(false)
  );
  public useScrollLock$ = this.appState.useScrollLock$.pipe(startWith(false));
  public showHeader$ = this.appState.showHeader$.pipe(startWith(true));
  public showLogoOnly$ = this.appState.showLogoOnly$.pipe(startWith(false));
  public useFlex$ = this.appState.useFlex$;
  public showContent = !!['/callback', '/diag/'].find(
    (path) =>
      window.location.pathname.replace('/ui', '').toLowerCase().indexOf(path) >=
      0
  );

  constructor(
    private appState: AppStateRepository,
    public authservice: AuthService,
    private oidcSecurityService: OidcSecurityService,
    public router: Router,
    private mainMenuService: MainMenuService,
    private title: Title,
    private renderer: Renderer2,
    private http: HttpClient,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.authservice.initToken();
    this.setupAppState();
    this.setupPendo();

    this.appState.appTheme$.pipe(
      tap(x => window.localStorage.setItem('appTheme', x)),
      tap(x => 
        x === 'dark'
          ? this.renderer.removeClass(document.body, 'light-theme')
          : this.renderer.addClass(document.body, 'light-theme')
      )
    )
    .subscribe();

    
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setupAppState() {
    this.setUiConfig();

    this.authservice.user$.pipe(
      filterNullish(),
      tap(user => this.appState.setCurrentUser(
          {
              userId: user.username,
              subgroupCode: user.subgroup_code,
              orginalUserId: user.orginal_username,
              orginalSubgroupCode: user.orginal_subgroup
          }
      ))
    )
    .subscribe()

    // The following has been moved from the default
    // location in main.ts because Prod Mode needs to
    // be set after config is read from the assets folder.
    if (environment.production) {
      enableProdMode();
    }

    this.appState.setEnvironment(environment);
    this.appState.loadPermission();

    this.appState.useFlex$.subscribe((x) => {
      const bodyElement = document.body;
      x
        ? bodyElement.classList.remove('mb-1')
        : bodyElement.classList.add('mb-1');
    });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        map((e) => e as NavigationStart),
        tap(_ => sessionStorage.removeItem('GridUserPreference')),      
        tap(_ => this.appState.setUsingBuyerFilter(false)),
        tap(_ => this.appState.setNonFilteredRequest([])),
        tap(_ => this.appState.toggleLogoOnly(false)),
        tap(_ => this.appState.toggleShowHeader(true)),
        tap(_ => this.appState.useFlex(true)),
        tap(_ => this.appState.useScrollLock(false)),
        tap(e => this.setTabTitle(e)),
        tap(_ => this.toggleEmbedMode())
      )
      .subscribe();
  }



  private toggleEmbedMode() {
    const standAlone = window === window.top;

    this.appState.toggleShowHeader(standAlone);

    if(standAlone) return;

    this.appState.setThemeMode('light');

    interval(100).pipe(
      map(_ => document.querySelector('router-outlet')?.nextElementSibling),
      filterNullish(),
      take(1),
      switchMap(x => 
        interval(100).pipe(
          filter(_ => !!x.querySelector('div')),
          take(1),
          map(_ => x)
        )
      ),
      map(x => x.querySelector('h1')),
      map(x => {
        let checkingElement : HTMLElement | null = x;

        while (checkingElement) {
            if (checkingElement.classList.contains("row")) {
                return checkingElement;
            }
            checkingElement = checkingElement.parentElement;
        }

        return null;
      }),
      filter(x => !!x && !document.getElementById('companySelectorWrapper')),
      take(1),
      tap(x => {
        if(standAlone) return;
        
        const wrapper = document.createElement("div");
        wrapper.id = "companySelectorWrapper"
        wrapper.classList.add("col", "d-flex", "mx-auto", "justify-content-end", "gap-2");
        x?.appendChild(wrapper);
        
        const componentRef = this.viewContainerRef.createComponent(CompanySelectorComponent);
        wrapper.appendChild(componentRef.location.nativeElement);
        
      })
    )
    .subscribe();
  }

  private setUiConfig() {
    var configUrl = new URL("assets/env/environment.json", document.baseURI).href;
    this.http.get<Environment>(configUrl).subscribe((data: Environment) => {
      uiConfig = data;
    });
  }

  setTabTitle(e:any){
    if(window.localStorage.getItem('reportAnalyticsData')){
        this.reportName = JSON.parse(window.localStorage.getItem('reportAnalyticsData') as string).reportNameFull;
    }
    if(e.url.includes('/dashboard')){
      this.title.setTitle('VIZIV | My Day');
    }else if(e.url === '/map/supply-chain-map'){
      this.title.setTitle('VIZIV | Supply Chain Map');
    }else if(e.url === '/purchase-order/overview'){
      this.title.setTitle('VIZIV | Purchase Orders');
    }else if(e.url.includes('/purchase-order/view')){
      this.title.setTitle('VIZIV | PO Details');
    }else if(e.url.includes('/booking') && e.url.includes('/overview')){
      this.title.setTitle('VIZIV | Bookings');
    }else if(e.url.includes('/booking/view')){
      this.title.setTitle('VIZIV | Booking Details');
    }else if(e.url === '/container/overview' || e.url.includes('/container/overview?filter')){
      this.title.setTitle('VIZIV | Containers');
    }else if(e.url.includes('/container/view')){
      this.title.setTitle('VIZIV | Container Details');
    }else if(e.url === '/shipping/overview'){
      this.title.setTitle('VIZIV | Shipments');
    }else if(e.url.includes('/shipping/view')){
      this.title.setTitle('VIZIV | Shipment Details');
    }else if(e.url === '/inbound-planning/overview'){
      this.title.setTitle('VIZIV | Inbound Planning');
    }else if(e.url === '/vibe/vibe-overview'){
      this.title.setTitle('VIZIV | VIBE Exceptions');
    }else if(e.url.includes('/legacy/pagelayout?moduleCd=Message&configName=MessageInbox')){
      this.title.setTitle('VIZIV | Messages');
    }else if(e.url === '/analytics/analytics-library' || e.url.includes('/analytics/analytics-collection-dashboard')){
      this.title.setTitle('VIZIV | Analytics');
    }else if(e.url === '/analytics/report-viewer'){
      this.title.setTitle(`VIZIV | Analytics | ${this.reportName}`);
    }else if(e.url === '/vibe/vibe-details'){
      this.title.setTitle('VIZIV | VIBE Exception Edit');
    }else if(e.url === '/ghosting/ghosting-confirmation'){
      this.title.setTitle('VIZIV | Ghosting Confirmation');
    }else if(true){
      this.title.setTitle('VIZIV');
    }
  }

  setupPendo() {
    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd &&
            !window.location.href.includes('localhost')
        ),
        map((e) => e as NavigationEnd)
      )
      .subscribe((e) => {
        if (e instanceof NavigationEnd && !_.includes(e.url, '/callback')) {
          this.mainMenuService.getPendoInfo().subscribe((response) => {
            const pendoInfo = {
              visitor: {
                id: response.originalUserId,
                subgroupCd: response.originalSubgroupCd,
                ghostId: response.currentUserId,
                ghostSubgroupCd: response.currentSubgroupCd,
                firstName: response.firstName,
                lastName: response.lastName,
                persona: response.persona,
                compCd: response.compCode,
                accountCreateDate: response.createDate
              },
              account: { id: response.compName }
            };
            pendo.initialize(pendoInfo);
          });
        }
      });
  }
}
