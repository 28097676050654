export const menuMapping: { [subscription: string] : { seq: number, group?: string, name: string, url: string } } = 
{ 
    "my-day": { seq:0 , name: "My Day", url: "dashboard/portal" },
    "supply-chain-map:action:vessel:list": { seq:1, name: "Supply Chain Map", url: "map/supply-chain-map" },
    "purchase-order:action:purchase-order:list": { seq:2, group: "Order Management", name: "Purchase Orders", url: "purchase-order/overview" },
    "purchase-order:action:purchase-order-management:list": { seq:3, group: "Order Management", name: "Purchase Order Management", url: "purchase-order/poi-poc/overview" },
    "booking:action:booking:list": { seq:3, name: "Bookings", url: "booking/portal/overview" },
    "container:action:container:list": { seq:4, group:"Shipment", name: "Containers", url: "container/overview" },
    "shipment:action:shipment:list": { seq:5, group:"Shipment", name: "Shipments", url: "shipping/overview" },
    "inbound-planning:action:plan:list": { seq:6, group:"Shipment", name: "Inbound Planning", url: "inbound-planning/overview" },
    "item-master:action:item:list" : { seq:7, group:"Compliance", name: "Item Management", url: "item/item-master-summary-detail" },
    "analytics": { seq:8, name: "Analytics", url: "analytics/analytics-library" },
    "transload:action:transload:list": { seq:8, group:"Shipment", name: "Transload", url: "transload/overview" },
    "analyticsLibrary": { seq:8, group:"Analytics", name: "Analytics Library", url: "analytics/analytics-library" },
    "analyticsReporting": { seq:9, group:"Analytics", name: "Reporting", url: "analytics/report/library" }
};