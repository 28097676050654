import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppStateRepository } from '@cds-ui/shared/core-state';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, ReplaySubject, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
//import { ActivityPanel } from '../activity-panel.component';
import {  ActivityPanelViewMoreModalService } from './activity-panel-view-more-detail-modal.service';

@Component({
  selector: 'cds-activity-panel-view-more-detail-modal',
  templateUrl: './activity-panel-view-more-detail-modal.component.html',
  styleUrls: ['./activity-panel-view-more-detail-modal.component.scss'],
})
export class ActivityPanelViewMoreDetailModalComponent implements OnInit, OnDestroy {
  private destroy$$ = new Subject<void>();
  private dataSubject$$ = new ReplaySubject<any[]>(1);
  public  data$ = this.dataSubject$$.asObservable();
  private modalRef$$ = new ReplaySubject<BsModalRef>(1);
  public isLoading$ = this.appState.isLoading$;
  @ViewChild('template') template!: TemplateRef<any>;

  constructor(
    private modalservice: BsModalService,
    private vmService: ActivityPanelViewMoreModalService,
    private appState: AppStateRepository
  ) {}

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  ngOnInit(): void {
    this.subscribeBindGridDataSingal();
    this.subscribeShowSingal();
    this.subscribeHideSingal();
  }
  private subscribeBindGridDataSingal() {
    this.vmService.onShow$.pipe(
      takeUntil(this.destroy$$),
      withLatestFrom(this.vmService.activityPanelData$$),
      tap(([_, x])=> this.dataSubject$$.next(x))
      )
    .subscribe();
  }
  private subscribeShowSingal() {
    this.vmService.onShow$
    .pipe(takeUntil(this.destroy$$),
     map(_ => this.modalservice.show(
      this.template, 
      {
        backdrop: false,
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-dialog-centered modal-xl'
      })),
    tap(x => this.modalRef$$.next(x))
    ).subscribe()
  }
  
  dateFormatter(date: any) {
      return date?moment.utc(date).format('MM/DD/YYYY'): null;
  }
  public onCancel() {
    this.vmService.cancel();
  }

  private subscribeHideSingal() {
    this.vmService.onCancel$.pipe(
      takeUntil(this.destroy$$),
      withLatestFrom(this.modalRef$$),
      tap(([_, ref]) => ref.hide()),
      tap(([_, __]) => this.dataSubject$$.next([])),
    )
    .subscribe();
  }
}
