export const inboundPlanningDescriptions = {
  inboundToPort : 'Shipments arriving in 14 days',
  atDischargePort : 'Shipments arrived at port',
  inboundToDC : 'Shipments outgated from Terminal',
  inboundToDCTrailers : 'Shipments departed transload facility'
}

export interface InboundPlanningGridData {
  uniqueId: string,
  vesselCode: string,
  voyageNumber: string,
  carrierCode: string,
  containerNumber: string,
  masterBlNumber: string,
  houseBlNumber: string,
  containerSize: string,
  sealNumber: string,
  deliveryDc: string,
  deliveryDcName: string,
  ddp: boolean,
  hotFlag: boolean,
  vaActualVesselArrive: string,
  uvVesselUnloaded: string,
  oaOutGate: string,
  assignedTrucker: string,
  dContainerDelivered: string,
  d1ContainerUnloaded: string,
  emEmptyNoticeToTrucker: string,
  rdEmptyReturn: string,
  baCustomsCleared: string,
  shipKey: string,
  volume: number,
  weight: number,
  status: string,
  comments: string
}

export interface InboundPlanningDC {
  deliveryDc: string,
  deliveryDcName: string,
  inboundCount: number
}

export interface InboundPlanningTallyCount {
  milestoneKeyCount: number,
  milestoneKeyName: string,
  atRiskDemurrage:number,
  atRiskDetention:number,
  inDemurrage:number,
  inDetention:number,
}

export interface InboundPlanning {
  dcInbound: InboundPlanningDC[];
  inbound: InboundPlanningGridData[];
  inboundTallyCount: InboundPlanningTallyCount[];
}

export interface QuickMenuData {
  tallyCardName: string;
  menuList: MenuName[]
}

export interface MenuName {
  menuName: string;
  permissionKey: string;
}

export interface InlandTrucker {
  inlandTrucker: string;
  carrierCode: string;
}

export interface FinalDeliveryLocation {
  finalDeliveryLocation: string;
}
