export const LanguageResourceModule = {
    "containerNumber": "Container #",
    "status": "Status",
    "docs": "Docs",
    "containerSize": "Container Size",
    "originPort": "Origin Port",
    "estOriginDepart": "Est Origin Depart",
    "motherPort": "Mother Port",
    "estMotherDepart": "Est Mother Depart",
    "actMotherDepart": "Act Mother Depart",
    "dischargePort": "Discharge Port",
    "estDischarge": "Est Discharge",
    "actDischarge": "Act Discharge",
    "estFinalDestination": "Est Final Destination",
    "lastEvent": "Last Event",
    "lastEventDate": "Last Event Date",
    "lastEventLocation": "Last Event Location",
    "freightType": "Freight Type",
    "shipMode":"Shipping Mode",
    "carrier": "Carrier",
    "vessel": "Vessel",
    "voyageNumber": "Voyage #",
    "mblNumber": "MBL #",
    "sealNumber": "Seal #",
    "hblNumber": "HBL #",
    "deliveryDc": "Final Dest",
    "distributionCenter": "DC",
    "ddp": "DDP",
    "hotFlag": "Hot",
    "vesselUnloaded": "Vessel Unloaded",
    "outGate": "Out Gate",
    "assignedTrucker": "Assigned Trucker",
    "inYard": "In Yard",
    "containerDelivered": "Container Delivered",
    "containerUnloaded": "Container Unloaded",
    "emptyNotice": "Empty Notice",
    "emptyReturn": "Empty Return",
    "customsCleared": "Customs Cleared",
    "shipKey": "Ship Key",
    "volume": "Volume (CBM)",
    "weight": "Weight (KG)",
    "comments": "Comments",
    "estArrival": "Est Final Destination",
    "actVesselArrival": "Act Vessel Arrival",
    "vesselArivePort": "Vessel Arrive Port",
    "estVesselArrive": "Est Vessel Arrive",
    "estFinalDestinationUpdatedEta": "Final Destination Updated ETA",
    "finalDestination": "Final Destination",
    "poCount": "PO Count",
    "vendor": "Vendor",
    "qty": "Qty",
    "cartons": "Cartons",
    "volumeUnitOfMeasure": "Volume Unit",
    "weightUnitOfMeasure": "Weight Unit",
    "PurchaseOrderNumber": "PO #",
    "item": "Item #",
    "poNumber": "PO #",
    "vendors": "Vendor",
    "itemDescription": "Description",
    "quantity": "Qty",
    "demurrageMessage": "Demurrage",
    "demurrageTotal": "Est Demurrage",
    "detentionMessage": "Detention",
    "detentionTotal": "Est Detention",
    "unitCost": "Unit Cost",
    "costOfGoodsSold":"Cost Of Goods Sold",
    "milestone":'Milestone',
    "inlandTrucker":"Inland Trucker",
    "finalDeliveryLocation":"Final Delivery Location",
    "senttoTrucker":"Sent to Trucker",
    "bLDestination":"BL Destination",
    "departureDate":'Departure Date',
    "estArrivalDate":'Est Arrival Date',
    "finalArriveUpdatedETA":'Final Arrive Updated ETA',
    "actVesselArrive": "Act Vessel Arrive",
    "commercialInvoiceNumber": "Commercial Invoice #",
    "Received204":"204 Received",
    "Updated204":"204 Updated",
    "DCM204Status":"DCM 204 Status"
}





