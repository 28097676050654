import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, filter } from 'rxjs';

export interface AdvanceSearchEventModalConfig {
    title: string,
    message?: string,
    btnOne?:string,
    btnTwo?:string
}

export enum AdvanceSearchDialogueEvent {
    OnShow,
    OnShown,
    OnHide,
    OnHidden,
    OnClosingRemove,
    OnClosingRemoved   
}

interface AdvanceSearchModalEvent {
    type: AdvanceSearchDialogueEvent,
    params?: any
}

@Injectable({ providedIn: 'root' })

export class AdvanceSearchModalService implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  private event$: Subject<AdvanceSearchModalEvent> = new Subject();
  private showSubject$ = new Subject();
  public saveDialogue$ = new Subject();
  public isDirty = false;

  public show$: Observable<unknown> = this.showSubject$.pipe(takeUntil(this.destroy$));
  public onShow$ = this.event$.pipe(filter((x) => x.type === AdvanceSearchDialogueEvent.OnShow));
  public onShown$ = this.event$.pipe(filter((x) => x.type === AdvanceSearchDialogueEvent.OnShown));
  public onHide$ = this.event$.pipe(filter((x) => x.type === AdvanceSearchDialogueEvent.OnHide));
  public onHidden$ = this.event$.pipe(filter((x) => x.type === AdvanceSearchDialogueEvent.OnHidden));
  public onClosingAdvanceSearchRemove$ = this.event$.pipe(filter((x) => x.type === AdvanceSearchDialogueEvent.OnClosingRemove));
  public onClosingAdvanceSearchRemoved$ = this.event$.pipe(filter((x) => x.type === AdvanceSearchDialogueEvent.OnClosingRemoved));

  show(config: AdvanceSearchEventModalConfig): void {
    this.showSubject$.next(config);
  }

  handle(event: AdvanceSearchModalEvent): void {
    this.event$.next(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setClosingAdvanceSearch(params?: any) {
    this.event$.next({
      type: AdvanceSearchDialogueEvent.OnClosingRemove,
      params: params
    });
  }

  setClosingAdvanceSearchRemoved() {
    this.event$.next({
      type: AdvanceSearchDialogueEvent.OnClosingRemoved,
    });
  }
}